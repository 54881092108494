import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";
import firebase from "firebase/app";
import "firebase/auth";
import initFirebase from "../../firebase";

initFirebase();

const SignOut = () => {
  const setting = useSettings();
  const history = useHistory();

  firebase
    .auth()
    .signOut()
    .then(() => {
      setting.isLogin = false;
      setting.email = "";
      setting.userName = "";
      setting.id = "";
      setting.token = "";
      setting.isSnsLogin = false;
      window.localStorage.setItem("token", "");
      history.push("/mypage/signin");
      swal("로그아웃 되었습니다");
    })
    .catch((error) => {
      console.log("error : ", error);
    });

  return "";
};

export default SignOut;
