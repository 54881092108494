import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import { useQuery, useMutation } from "@apollo/client";
import swal from "@sweetalert/with-react";
import MyPage from "./MyPage";
import { ALL_PRODUCTS, CREATE_CMS } from "../../graphql/gql/select";

const ScreenAdmin = ({ category, name }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const setting = useSettings();
  const memberId = setting.id;

  console.log(`memberId : ${memberId}`);
  console.log(`category : ${category}  , name : ${name}`);

  const [createCms, { data: createdData }] = useMutation(CREATE_CMS, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (createdData !== undefined) {
      const data1 = createdData.createCms;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        switch (category) {
          case "home":
            setting.cmsHomeUpdates = true;
            break;
          case "about":
            setting.cmsAboutUpdates = true;
            break;
          case "hangul":
            setting.cmsHangulUpdates = true;
            break;
          case "ground":
            setting.cmsGroundUpdates = true;
            break;
          case "tv":
            setting.cmsTVUpdates = true;
            break;
          case "category":
            setting.cmsCategoryUpdates = true;
            break;
          default:
            setting.cmsHomeUpdates = true;
            break;
        }
      }
    }
  }, [createdData]);

  const createCmsHandler = ({ category, name, img, no, targetUrl, targetId, title, contents, visible }) => {
    if (targetUrl) {
      console.log("targetUrl: ", targetUrl);
      createCms({
        variables: {
          category,
          name,
          img,
          no,
          targetUrl,
          targetId,
          title,
          contents,
          visible,
        },
      });
    } else {
      swal("이동URL : 빈 값이 올 수 없습니다", { button: false, timer: 3000 });
    }
  };

  console.log("setting.allProductsUpdates: ", setting.allProductsUpdates);

  const { loading, error, data } = useQuery(ALL_PRODUCTS, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  console.log("data , data.allProducts: ", data, data.allProducts);
  if (!data || !data.allProducts) return <p>Error</p>;
  const items = data.allProducts;

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("상품을 선택해 주세요")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <Header />
            <div className="grayline" />
            {memberId === undefined && <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>}
            {memberId !== undefined &&
              memberId !== "" &&
              items &&
              items.length > 0 &&
              items.map((item) => (
                <div className="w-full flex-row-center table-text" key={item.id}>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.category}</div>
                  </div>
                  <div className="w-2/20 ">
                    <div className="w-full text-center">{item.model}</div>
                  </div>
                  <div className="w-4/20 ">
                    <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                      <img src={item.mainImage} className="w-12 h-12 border mx-auto" alt="" />
                    </div>
                  </div>

                  <div className="w-5/20 text-center">{item.name}</div>
                  <div className="w-2/20 text-center">{item.price}</div>
                  <div className="w-2/20 text-center">{item.brand}</div>
                  <div className="w-2/20 text-center flex-center">
                    <button
                      className="w-24 h-8  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1"
                      onClick={() => {
                        const currentItem = {
                          category,
                          name,
                          img: item.mainImage,
                          no: "",
                          targetUrl: "product",
                          targetId: item.id,
                          title: item.name,
                          contents: item.name,
                          visible: true,
                        };
                        createCmsHandler(currentItem);
                      }}
                    >
                      {t("추가")}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-4 flex-col-left">
          <div className="mt-3 h-8 flex flex-row table-text relative">
            <div className="absolute right-0 w-96 h-8 ml-4 flex flex-row">
              <button
                className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                onClick={() => history.push(`/mypage/cms/${category}`)}
              >
                돌아가기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectProduct = ({ match }) => {
  const setting = useSettings();
  const { category, name } = match.params;

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin category={category} name={name} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default SelectProduct;

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/20 text-center">{t("카테고리")}</div>
      <div className="w-2/20 text-center">{t("모델명")}</div>
      <div className="w-4/20 text-center">{t("대표이미지")}</div>
      <div className="w-5/20 text-center">{t("상품명")}</div>
      <div className="w-2/20 text-center">{t("가격")}</div>
      <div className="w-2/20 text-center">{t("브랜드")}</div>
      <div className="w-2/20 text-center">{t("선택")}</div>
    </div>
  );
};
