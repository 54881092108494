import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MyPage from "./MyPage";
import template_basic from "../../assets/images/template_basic.svg";
import template_retro from "../../assets/images/template_retro.svg";
import template_lovely from "../../assets/images/template_lovely.svg";
import template_calligraphy from "../../assets/images/template_calligraphy.svg";
import template_graphic from "../../assets/images/template_graphic.svg";
import template_logo_style from "../../assets/images/template_logo_style.svg";

const templateItems = [
  {
    img: template_basic,
    templateName: "베이직",
    templateUrl: "basic",
  },
  {
    img: template_retro,
    templateName: "레트로",
    templateUrl: "retro",
  },
  {
    img: template_lovely,
    templateName: "러블리",
    templateUrl: "lovely",
  },
  {
    img: template_calligraphy,
    templateName: "켈리그라피",
    templateUrl: "calligraphy",
  },
  {
    img: template_graphic,
    templateName: "그래픽&타이포",
    templateUrl: "graphic",
  },
  {
    img: template_logo_style,
    templateName: "로고스타일",
    templateUrl: "logo_style",
  },
];

const Screen = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">ALL DESIGN</div>
      <div className="bg-black mt-1" style={{ height: 2 }} />
      <div className="mt-4  flex flex-row flex-wrap ">
        {templateItems &&
          templateItems.length > 0 &&
          templateItems.map((item) => (
            <div className="flex-col-center mr-4 mb-4" key={item.templateName}>
              <img
                className="w-32 h-32 "
                src={item.img}
                alt=""
                onClick={() => {
                  history.push(`/mypage/all_designs/${item.templateUrl}`);
                }}
              />
            </div>
          ))}
        {templateItems.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const AllDesign = () => {
  return (
    <div>
      <MyPage>
        <Screen />
      </MyPage>
    </div>
  );
};

export default AllDesign;
