import React, { useState, useEffect } from "react";
import { SIGNUP, SIGNUP_ADMIN } from "graphql/gql/select";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import swal from "@sweetalert/with-react";
import CheckBox from "../../components/CheckBox";

import firebase from "firebase/app";
import "firebase/auth";
import initFirebase from "../../firebase";

initFirebase();

const auth = firebase.auth();

const imgboxStyle = {
  width: "100% ",
  height: "100% ",
};

const ScreenAdmin = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [signupAdmin, { data }] = useMutation(SIGNUP_ADMIN, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.signupAdmin;
      console.log(data1);
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
        history.push("/mypage/signin");
      }
    }
  }, [data]);

  // 로그인 버튼 클릭시, 필드 검사 후 login 뮤테이션 호출
  const handlerSignUpClick = () => {
    if (!email || !name || !password) {
      swal(t("이름, 이메일이나 암호를 입력해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    signupAdmin({ variables: { name, email, password } });
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen font-mont  text-xs flex-col-center">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white shadow-lg">
          <div className="pt-8 mx-8 mb-2 flex flex-row items-center justify-around">
            <p className="font-mont text-black text-sm font-bold text-center ">
              {t("회원가입")}
            </p>
            <Link to="/mypage/signin">
              <p className="font-mont text-black text-sm text-center ">
                {t("로그인")}
              </p>
            </Link>
          </div>
          <div className="mx-8 flex flex-row items-center justify-around">
            <p className="w-1/2 bg-black  height-2" />
            <p className="w-1/2 bg-gray height-1" />
          </div>
          <div className="mx-8 mt-4 mb-4">
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이름")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="w-full px-2 py-2  outline-none"
              placeholder={t("암호")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="h-10 mx-8 mt-8 mb-4 flex flex-wrap items-center justify-center bg-black">
            <div
              className="w-full btn-text  text-center text-white font-bold py-2 px-4 my-auto"
              onClick={handlerSignUpClick}
            >
              {t("회원 가입")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Screen = () => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [emailMessage, setEmailMessage] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);

  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [signup, { data }] = useMutation(SIGNUP, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  const signUpFunction = async () => {
    if (!checkEmail) {
      swal(t("Invalid Email"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    if (!checkPassword) {
      swal(
        t(
          "Please enter a password with at least 8 characters including special characters and numbers."
        ),
        {
          button: false,
          timer: 3000,
        }
      );
      return;
    }
    if (!agree1 || !agree2) {
      swal(t("이용약관과 개인정보수집에 대해 동의해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    if (!email || !name || !password) {
      swal(t("이름, 이메일이나 암호를 입력해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    try {
      const result = await auth.createUserWithEmailAndPassword(email, password);
      sendVerificationEmail();
      swal("email 인증 요청", { button: false, timer: 3000 });
      console.log("result : ", result);
      const { user } = result;
      signup({
        variables: {
          name,
          email,
          password: user.uid,
          snsProvider: "email",
          img: "",
        },
      });
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        swal("The email address is already in use by another account", {
          button: false,
          timer: 3000,
        });
        // setEmail("");
        // setName("");
        // setPassword("");
        setAgree1(false);
        setAgree2(false);
      }
    }
  };

  const sendVerificationEmail = async () => {
    try {
      auth.currentUser.sendEmailVerification();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      history.push("/mypage/signin");
    }
  }, [data]);

  useEffect(() => {
    const pattern1 = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!pattern1.test(email)) {
      if (email === "") {
        setEmailMessage("");
      } else {
        setEmailMessage("Invalid Email");
      }
    } else {
      setCheckEmail(true);
    }
  }, [email]);

  useEffect(() => {
    const pattern1 = /[0-9]/;
    const pattern2 = /[a-zA-Z]/;
    const pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;

    if (
      !pattern1.test(password) ||
      !pattern2.test(password) ||
      !pattern3.test(password) ||
      password.length < 8
    ) {
      if (password === "") {
        setPasswordMessage("");
      } else {
        setPasswordMessage(
          "Please enter a password with at least 8 characters including special characters and numbers."
        );
      }
    } else {
      setPasswordMessage("");
      setCheckPassword(true);
    }
  }, [password]);

  // 로그인 버튼 클릭시, 필드 검사 후 login 뮤테이션 호출
  const handlerSignUpClick = () => {
    if (!checkEmail) {
      swal(t("Invalid Email"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    if (!checkPassword) {
      swal(
        t(
          "Please enter a password with at least 8 characters including special characters and numbers."
        ),
        {
          button: false,
          timer: 3000,
        }
      );
      return;
    }
    if (!agree1 || !agree2) {
      swal(t("이용약관과 개인정보수집에 대해 동의해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    if (!email || !name || !password) {
      swal(t("이름, 이메일이나 암호를 입력해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    signup({ variables: { name, email, password } });
  };

  const handleSocialLogin = (user) => {
    console.log(user);
  };

  const handleSocialLoginFailure = (error) => {
    console.error(error);
  };

  const handleEmailAuthentication = async () => {
    try {
      console.log("email", email);
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      console.log("user : ", result);
      const { user } = result;
      const { uid } = user;
      console.log("uid:", uid);
      const email2 = user.email;
      // const uid = user.uid;
      signup({
        variables: {
          name,
          email: email2,
          password: uid,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen font-mont  text-xs flex-col-center">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white shadow-lg">
          <div className="pt-8 mx-8 mb-2 flex flex-row items-center justify-around">
            <p className="font-mont text-black text-sm font-bold text-center ">
              {t("회원가입")}
            </p>
            <Link to="/mypage/signin">
              <p className="font-mont text-black text-sm text-center ">
                {t("로그인")}
              </p>
            </Link>
          </div>
          <div className="mx-8 flex flex-row items-center justify-around">
            <p className="w-1/2 bg-black  height-2" />
            <p className="w-1/2 bg-gray height-1" />
          </div>
          <div className="mx-8 mt-4 mb-4">
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이름")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
            <div className="mt-1">{emailMessage}</div>
          </div>
          <div className="mx-8 mb-4">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="w-full px-2 py-2  outline-none"
              placeholder={t("암호")}
            />
            <p className="w-full bg-black  height-1" />
            <div className="mt-1">{passwordMessage}</div>
          </div>
          <div className="mx-8 mb-8">
            <div
              className="flex flex-row items-center mb-2 font-mont "
              onClick={() => setAgree1(!agree1)}
            >
              <CheckBox isCheck={agree1} />
              <div className="text-2xs ml-2">{t("이용약관 동의")}</div>
              <div className="text-2xs ml-4 underline ">{t("보기")}</div>
            </div>
            <div
              className="flex flex-row items-center"
              onClick={() => setAgree2(!agree2)}
            >
              <CheckBox isCheck={agree2} />
              <div className="text-2xs ml-2">
                {t("개인정보 수집 및 이용에 대해 동의")}
              </div>
              <div className="text-2xs ml-4 underline ">{t("보기")}</div>
            </div>
          </div>
          <div className="h-10 mx-8 mt-8 mb-8 flex flex-wrap items-center justify-center bg-black">
            <div
              className="font-mont w-full btn-text  text-center text-white font-bold py-2 px-4 my-auto"
              // onClick={handlerSignUpClick}
              onClick={signUpFunction}
            >
              {t("회원 가입")}
            </div>
          </div>
          {/* social login */}
        </div>
      </div>
    </div>
  );
};

const ScreenMobile = () => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();

  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);

  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [signup, { data }] = useMutation(SIGNUP, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  const signUpFunction = async () => {
    if (!checkEmail) {
      swal(t("Invalid Email"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    if (!checkPassword) {
      swal(
        t(
          "Please enter a password with at least 8 characters including special characters and numbers."
        ),
        {
          button: false,
          timer: 3000,
        }
      );
      return;
    }
    if (!agree1 || !agree2) {
      swal(t("이용약관과 개인정보수집에 대해 동의해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    if (!email || !name || !password) {
      swal(t("이름, 이메일이나 암호를 입력해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    try {
      const result = await auth.createUserWithEmailAndPassword(email, password);
      sendVerificationEmail();
      swal("email 인증 요청", { button: false, timer: 3000 });
      console.log("result : ", result);
      const { user } = result;
      signup({
        variables: { name, email, password: user.uid, snsProvider: "email" },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sendVerificationEmail = async () => {
    try {
      auth.currentUser.sendEmailVerification();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      history.push("/mypage/signin");
    }
  }, [data]);

  useEffect(() => {
    const pattern1 = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!pattern1.test(email)) {
      if (email === "") {
        setEmailMessage("");
      } else {
        setEmailMessage("Invalid Email");
      }
    } else {
      setCheckEmail(true);
    }
  }, [email]);

  useEffect(() => {
    const pattern1 = /[0-9]/;
    const pattern2 = /[a-zA-Z]/;
    const pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;

    if (
      !pattern1.test(password) ||
      !pattern2.test(password) ||
      !pattern3.test(password) ||
      password.length < 8
    ) {
      if (password === "") {
        setPasswordMessage("");
      } else {
        setPasswordMessage(
          "Please enter a password with at least 8 characters including special characters and numbers."
        );
      }
    } else {
      setPasswordMessage("");
      setCheckPassword(true);
    }
  }, [password]);

  return (
    <div style={imgboxStyle}>
      <div className="w-full h-screen font-mont text-sm  bg-white shadow-lg">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-full h-full bg-white shadow-lg">
          <div className="pt-8 mx-2 flex flex-row items-center justify-around">
            <p className="font-mont fontsize-16 text-black text-sm font-bold text-center uppercase tracking-wider">
              {t("회원가입")}
            </p>
            <Link to="/mypage/signin">
              <p className="font-mont fontsize-16 text-black text-sm text-center uppercase tracking-wider">
                {t("로그인")}
              </p>
            </Link>
          </div>
          <div className="mx-2 flex flex-row items-center justify-around">
            <p className="w-1/2 bg-black  height-2" />
            <p className="w-1/2 bg-gray height-1" />
          </div>
          <div className="mx-2 mt-4 mb-4">
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이름")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-2 mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
            <div className="mt-1">{emailMessage}</div>
          </div>

          <div className="mx-2 mb-4">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="w-full px-2 py-2  outline-none"
              placeholder={t("암호")}
            />
            <p className="w-full bg-black  height-1" />
            <div className="mt-1">{passwordMessage}</div>
          </div>
          <div className="mx-2 mb-8">
            <div
              className="flex flex-row items-center mb-2  "
              onClick={() => setAgree1(!agree1)}
            >
              <CheckBox isCheck={agree1} />
              <div className="font-mont text-2xs ml-2">
                {t("이용약관 동의")}
              </div>
              <div className="font-mont text-2xs ml-4 underline ">
                {t("보기")}
              </div>
            </div>
            <div
              className="flex flex-row items-center"
              onClick={() => setAgree2(!agree2)}
            >
              <CheckBox isCheck={agree2} />
              <div className="text-2xs ml-2 font-mont">
                {t("개인정보 수집 및 이용에 대해 동의")}
              </div>
              <div className="text-2xs ml-4 underline font-mont ">
                {t("보기")}
              </div>
            </div>
          </div>
          <div className="h-12 mx-2 mt-8 flex flex-wrap items-center justify-center bg-black">
            <div
              className="font-mont w-full btn-text  text-center text-white fontsize-16 uppercase"
              onClick={signUpFunction}
            >
              {t("회원 가입")}
            </div>
          </div>
          {/* social login */}
        </div>
      </div>
    </div>
  );
};

const SignUp = () => {
  const setting = useSettings();
  const screen = setting.isMobile ? (
    setting.isAdminScreenMode ? (
      <ScreenAdmin />
    ) : (
      <ScreenMobile />
    )
  ) : setting.isAdminScreenMode ? (
    <ScreenAdmin />
  ) : (
    <Screen />
  );
  return <div>{screen}</div>;
};

export default SignUp;
