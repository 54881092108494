import { useContext, createContext } from "react";
import { observable } from "mobx";

// INIPAY 데이터 셋팅
const payNextUrl =
  process.env.NODE_ENV === "production"
    ? "https://nenoonsapi.du.r.appspot.com"
    : "http://192.168.1.152:4002";
const payNoti =
  process.env.NODE_ENV === "production"
    ? "https://nenoons.com"
    : "http://192.168.1.152:3000";
const mid = process.env.NODE_ENV === "production" ? "nenoons001" : "INIpayTest";
const hashKey =
  process.env.NODE_ENV === "production"
    ? "EGBwIGMopVMKP5m4"
    : "ItEQKi3rY7uvDS8l";
const clientIp = "123.123.123.123";

const context = createContext(
  observable({
    // mobile , desktop
    isBigScreen: false,
    isMobile: false,
    isPotrait: false,
    headerTitle: "logo",
    // header
    headerLangMenuOpen: false,
    lang: "en",
    displayLanguage: "English",
    // lang: "ko",
    // displayLanguage: "한국어",

    // ==========================================
    // 일반 로그인
    // isAdminScreenMode: false,
    // id: "ckcj1860t0000mu9hmdlbtygs",
    // id: "ckmbstgnl0026pksmp7mpcrln",
    // isLogin: true,
    // token:
    //   "a.eyJ1c2VyIjp7ImlkIjoiY2tjajE4NjB0MDAwMG11OWhtZGxidHlncyIsImVtYWlsIjoibWhqODhAbmF0ZS5jb20iLCJuYW1lIjoi66y47ZiE7KCVIiwicGhvbmUiOiIiLCJzbnNQcm92aWRlciI6ImZhY2Vib29rIn0sImlhdCI6MTU5NDU5NzM3OCwiZXhwIjoxNTk1MjAyMTc4fQ.ElrZbYJwfgruz9KwuwBy3wojF7PGakW4ruIFWrgybyM", // email: "mhj88@nate.com",
    // userName: "문현정",
    // email: "mhj88@nate.com",
    // phone: "",
    // snsProvider: "facebook",
    // ==========================================
    // isAdminScreenMode: true,
    // isAdmin: true, // 관리자 인지 여부
    // isLogin: true,
    // token:
    //   "a.eyJ1c2VyIjp7ImlkIjoiY2tjajE4NjB0MDAwMG11OWhtZGxidHlncyIsImVtYWlsIjoibWhqODhAbmF0ZS5jb20iLCJuYW1lIjoi66y47ZiE7KCVIiwicGhvbmUiOiIiLCJzbnNQcm92aWRlciI6ImZhY2Vib29rIn0sImlhdCI6MTU5NDU5NzM3OCwiZXhwIjoxNTk1MjAyMTc4fQ.ElrZbYJwfgruz9KwuwBy3wojF7PGakW4ruIFWrgybyM", // email: "mhj88@nate.com",
    // userName: "문현정관리자",
    // email: "cs@enkino.com",
    // phone: "010-2733-8096",
    // id: "ckmbstgnl0026pksmp7mpcrln",
    // ==========================================
    // 관리자 로그인
    // isAdminScreenMode: true,
    // isAdmin: true, // 관리자 인지 여부
    // id: "ckanlhgoi0000nv9hzux9n03c",
    // id: "ckanlhgoi0000nv9hzux9n03c",
    // isLogin: true,
    // token:
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY2thbmxoZ29pMDAwMG52OWh6dXg5bjAzYyIsImVtYWlsIjoiY3NAZW5raW5vLmNvbSIsIm5hbWUiOiLrrLjtmITsoJXqtIDrpqzsnpAiLCJwaG9uZSI6IiIsImlzQWRtaW4iOnRydWV9LCJpYXQiOjE1OTU0ODA2MzYsImV4cCI6MTU5NjA4NTQzNn0.7jHWDNlUJ8VV8ZGBZwEtp3875RgGajmbfN-wRXtJLd8", // email: "cs@enkino.com",
    // userName: "문현정관리자",
    // email: "cs@enkino.com",
    // phone: "010-2733-8096",
    // ==========================================
    // 관리자
    // isAdminScreenMode: true,
    // isAdmin: true,
    // id: "ckmli1exj0024xp8l007bpaxa",
    // isLogin: true,
    // token:
    //   "a.eyJ1c2VyIjp7ImlkIjoiY2tjajE4NjB0MDAwMG11OWhtZGxidHlncyIsImVtYWlsIjoibWhqODhAbmF0ZS5jb20iLCJuYW1lIjoi66y47ZiE7KCVIiwicGhvbmUiOiIiLCJzbnNQcm92aWRlciI6ImZhY2Vib29rIn0sImlhdCI6MTU5NDU5NzM3OCwiZXhwIjoxNTk1MjAyMTc4fQ.ElrZbYJwfgruz9KwuwBy3wojF7PGakW4ruIFWrgybyM",
    // userName: "손영균",
    // email: "dudrbs117@naver.com",
    // phone: "010-5119-6943",
    // ==========================================
    // 배포용
    isAdminScreenMode: false,
    isAdmin: false,
    id: "",
    isLogin: false,
    token: "",
    email: "",
    userName: "",
    phone: "",
    snsProvider: "",
    // ==========================================
    snsLikeUpdate: false,

    domestic: true,
    isBuy: false,

    userLikesCount: 14,
    userDesignsCount: 3,
    myPoints: 1500,

    // popup
    popup: {
      show: false,
      title: "",
      message: "",
    },

    // custom , template, preview, x,y, 등
    customCreate: {
      previewFile: "",
      template: null,
      currentItem: null,
    },

    // custom 에서 선택한 상품
    currentProduct: {
      brand: "",
      category: "",
      colorOption: "",
      createdAt: null,
      detailImage: "",
      id: "",
      keyword: "",
      mainImage: "",
      backImage: "",
      manufacturer: "",
      model: "",
      name: "",
      option: "",
      displayPrice: "",
      displayDollar: "",
      price: "",
      priceDollar: "",
      sizeOption: "",
      visible: false,
      warning: "",
      warranty: "",
      __typename: "",
    },

    allProductsUpdates: false,
    allMembersUpdates: false,
    specialOrdersUpdates: false,
    shoppingBagUpdates: false,
    payUpdates: false,

    categoryList: [],
    categoryOptions: [],
    cmsItem: null,
    cmsHomeUpdates: false,
    cmsAboutUpdates: false,
    cmsHangulUpdates: false,
    cmsGroundUpdates: false,
    cmsTVUpdates: false,
    cmsCategoryUpdates: false,
    cmsRecommendUpdates: false,
    cmsTemplateUpdates: false,
    cmsTemplateImageUpdates: false,

    // playCustom
    createItem: null,
    createGraphic: "Basic",
    createRecommend: {},
    createProduct: {},
    createColor: "black",
    createLabelText: "eshe",

    createPrintColor: "",
    createPrintStyle: "Embroidery",

    // front에 들어가는 리스트
    graphicList: [
      {
        id: 0,
        template: "text",
        txtSrc: "insert typo",
        fontStyle: "normal",
        textColor: "#000000",
        topR: 200,
        leftR: 230,
        widthR: 290,
        heightR: 80,
        txtKor: "",
        txtEng: "",
        explain: "",
        imgSrc: "",
        tempFontSize: "",
        realWidth: "",
        printStyle: "print",
      },
    ],

    // back에 들어가는 리스트
    graphicBackList: [],

    tempGraphic: null,
    productId: null,

    // Template관련 요소
    // selectTemplate: null,
    selectTemplate: [
      {
        id: 0,
        template: "text",
        txtSrc: "insert typo",
        fontStyle: "normal",
        textColor: "#000000",
        topR: 200,
        leftR: 230,
        widthR: 290,
        heightR: 80,
        txtKor: "",
        txtEng: "",
        explain: "",
        imgSrc: "",
        tempFontSize: "",
        realWidth: "",
        printStyle: "print",
      },
    ],
    explain: null,
    translate: null,

    // 물품 수량
    amount: {
      amountXS: 0,
      amountS: 0,
      amountM: 0,
      amountL: 0,
      amountXL: 0,
      amount2XL: 0,
      amount110: 0,
      amount130: 0,
      amount150: 0,
      amount160: 0,
      amount: 0,
    },

    // order
    orderName: "",
    orderPhone: "",
    orderEmail: "",
    orderWay: "",

    receiverName: "",
    receiverPhone: "",
    receiverReservePhone: "",
    receiverPostNum: "",
    receiverAddress: "",
    receiverAddress2: "",
    receiverMessage: "",

    // 기본 배송지
    baseName: "기본 배송지 수령인",
    basePhone: "기본 배송지 연락처",
    basePostNum: "기본 배송지 우편번호",
    baseAddress: "기본 배송지 주소",
    baseAddress2: "기본 배송지 주소2",
    baseMessage: "기본 베송지 메세지",

    // recommend modal의 cms
    jsonDaily: "",
    jsonTitle: "",
    jsonLyrics: "",
    jsonInterjection: "",
    jsonPoem: "",
    jsonProverb: "",

    // capture위치
    playCustomFront: 0,

    // playcustom 한글(추천, 번역)
    hangulList: [],
    graphicId: 1,

    // INIPAY (결제 데이터 셋팅)
    payNextUrl,
    payNoti,
    mid,
    hashKey,
    clientIp,

    // label
    labelContents: "",
    labelMessage: "",
    labelId: null,

    // review
    reviewUpdate: false,

    qrOptions: {
      level: "M",
      margin: 3,
      scale: 1,
      width: 200,
      color: {
        dark: "#fff",
        light: "#000",
      },
    },

    productLike: false,
    templateLike: false,

    labelQrCode: "",

    labelData: {
      labelSize: "5cm",
      labelLink: "",
      labelWay: "",
      template: "qr",
      topR: 200,
      leftR: 200,
      heightR: 100,
      widthR: 100,
    },

    labelGraphicItems: [],
  })
);

const useSettings = () => useContext(context);
export default useSettings;
