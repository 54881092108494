import React from "react";
import useSettings from "stores/settings";
import CmsPageTopMenu from "./CmsPageTopMenu";
import MyPage from "../MyPage";

const ScreenWide = ({ children }) => {
  return (
    <div>
      <CmsPageTopMenu />
      <div className="w-full mt-6 min-h-screen">{children}</div>
    </div>
  );
};

const CmsPage = ({ children }) => {
  return (
    <div>
      <MyPage>
        <ScreenWide>{children}</ScreenWide>
      </MyPage>
    </div>
  );
};

export default CmsPage;
