/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import icon_star_white from "assets/images/icon_star_white.svg";
import icon_star_black from "assets/images/icon_star_black.svg";

const Star = ({ star }) => {
  const starCount = Number(star);
  return (
    <div className="flex-row-center ">
      <img className="w-4 h-4" src={starCount >= 1 ? icon_star_black : icon_star_white} alt="" />
      <img className="w-4 h-4" src={starCount >= 2 ? icon_star_black : icon_star_white} alt="" />
      <img className="w-4 h-4" src={starCount >= 3 ? icon_star_black : icon_star_white} alt="" />
      <img className="w-4 h-4" src={starCount >= 4 ? icon_star_black : icon_star_white} alt="" />
      <img className="w-4 h-4" src={starCount >= 5 ? icon_star_black : icon_star_white} alt="" />
    </div>
  );
};

export default Star;
