import React from "react";
import { useHistory, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import icon_my_likes from "assets/images/icon_my_likes.svg";
import icon_my_design from "assets/images/icon_my_design.svg";
// import icon_my_template from "assets/images/icon_template.svg";
// import icon_create_design from "assets/images/icon_create_design.svg";
import swal from "@sweetalert/with-react";
import firebase from "firebase/app";
import "firebase/auth";
import initFirebase from "../../firebase";

initFirebase();

const ScreenAdmin = observer(() => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();

  const handlerLogout = () => {
    swal(t("정상적으로 로그아웃 되었습니다"), { button: false, timer: 3000 });

    setTimeout(() => {
      setting.isLogin = false;
      setting.userName = "";
      setting.email = "";
      setting.id = -1;
      setting.userLikesCount = 0;
      setting.userDesignsCount = 0;
      setting.myPoints = 0;
      window.localStorage.setItem("token", "");
      history.push("/mypage/signin");
    }, 3000);
  };

  return (
    <div className="w-full h-screen">
      <div className="mx-auto px-10 relative items-center justify-between  md:justify-center">
        {setting.userName ? (
          <div className="mt-2 mb-4 text-medium-kr-15">{setting.userName} </div>
        ) : (
          <Link to="/mypage/signin">
            <div className="mt-2 mb-4 text-medium-kr-15">
              {t("로그인하세요")}
            </div>
          </Link>
        )}
        {console.log(
          "setting login : ",
          setting.isAdmin,
          "setting.admin screen : ",
          setting.isAdminScreenMode
        )}
        {setting.isLogin ? (
          <div>
            <Link to="/mypage/all_likes">
              <div className="mt-2 text-medium-13">
                <div className="flex flex-wrap justify-start items-center">
                  <img
                    src={icon_my_likes}
                    style={{ width: "10px", height: "10px" }}
                    alt=""
                  />
                  <div className="ml-1">LIKE</div>
                </div>
              </div>
            </Link>
            <Link to="/mypage/all_designs">
              <div className="mt-1 text-medium-13">
                <div className="flex flex-wrap justify-start items-center">
                  <img
                    src={icon_my_design}
                    style={{ width: "10px", height: "10px" }}
                    alt=""
                  />
                  <div className="ml-1">DESIGN</div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <div />
        )}
        <p />
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        {/* ㄴ */}
        <Link to="/mypage/all_members">
          <div className="text-medium-13 mt-2 ">회원 관리</div>
        </Link>
        <Link to="/mypage/all_products">
          <div className="text-medium-13 mt-2 ">상품 관리</div>
        </Link>
        <Link to="/mypage/all_orders">
          <div className="text-medium-13 mt-2 ">주문배송 조회</div>
        </Link>
        <Link to="/mypage/all_reviews">
          <div className="text-medium-13 mt-2 ">상품 리뷰</div>
        </Link>
        <Link to="/mypage/all_qa">
          <div className="text-medium-13 mt-2 ">1:1 문의</div>
        </Link>
        <Link to="/mypage/cms">
          <div className="text-medium-13 mt-2 ">CMS</div>
        </Link>
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        {console.log(
          "setting login : ",
          setting.isAdmin,
          "setting.admin screen : ",
          setting.isAdminScreenMode
        )}
        <div className="text-bold-13">관리자 계정 설정 </div>
        {setting.isAdminScreenMode ? (
          <Link to="/mypage/authorize_admin">
            <div className="text-medium-13 mt-2 ">관리자 회원 권한</div>
          </Link>
        ) : (
          <Link to="/mypage/myprofile">
            <div className="text-medium-13 mt-2 ">개인 정보 수정</div>
          </Link>
        )}
        <div className="text-medium-13 mt-2 ">
          <button onClick={() => handlerLogout()}>로그아웃</button>
        </div>
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        <div className="text-bold-13">고객 센터 </div>
        <Link to="/mypage/all_specialorders">
          <div className="text-medium-13 mt-2 ">주문/단체 제작 의뢰</div>
        </Link>
        <Link to="/mypage/all_notices">
          <div className="text-medium-13 mt-2 ">공지사항</div>
        </Link>
        <Link to="/mypage/faq">
          <div className="text-medium-13 mt-2 ">FAQ</div>
        </Link>
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        <Link to="/mypage/all_points">
          <div className="text-bold-13">전체 포인트 확인 </div>
        </Link>
      </div>
    </div>
  );
});

const Screen = observer(() => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();

  const handlerLogout = () => {
    swal(t("정상적으로 로그아웃 되었습니다"), { button: false, timer: 3000 });

    firebase
      .auth()
      .signOut()
      .then(() => {
        setting.isLogin = false;
        setting.email = "";
        setting.userName = "";
        setting.id = "";
        setting.token = "";
        setting.isSnsLogin = false;
        window.localStorage.setItem("token", "");
        history.push("/mypage/signin");
        swal("로그아웃 되었습니다");
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };
  return (
    <div className="w-full h-screen">
      <div className="mx-auto px-10 relative items-center justify-between  md:justify-center">
        {setting.userName ? (
          <div className="mt-2 mb-4 text-medium-kr-15">{setting.userName} </div>
        ) : (
          <Link to="/mypage/signin">
            <div className="mt-2 mb-4 text-medium-kr-15">
              {t("로그인하세요")}{" "}
            </div>
          </Link>
        )}
        {console.log(
          "setting login : ",
          setting.isAdmin,
          "setting.admin screen : ",
          setting.isAdminScreenMode
        )}
        {setting.isLogin ? (
          <div>
            <Link to="/mypage/mylikes">
              <div className="mt-2 text-medium-13">
                <div className="flex flex-wrap justify-start items-center">
                  <img
                    src={icon_my_likes}
                    style={{ width: "10px", height: "10px" }}
                    alt=""
                  />
                  <div className="ml-1">My Like</div>
                </div>
              </div>
            </Link>
            <Link to="/mypage/mydesigns">
              <div className="mt-1 text-medium-13">
                <div className="flex flex-wrap justify-start items-center">
                  <img
                    src={icon_my_design}
                    style={{ width: "10px", height: "10px" }}
                    alt=""
                  />
                  <div className="ml-1">My Design</div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <div />
        )}
        <p />
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        <div className="text-bold-13">{t("나의 쇼핑 정보")}</div>
        <Link to="/mypage/myorders">
          <div className="text-medium-13 mt-2 ">{t("주문배송 조회")}</div>
        </Link>
        <Link to="/mypage/myreview">
          <div className="text-medium-13 mt-2 ">{t("상품 리뷰")}</div>
        </Link>
        <Link to="/mypage/my_qa">
          <div className="text-medium-13 mt-2 ">{t("11문의")}</div>
        </Link>
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        <div className="text-bold-13">{t("나의 계정 설정")}</div>
        <Link to="/mypage/myprofile">
          <div className="text-medium-13 mt-2 ">{t("개인 정보 수정")}</div>
        </Link>
        {setting.isLogin ? (
          <div className="text-medium-13 mt-2 ">
            <button onClick={() => handlerLogout()}>{t("로그아웃")}</button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        <div className="text-bold-13">{t("고객 센터")} </div>
        <Link to="/mypage/myspecialorders">
          <div className="text-medium-13 mt-2 ">{t("주문/단체 제작 의뢰")}</div>
        </Link>
        <Link to="/mypage/mynotices">
          <div className="text-medium-13 mt-2 ">{t("공지사항")}</div>
        </Link>
        <Link to="/mypage/faq">
          <div className="text-medium-13 mt-2 ">{t("FAQ")}</div>
        </Link>
      </div>

      <div className="mx-auto mt-8 px-10 relative items-center justify-between  md:justify-center">
        <Link to="/mypage/mypoints">
          <div className="text-bold-13">{t("나의 포인트 확인")}</div>
        </Link>
      </div>
    </div>
  );
});

const MyPageLeftMenu = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      <Screen />
    );

  return <div>{screen}</div>;
};
export default MyPageLeftMenu;
