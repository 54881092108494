import styled from "styled-components";

export default styled.div`
  position: absolute;
  border: 1px solid #0000dd;

  /* .square {
    position: absolute;
    width: 7px;
    height: 7px;
    background: white;
    border: 1px solid #0000dd;
    border-radius: 1px;
  } */

  .square {
    position: absolute;
    right: 5px;
    bottom: -26000px;
    width: 18px;
    height: 18px;
    margin-right: -6px;
    margin-bottom: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .resizable-handler {
    position: absolute;
    width: 22px;
    height: 22px;
    cursor: pointer;
    z-index: 1;

    &.tl,
    &.t,
    &.tr {
      top: -7px;
    }

    &.tl,
    &.l,
    &.bl {
      left: -7px;
    }

    &.bl,
    &.b,
    &.br {
      bottom: -7px;
    }

    &.br,
    &.r,
    &.tr {
      right: -7px;
    }

    &.l,
    &.r {
      margin-top: -7px;
    }

    &.t,
    &.b {
      margin-left: -7px;
    }
  }

  .translate {
    position: absolute;
    right: 5px;
    top: -26px;
    width: 18px;
    height: 18px;
    margin-right: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .delete {
    position: absolute;
    left: 5px;
    top: -26px;
    width: 28px;
    height: 28px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .rotate {
    position: absolute;
    left: 50%;
    top: -26px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .resize {
    position: absolute;
    left: 5px;
    bottom: -26px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .t,
  .tl,
  .tr {
    top: -3px;
  }

  .b,
  .bl,
  .br {
    bottom: -3px;
  }

  .r,
  .tr,
  .br {
    right: -3px;
  }

  .tl,
  .l,
  .bl {
    left: -3px;
  }

  .l,
  .r {
    top: 50%;
    margin-top: -3px;
  }

  .t,
  .b {
    left: 50%;
    margin-left: -3px;
  }
`;
