import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useQuery } from "@apollo/client";
import { FIND_NEW_TEMPLATE_LIKE } from "../../graphql/gql/select";
import swal from "sweetalert";
import PlayCustomTemplate from "../playcustom/PlayCustomTemplate";

const MyLikeTemplates = () => {
  const { t } = useTranslation();
  const setting = useSettings();
  const noDataStr = t("내역이 없습니다");

  const { loading, error, data, refetch } = useQuery(FIND_NEW_TEMPLATE_LIKE, {
    variables: { memberId: setting.id },
  });

  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (data) {
    console.log("data : , ", data);
  }

  if (setting.templateLike) {
    setting.templateLike = false;
    refetch();
  }

  const items = data.findNewTemplateLike;
  console.log("items : ", items);

  return (
    <div className="max-w-5xl mt-12 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Template</div>
      <div className="blackline2 mt-1" />
      <div className="mt-4 flex flex-row flex-wrap">
        {items.length > 0 &&
          items.map((item) => (
            <div className="m-2 border">
              <PlayCustomTemplate
                widthR={300}
                heightR={300}
                template={JSON.parse(item.newTemplate.template)}
              />
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8m font-mont-bold fontsize-15 tracking-tight text-center">
            {noDataStr}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const MyLikeTemplateMobile = () => {
  const { t } = useTranslation();
  const setting = useSettings();
  const noDataStr = t("내역이 없습니다");

  const { loading, error, data, refetch } = useQuery(FIND_NEW_TEMPLATE_LIKE, {
    variables: { memberId: setting.id },
  });

  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (data) {
    console.log("data : ", data);
  }

  const items = data.findNewTemplateLike;
  console.log("items : ", items);

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Template</div>
      <div className="blackline2 mt-1" />
      <div className="mt-4 flex flex-row flex-wrap w-full">
        {items.length > 0 &&
          items.map((item) => (
            <div className="w-1/5">
              <img
                src={item.product.mainImage}
                className="object-cover pr-2 border"
                alt=""
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const MyLikeTemplate = () => {
  const setting = useSettings();
  const screen = setting.isMobile ? (
    <MyLikeTemplateMobile />
  ) : (
    <MyLikeTemplates />
  );
  return <div>{screen}</div>;
};

export default MyLikeTemplate;
