import React, { useState, useEffect } from "react";
import { ALL_QA_ADMIN, UPDATE_QA } from "graphql/gql/select";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import Moment from "react-moment";
import useSettings from "stores/settings";
import { SRLWrapper } from "simple-react-lightbox";
import { observer } from "mobx-react-lite";
import icon_switch_on from "assets/images/icon_switch_on.svg";
import icon_switch_off from "assets/images/icon_switch_off.svg";
import MyPage from "./MyPage";
import MultiImages from "../../components/MultiImages";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/12 text-center">{t("주문일")}</div>
      <div className="w-2/12 ">{t("주문내역")}</div>
      <div className="w-1/12 text-center">{t("고객명")}</div>
      <div className="w-5/12 text-center">{t("문의 내용")}</div>
      <div className="w-2/12 text-left">{t("문의 사진")}</div>
      <div className="w-1/12 text-center" />
    </div>
  );
};

const Screen = observer(() => {
  const [isReply, setIsReplay] = useState(false);
  const { loading, error, data, refetch } = useQuery(ALL_QA_ADMIN, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (!data || !data.allQAAdmin) return <p>Error</p>;
  const items = data.allQAAdmin;
  console.log("AllQA , items: ", items);

  // 답변 없는 문의
  const itemNotReply = items.filter((item) => item.replyContents === "");

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="flex justify-content-between">
        <div className="content-title">전체 1:1 문의</div>
        <div className="flex">
          <div className="block px-1 font-bold fontsize-10 text-center my-auto">
            답변 없는 것만 보기
          </div>
          <img
            src={isReply ? icon_switch_on : icon_switch_off}
            className="mr-2 w-8 h-4"
            alt=""
            onClick={() => setIsReplay(!isReply)}
          />
        </div>
      </div>
      <div className="blackline2 mt-1" />
      {isReply ? (
        <AllQAList items={itemNotReply} refetch={refetch} />
      ) : (
        <AllQAList items={items} refetch={refetch} />
      )}
    </div>
  );
});

const AllQAList = ({ items, refetch }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const [currentItem, setCurrentItem] = useState({ id: 0, replyContents: "" });

  const [updateQA, { data: updateData }] = useMutation(UPDATE_QA, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updateData !== undefined) {
      const data1 = updateData.updateQA;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("11답변 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("1:1 답변이 잘 생성 되었습니다"), {
          button: false,
          timer: 3000,
        });
        refetch();
      }
    }
  }, [updateData]);

  const handlerUpdateQAClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    updateQA({
      variables: {
        id: currentItem.id,
        replyContents: currentItem.replyContents,
      },
    });
  };

  return (
    <div>
      <div className="flex-row-center text-medium-15">
        <Header />
        <div className="blackline" />
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="w-full flex-col-center" key={item.id}>
              <div className="w-full flex-row-center table-text">
                <div className="w-1/12 text-center">
                  <Moment unix format="YYYY.MM.DD">
                    {Number(item.createdAt) / 1000}
                  </Moment>
                </div>
                <div className="w-2/12 ">
                  <div className="flex-row-left ">
                    <img
                      className="w-10 h-10 border-black border"
                      src={item.order.product.mainImage}
                      alt=""
                    />
                    <div className="ml-4  font-mont fontsize-12 tracking-tight ">
                      {item.order.product.name}
                    </div>
                  </div>
                </div>
                <div className="w-1/12 text-center">{item.order.name}</div>
                <div className="w-5/12 text-center">{item.contents}</div>
                <div className="w-3/12 text-center flex-row-left">
                  <MultiImages images={item.images} />
                </div>
              </div>
              <div className="grayline mt-2" />
              {item.replyContents ? (
                <div className="mt-1 w-full flex-row-center table-text">
                  <div className="w-5/12" />
                  <div className="w-6/12 h-auto">
                    <textarea
                      className="w-full resize-y"
                      defaultValue={item.replyContents}
                      readOnly
                    />
                  </div>
                  <div className="w-1/12" />
                </div>
              ) : (
                <div className="mt-1 w-full flex-row-center table-text">
                  <div className="w-5/12" />
                  <div className="w-6/12 overflow-auto">
                    <textarea
                      onChange={(e) =>
                        setCurrentItem({
                          id: item.id,
                          replyContents: e.target.value,
                        })
                      }
                      type="text"
                      className="w-full resize-y outline-none"
                      placeholder="답장을 적은 후 답장하기 버튼을 누르세요"
                    />
                  </div>
                  <div className="w-1/12">
                    <button
                      className="w-full h-8 table-text bg-black text-white font-bold mt-2 py-2 "
                      onClick={() => {
                        if (currentItem.id === item.id)
                          handlerUpdateQAClick(item.id);
                      }}
                    >
                      {t("답장하기")}
                    </button>
                  </div>
                </div>
              )}
              <div className="blackline mt-1" />
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const AllQA = observer(() => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");

  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <MyPage>
        <SRLWrapper>
          <Screen />
        </SRLWrapper>
      </MyPage>
    ) : (
      ""
    );

  return <div>{screen}</div>;
});

export default AllQA;
