import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import btn_modal_close from "assets/images/btn_modal_close.svg";
import { useQuery, useMutation } from "@apollo/client";
import Modal from "react-modal";
import swal from "sweetalert";
import useSettings from "stores/settings";
import { UPLOAD_FILE, UPDATE_REVIEW } from "../graphql/gql/select";
import customStyles from "./modalStyle";
import icon_star_black from "assets/images/icon_star_black.svg";
import icon_star_white from "assets/images/icon_star_white.svg";

const ReviewUpdateModal = ({
  modalIsOpen,
  closeModal,
  reviewItem,
  refetch,
}) => {
  const [oneStar, setOneStar] = useState(icon_star_white);
  const [twoStar, setTwoStar] = useState(icon_star_white);
  const [threeStar, setThreeStar] = useState(icon_star_white);
  const [fourStar, setFourStar] = useState(icon_star_white);
  const [fiveStar, setFiveStar] = useState(icon_star_white);
  const [currentItem, setCurrentItem] = useState({
    ...reviewItem,
    hashTag: "",
  });
  const [reviewStar, setReviewStar] = useState(currentItem.reviewStar);
  const [tempUrl, setTempUrl] = useState(currentItem.mainImg); // reviewitem
  const [review, setReview] = useState(currentItem.comment);

  const { t } = useTranslation();

  const setting = useSettings();

  const [singleUpload, { data: uploadedData }] = useMutation(UPLOAD_FILE, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  const [updateReview, { data: updatedReviewData }] = useMutation(
    UPDATE_REVIEW,
    {
      onError(error) {
        console.log("UPDATE_REVIEW: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    if (uploadedData !== undefined) {
      const data1 = uploadedData.singleUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        setCurrentItem({ ...currentItem, mainImg: data1.filename });
      }
    }
  }, [uploadedData]);

  const onRequestClose = () => {
    document.documentElement.style.overflowY = "visible";
    document.documentElement.removeEventListener("touchmove", preventIOSScroll);
    closeModal();
  };
  //   setCurrentItem(reviewItem);

  const preventIOSScroll = (e) => {
    e.preventDefault();
  };

  const updateReviewHandler = ({
    id,
    memberId,
    mainImg,
    comment,
    starPoint,
    visible,
    hashTag,
  }) => {
    updateReview({
      variables: {
        id,
        memberId,
        mainImg,
        comment,
        starPoint,
        visible,
        hashTag,
      },
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div
        style={{ width: 800 }}
        className="h-600 flex flex-row bg-transparent"
      >
        {console.log("currnetItem : ", currentItem)}
        <div className="p-4 w-full table-text bg-white">
          <div className="flex-col-left">
            <div>리뷰 쓰기</div>
            <div className="mt-8 flex">
              <img src={oneStar} alt="" onClick={() => setReviewStar("1")} />
              <img src={twoStar} alt="" onClick={() => setReviewStar("2")} />
              <img src={threeStar} alt="" onClick={() => setReviewStar("3")} />
              <img src={fourStar} alt="" onClick={() => setReviewStar("4")} />
              <img src={fiveStar} alt="" onClick={() => setReviewStar("5")} />
            </div>
            <div className="flex mt-8 justify-between">
              <div className="flex">
                <form onSubmit={() => {}} encType="multipart/form-data">
                  <label
                    for="input-file"
                    className="w-24 h-12 border bg-white border mr-4"
                  >
                    사진첨부
                  </label>
                  <input
                    id="input-file"
                    style={{ display: "none" }}
                    name="document"
                    type="file"
                    accept="image"
                    method="POST"
                    onChange={async ({ target: { files } }) => {
                      const file = files[0];
                      console.log("file", file);
                      if (file) singleUpload({ variables: { file } });
                    }}
                  />
                </form>
                <button className="h-12 w-24 bg-white border mr-4">
                  영상첨부
                </button>
                <button className="h-12 w-24 bg-white border">
                  디자인 첨부
                </button>
              </div>
              <button
                onClick={() => {
                  console.log("item : ", currentItem);
                  updateReviewHandler(currentItem);
                  refetch();
                }}
                className="h-12 w-24 bg-black text-white"
              >
                등록하기
              </button>
            </div>
            <div className="mt w-8 w-full">
              <textarea
                defaultValue={review}
                className="w-full p-2 outline-none bg-gray-100"
                type="text"
                cols="80"
                row="10"
                placeholder="리뷰는 Play Tv에서 확인이 가능합니다"
                style={{ fontSize: 20 }}
                onChange={(e) => {
                  setCurrentItem({ ...currentItem, comment: e.target.value });
                }}
              />
              <input
                className="w-full outline-none mt-2 bg-gray-100"
                type="text"
                value={currentItem.hashTag}
                placeholder="태그를 입력하세요"
                style={{ fontSize: 20 }}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, hashTag: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6"
            src={btn_modal_close}
            alt=""
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ReviewUpdateModal;
