/* eslint-disable no-underscore-dangle */
import { FIND_ID, SIGNUP, SIGNIN, SIGNIN_ADMIN } from "graphql/gql/select";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";
import img_google from "assets/images/img_google.svg";
import img_facebook from "assets/images/img_facebook.svg";
import firebase from "firebase/app";
import "firebase/auth";
import initFirebase from "../../firebase";

initFirebase();

const auth = firebase.auth();

const imgboxStyle = {
  width: "100% ",
  height: "100% ",
};

const ScreenAdmin = () => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [signIn, { data }] = useMutation(SIGNIN_ADMIN, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.signInAdmin;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        // TODO 토큰을 로칼 스토리지에 저장해서 아폴로 클라이언트에서 헤더에 넣어 사용하게 해야함
        const member = jwtDecode(data1.token);
        setting.isLogin = true;
        setting.email = member.user.email;
        setting.id = member.user.id;
        setting.phone = member.user.phone;
        setting.userName = member.user.name;
        setting.token = data1.token;
        setting.isAdmin = true;
        window.localStorage.setItem("token", data1.token);
        history.push("/");
      }
    }
  }, [data]);

  // 로그인 버튼 클릭시, 필드 검사 후 login 뮤테이션 호출
  const handlerSignInClick = () => {
    if (!email || !password) {
      swal(t("이메일이나 암호를 입력해 주세요"), {
        button: false,
        timer: 3000,
      });
      return;
    }
    signIn({ variables: { email, password } });
  };
  const handleKeyPress = (e) => {
    // 눌려진 키가 Enter 면 handleCreate 호출
    if (e.key === "Enter") {
      handlerSignInClick();
    }
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen font-mont  text-xs flex-col-center">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white shadow-lg">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around">
            <Link to="/mypage/signup">
              <p className="font-mont  text-black text-sm text-center ">
                {t("회원가입")}
              </p>
            </Link>
            <p className="font-mont  text-black text-sm font-bold text-center ">
              {t("로그인")}
            </p>
          </div>
          <div className="mx-8 flex flex-row items-center justify-around">
            <p className="w-1/2 bg-gray height-1" />
            <p className="w-1/2 bg-black height-2" />
          </div>
          <div className="mx-8 mt-4 mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              onKeyPress={handleKeyPress}
              className="w-full px-2 py-2  outline-none"
              placeholder={t("암호")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="h-10 mx-8 mt-8 mb-4 flex flex-wrap items-center justify-center bg-black">
            <div
              className="w-full btn-text  text-center text-white font-bold py-2 px-4 my-auto"
              onClick={handlerSignInClick}
            >
              {t("로그인버튼")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Screen = () => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [snsUser, setSnsUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [findId, { data: findIdData }] = useMutation(FIND_ID);

  const [signUp, { data: signUpData }] = useMutation(SIGNUP);

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [signIn, { data: signInData }] = useMutation(SIGNIN, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  const signInWithEmailFunction = async () => {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      const { user } = result;

      if (!user) {
        throw new Error("Error authorizing");
      }

      if (!user.emailVerified) {
        swal("Email verification required", { button: false, timer: 3000 });
      }

      if (user.emailVerified) {
        signIn({
          variables: { email, password: user.uid },
        });
      }
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/invalid-email": {
          swal("이메일 혹은 비밀번호가 일치하지 않습니다", {
            button: false,
            timer: 3000,
          });
          break;
        }
        case "auth/wrong-password": {
          swal("이메일 혹은 비밀번호가 일치하지 않습니다", {
            button: false,
            timer: 3000,
          });
          break;
        }
        case "auth/user-not-found": {
          swal("존재하지않는 email입니다", {
            button: false,
            timer: 3000,
          });
          break;
        }
        case "auth/too-many-requests": {
          swal("로그인 시도횟수 초과, 잠시후에 다시 시도하세요", {
            button: false,
            timer: 3000,
          });
          break;
        }
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (findIdData !== undefined) {
      const tempData = findIdData.findId;
      if (tempData.error) {
        console.log("error", tempData.error);
      } else {
        console.log("msg", tempData.msg);
        if (tempData.msg === "아이디가 존재하지 않습니다") {
          console.log("snsUser : ", snsUser);
          signUp({
            variables: snsUser,
          });
        } else if (tempData.msg === "아이디가 존재합니다") {
          signIn({
            variables: { email: snsUser.email, password: snsUser.password },
          });
          setting.isSnsLogin = true;
        }
      }
    }
  }, [findIdData]);

  useEffect(() => {
    if (signUpData !== undefined) {
      const tempSignUpData = signUpData.signup;
      console.log("signUpdata", signUpData);
      if (tempSignUpData.error) {
        swal(tempSignUpData.error, { button: false, timer: 3000 });
      } else {
        const member = jwtDecode(tempSignUpData.token);
        console.log("member : ", member);
        setting.isLogin = true;
        setting.email = member.user.email;
        setting.id = member.user.id;
        setting.phone = member.user.phone;
        setting.userName = member.user.name;
        setting.token = tempSignUpData.token;
        setting.snsProvider = member.user.snsProvider;
        window.localStorage.setItem("token", tempSignUpData.token);
        history.push("/");
        console.log("signUpData", signUpData);
      }
    }
  }, [signUpData]);

  useEffect(() => {
    if (signInData !== undefined) {
      const data1 = signInData.signIn;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        const member = jwtDecode(data1.token);
        setting.isLogin = true;
        setting.email = member.user.email;
        setting.id = member.user.id;
        setting.phone = member.user.phone;
        setting.userName = member.user.name;
        setting.token = data1.token;
        setting.snsProvider = "email";
        window.localStorage.setItem("token", data1.token);
        history.push("/");
      }
    }
  }, [signInData]);

  const handleKeyPress = (e) => {
    // 눌려진 키가 Enter 면 handleCreate 호출
    if (e.key === "Enter") {
      signInWithEmailFunction();
    }
  };

  const handleGoogleAuthentication = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(googleProvider);
      const { user } = result;

      console.log("user : ", user);

      if (!user) {
        throw new Error("Error authorizing");
      }
      const { displayName, email, uid, photoURL } = user;
      console.log("photoURL : ", photoURL);
      setSnsUser({
        name: displayName,
        email,
        password: uid,
        snsProvider: "google",
        img: photoURL,
      });

      findId({ variables: { email } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFacebookAuthentication = async () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(facebookProvider);
      const { user } = result;

      if (!user) {
        throw new Error("Error authorizing");
      }
      const { displayName, email, uid, photoURL } = user;
      setSnsUser({
        name: displayName,
        email,
        password: uid,
        snsProvider: "facebook",
        img: photoURL,
      });

      findId({ variables: { email } });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div style={imgboxStyle}>
        <div id="firebaseui-auth-container" />
        <div className="h-screen font-mont  text-xs flex-col-center">
          {/* 아메일 , 암호 필드 표시  */}
          <div className="w-96 bg-white shadow-lg">
            <div className="pt-8 mx-8 flex flex-row items-center justify-around">
              <Link to="/mypage/signup">
                <p className="font-mont  text-black text-sm text-center ">
                  {t("회원가입")}
                </p>
              </Link>
              <p className="font-mont text-black text-sm font-bold text-center ">
                {t("로그인")}
              </p>
            </div>
            <div className="mx-8 flex flex-row items-center justify-around">
              <p className="w-1/2 bg-gray height-1" />
              <p className="w-1/2 bg-black height-2" />
            </div>
            <div className="mx-8 mt-4 mb-4">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="w-full px-2 py-2 outline-none"
                placeholder={t("이메일")}
              />
              <p className="w-full bg-black  height-1" />
            </div>

            <div className="mx-8 mb-4">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                onKeyPress={handleKeyPress}
                className="w-full px-2 py-2  outline-none"
                placeholder={t("암호")}
              />
              <p className="w-full bg-black  height-1" />
            </div>

            <div className="h-10 mx-8 mt-8 flex flex-wrap items-center justify-center bg-black">
              <button
                className="w-full btn-text  text-center text-white font-bold py-2 px-4 my-auto focus:outline-none"
                onClick={signInWithEmailFunction}
              >
                {t("로그인버튼")}
              </button>
            </div>

            {/* social login */}
            <div className=" mx-8 ">
              <div className="w-full text-center flex flex-col">
                <div
                  className="mt-2 w-full border-black border-1 text-center relative"
                  onClick={() => {
                    handleGoogleAuthentication();
                  }}
                >
                  <button className="w-full h-10 flex-center focus:outline-none">
                    <div className="w-full">{t("Google ID로 로그인")}</div>
                    <img
                      src={img_google}
                      alt=""
                      className="absolute left-0 w-6 h-6 ml-2"
                    />
                  </button>
                </div>
                <div
                  className="mt-2 w-full border-black border-1 text-center relative"
                  onClick={() => {
                    handleFacebookAuthentication();
                  }}
                >
                  <button className="w-full h-10 flex-center focus:outline-none">
                    <div className="w-full">{t("Facebook ID로 로그인")}</div>
                    <img
                      src={img_facebook}
                      alt=""
                      className="absolute left-0 w-6 h-6 ml-2"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className=" mx-8 mt-8 mb-4">
              <div className="w-full font-mont font-normal text-center flex-row-center">
                <Link to="/mypage/findid">
                  <span className="no-underline text-black px-2 ml-2 ">
                    {t("아이디 찾기")}
                  </span>{" "}
                  |
                </Link>
                <Link to="/mypage/findpassword">
                  <span className="no-underline text-black px-2 ">
                    {t("비밀번호 찾기")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ScreenMobile = () => {
  const setting = useSettings();
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [snsUser, setSnsUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [findId, { data: findIdData }] = useMutation(FIND_ID);

  const [signUp, { data: signUpData }] = useMutation(SIGNUP);

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [signIn, { data: signInData }] = useMutation(SIGNIN, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  const signInWithEmailFunction = async () => {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      const { user } = result;

      if (!user) {
        throw new Error("Error authorizing");
      }

      if (!user.emailVerified) {
        swal("Email verification required", { button: false, timer: 3000 });
      }

      signIn({
        variables: { email, password: user.uid },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (findIdData !== undefined) {
      const tempData = findIdData.findId;
      if (tempData.error) {
        console.log("error", tempData.error);
      } else {
        console.log("msg", tempData.msg);
        if (tempData.msg === "아이디가 존재하지 않습니다") {
          signUp({
            variables: snsUser,
          });
          setting.isSnsLogin = true;
        } else if (tempData.msg === "아이디가 존재합니다") {
          signIn({
            variables: { email: snsUser.email, password: snsUser.password },
          });
          setting.isSnsLogin = true;
        }
      }
    }
  }, [findIdData]);

  useEffect(() => {
    if (signUpData !== undefined) {
      const tempSignUpData = signUpData.signup;
      console.log("signUpdata", signUpData);
      if (tempSignUpData.error) {
        swal(tempSignUpData.error, { button: false, timer: 3000 });
      } else {
        const member = jwtDecode(tempSignUpData.token);
        console.log("member : ", member);
        setting.isLogin = true;
        setting.email = member.user.email;
        setting.id = member.user.id;
        setting.phone = member.user.phone;
        setting.userName = member.user.name;
        setting.token = tempSignUpData.token;
        setting.snsProvider = member.user.snsProvider;
        window.localStorage.setItem("token", tempSignUpData.token);
        history.push("/");
        console.log("signUpData", signUpData);
      }
    }
  }, [signUpData]);

  useEffect(() => {
    if (signInData !== undefined) {
      const data1 = signInData.signIn;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        const member = jwtDecode(data1.token);
        setting.isLogin = true;
        setting.email = member.user.email;
        setting.id = member.user.id;
        setting.phone = member.user.phone;
        setting.userName = member.user.name;
        setting.token = data1.token;
        setting.snsProvider = "email";
        window.localStorage.setItem("token", data1.token);
        history.push("/");
      }
    }
  }, [signInData]);

  const handleKeyPress = (e) => {
    // 눌려진 키가 Enter 면 handleCreate 호출
    if (e.key === "Enter") {
      signInWithEmailFunction();
    }
  };

  const handleGoogleAuthentication = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(googleProvider);
      const { user } = result;

      if (!user) {
        throw new Error("Error authorizing");
      }
      const { displayName, email, uid } = user;
      setSnsUser({
        name: displayName,
        email,
        password: uid,
        snsProvider: "google",
      });

      findId({ variables: { email } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFacebookAuthentication = async () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(facebookProvider);
      const { user } = result;

      if (!user) {
        throw new Error("Error authorizing");
      }
      const { displayName, email, uid } = user;
      setSnsUser({
        name: displayName,
        email,
        password: uid,
        snsProvider: "facebook",
      });

      findId({ variables: { email } });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full h-screen font-mont text-sm  bg-white shadow-lg">
      {/* 아메일 , 암호 필드 표시  */}
      <div className="pt-8 mx-2 flex flex-row items-center justify-around">
        <Link to="/mypage/signup">
          <p className="font-mont fontsize-16 text-black text-sm text-center uppercase tracking-wider">
            {t("회원가입")}
          </p>
        </Link>
        <p className="font-mont fontsize-16 text-black text-sm font-bold text-center uppercase tracking-wider">
          {t("로그인")}
        </p>
      </div>
      <div className="mx-2 flex flex-row items-center justify-around">
        <p className="w-1/2 bg-gray height-1" />
        <p className="w-1/2 bg-black height-2" />
      </div>
      <div className="mx-2 mt-4 mb-4">
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          className="w-full px-2 py-2 outline-none"
          placeholder={t("이메일")}
        />
        <p className="w-full bg-black  height-1" />
      </div>

      <div className="mx-2 mb-4">
        <input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          onKeyPress={handleKeyPress}
          className="w-full px-2 py-2  outline-none"
          placeholder={t("암호")}
        />
        <p className="w-full bg-black  height-1" />
      </div>

      <div className="h-12 mx-2 mt-8 flex flex-wrap items-center justify-center bg-black">
        <div
          className="w-full btn-text  text-center text-white fontsize-16 uppercase"
          onClick={signInWithEmailFunction}
        >
          {t("로그인버튼")}
        </div>
      </div>
      {/* 아이디 찾기 */}
      <div className=" mx-2 mt-3 mb-4">
        <div className="w-full font-mont-normal fontsize-12 text-center flex-row-center">
          <Link to="/mypage/findid">
            <span className="no-underline text-black px-2 ml-2 ">
              {t("아이디 찾기")}
            </span>{" "}
            |
          </Link>
          <Link to="/mypage/findpassword">
            <span className="no-underline text-black px-2 ">
              {t("비밀번호 찾기")}
            </span>
          </Link>
        </div>
      </div>

      {/* social login */}
      <div className=" mx-2 ">
        <div className="w-full text-center flex flex-col">
          <div className="mt-2 w-full border-black border-1 text-center relative">
            <div
              onClick={() => {
                handleGoogleAuthentication();
              }}
              className="w-full h-10 flex-center"
            >
              <div className="w-full">{t("Google ID로 로그인")}</div>
              <img
                src={img_google}
                alt=""
                className="absolute left-0 w-8 h-8 ml-2"
              />
            </div>
          </div>
          <div className="mt-2 w-full border-black border-1 text-center relative">
            <div
              onClick={() => {
                handleFacebookAuthentication();
              }}
              className="w-full h-10 flex-center"
            >
              <div className="w-full">{t("Facebook ID로 로그인")}</div>
              <img
                src={img_facebook}
                alt=""
                className="absolute left-0 w-8 h-8 ml-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SignIn = () => {
  const setting = useSettings();
  console.log("setting.isMobile : ", setting.isMobile);
  setting.headerTitle = "logo";
  const screen = setting.isMobile ? (
    setting.isAdminScreenMode ? (
      <ScreenAdmin />
    ) : (
      <ScreenMobile />
    )
  ) : setting.isAdminScreenMode ? (
    <ScreenAdmin />
  ) : (
    <Screen />
  );
  return <div>{screen}</div>;
};

export default SignIn;
