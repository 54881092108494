import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";

import { useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import swal from "@sweetalert/with-react";
import MyPage from "./MyPage";
import { ALL_MEMBERS } from "../../graphql/gql/select";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-3/20 text-center">{t("이메일")}</div>
      <div className="w-1/20 text-center">{t("이미지")}</div>
      <div className="w-4/20 text-center">{t("이름")}</div>
      <div className="w-6/20 text-center">{t("전화")}</div>
      <div className="w-2/20 text-center">{t("")}</div>
      <div className="w-2/20 text-center">{t("")}</div>
    </div>
  );
};

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  console.log(`memberId : ${memberId}`);
  console.log("setting.allProductsUpdates: ", setting.allProductsUpdates);

  const { loading, error, data, refetch } = useQuery(ALL_MEMBERS);
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (setting.allMembersUpdates) {
    setting.allMembersUpdates = false;
    refetch();
  }
  if (!data || !data.allMembers) return <p>Error</p>;
  const items = data.allMembers;

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("회원 전체")}</div>
            <Link to="/mypage/member/new">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <Header />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              items &&
              items.length > 0 &&
              items.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-3/20 ">
                    <Link to={`/mypage/member/${item.id}`}>
                      <div className="w-full">{item.email}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center">
                    <Link to={`/mypage/member/${item.id}`}>
                      <div className="w-12 h-12 rounded-full mx-auto">
                        {item.img ? (
                          <img
                            src={item.img}
                            className="w-12 h-12 border rounded-full mx-auto"
                            alt=""
                          />
                        ) : (
                          <div className="w-full h-full rounded-full object-cover object-center border bg-black" />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-4/20 ">
                    <Link to={`/mypage/member/${item.id}`}>
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        <div className="w-full text-center">{item.name}</div>
                      </div>
                    </Link>
                  </div>
                  <div className="w-6/20 text-center">{item.phone}</div>
                  <div className="w-2/20 text-center" />
                  <div className="w-2/20 text-center flex-center" />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

const AllMembers = () => {
  const setting = useSettings();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default AllMembers;
