import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";
import { useQuery } from "@apollo/client";

import { observer } from "mobx-react-lite";
import { Tooltip } from "react-tippy";
import CmsPage from "./CmsPage";
import { ALL_CMS } from "../../../graphql/gql/select";
import CmsHeader from "./CmsHeader";
import CheckBox from "../../../components/CheckBox";

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;

  const { loading, error, data, refetch } = useQuery(ALL_CMS, {
    variables: { category: "recommend" },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (setting.cmsRecommendUpdates) {
    setting.cmsRecommendUpdates = false;
    refetch();
  }
  const sortFunc = (a, b) => {
    const a1 = Number(a.no) || 0;
    const b1 = Number(b.no) || 0;
    if (Number(a1) > b1) {
      return 1;
    }
    if (a1 < b1) {
      return -1;
    }
    // a must be equal to b
    return 0;
  };

  if (!data || !data.allCms) return <p>Error</p>;

  const itemsDaily = data.allCms
    .filter((item) => item.name === "daily")
    .sort(sortFunc);
  const itemsTitle = data.allCms
    .filter((item) => item.name === "title")
    .sort(sortFunc);
  const itemsLyrics = data.allCms
    .filter((item) => item.name === "lyrics")
    .sort(sortFunc);
  const itemsInterjection = data.allCms
    .filter((item) => item.name === "interjection")
    .sort(sortFunc);
  const itemsPoem = data.allCms
    .filter((item) => item.name === "poem")
    .sort(sortFunc);
  const itemsProverb = data.allCms
    .filter((item) => item.name === "proverb")
    .sort(sortFunc);

  return (
    <div>
      {console.log(data)}
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Recommend / Daily")}</div>
            <Link to="/mypage/cms/new/recommend/daily">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsDaily &&
              itemsDaily.length > 0 &&
              itemsDaily.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        {/* <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Recommend / Title")}</div>
            <div className="flex flex-row items-center justify-between ">
              <Link to="/mypage/cms/new/recommend/title">
                <i className="ml-5 fa fa-plus-square">추가</i>
              </Link>
            </div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsTitle &&
              itemsTitle.length > 0 &&
              itemsTitle.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        {/* <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Recommend / Lyrics")}</div>
            <Link to="/mypage/cms/new/recommend/lyrics">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsLyrics &&
              itemsLyrics.length > 0 &&
              itemsLyrics.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        {/* <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Recommend / Interjection")}</div>
            <Link to="/mypage/cms/new/recommend/interjection">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsInterjection &&
              itemsInterjection.length > 0 &&
              itemsInterjection.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        {/* <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Recommend / Poem")}</div>
            <Link to="/mypage/cms/new/recommend/poem">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsPoem &&
              itemsPoem.length > 0 &&
              itemsPoem.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        {/* <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Recommend / Proverb")}</div>
            <Link to="/mypage/cms/new/recommend/proverb">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsProverb &&
              itemsProverb.length > 0 &&
              itemsProverb.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        {/* <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});
const CmsRecommend = () => {
  const setting = useSettings();
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );
  return (
    <div>
      <CmsPage>
        <div className="">{screen}</div>
      </CmsPage>
    </div>
  );
};

export default CmsRecommend;
