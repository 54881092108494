import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";
import { useQuery } from "@apollo/client";
import { ALL_SPECIAL_ORDERS } from "graphql/gql/select";
import MyPage from "./MyPage";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/6 text-center">{t("의뢰 날짜")}</div>
      <div className="w-7/12 pl-4">{t("의뢰 내용")}</div>
      <div className="w-1/12 ">{t("첨부파일")}</div>
      <div className="w-2/12 text-center">{t("상태")}</div>
    </div>
  );
};

const MultiImages = ({ images }) => {
  console.log(images);
  // 여기서는 1개만 표시

  if (!images || images === "") return "";
  try {
    const detailImages = JSON.parse(images);
    if (!detailImages || detailImages.length === 0) return "";
    const MultiImages1 = (
      <img
        src={detailImages[0]}
        key={detailImages[0]}
        className="w-12 h-12 mr-2"
        alt=""
      />
    );
    return MultiImages1;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const Screen = () => {
  const { t } = useTranslation();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const setting = useSettings();
  const memberId = setting.id;
  console.log(`memberId : ${memberId}`);

  const { loading, error, data, refetch } = useQuery(ALL_SPECIAL_ORDERS, {
    variables: { memberId },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (setting.specialOrdersUpdates) {
    setting.specialOrdersUpdates = false;
    refetch();
  }

  if (!(data && data.allSpecialOrders)) return <p />;
  const items = data.allSpecialOrders;
  console.log("items: ", items);

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("의뢰 내역")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <Header />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              items &&
              items.length > 0 &&
              items.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-1/6 text-center">
                    <Moment unix format="YYYY.MM.DD">
                      {Number(item.createdAt) / 1000}
                    </Moment>
                  </div>

                  <div className="w-7/12 ">
                    <Link to={`/mypage/specialorderdetail/${item.id}`}>
                      <div className="flex-row-left ">
                        <div className="ml-4  font-mont fontsize-12 tracking-tight  text-black">
                          {item.contents}
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="w-1/12 ">
                    {/* <img className="w-10 h-10 border-black border" src={item.detailImage} alt="" /> */}
                    <MultiImages images={item.detailImage} />
                  </div>
                  <div className="w-2/12 text-center">{item.status}</div>
                </div>
              ))}
            {memberId !== "" && items.length === 0 ? (
              <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
                {t("내역이 없습니다")}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Link to="/mypage/specialorder">
        <div className="mt-16 w-48 bg-black table-text text-center text-white  py-2 px-4">
          {t("의뢰하기")}
        </div>
      </Link>
    </div>
  );
};

const ScreenMobile = () => {
  const { t } = useTranslation();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const setting = useSettings();
  const memberId = setting.id;
  console.log(`memberId : ${memberId}`);

  const { loading, error, data, refetch } = useQuery(ALL_SPECIAL_ORDERS, {
    variables: { memberId },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (setting.specialOrdersUpdates) {
    setting.specialOrdersUpdates = false;
    refetch();
  }

  if (!(data && data.allSpecialOrders)) return <p />;
  const items = data.allSpecialOrders;
  console.log("items: ", items);

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-end justify-between">
            <div className="content-title w-2/3 ">{t("의뢰 내역")}</div>
            <div className="w-1/3 flex justify-end">
              <div className="">
                <Link to="/mypage/specialorder">
                  <div className="w-24 bg-black table-text text-center text-white  py-1 px-0">
                    {t("의뢰하기")}
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              items &&
              items.length > 0 &&
              items.map((item) => (
                <div className="w-full flex-col-left table-text" key={item.id}>
                  <div className="text-left">
                    <Moment unix format="YYYY.MM.DD">
                      {Number(item.createdAt) / 1000}
                    </Moment>
                  </div>

                  <div className="mt-2 mb-2 ">
                    <Link to={`/mypage/specialorderdetail/${item.id}`}>
                      <div className="flex-row-left ">
                        <div className="ml-4  font-mont fontsize-12 tracking-tight  text-black">
                          {item.contents}
                        </div>
                      </div>
                    </Link>
                    <div className="flex flex-row justify-end">
                      <MultiImages images={item.detailImage} />
                    </div>
                  </div>
                  <div className="blackline" />
                </div>
              ))}
            {memberId !== "" && items.length === 0 ? (
              <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
                {t("내역이 없습니다")}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MySpecialOrders = () => {
  const setting = useSettings();
  const history = useHistory();
  // 로그인 안한 경우
  if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";
  if (setting.isAdminScreenMode) return "";
  const screen = setting.isMobile ? (
    <ScreenMobile />
  ) : (
    <MyPage>
      <Screen />
    </MyPage>
  );
  return <div>{screen}</div>;
};

export default MySpecialOrders;
