import React, { useState, useEffect } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import Moment from "react-moment";
import swal from "sweetalert";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import currencyFormatter from "currency-formatter";

import btn_modal_close from "assets/images/btn_modal_close.svg";
import { ALL_ORDERS, CREATE_QA, MULTI_UPLOAD_FILES } from "graphql/gql/select";
import { useQuery, useMutation } from "@apollo/client";
import customStyles from "../../components/modalStyle";
import MyPage from "./MyPage";
import { UPDATE_ORDER } from "../../graphql/gql/select";
import OrderHeader from "../../components/OrderHeader";
import MultiImages from "../../components/MultiImages";
import MultiVideos from "../../components/MultiVideos";
import StarEdit from "../../components/StarEdit";
import DateRange from "../../components/DateRange";

const preventIOSScroll = (e) => {
  e.preventDefault();
};

const Delivery = () => {
  const setting = useSettings();
  const [currentRange, setCurrentRange] = React.useState("all");

  const { loading, error, data } = useQuery(ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: { memberId: setting.id },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.allPays) return <p>Error</p>;
  const items = data.allPays;

  const date = new Date().getTime();

  // 1주일
  const itemsWeek = items.filter(
    (item) => (date - item.createdAt) / 1000 < 60 * 60 * 24 * 7
  );

  // 1달
  const itemsMonth = items.filter(
    (item) => (date - item.createdAt) / 1000 < 60 * 60 * 24 * 30
  );

  // 3달
  const itemsThreeMonth = items.filter(
    (item) => (date - item.createdAt) / 1000 < 60 * 60 * 24 * 90
  );

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <DateRange
        currentRange={currentRange}
        setCurrentRange={setCurrentRange}
      />
      {currentRange === "all" && <DeliveryList items={items} />}
      {currentRange === "1week" && <DeliveryList items={itemsWeek} />}
      {currentRange === "1month" && <DeliveryList items={itemsMonth} />}
      {currentRange === "3months" && <DeliveryList items={itemsThreeMonth} />}
      <DeliveryList items={items} />
    </div>
  );
};

const DeliveryMobile = () => {
  const { t } = useTranslation();
  const setting = useSettings();

  const [currentItem, setCurrentItem] = React.useState();
  // const [currentRange, setCurrentRange] = React.useState("all");
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
  const [questionModalOpen, setQuestionModalOpen] = React.useState(false);
  const [deliveryModalOpen, setDeliveryModalOpen] = React.useState(false);

  const { loading, error, data } = useQuery(ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: { memberId: setting.id },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.allOrders) return <p>Error</p>;
  const items = data.allOrders;

  return (
    <div className="w-full mt-6 px-2">
      {/* <DateRange currentRange={currentRange} setCurrentRange={setCurrentRange} /> */}
      <div className="mt-4 content-title">{t("주문배송 조회")}</div>
      <div className="blackline2 mt-1" />
      <SRLWrapper>
        <div className="flex-row-center text-medium-15 ">
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div className="w-full py-2" key={item.id}>
                <div className="w-full flex flex-col table-text">
                  <div className="w-full pl-0">
                    <div className="flex flex-row ">
                      <img
                        className="w-32 h-32 border-none"
                        src={item.product.mainImage}
                        alt=""
                      />
                      <div className="ml-4 flex flex-col">
                        <div className="font-bold ">
                          {item.product.name}({item.product.category})
                        </div>
                        <div className="text-left">
                          <Moment unix format="YYYY.MM.DD HH:mm">
                            {Number(item.createdAt) / 1000}
                          </Moment>
                        </div>

                        <div className="mt-1 ">
                          {item.designAvailable
                            ? t("디자인 있음")
                            : t("디자인 없음")}
                        </div>
                        <div className="">Size | {item.productSize}</div>
                        <div className="mt-1 font-bold">{item.status}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full text-center flex flex-wrap ">
                  <div className="w-1/2 text-center text-black ">
                    <div className="mx-3 text-center text-black font-bold">
                      {item.status}
                    </div>
                  </div>
                  <div className="w-1/2 text-center text-black ">
                    <div
                      className="mx-4 border border-black text-center text-black "
                      onClick={() => {
                        setCurrentItem(item);
                        setQuestionModalOpen(true);
                      }}
                    >
                      {t("11문의")}
                    </div>
                  </div>
                  <div className="mt-1 w-1/2 text-center text-black ">
                    <div
                      className="mx-4 border border-black text-center text-black "
                      onClick={() => {
                        setCurrentItem(item);
                        setDeliveryModalOpen(true);
                      }}
                    >
                      {t("배송조회")}
                    </div>
                  </div>
                  <div className="mt-1 w-1/2 text-center text-black ">
                    <div
                      className="mx-4 border border-black text-center text-black "
                      onClick={() => {
                        setCurrentItem(item);
                        setReviewModalOpen(true);
                      }}
                    >
                      {t("리뷰작성")}
                    </div>
                  </div>
                </div>
                <div className="mt-3 blackline" />
              </div>
            ))}
          {items.length === 0 ? (
            <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
              {t("내역이 없습니다")}
            </div>
          ) : (
            ""
          )}
        </div>
      </SRLWrapper>
      <DeliveryMobileModal
        modalIsOpen={deliveryModalOpen}
        closeModal={() => setDeliveryModalOpen(false)}
        currentItem={currentItem}
      />
      <QuestionMobileModal
        modalIsOpen={questionModalOpen}
        closeModal={() => setQuestionModalOpen(false)}
        currentItem={currentItem}
      />
      <ReviewMobileModal
        modalIsOpen={reviewModalOpen}
        closeModal={() => setReviewModalOpen(false)}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </div>
  );
};

const QuestionModal = ({ modalIsOpen, closeModal, currentItem }) => {
  const { t } = useTranslation();
  const [contents, setContents] = useState("");
  const [image, setImage] = useState("");
  const setting = useSettings();

  const [createQA, { data }] = useMutation(CREATE_QA, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.createQA;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("11 문의 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("11 문의가 잘 생성 되었습니다"), { button: false, timer: 3000 });
        setImage("");
        setContents("");
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }
    }
  }, [data]);

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);

        const curImages = [];
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curImages.push(file1);
        });
        setImage(JSON.stringify(curImages));
      }
      console.log(data1);
    }
  }, [multiData]);

  const handlerQAClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    createQA({
      variables: {
        memberId: setting.id,
        orderId: currentItem.id,
        contents,
        images: image,
        replyAt: "",
        replyContents: "",
      },
    });
  };

  const uploadClick = () => {
    document.getElementById("selectImage").click();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-140 flex flex-row bg-transparent">
        <div className="p-4 w-120 table-text bg-white">
          <div className="dialog-header-bold">{t("11문의")}</div>
          <div className="mt-2 blackline2" />
          <div className="w-full bg-white">
            <div className="mt-3 flex-row-left">
              <div className="w-12 font-bold">{t("이름")}</div>
              <input
                type="text"
                className="w-auto px-2 outline-none"
                placeholder={t("이름")}
                readOnly
                defaultValue={setting.userName}
              />
            </div>

            <div className="mt-3 flex-row-left">
              <div className="w-12 font-bold">{t("이메일")}</div>
              <input
                id="email"
                type="email"
                className="w-auto px-2 outline-none"
                placeholder={t("이메일")}
                readOnly
                defaultValue={setting.email}
              />
            </div>
            <div className="mt-3 h-40 flex-col-left">
              <div className="w-12 font-bold">{t("내용")}</div>
              <textarea
                onChange={(e) => setContents(e.target.value)}
                type="text"
                className="mt-3 w-full px-2 py-2 border outline-none h-full dialog-bg-gray"
                placeholder={t("내용을 적어주세요")}
                value={contents}
              />
            </div>
            <div className="mt-4 flex-col-left">
              <div className="w-12 font-bold">{t("첨부파일")}</div>
              <div className="mt-3 h-8 flex flex-row table-text relative">
                <div className="flex-col-left">
                  <form
                    onSubmit={() => {
                      console.log("Submitted");
                    }}
                    encType="multipart/form-data"
                  >
                    <div
                      className="w-16 h-8 ml-0 bg-black text-center text-white py-2 px-1 "
                      onClick={uploadClick}
                    >
                      {t("찾아보기")}
                    </div>
                    <input
                      id="selectImage"
                      name="document"
                      multiple
                      type="file"
                      accept="image/*"
                      method="POST"
                      hidden
                      onChange={async ({ target: { files } }) => {
                        if (files) multiUpload({ variables: { files } });
                      }}
                    />
                  </form>
                  <MultiImages images={image} className="px-1" />
                </div>
                <button
                  className="absolute right-0 w-48 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                  onClick={handlerQAClick}
                >
                  {t("문의하기")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* 대화상자 종료 버튼 */}
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6 "
            src={btn_modal_close}
            alt=""
            onClick={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
};

const ReviewModal = ({
  modalIsOpen,
  closeModal,
  currentItem,
  setCurrentItem,
}) => {
  console.log("ReviewModal , item= ", currentItem); // order item

  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const setting = useSettings();

  const [updateOrder, { data: updateOrderData }] = useMutation(UPDATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updateOrderData !== undefined) {
      const data1 = updateOrderData.updateOrder;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("리뷰가 잘 생성 되었습니다"), { button: false, timer: 3000 });
        setImage("");
        setVideo("");
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );

        closeModal();
      }
    }
  }, [updateOrderData]);

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);

        const curImages = [];
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curImages.push(file1);
        });
        setImage(JSON.stringify(curImages));
        setCurrentItem({
          ...currentItem,
          reviewImage: JSON.stringify(curImages),
        });
      }
      console.log(data1);
    }
  }, [multiData]);

  // 메인 이미지만 올리기
  const [multiUpload2, { data: multiData2 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData2 !== undefined) {
      const data1 = multiData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);

        const curVideos = [];
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curVideos.push(file1);
        });
        setVideo(JSON.stringify(curVideos));
        setCurrentItem({
          ...currentItem,
          reviewVideo: JSON.stringify(curVideos),
        });
      }
      console.log(data1);
    }
  }, [multiData2]);

  const handlerReviewClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    updateOrder({
      variables: {
        id: currentItem.id,
        reviewStar: currentItem.reviewStar,
        reviewImage: currentItem.reviewImage,
        reviewVideo: currentItem.reviewVideo,
        reviewText: currentItem.reviewText,
      },
    });
  };

  const uploadClick = () => {
    document.getElementById("selectImage").click();
  };
  const uploadVideoClick = () => {
    document.getElementById("selectVideo").click();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-140 flex flex-row bg-transparent">
        <div className="p-4 w-120 table-text bg-white">
          <div className="dialog-header-bold">{t("리뷰쓰기")}</div>
          <div className="mt-2 blackline2" />
          <StarEdit currentItem={currentItem} setCurrentItem={setCurrentItem} />
          <div className="mt-3 flex flex-row relative">
            <form
              onSubmit={() => {
                console.log("Submitted");
              }}
              encType="multipart/form-data"
            >
              <div
                className="w-20 border border-black text-center text-black py-1 px-1 "
                onClick={uploadClick}
              >
                {t("사진첨부")}
              </div>
              <input
                id="selectImage"
                name="document"
                multiple
                type="file"
                accept="image/*"
                method="POST"
                hidden
                onChange={async ({ target: { files } }) => {
                  if (files) multiUpload({ variables: { files } });
                }}
              />
            </form>

            <form
              onSubmit={() => {
                console.log("Submitted");
              }}
              encType="multipart/form-data"
            >
              <div
                className="w-20 ml-2 border border-black text-center text-black py-1 px-1 "
                onClick={uploadVideoClick}
              >
                {t("영상첨부")}
              </div>
              <input
                id="selectVideo"
                name="document"
                multiple
                type="file"
                accept="video/*"
                method="POST"
                hidden
                onChange={async ({ target: { files } }) => {
                  if (files) multiUpload2({ variables: { files } });
                }}
              />
            </form>
            <div className="w-20 ml-2 border border-black text-center text-black py-1 px-1 ">
              {t("디자인첨부")}
            </div>

            <button
              className="absolute right-0 w-16 bg-black text-center text-white py-1 px-1 "
              onClick={() => {
                handlerReviewClick();
              }}
            >
              {t("등록하기")}
            </button>
          </div>

          <MultiImages images={image} className="px-1" />
          <MultiVideos videos={video} className="px-1" />
          <div className="mt-3">
            <div className="">
              <input
                className="w-full h-40 dialog-bg-gray px-2"
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, reviewText: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        {/* 대화상자 종료 버튼 */}
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6 "
            src={btn_modal_close}
            alt=""
            onClick={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
};

const DeliveryModal = ({ modalIsOpen, closeModal, currentItem }) => {
  const { t } = useTranslation();

  if (!currentItem) return "";
  const item = currentItem;
  console.log("DeliveryModal item: ", item);

  const Header = () => {
    return (
      <div className="w-full flex-row-center table-header-bold ">
        <div className="w-1/4 text-center">{t("처리일시")}</div>
        <div className="w-2/4 text-center">{t("현재위치")}</div>
        <div className="w-1/4 text-center">{t("상태")}</div>
      </div>
    );
  };

  // TODO 실시간 조회 코드 넣을 것
  const Contents = () => {
    const delivers = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }];
    return delivers.map((item) => (
      <div className="min-h" key={item.id}>
        <div className="w-full h-6 flex-row-center table-text ">
          <div className="w-1/4 text-center">{item.date}</div>
          <div className="w-2/4 text-center">{item.location}</div>
          <div className="w-1/4 text-center">{item.status}</div>
        </div>
        <div className="mt-0 grayline" />
      </div>
    ));
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-140 flex flex-row bg-transparent">
        <div className="p-4 w-120 table-text bg-white">
          <div className="dialog-header-bold">{t("배송조회")}</div>
          <div className="mt-2 blackline2" />

          <div className="mt-3 dialog-header-bold text-center">
            {item.invoiceNumber ? t("배송중 입니다") : t("배송전 입니다")}
          </div>
          <div className="mt-3 grayline" />

          <div className="mt-3 flex-row-left ">
            <img
              className="w-10 h-10 border-black border"
              src={item.product.mainImage}
              alt=""
            />
            <div className="ml-4 flex-col-left">
              <div className="font-bold ">{item.product.name}</div>
              <div className="mt-1 ">
                {item.designAvailable ? t("디자인 있음") : t("디자인 없음")}
              </div>
            </div>
          </div>

          <div className="mt-3 blackline2" />

          <div>
            <div className="mt-2 table-text-bold">{t("송장정보")}</div>
            <div className="mt-2 grayline" />

            <div className="ml-0 mt-1 flex-row-left">
              <div className="w-12 table-text">{t("송장번호")}</div>
              <div className="ml-2 table-text">{item.invoiceNumber}</div>
            </div>
            <div className="ml-0 mt-0 flex-row-left">
              <div className="w-12 table-text">{t("택배사")}</div>
              <div className="ml-2 table-text">{item.invoiceCompany}</div>
            </div>
            <div className="ml-0 mt-0 flex-row-left">
              <div className="w-12 table-text">{t("대표번호")}</div>
              <div className="ml-2 table-text">{item.invoicePhone}</div>
            </div>
          </div>
          <div className="mt-3 blackline2" />
          <div>
            <Header />
            <div className="mt-2 grayline" />
            <Contents />
          </div>
        </div>
        {/* 대화상자 종료 버튼 */}
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6 "
            src={btn_modal_close}
            alt=""
            onClick={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
};

const QuestionMobileModal = ({ modalIsOpen, closeModal, currentItem }) => {
  const { t } = useTranslation();
  const [contents, setContents] = useState("");
  const [image, setImage] = useState("");
  const setting = useSettings();

  const [createQA, { data }] = useMutation(CREATE_QA, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.createQA;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("11 문의 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("11 문의가 잘 생성 되었습니다"), { button: false, timer: 3000 });
        setImage("");
        setContents("");
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );

        closeModal();
      }
    }
  }, [data]);

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);

        const curImages = [];
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curImages.push(file1);
        });
        setImage(JSON.stringify(curImages));
      }
      console.log(data1);
    }
  }, [multiData]);

  const handlerQAClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    createQA({
      variables: {
        memberId: setting.id,
        orderId: currentItem.id,
        contents,
        images: image,
        replyAt: "",
        replyContents: "",
      },
    });
  };

  const uploadClick = () => {
    document.getElementById("selectImage").click();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-full flex flex-row bg-transparent">
        <div className="p-2 w-full table-text bg-white">
          <div className="dialog-header-bold">{t("11문의")}</div>
          <div className="mt-2 blackline2" />
          <div className="w-full bg-white">
            <div className="mt-3 flex-row-left">
              <div className="w-12 font-bold">{t("이름")}</div>
              <input
                type="text"
                className="w-auto px-2 outline-none"
                placeholder={t("이름")}
                readOnly
                defaultValue={setting.userName}
              />
            </div>

            <div className="mt-3 flex-row-left">
              <div className="w-12 font-bold">{t("이메일")}</div>
              <input
                id="email"
                type="email"
                className="w-auto px-2 outline-none"
                placeholder={t("이메일")}
                readOnly
                defaultValue={setting.email}
              />
            </div>
            <div className="mt-3 h-40 flex-col-left">
              <div className="w-12 font-bold">{t("내용")}</div>
              <textarea
                onChange={(e) => setContents(e.target.value)}
                type="text"
                className="mt-3 w-full px-2 py-2 border outline-none h-full dialog-bg-gray"
                placeholder={t("내용을 적어주세요")}
                value={contents}
              />
            </div>
            <div className="mt-4 flex-col-left">
              <div className="w-12 font-bold">{t("첨부파일")}</div>
              <div className="mt-3 h-8 flex flex-row table-text relative">
                <form
                  onSubmit={() => {
                    console.log("Submitted");
                  }}
                  encType="multipart/form-data"
                >
                  <div
                    className="w-16 h-8 ml-0 bg-black text-center text-white py-2 px-1 "
                    onClick={uploadClick}
                  >
                    {t("찾아보기")}
                  </div>
                  <input
                    id="selectImage"
                    name="document"
                    multiple
                    type="file"
                    accept="image/*"
                    method="POST"
                    hidden
                    onChange={async ({ target: { files } }) => {
                      if (files) multiUpload({ variables: { files } });
                    }}
                  />
                </form>
                <MultiImages images={image} className="mx-1 px-1" />
                <button
                  className="absolute right-0 w-48 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                  onClick={handlerQAClick}
                >
                  {t("문의하기")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ReviewMobileModal = ({
  modalIsOpen,
  closeModal,
  currentItem,
  setCurrentItem,
}) => {
  console.log("ReviewModal , item= ", currentItem); // order item

  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const setting = useSettings();

  const [updateOrder, { data: updateOrderData }] = useMutation(UPDATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updateOrderData !== undefined) {
      const data1 = updateOrderData.updateOrder;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("리뷰가 잘 생성 되었습니다"), { button: false, timer: 3000 });
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );

        closeModal();
      }
    }
  }, [updateOrderData]);

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);

        const curImages = [];
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curImages.push(file1);
        });
        setImage(JSON.stringify(curImages));
        setCurrentItem({
          ...currentItem,
          reviewImage: JSON.stringify(curImages),
        });
      }
      console.log(data1);
    }
  }, [multiData]);

  // 메인 이미지만 올리기
  const [multiUpload2, { data: multiData2 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData2 !== undefined) {
      const data1 = multiData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);

        const curVideos = [];
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curVideos.push(file1);
        });
        setVideo(JSON.stringify(curVideos));
        setCurrentItem({
          ...currentItem,
          reviewVideo: JSON.stringify(curVideos),
        });
      }
      console.log(data1);
    }
  }, [multiData2]);

  const handlerReviewClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    updateOrder({
      variables: {
        id: currentItem.id,
        reviewStar: currentItem.reviewStar,
        reviewImage: currentItem.reviewImage,
        reviewVideo: currentItem.reviewVideo,
        reviewText: currentItem.reviewText,
      },
    });
  };

  const uploadClick = () => {
    document.getElementById("selectImage").click();
  };
  const uploadVideoClick = () => {
    document.getElementById("selectVideo").click();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-full flex flex-row bg-transparent">
        <div className="p-2 w-full table-text bg-white">
          <div className="dialog-header-bold">{t("리뷰쓰기")}</div>
          <div className="mt-2 blackline2" />
          <StarEdit currentItem={currentItem} setCurrentItem={setCurrentItem} />
          <div className="mt-3 flex flex-row relative">
            <form
              onSubmit={() => {
                console.log("Submitted");
              }}
              encType="multipart/form-data"
            >
              <div
                className="w-20 border border-black text-center text-black py-1 px-1 "
                onClick={uploadClick}
              >
                {t("사진첨부")}
              </div>
              <input
                id="selectImage"
                name="document"
                multiple
                type="file"
                accept="image/*"
                method="POST"
                hidden
                onChange={async ({ target: { files } }) => {
                  if (files) multiUpload({ variables: { files } });
                }}
              />
            </form>

            <form
              onSubmit={() => {
                console.log("Submitted");
              }}
              encType="multipart/form-data"
            >
              <div
                className="w-20 ml-2 border border-black text-center text-black py-1 px-1 "
                onClick={uploadVideoClick}
              >
                {t("영상첨부")}
              </div>
              <input
                id="selectVideo"
                name="document"
                multiple
                type="file"
                accept="video/*"
                method="POST"
                hidden
                onChange={async ({ target: { files } }) => {
                  if (files) multiUpload2({ variables: { files } });
                }}
              />
            </form>
            <div className="w-20 ml-2 border border-black text-center text-black py-1 px-1 ">
              {t("디자인첨부")}
            </div>

            <button
              className="absolute right-0 w-16 bg-black text-center text-white py-1 px-1 "
              onClick={() => {
                handlerReviewClick();
              }}
            >
              {t("등록하기")}
            </button>
          </div>

          <MultiImages images={image} className="px-1" />
          <MultiVideos videos={video} className="px-1" />
          <div className="mt-3">
            <div className="">
              <input
                className="w-full h-40 dialog-bg-gray px-2"
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, reviewText: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        {/* 대화상자 종료 버튼 */}
        {/* <div className="w-16 bg-transparent">
          <img className="ml-4 mt-1 w-6 h-6 " src={btn_modal_close} alt="" onClick={closeModal} />
        </div> */}
      </div>
    </Modal>
  );
};

const DeliveryMobileModal = ({ modalIsOpen, closeModal, currentItem }) => {
  const { t } = useTranslation();

  if (!currentItem) return "";
  const item = currentItem;
  console.log("DeliveryModal item: ", item);

  const Header = () => {
    return (
      <div className="w-full flex-row-center table-header-bold ">
        <div className="w-1/4 text-center">{t("처리일시")}</div>
        <div className="w-2/4 text-center">{t("현재위치")}</div>
        <div className="w-1/4 text-center">{t("상태")}</div>
      </div>
    );
  };

  // TODO 실시간 조회 코드 넣을 것
  const Contents = () => {
    const delivers = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }];
    return delivers.map((item) => (
      <div className="min-h" key={item.id}>
        <div className="w-full h-6 flex-row-center table-text ">
          <div className="w-1/4 text-center">{item.date}</div>
          <div className="w-2/4 text-center">{item.location}</div>
          <div className="w-1/4 text-center">{item.status}</div>
        </div>
        <div className="mt-0 grayline" />
      </div>
    ));
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-full flex flex-row bg-transparent">
        <div className="p-2 w-full table-text bg-white">
          <div className="dialog-header-bold">{t("배송조회")}</div>
          <div className="mt-2 blackline2" />

          <div className="mt-3 dialog-header-bold text-center">
            {item.invoiceNumber ? t("배송중 입니다") : t("배송전 입니다")}
          </div>
          <div className="mt-3 grayline" />

          <div className="mt-3 flex-row-left ">
            <img
              className="w-10 h-10 border-black border"
              src={item.product.mainImage}
              alt=""
            />
            <div className="ml-4 flex-col-left">
              <div className="font-bold ">{item.product.name}</div>
              <div className="mt-1 ">
                {item.designAvailable ? t("디자인 있음") : t("디자인 없음")}
              </div>
            </div>
          </div>

          <div className="mt-3 blackline2" />

          <div>
            <div className="mt-2 table-text-bold">{t("송장정보")}</div>
            <div className="mt-2 grayline" />

            <div className="ml-0 mt-1 flex-row-left">
              <div className="w-12 table-text">{t("송장번호")}</div>
              <div className="ml-2 table-text">{item.invoiceNumber}</div>
            </div>
            <div className="ml-0 mt-0 flex-row-left">
              <div className="w-12 table-text">{t("택배사")}</div>
              <div className="ml-2 table-text">{item.invoiceCompany}</div>
            </div>
            <div className="ml-0 mt-0 flex-row-left">
              <div className="w-12 table-text">{t("대표번호")}</div>
              <div className="ml-2 table-text">{item.invoicePhone}</div>
            </div>
          </div>
          <div className="mt-3 blackline2" />
          <div>
            <Header />
            <div className="mt-2 grayline" />
            <Contents />
          </div>
        </div>
        {/* 대화상자 종료 버튼 */}
        {/* <div className="w-8 bg-transparent">
          <img className="ml-1 mt-1 w-4 h-4 " src={btn_modal_close} alt="" onClick={closeModal} />
        </div> */}
      </div>
    </Modal>
  );
};

Modal.setAppElement("#root");

const MyDelivery = () => {
  const setting = useSettings();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  if (setting.isAdminScreenMode && setting.isAdmin && setting.isLogin)
    return "";

  setting.headerTitle = "My Page";
  const screen = setting.isMobile ? (
    <DeliveryMobile />
  ) : (
    <MyPage>
      <Delivery />
    </MyPage>
  );
  return <div>{screen}</div>;
};

const DeliveryList = ({ items }) => {
  const { t } = useTranslation();

  const [currentItem, setCurrentItem] = useState();
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  return (
    <div>
      <div className="mt-4 content-title">{t("주문배송 조회")}</div>
      <div className="blackline2 mt-1" />
      <SRLWrapper>
        <div className="flex-row-center text-medium-15">
          <OrderHeader />
          <div className="grayline" />
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div className="w-full py-2" key={item.id}>
                <div className="w-full flex-row-center table-text">
                  <div className="w-3/12 pl-0">
                    <div className="flex-row-left">
                      <img
                        className="w-10 h-10 border-black border"
                        src={item.product.mainImage}
                        alt=""
                      />
                      <div className="ml-4 flex-col-left">
                        <div className="font-bold">{item.product.name}</div>
                        <div className="mt-1">
                          {item.designAvailable
                            ? t("디자인 있음")
                            : t("디자인 없음")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/12 text-center">
                    <Moment unix format="YYYY.MM.DD">
                      {Number(item.createdAt) / 1000}
                    </Moment>
                  </div>
                  <div className="w-2/12 text-center">{item.id}</div>
                  <div className="w-2/12 text-center">
                    {currencyFormatter.format(Number(item.productTotal), {
                      code: item.currency,
                    })}
                  </div>
                  <div className="w-4/12 text-center flex flex-wrap">
                    <div className="w-1/2 text-center text-black">
                      <div className="mx-3 text-center text-black font-bold">
                        {item.status}
                      </div>
                    </div>
                    <div className="w-1/2 text-center text-black">
                      <div
                        className="mx-4 border border-black text-center text-black"
                        onClick={() => {
                          setCurrentItem(item);
                          setQuestionModalOpen(true);
                        }}
                      >
                        {t("11문의")}
                      </div>
                    </div>
                    <div className="mt-1 w-1/2 text-center text-black">
                      <div
                        className="mx-4 border border-black text-center text-black"
                        onClick={() => {
                          setCurrentItem(item);
                          setDeliveryModalOpen(true);
                        }}
                      >
                        {t("배송조회")}
                      </div>
                    </div>
                    <div className="mt-1 w-1/2 text-center text-black">
                      <div
                        className="mx-4 border border-black text-center text-black"
                        onClick={() => {
                          setCurrentItem(item);
                          setReviewModalOpen(true);
                        }}
                      >
                        {t("리뷰작성")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grayline" />
              </div>
            ))}
          {items && items.length === 0 ? (
            <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
              {t("내역이 없습니다")}
            </div>
          ) : (
            ""
          )}
        </div>
      </SRLWrapper>
      <DeliveryModal
        modalIsOpen={deliveryModalOpen}
        closeModal={() => setDeliveryModalOpen(false)}
        currentItem={currentItem}
      />
      <QuestionModal
        modalIsOpen={questionModalOpen}
        closeModal={() => setQuestionModalOpen(false)}
        currentItem={currentItem}
      />
      <ReviewModal
        modalIsOpen={reviewModalOpen}
        closeModal={() => setReviewModalOpen(false)}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </div>
  );
};

export default MyDelivery;
