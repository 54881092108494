import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useQuery } from "@apollo/client";
// import { FIND_PRODUCT_LIKE_LIST } from "../../graphql/gql/select";
import { FIND_PRODUCT_LIKE } from "../../graphql/gql/select";
import MyPage from "./MyPage";

import MyLikeProduct from "./MyLikeProduct";
import useQrCode from "react-qrcode-hook";
import MyLikeTemplate from "./MyLikeTemplate";

const Likes = () => {
  const { t } = useTranslation();
  const setting = useSettings();

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <MyLikeProduct />
      <MyLikeTemplate />
    </div>
  );
};

const LikesMobile = () => {
  // TODO Like 쿼리 만들것
  const { t } = useTranslation();
  const noDataStr = t("내역이 없습니다");
  const items = [];
  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Like</div>
      <div className="blackline2 mt-1" />
      <div className="mt-4 flex flex-row flex-wrap">
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="flex-col-center mr-4 mb-4" key={item.id}>
              <img
                className="w-32 h-32 border-black border"
                src={item.img}
                alt=""
              />
              <div className="mt-2 text-xs text-bold-tight-15">
                {item.templateName}
              </div>
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {noDataStr}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const MyLikes = () => {
  const setting = useSettings();
  // const history = useHistory();

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";
  const screen = setting.isMobile ? (
    <LikesMobile />
  ) : (
    <MyPage>
      <Likes />
    </MyPage>
  );

  return <div>{screen}</div>;
};

export default MyLikes;
