import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { getLength, getAngle, getCursor } from "../utils";
import StyledRect from "./StyledRect";
import resizeButton from "assets/images/resizeButton.svg";
import trashButton from "assets/images/trashButton.svg";
import icon_translate from "assets/images/icon_translate.svg";
import { async } from "regenerator-runtime";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://enkino.xyz:9901"
    : "https://enkino.xyz:9901";

const zoomableMap = {
  n: "t",
  s: "b",
  e: "r",
  w: "l",
  ne: "tr",
  nw: "tl",
  se: "br",
  sw: "bl",
};

export default class Rect extends PureComponent {
  static propTypes = {
    // children: PropTypes.children,
    visible: PropTypes.bool,
    styles: PropTypes.object,
    zoomable: PropTypes.string,
    rotatable: PropTypes.bool,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    parentRotateAngle: PropTypes.number,
    graphicItems: PropTypes.array,
    setGraphicItems: PropTypes.func,
    setCurrentItem: PropTypes.func,
    graphicItem: PropTypes.object.isRequired,
  };

  setElementRef = (ref) => {
    this.$element = ref;
  };

  // Drag
  startDrag = (e) => {
    let { clientX: startX, clientY: startY } = e;
    this.props.onDragStart && this.props.onDragStart();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const deltaX = clientX - startX;
      const deltaY = clientY - startY;
      this.props.onDrag(deltaX, deltaY);
      startX = clientX;
      startY = clientY;
    };
    const onUp = () => {
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onDragEnd && this.props.onDragEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  // Rotate
  startRotate = (e) => {
    if (e.button !== 0) return;
    const { clientX, clientY } = e;
    const {
      styles: {
        transform: { rotateAngle: startAngle },
      },
    } = this.props;
    const rect = this.$element.getBoundingClientRect();
    const center = {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
    const startVector = {
      x: clientX - center.x,
      y: clientY - center.y,
    };
    this.props.onRotateStart && this.props.onRotateStart();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const rotateVector = {
        x: clientX - center.x,
        y: clientY - center.y,
      };
      const angle = getAngle(startVector, rotateVector);
      this.props.onRotate(angle, startAngle);
    };
    const onUp = () => {
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onRotateEnd && this.props.onRotateEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  // Resize
  startResize = (e, cursor) => {
    if (e.button !== 0) return;
    document.body.style.cursor = cursor;
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle },
      },
    } = this.props;
    const { clientX: startX, clientY: startY } = e;
    const rect = { width, height, centerX, centerY, rotateAngle };
    const type = e.target.getAttribute("class").split(" ")[0];
    this.props.onResizeStart && this.props.onResizeStart();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const deltaX = clientX - startX;
      const deltaY = clientY - startY;
      const alpha = Math.atan2(deltaY, deltaX);
      const deltaL = getLength(deltaX, deltaY);
      const isShiftKey = e.shiftKey;
      this.props.onResize(deltaL, alpha, rect, type, isShiftKey);
    };

    const onUp = () => {
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onResizeEnd && this.props.onResizeEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  render() {
    const {
      visible,
      children,
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle },
      },
      zoomable,
      rotatable,
      parentRotateAngle,
      graphicItems,
      setGraphicItems,
      setCurrentItem,
      graphicItem,
    } = this.props;
    const style = {
      width: Math.abs(width),
      height: Math.abs(height),
      transform: `rotate(${rotateAngle}deg)`,
      left: centerX - Math.abs(width) / 2,
      top: centerY - Math.abs(height) / 2,
      border: visible ? `1px solid #0000dd` : `0px`,
    };
    const direction = zoomable
      .split(",")
      .map((d) => d.trim())
      .filter((d) => d); // TODO: may be speed up

    const send = async (text) => {
      const response = await fetch(`${API_URL}/translate?text=${text}`);
      const result = await response.json();
      return result.data;
    };

    return (
      <StyledRect
        ref={this.setElementRef}
        onMouseDown={this.startDrag}
        className="rect single-resizer"
        style={style}
      >
        {/* {visible && (
          <div className="moverect" onMouseDown={this.startDrag}>
            <img
              src={trashButton}
              className="w-5 h-5"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        )} */}
        {visible && (
          <div className="delete">
            <img
              onClick={() => {
                const temp = this.props.graphicItems;
                console.log(
                  "this.props.graphicItems;: ",
                  this.props.graphicItems
                );
                console.log("temp: ", temp);
                if (temp) {
                  this.props.setGraphicItems(
                    temp.filter((item) => item.id !== this.props.graphicItem.id)
                  );
                }
                this.props.setCurrentItem(null);
              }}
              src={trashButton}
              className="w-12 h-12 "
            />
          </div>
        )}
        {visible && (
          <div className="translate">
            <img
              onClick={async () => {
                const temp = this.props.graphicItems;
                const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
                const tempResult = await send(this.props.graphicItem.txtSrc);
                const tempArray = temp.map((item) => {
                  if (item.id !== this.props.graphicItem.id) return item;
                  const temp1 = item;
                  if (korean.test(temp1.txtSrc)) {
                    if (temp1.txtSrc === temp1.txtKor) {
                      if (temp1.txtEng) {
                        temp1.txtSrc = temp1.txtEng;
                        console.log("temp1 -1 : ", temp1);
                        return temp1;
                      } else {
                        temp1.txtSrc = tempResult;
                        temp1.txtEng = tempResult;
                        console.log("temp1 -2: ", temp1);
                        return temp1;
                      }
                    } else {
                      temp1.txtSrc = tempResult;
                      temp1.txtEng = tempResult;
                      console.log("temp1 -3: ", temp1);
                      return temp1;
                    }
                  } else {
                    if (temp1.txtSrc === temp1.txtEng) {
                      if (temp1.txtKor) {
                        temp1.txtSrc = temp1.txtKor;
                        console.log("temp1 -4: ", temp1);
                        return temp1;
                      } else {
                        temp1.txtSrc = tempResult;
                        temp1.txtKor = tempResult;
                        console.log("temp1 -5: ", temp1);
                        return temp1;
                      }
                    } else {
                      temp1.txtSrc = tempResult;
                      temp1.txtKor = tempResult;
                      console.log("temp1 -6: ", temp1);
                      return temp1;
                    }
                  }
                });
                this.props.setGraphicItems(tempArray);
              }}
              src={icon_translate}
              className="w-5 h-5"
            />
          </div>
        )}
        {visible && rotatable && (
          <div className="rotate" onMouseDown={this.startRotate}>
            <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.536 3.464A5 5 0 1 0 11 10l1.424 1.425a7 7 0 1 1-.475-9.374L13.659.34A.2.2 0 0 1 14 .483V5.5a.5.5 0 0 1-.5.5H8.483a.2.2 0 0 1-.142-.341l2.195-2.195z"
                fill="#0000FF"
                fillRule="nonzero"
              />
            </svg>
          </div>
        )}
        {visible &&
          direction.map((d) => {
            const cursor = `${getCursor(
              rotateAngle + parentRotateAngle,
              d
            )}-resize`;
            return (
              <div
                key={d}
                style={{ cursor }}
                className={`${zoomableMap[d]} resizable-handler`}
                onMouseDown={(e) => this.startResize(e, cursor)}
              />
            );
          })}
        {visible &&
          direction.map((d) => {
            return (
              <img
                src={resizeButton}
                key={d}
                className={`${zoomableMap[d]} square`}
              />
            );
          })}
        {children}
      </StyledRect>
    );
  }
}
