import React from "react";
import Modal from "react-modal";
import customStyles from "components/modalStyle";
import btn_modal_close from "assets/images/btn_modal_close.svg";
import useSettings from "stores/settings";

const ReviewModalAdmin2 = ({ modalIsOpen, closeModal, reviewItem }) => {
  const onRequestClose = () => {
    document.documentElement.style.overflowY = "visible";
    document.documentElement.removeEventListener("touchmove", preventIOSScroll);
    closeModal();
  };

  const preventIOSScroll = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div style={{ width: 800 }} className="flex flex-row bg-transparent">
        <div className="p-4 w-full table-text bg-white flex flex-col">
          {/* {console.log("reviewItem : ", reviewItem)} */}
          <div className="w-full flex">
            <div className="w-1/2">
              {reviewItem && reviewItem.mainImg && (
                <img className="w-full" src={reviewItem.mainImg} alt="" />
              )}
            </div>
            <div className="ml-4 w-1/2 relative">
              <div className="w-full border-b absolute top-0 h-20 flex">
                <div className="my-auto w-14 h-14 rounded-full bg-black" />
                <div className="my-auto ml-4 h-14 font-bold fontsize-20 items-center">
                  닉네임
                </div>
              </div>
              <div className="mt-20 fontsize-16 w-full">
                {reviewItem && reviewItem.comment && (
                  <div>{reviewItem.comment}</div>
                )}
              </div>
              <div className="mt-4 font-bold fontsize-16">
                #해시태그 #해시태그 #해시태그
              </div>
              <div className="w-full absolute bottom-0 h-40 bg-gray flex flex-col">
                {" "}
              </div>
            </div>
          </div>
        </div>
        <div className="w-16 bg-transparent">
          <img
            className="w-16 mt-1 w-6 h-6"
            src={btn_modal_close}
            alt=""
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModalAdmin2;
