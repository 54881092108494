/* eslint-disable react/react-in-jsx-scope */
import React from "react";

const MultiImagesVertical = ({ images }) => {
  console.log(images);

  if (!images || images === "") return "";
  try {
    const detailImages = JSON.parse(images);
    if (!detailImages || detailImages.length === 0) return "";
    const MultiImage1 = detailImages.map((image) => {
      return <img src={image} key={image} className="w-full" alt="" />;
    });
    return MultiImage1;
  } catch (e) {
    return "";
  }
};
export default MultiImagesVertical;
