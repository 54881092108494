import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDiHYnJxgeKEkYSgER60EjIGoX6im6kS4w",
  authDomain: "playtiger-api2.firebaseapp.com",
  databaseURL: "https://playtiger-api2.firebaseio.com",
  projectId: "playtiger-api2",
  storageBucket: "playtiger-api2.appspot.com",
  messagingSenderId: "264170838791",
  appId: "1:264170838791:web:fb00f00cfcdd89d120c3a2",
  measurementId: "G-7KKV3Y1BSR",
};

export default function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}
