// import React from "react";
// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import useSettings from "stores/settings";
// import swal from "@sweetalert/with-react";

// import { useQuery } from "@apollo/client";

// import { observer } from "mobx-react-lite";
// import "react-tippy/dist/tippy.css";
// import { Tooltip } from "react-tippy";
// import CmsPage from "./CmsPage";
// import { ALL_CMS } from "../../../graphql/gql/select";
// import CmsHeader from "./CmsHeader";
// import CheckBox from "../../../components/CheckBox";

// const ScreenAdmin = observer(() => {
//   const { t } = useTranslation();
//   const setting = useSettings();
//   const memberId = setting.id;
//   console.log(`memberId : ${memberId}`);
//   console.log("setting.cmsTVUpdates: ", setting.cmsTVUpdates);

//   // const [currentItem, setCurrentItem] = useState({});
//   const { loading, error, data, refetch } = useQuery(ALL_CMS, { variables: { category: "tv" } });
//   if (loading) return <p />;
//   if (error) {
//     swal(error.message || "Error", { button: false, timer: 3000 });
//   }

//   if (setting.cmsTVUpdates) {
//     setting.cmsTVUpdates = false;
//     refetch();
//   }

//   const sortFunc = (a, b) => {
//     const a1 = Number(a.no) || 0;
//     const b1 = Number(b.no) || 0;
//     if (Number(a1) > b1) {
//       return 1;
//     }
//     if (a1 < b1) {
//       return -1;
//     }
//     // a must be equal to b
//     return 0;
//   };

//   if (!data || !data.allCms) return <p>Error</p>;
//   const itemsTV = data.allCms.filter((item) => item.name === "instagram").sort(sortFunc);
//   const itemsRecommend = data.allCms.filter((item) => item.name === "recommend").sort(sortFunc);

//   return (
//     <div>
//       <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
//         <div className="">
//           <div className="flex flex-row items-center justify-between">
//             <div className="content-title">{t("TV (instagram)")}</div>
//             <div className="flex flex-row items-center justify-between ">
//               <Link to="/mypage/cms/new/tv/instagram">
//                 <i className="ml-5 fa fa-plus-square">추가</i>
//               </Link>
//               <Link to="/mypage/cms/instagram/tv/instagram">
//                 <i className="ml-5 fa fa-plus-square">인스타그램에서 추가</i>
//               </Link>
//             </div>
//           </div>
//           <div className="blackline2 mt-1" />
//           <div className="flex-row-center text-medium-15 ">
//             <CmsHeader />
//             <div className="grayline" />
//             {memberId === undefined && <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>}
//             {memberId !== undefined &&
//               memberId !== "" &&
//               itemsTV &&
//               itemsTV.length > 0 &&
//               itemsTV.map((item) => (
//                 <div className="w-full flex-row-center table-text" key={item.id}>
//                   <div className="w-2/20 ">
//                     <Link
//                       to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
//                       onClick={() => {
//                         setting.cmsItem = item;
//                       }}
//                     >
//                       <div className=" font-mont fontsize-12 tracking-tight  text-black ">
//                         <img src={item.img} className="w-12 h-12 border mx-auto" alt="" />
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="w-2/20 ">
//                     <Link
//                       to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
//                       onClick={() => {
//                         setting.cmsItem = item;
//                       }}
//                     >
//                       <div className="w-full text-center">{item.name}</div>
//                     </Link>
//                   </div>
//                   <div className="w-1/20 ">
//                     <div className="w-full text-center">{item.no}</div>
//                   </div>

//                   <div className="w-3/20 text-center">{item.targetUrl}</div>
//                   <div className="w-2/20 text-center overflow-auto">
//                     <Tooltip title={item.targetId} position="bottom">
//                       {item.targetId}
//                     </Tooltip>
//                   </div>
//                   <div className="w-4/20 text-center">{item.title}</div>
//                   <div className="w-4/20 text-center">{item.contents}</div>
//                   <div className="w-2/20 text-center flex-center">
//                     <CheckBox isCheck={item.visible} />
//                   </div>
//                 </div>
//               ))}
//           </div>
//         </div>
//         <div className="mt-16">
//           <div className="flex flex-row items-center justify-between">
//             <div className="content-title">{t("Recommends")}</div>
//             <Link to="/mypage/cms/new/tv/recommend">
//               <i className="ml-5 fa fa-plus-square">추가</i>
//             </Link>
//           </div>
//           <div className="blackline2 mt-1" />
//           <div className="flex-row-center text-medium-15 ">
//             <CmsHeader />
//             <div className="grayline" />
//             {memberId === undefined && <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>}
//             {memberId !== undefined &&
//               memberId !== "" &&
//               itemsRecommend &&
//               itemsRecommend.length > 0 &&
//               itemsRecommend.map((item) => (
//                 <div className="w-full flex-row-center table-text" key={item.id}>
//                   <div className="w-2/20 ">
//                     <div className=" font-mont fontsize-12 tracking-tight  text-black ">
//                       <img src={item.img} className="w-12 h-12 border mx-auto" alt="" />
//                     </div>
//                   </div>
//                   <div className="w-2/20 ">
//                     <div className="w-full text-center">{item.name}</div>
//                   </div>
//                   <div className="w-1/20 ">
//                     <div className="w-full text-center">{item.no}</div>
//                   </div>

//                   <div className="w-3/20 text-center">{item.targetUrl}</div>
//                   <div className="w-2/20 text-center overflow-auto">
//                     <Tooltip title={item.targetId} position="bottom">
//                       {item.targetId}
//                     </Tooltip>
//                   </div>
//                   <div className="w-4/20 text-center">{item.title}</div>
//                   <div className="w-4/20 text-center">{item.contents}</div>
//                   <div className="w-2/20 text-center flex-center">
//                     <CheckBox isCheck={item.visible} />
//                   </div>
//                 </div>
//               ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// });

// const CmsTV = () => {
//   const setting = useSettings();
//   // const history = useHistory();
//   // 로그인 안한 경우
//   // if (!setting.isLogin) history.push("/Cmspage/signin");
//   const screen = setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? <ScreenAdmin /> : "";
//   return (
//     <div>
//       <CmsPage>
//         <div className="">{screen}</div>
//       </CmsPage>
//     </div>
//   );
// };

// export default CmsTV;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";

import { useQuery } from "@apollo/client";

import { observer } from "mobx-react-lite";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import CmsPage from "./CmsPage";
import { ALL_CMS_TV } from "../../../graphql/gql/select";
import CmsHeader from "./CmsHeader";
import CheckBox from "../../../components/CheckBox";

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  console.log(`memberId : ${memberId}`);
  console.log("setting.cmsTVUpdates: ", setting.cmsTVUpdates);

  const { loading, error, data, refetch } = useQuery(ALL_CMS_TV, {
    variables: { category: "Play Tv" },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (setting.cmsTVUpdates) {
    setting.cmsTVUpdates = false;
    refetch();
  }

  if (!data || !data.allCmsTv) return <p>Error</p>;
  const itemsTV = data.allCmsTv;

  if (data) console.log("data : ", data);

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("TV")}</div>
            <div className="flex flex-row items-center justify-between ">
              <Link to="/mypage/cms/newtv">
                <i className="ml-5 fa fa-plus-square">추가</i>
              </Link>
            </div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsTV &&
              itemsTV.length > 0 &&
              itemsTV.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  {/* todo: 목록 리스트에서 뭘 보여줘야할지 ... */}
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/tv/edit/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        <img
                          src={item.mainImg}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/tv/edit/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

const CmsTV = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/Cmspage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );
  return (
    <div>
      <CmsPage>
        <div className="">{screen}</div>
      </CmsPage>
    </div>
  );
};

export default CmsTV;
