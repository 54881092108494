import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useQuery } from "@apollo/client";
import { FIND_PRODUCT_LIKE } from "../../graphql/gql/select";
import swal from "sweetalert";

const Screen = () => {
  const { t } = useTranslation();
  const setting = useSettings();
  const noDataStr = t("내역이 없습니다");

  const { loading, error, data, refetch } = useQuery(FIND_PRODUCT_LIKE, {
    variables: { memberId: setting.id },
  });

  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (setting.productLike) {
    setting.productLike = false;
    refetch();
  }

  const items = data.findProductLike;
  console.log("items : ", items);

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Product</div>
      <div className="blackline2 mt-1" />
      <div className="mt-4 flex flex-row flex-wrap w-full">
        {items.length > 0 &&
          items.map((item) => (
            <div className="w-1/5 m-2">
              <img
                src={item.product.mainImage}
                className="object-cover pr-2 border"
                alt=""
              />
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 trackikng-tight text-center">
            {noDataStr}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ScreenMobile = () => {
  const { t } = useTranslation();
  const noDataStr = t("내역이 없습니다");

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Product</div>
    </div>
  );
};

const MyLikeProduct = () => {
  const setting = useSettings();
  const screen = setting.isMobile ? <ScreenMobile /> : <Screen />;
  return <div>{screen}</div>;
};

export default MyLikeProduct;
