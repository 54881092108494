import React from "react";
import Modal from "react-modal";
import customStyles from "components/modalStyle";
import btn_modal_close from "assets/images/btn_modal_close.svg";
import { useQuery } from "@apollo/client";
import useSettings from "stores/settings";
import swal from "sweetalert";
import { PAY } from "../graphql/gql/select";

const preventIOSScroll = (e) => {
  e.preventDefault();
};

const OrdersModal = ({ modalIsOpen, closeModal, payId }) => {
  const { loading, error, data } = useQuery(PAY, {
    variables: { id: payId },
  });

  const onRequestClose = () => {
    document.documentElement.style.overflowY = "visible";
    document.documentElement.removeEventListener("touchmove", preventIOSScroll);
    closeModal();
  };

  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (data) {
    console.log("order", data.pay);
    console.log("pay", data);
  }
  const item = data.pay;

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div
        style={{ width: 800 }}
        className="h-600 flex flex-row bg-transparent"
      >
        <div className="p-4 w-full table-text bg-white">
          <div className="flex-col-left">
            <div className="dialog-header-bold">결제 정보</div>
            <div className="blackline2 mt-2" />
            <div>
              <div className="ml-0 mt-1 flex-row-left">
                <div>이름 : </div>
                {item && item.name && (
                  <div className="ml-2 table-text">{item.name}</div>
                )}
              </div>
              <div className="ml-0 mt-1 flex-row-left">
                <div>이메일 : </div>
                {item && item.email && (
                  <div className="ml-2 table-text">{item.email}</div>
                )}
              </div>
              <div className="ml-0 mt-1 flex-row-left">
                <div>결제 정보 : </div>
                {item && item.paymentType && (
                  <div className="ml-2 table-text">{item.paymentType}</div>
                )}
              </div>
            </div>
            <div className="mt-3 blackline2" />
            <div>
              <div className="mt-2 table-text-bold">주소 정보</div>
              <div className="mt-2 grayline" />

              <div className="ml-0 mt-1 flex-row-left">
                <div>우편번호</div>
                {item && item.receiverZip && (
                  <div className="ml-2 table-text">{item.receiverZip}</div>
                )}
              </div>
              <div className="ml-0 mt-1 flex-row-left">
                <div>주소 : </div>
                {item && item.receiverAddr1 && (
                  <div className="ml-2 table-text">{item.receiverAddr1}</div>
                )}
              </div>
              <div className="ml-0 mt-1 flex-row-left">
                <div>상세주소 : </div>
                {item && item.receiverAddr2 && (
                  <div className="ml-2 table-text">{item.receiverAddr2}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6"
            src={btn_modal_close}
            alt=""
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  );
};

const MyOrderModal = ({ modalIsOpen, closeModal, payId }) => {
  const setting = useSettings();
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <OrdersModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        payId={payId}
      />
    ) : (
      ""
    );

  return <div>{screen}</div>;
};

export default MyOrderModal;
