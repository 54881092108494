import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import useSettings from "stores/settings";
import currencyFormatter from "currency-formatter";
import heart_black from "assets/images/heart_black@2x.png";
import heart_white from "assets/images/heart_white@2x.png";

import {
  CREATE_PRODUCT_LIKE,
  DELETE_PRODUCT_LIKE,
} from "../../graphql/gql/select";

const Screen = ({ productItem, refetch }) => {
  console.log("productItem : ", productItem);
  const setting = useSettings();
  const history = useHistory();

  const [createProductLike, { data: createdData }] = useMutation(
    CREATE_PRODUCT_LIKE,
    {
      onError(error) {
        console.log("CREATE_PRODUCT_LIKE : err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );
  const [deleteProductLike, { data: deletedData }] = useMutation(
    DELETE_PRODUCT_LIKE,
    {
      onError(error) {
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [createdData]);

  useEffect(() => {
    refetch();
  }, [deletedData]);

  const createProductLikeHandler = ({ memberId, productId }) => {
    createProductLike({
      variables: {
        memberId,
        productId,
      },
    });
  };

  const deleteProductLikeHandler = ({ memberId, productId }) => {
    deleteProductLike({
      variables: {
        memberId,
        productId,
      },
    });
  };

  return (
    <div>
      <div
        onClick={() => {
          setting.currentProduct = productItem;
          history.push("/playcustom_create");
        }}
      >
        {productItem.wearingPic ? (
          <img
            src={productItem.wearingPic}
            className="w-full object-cover pr-2 border"
            alt=""
          />
        ) : (
          <img
            src={productItem.mainImage}
            className="w-full object-cover pr-2 border"
            alt=""
          />
        )}
      </div>
      <div className="flex w-full justify-between ">
        <div className="p-1 flex flex-col">
          <div className="font-semibold">{productItem.name}</div>
          <div>{productItem.brand}</div>
          <div className="fontsize-12">{productItem.comment}</div>
          {setting.lang === "en"
            ? currencyFormatter.format(Number(productItem.priceDollar), {
                code: "USD",
              })
            : currencyFormatter.format(Number(productItem.priceDollar), {
                code: "USD",
              })}
        </div>
        <img
          src={productItem.heart === "black" ? heart_black : heart_white}
          className="m-1 w-4 h-4"
          alt=""
          onClick={() => {
            if (!setting.id) {
              swal("로그인이 필요합니다");
            } else if (productItem.heart === "white") {
              createProductLikeHandler({
                memberId: setting.id,
                productId: productItem.id,
              });
            } else if (productItem.heart === "black") {
              deleteProductLikeHandler({
                memberId: setting.id,
                productId: productItem.id,
              });
            }
            setting.productLike = true;
          }}
        />
      </div>
      <div className="ml-1">{productItem.descKr}</div>
    </div>
  );
};

const CategoryProductItem = ({ productItem, refetch }) => {
  const setting = useSettings();
  const screen = setting.isMobile ? (
    ""
  ) : (
    <Screen productItem={productItem} refetch={refetch} />
  );

  return <div>{screen}</div>;
};

export default CategoryProductItem;
