import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import { useQuery, useMutation } from "@apollo/client";
import swal from "@sweetalert/with-react";
import { SRLWrapper } from "simple-react-lightbox";
import MyPage from "./MyPage";
import {
  MEMBER,
  UPDATE_MEMBER,
  DELETE_MEMBER,
  MULTI_UPLOAD_FILES,
} from "../../graphql/gql/select";
import SingleImage from "../../components/SingleImage";

const ScreenAdmin = ({ id }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  const history = useHistory();

  console.log(`memberId : ${memberId}`);
  console.log("param id: ", id);

  const [currentItem, setCurrentItem] = useState(null);

  const [updateMember, { data: updatedData }] = useMutation(UPDATE_MEMBER, {
    onError(error) {
      console.log("updateMember: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updatedData !== undefined) {
      const data1 = updatedData.updateMember;
      if (data1.error) {
        swal(data1.error || "Error", { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.allMembersUpdates = true;
        history.replace("/mypage/all_members");
      }
    }
  }, [updatedData]);

  const [deleteMember, { data: deleteData }] = useMutation(DELETE_MEMBER, {
    onError(error) {
      console.log("deleteMember: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      const data1 = deleteData.deleteMember;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("삭제가 잘 되었습니다"), { button: false, timer: 3000 });
        setting.allProductsUpdates = true;
        history.push("/mypage/all_members");
      }
    }
  }, [deleteData]);

  // 자료 저장
  const updateMemberHandler = ({ id, name, phone, email, img }) => {
    updateMember({
      variables: {
        id,
        name,
        phone,
        email,
        img,
      },
    });
  };

  // 삭제 버튼시
  const deleteMemberHandler = ({ id }) => {
    swal({
      text: "정말 삭제를 하시겠습니까",
      buttons: false,
      content: (
        <div className="max-w-xs flex flex-row-center mx-auto mx-2">
          <button
            className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
            onClick={() => {
              swal.close();
            }}
          >
            취소
          </button>
          <button
            className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
            onClick={() => {
              if (id) {
                deleteMember({
                  variables: {
                    id,
                  },
                });
              } else {
                swal("id : 빈 값이 올 수 없습니다", {
                  button: false,
                  timer: 3000,
                });
              }
              swal.close();
            }}
          >
            삭제
          </button>
        </div>
      ),
    });
  };

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, img: filenames[0] });
      }
      console.log(data1);
    }
  }, [multiData]);

  const { loading, error, data } = useQuery(MEMBER, {
    variables: { id },
    skip: !!currentItem,
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.member) return <p>Error</p>;

  if (data && data.member) {
    console.log(data.member);
    setCurrentItem(data.member);
  }

  if (!currentItem) return <div />;
  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("회원 상세")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="mt-3 flex-row-left">
                <div className="mt-3 flex-row-left">
                  <div className="w-20 font-bold">모델</div>
                  <input
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, email: e.target.value })
                    }
                    type="text"
                    className="w-80 px-2 outline-none"
                    placeholder="이메일"
                    value={currentItem.email}
                  />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">{t("이름")}</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, name: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder={t("이름")}
                  value={currentItem.name}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">전화</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, phone: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="가격"
                  value={currentItem.phone}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">사진</div>
                <SRLWrapper>
                  <SingleImage image={currentItem.img} />
                </SRLWrapper>
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex-col-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <div className="w-60 h-8 flex flex-row">
                    <button
                      className="w-26 h-8 ml-1 table-text bg-gray text-white font-bold mt-0 py-2 px-4"
                      onClick={() => history.goBack()}
                    >
                      {t("취소")}
                    </button>
                    <button
                      className="w-26 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
                      onClick={() => deleteMemberHandler(currentItem)}
                    >
                      {t("삭제하기")}
                    </button>
                    <button
                      className="w-26 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => updateMemberHandler(currentItem)}
                    >
                      {t("수정하기")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Member = ({ match }) => {
  const setting = useSettings();
  // const history = useHistory();
  console.log("match.params.id: ", match.params.id);
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin id={match.params.id} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default Member;
