import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { useMutation } from "@apollo/client";
import {
  DELETE_CMS_TV,
  UPDATE_CMS_TV,
  MULTI_UPLOAD_FILES,
} from "graphql/gql/select";
import MyPage from "../MyPage";

import CheckBox from "../../../components/CheckBox";
import SingleImage from "../../../components/SingleImage";

const ScreenAdmin = observer(
  ({
    id,
    currentItem,
    setCurrentItem,
    tempImgVideoUrl,
    setTempImgVideoUrl,
  }) => {
    const { t } = useTranslation();
    const setting = useSettings();
    const history = useHistory();

    const [updateCmsTv, { data: updatedData }] = useMutation(UPDATE_CMS_TV, {
      onError(error) {
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    });

    useEffect(() => {
      if (updatedData !== undefined) {
        const data1 = updatedData.updateCmsTv;
        if (data1.error) {
          swal(data1.error, { button: false, timer: 3000 });
        } else {
          swal("잘 수정 되었습니다", { button: false, timer: 3000 });

          setting.cmsTVUpdates = true;
        }
      }
    }, [updatedData]);

    const [deleteCmsTv, { data: deleteData }] = useMutation(DELETE_CMS_TV, {
      onError(error) {
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    });

    useEffect(() => {
      if (deleteData !== undefined) {
        const data1 = deleteData.deleteCmsTv;
        if (data1.error) {
          swal(data1.error, { button: false, timer: 3000 });
        } else {
          swal("잘 삭제 되었습니다.", { button: false, timer: 3000 });
          setting.cmsTVUpdates = true;
        }
        history.replace(`/mypage/cms/tv`);
      }
    }, [deleteData]);

    const updateCmsTvHandler = ({
      id,
      category,
      mainImg,
      videoUrl,
      visible,
    }) => {
      updateCmsTv({
        variables: {
          id,
          category,
          mainImg,
          videoUrl,
          visible,
        },
      });
    };

    const deleteCmsTvHandler = ({ id }) => {
      swal({
        text: "정말 삭제를 하시겠습니까",
        button: false,
        content: (
          <div className="max-w-xs flex flex-row-center mx-auto mx-2">
            <button
              className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
              onClick={() => {
                swal.close();
              }}
            >
              취소
            </button>
            <button
              className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
              onClick={() => {
                deleteCmsTv({
                  variables: {
                    id,
                  },
                });
              }}
            >
              삭제
            </button>
          </div>
        ),
      });
    };

    const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
      onError(error) {
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    });

    useEffect(() => {
      if (multiData !== undefined) {
        const data1 = multiData.multiUpload;
        if (data1.length <= 0) {
          swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
        } else {
          const filenames = data1.map((file) => file.filename);
          setCurrentItem({ ...currentItem, mainImg: filenames[0] });
          setTempImgVideoUrl(filenames[0]);
        }
      }
    }, [multiData]);

    return (
      <div>
        <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
          <div className="">
            <div className="flex flex-row items-center justify-between">
              <div className="content-title">{t(`Play TV 수정`)}</div>
            </div>
            <div className="blackline2 mt-1" />
            <div className="flex-row-center text-medium-15">
              <div className="w-full bg-white">
                <div className="mt-3 flex-row-left">
                  <div className="w-20 font-bold">이미지</div>
                  <SRLWrapper>
                    <SingleImage image={currentItem.mainImg} />
                  </SRLWrapper>
                  <div className="ml-12 flex-row-left">
                    <div className="w-12 font-bold">첨부파일</div>
                    <div className="mt-3 h-8 flex flex-row table-text relative">
                      <form onSubmit={() => {}} encType="multipart/form-data">
                        <input
                          name="document"
                          multiple
                          type="file"
                          accept="image/*"
                          method="POST"
                          onChange={async ({ target: { files } }) => {
                            if (files) multiUpload({ variables: { files } });
                          }}
                        />
                      </form>
                    </div>
                  </div>
                  <div className="ml-20 w-full px-2">
                    <div className="flex flex-row">
                      <input
                        onChange={(e) => setTempImgVideoUrl(e.target.value)}
                        type="text"
                        className="flex-1 px-2 outline-none"
                        placeholder="파일 선택 또는 여기에 웹주소를 붙여놓은 후 변경하기 버튼을 누르세요"
                        value={tempImgVideoUrl}
                      />
                      <button
                        className="w-24 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                        onClick={() => {
                          setCurrentItem({
                            ...currentItem,
                            mainImg: tempImgVideoUrl,
                          });
                        }}
                      >
                        변경하기
                      </button>
                    </div>
                    <div className="blackline" />
                  </div>
                </div>
                <div className="mt-3 flex-row-left">
                  <div className="mt-3 flex-row-left">
                    <div className="w-20 font-bold">영상 URL</div>
                    <input
                      onChange={(e) =>
                        setCurrentItem({
                          ...currentItem,
                          videoUrl: e.target.value,
                        })
                      }
                      type="text"
                      className="w-80 px-2 outline-none"
                      placeholder="영상 URL을 적어주세요"
                      value={currentItem.videoUrl}
                    />
                  </div>
                </div>
                <div className="mt-3 flex-row-left">
                  <div className="w-20 font-bold">보이기</div>
                  <CheckBox
                    isCheck={currentItem.visible}
                    onClick={() =>
                      setCurrentItem({
                        ...currentItem,
                        visible: !currentItem.visible,
                      })
                    }
                  />
                </div>
                <div className="mt-4 flex-col-left">
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <div className="absolute right-0 w-96 h-8 ml-4 flex flex-row">
                      <button className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4">
                        취소
                      </button>
                      <button
                        className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
                        onClick={() => {
                          deleteCmsTvHandler(currentItem);
                        }}
                      >
                        삭제
                      </button>
                      <button
                        className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                        onClick={() => {
                          updateCmsTvHandler(currentItem);
                        }}
                      >
                        수정하기
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const EditCmsSnsTv = ({ match }) => {
  const setting = useSettings();
  const { id } = match.params;
  const [currentItem, setCurrentItem] = useState(setting.cmsItem);
  const [tempImgVideoUrl, setTempImgVideoUrl] = useState(
    setting.cmsItem.mainImg
  );
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin
        id={id}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        tempImgVideoUrl={tempImgVideoUrl}
        setTempImgVideoUrl={setTempImgVideoUrl}
      />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default EditCmsSnsTv;
