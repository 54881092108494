import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";
import { useQuery } from "@apollo/client";
import { ALL_SPECIAL_ORDERS_ADMIN } from "graphql/gql/select";
import { observer } from "mobx-react-lite";
import MyPage from "./MyPage";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/6 text-center">{t("의뢰 날짜")}</div>
      <div className="w-7/12 pl-4">{t("의뢰 내용")}</div>
      <div className="w-1/12 ">{t("첨부파일")}</div>
      <div className="w-1/12 text-center">{t("상태")}</div>
      <div className="w-1/12 text-center">{t("고객명")}</div>
    </div>
  );
};

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  // const [items, setItems] = useState(null);

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const setting = useSettings();
  const memberId = setting.id;
  console.log(`memberId : ${memberId}`);

  const { loading, error, data, refetch } = useQuery(ALL_SPECIAL_ORDERS_ADMIN, {
    fetchPolicy: "network-only",
  });

  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
    return <p />;
  }
  if (setting.specialOrdersUpdates) {
    setting.specialOrdersUpdates = false;
    refetch();
  }
  if (!data || !data.allSpecialOrdersAdmin) return <p>Error</p>;
  const items = data.allSpecialOrdersAdmin;
  if (!items) return "";

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("의뢰 내역 전체")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <Header />
            <div className="grayline" />
            {memberId === undefined && <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>}
            {memberId !== undefined &&
              memberId !== "" &&
              items &&
              items.length > 0 &&
              items.map((item) => (
                <div className="w-full flex-row-center table-text" key={item.id}>
                  <div className="w-1/6 text-center">
                    <Moment unix format="YYYY.MM.DD">
                      {Number(item.createdAt) / 1000}
                    </Moment>
                  </div>

                  <div className="w-7/12 ">
                    <Link to={`/mypage/specialorderdetail/${item.id}`}>
                      <div className="flex-row-left ">
                        <div className="ml-4  font-mont fontsize-12 tracking-tight  text-black">{item.contents}</div>
                      </div>
                    </Link>
                  </div>

                  <div className="w-1/12 ">{item.productType}</div>
                  <div className="w-1/12 text-center">{item.status}</div>
                  <div className="w-1/12 text-center">{item.member.name}</div>
                </div>
              ))}
            <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
              {!setting.isLogin ? t("로그인을 먼저 해주세요") : items.length === 0 ? t("내역이 없습니다") : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const AllSpecialOrders = () => {
  const setting = useSettings();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen = setting.isAdminScreenMode ? <ScreenAdmin /> : "";
  return (
    <div>
      <MyPage>{screen}</MyPage>
    </div>
  );
};

export default AllSpecialOrders;
