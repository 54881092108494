import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { ALL_CMS } from "../../graphql/gql/select";
import icon_my_likes from "assets/images/icon_my_likes.svg";
import icon_my_design from "assets/images/icon_my_design.svg";
import icon_my_template from "assets/images/icon_template.svg";
import icon_create_design from "assets/images/icon_create_design.svg";
import swal from "sweetalert";

const PlayCustomCategoryLeftMenu = observer(() => {
  const setting = useSettings();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(ALL_CMS, {
    variables: { category: "category", visible: true },
  });

  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  const sortFunc = (a, b) => {
    const a1 = Number(a.no) || 0;
    const b1 = Number(b.no) || 0;
    if (Number(a1) > b1) {
      return 1;
    }
    if (a1 < b1) {
      return -1;
    }
    return 0;
  };

  if (!data || !data.allCms) return <p>Error</p>;
  const items = data.allCms.sort(sortFunc);
  console.log("categoryList? : ", items);

  return (
    <div className="w-full h-screen">
      <div className="mx-auto px-10 relative items-center justify-between md:justify-center">
        <div className="mt-2 mb-4 text-medium-kr-15">
          {setting.userName ? setting.userName : "로그인을 하지 않았습니다"}
        </div>
        {setting.isLogin ? (
          <div>
            <Link to="/mapage/mylikes">
              <div className="mt-2 text-medium-13">
                <div className="flex flex-wrap justify-start items-center">
                  <img
                    src={icon_my_likes}
                    style={{ width: "10px", height: "10px" }}
                    alt=""
                  />
                  <div className="ml-1">MY LIKES</div>
                </div>
              </div>
            </Link>

            <Link to="/mypage/mydesigns">
              <div className="mt-1 text-medium-13">
                <div className="flex flex-wrap justify-start items-center">
                  <img
                    src={icon_my_design}
                    style={{ width: "10px", height: "10px" }}
                    alt=""
                  />
                  <div className="ml-1">MY DESIGN</div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <div> </div>
        )}
      </div>
      <div className="mx-auto mt-8 px-10 relative items-center justify-between md:justify-center">
        <div className="mt-2 mb-2 text-medium-kr-15">
          <div className="flex flex-wrap justify-start items-center">
            <img src={icon_my_template} className="w-2 h-2" alt="" />
            <div className="ml-1">{t("템플릿").toUpperCase()}</div>
          </div>
        </div>
        <div className="flex flex-col">
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div className="mb-1 ml-3 text-medium-13 text-black">
                <Link to={`/category/${item.targetId.toUpperCase()}`}>
                  {item.title}
                </Link>
              </div>
            ))}
        </div>
      </div>
      <div className="mx-auto mt-8 px-10 relative items-center justify-between md:jusify-center">
        <div className="text-medium-kr-15 mt-2 font-bold mb-3">
          <div className="flex flex-wrap justify-start items-center">
            <img src={icon_create_design} className="w-2 h-2" alt="" />
            <div className="ml-1">{"Create DESIGN".toUpperCase()}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlayCustomCategoryLeftMenu;
