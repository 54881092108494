import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import { useQuery, useMutation } from "@apollo/client";
import swal from "@sweetalert/with-react";
import { SRLWrapper } from "simple-react-lightbox";
import currencyFormatter from "currency-formatter";
import MyPage from "./MyPage";
import {
  PRODUCT,
  CREATE_ORDER,
  DELETE_PRODUCT,
} from "../../graphql/gql/select";
import MultiImagesVertical from "../../components/MultiImagesVertical";

const ScreenAdmin = ({ id }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  const history = useHistory();

  console.log(`memberId : ${memberId}`);
  console.log("product id: ", id);

  const [deleteProduct, { data: deletedData }] = useMutation(DELETE_PRODUCT, {
    onError(error) {
      console.log("updateFAQ: err=", error);

      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deletedData !== undefined) {
      const data1 = deletedData.deleteProduct;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        setting.allProductsUpdates = true;
        swal(t("삭제가 잘 되었습니다"), { button: false, timer: 3000 });
        history.replace("/mypage/all_products");
      }
    }
  }, [deletedData]);

  const handlerDeleteProduct = (productId) => {
    if (!id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    deleteProduct({ variables: { id: productId } });
  };

  const { loading, error, data } = useQuery(PRODUCT, { variables: { id } });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);
  if (!data || !data.product) return <p>Error</p>;
  const item = data.product;

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("상품 상세")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="flex-row-center">
                <div className="w-full mt-3 flex-row-left">
                  <div className="w-1/2">
                    <SRLWrapper>
                      <img
                        src={item.mainImage}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </SRLWrapper>
                  </div>
                  {item.backImage === "" ? (
                    ""
                  ) : (
                    <div className="w-1/2">
                      <SRLWrapper>
                        <img
                          src={item.backImage}
                          className="w-full h-full object-cover"
                          alt=""
                        />
                      </SRLWrapper>
                    </div>
                  )}
                  {item.backImage === "" ? (
                    <div className="mt-8  w-1/2 mb-8">
                      <table className="w-full">
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("카테고리")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.category}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("모델")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.model}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("상품명")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.name}
                          </td>
                        </tr>

                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {/* {currencyFormatter.format(Number(item.price), {
                              code: "KRW",
                            })} */}
                            {item.displayPrice}
                          </td>
                        </tr>

                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}($)
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {/* {currencyFormatter.format(
                              Number(item.priceDollar),
                              {
                                code: "USD",
                              }
                            )} */}
                            {item.displayDollar}
                          </td>
                        </tr>

                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("브랜드")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.brand || "-"}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("제조국") || "내용 없음"}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.manufacturer || "-"}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("취급시 주의사항")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.warning || "-"}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("품질보증기준")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.warranty || "-"}
                          </td>
                        </tr>
                      </table>
                    </div>
                  ) : (
                    <div className="mt-8  w-full mb-8">
                      <table className="w-full">
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("카테고리")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.category}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("모델")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.model}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("상품명")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.name}
                          </td>
                        </tr>

                        {/* <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2"> */}
                        {/* {currencyFormatter.format(Number(item.price), {
                              code: "KRW",
                            })} */}
                        {/* {item.price}
                          </td>
                        </tr> */}
                        {/* <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}($)
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2"> */}
                        {/* {currencyFormatter.format(
                              Number(item.priceDollar),
                              {
                                code: "USD",
                              }
                            )} */}
                        {/* {item.priceDollar}
                          </td>
                        </tr> */}

                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.displayPrice || "-"}
                          </td>
                        </tr>

                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}($)
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.displayDollar || "-"}
                          </td>
                        </tr>

                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("브랜드")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.brand || "-"}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("제조국") || "내용 없음"}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.manufacturer || "-"}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("취급시 주의사항")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.warning || "-"}
                          </td>
                        </tr>
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("품질보증기준")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {item.warranty || "-"}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-8 h-8 ml-4 flex-row-center">
                <button
                  className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                  onClick={() => history.goBack()}
                >
                  {t("취소")}
                </button>
                <button
                  className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
                  onClick={() => {
                    swal({
                      text: t("정말 삭제하시겠습니까? 되돌릴 수 없습니다."),
                      buttons: {
                        취소: "취소",
                        삭제: "삭제",
                      },
                    }).then((willDelete) => {
                      switch (willDelete) {
                        case "삭제":
                          handlerDeleteProduct(item.id);
                          break;
                        case "취소":
                          swal.close();
                          break;
                        default:
                          swal.close();
                      }
                    });
                  }}
                >
                  삭제
                </button>
                <button
                  className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
                  onClick={() => {
                    history.push(`/mypage/product/edit/${id}`);
                  }}
                >
                  {t("수정")}
                </button>
              </div>

              <SRLWrapper>
                <div className="mt-3 flex-col-left">
                  <div className="w-20 font-bold">{t("상세 설명")}</div>
                  <div className="flex-col-center">
                    <MultiImagesVertical images={item.detailImage} />
                  </div>
                </div>
              </SRLWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Screen = ({ id }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  const history = useHistory();
  const [payNow, setPayNow] = useState(false);

  const [createOrder, { data: dataOrder }] = useMutation(CREATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (dataOrder !== undefined) {
      const data1 = dataOrder.createOrder;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (data1.id) {
        if (payNow) {
          history.push(`/mypage/pay/${data1.id}`); // 즉시 구매
        } else {
          swal(t("쇼핑백에 잘 추가 하였습니다"), {
            button: false,
            timer: 3000,
          });
          setting.shoppingBagUpdates = true;
        }
      } else {
        swal(t("쇼핑백에 추가 실패하였습니다"), { button: false, timer: 3000 });
      }
    }
  }, [dataOrder]);

  const handlerCreateOrder = (product) => {
    if (!id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    createOrder({
      variables: {
        memberId,
        productId: product.id,
        productCount: "1",
        productPrice: setting.domestic ? product.price : product.priceDollar,
        productTotal: setting.domestic ? product.price : product.priceDollar,
        currency: setting.domestic ? "KRW" : "USD",
      },
    });
  };

  const { loading, error, data } = useQuery(PRODUCT, { variables: { id } });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);
  if (!data || !data.product) return <p>Error</p>;
  const item = data.product;

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("상품 상세")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="flex-row-center">
                <div className="w-full mt-3 flex-row-left">
                  <div className="w-1/2">
                    <SRLWrapper>
                      <img
                        src={item.mainImage}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </SRLWrapper>
                  </div>
                  <div className="mt-8  w-1/2 mb-8">
                    <table className="w-full">
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("카테고리")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.category}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("모델")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.model}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("상품명")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.name}
                        </td>
                      </tr>
                      {setting.domestic ? (
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {/* {currencyFormatter.format(Number(item.price), {
                              code: "KRW",
                            })} */}
                            {item.displayPrice}
                          </td>
                        </tr>
                      ) : (
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}($)
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {/* {currencyFormatter.format(
                              Number(item.priceDollar),
                              { code: "USD" }
                            )} */}
                            {item.displayDollar}
                          </td>
                        </tr>
                      )}
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("브랜드")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.brand || "-"}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("제조국")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.manufacturer || "-"}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("취급시 주의사항")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.warning || "-"}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("품질보증기준")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.warranty || "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              {setting.isLogin ? (
                <div className="mt-8 h-8 ml-4 flex-row-center">
                  <button
                    className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                    onClick={() => history.goBack()}
                  >
                    {t("취소")}
                  </button>
                  <button
                    className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                    onClick={() => {
                      setting.currentProduct = item;
                      history.push("/playcustom_create");
                    }}
                  >
                    {t("바로 주문하기")}
                  </button>
                  <button
                    className="w-42 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                    onClick={() => {
                      setPayNow(false);
                      handlerCreateOrder(item);
                    }}
                  >
                    {t("쇼핑백에 담기")}
                  </button>
                </div>
              ) : (
                ""
              )}
              <SRLWrapper>
                <div className="mt-3 flex-col-left">
                  <div className="w-20 font-bold">상세 설명</div>
                  <div className="flex-col-center">
                    <MultiImagesVertical images={item.detailImage} />
                  </div>
                </div>
              </SRLWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScreenMobile = ({ id }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  const history = useHistory();
  const [payNow, setPayNow] = useState(false);

  const [createOrder, { data: dataOrder }] = useMutation(CREATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (dataOrder !== undefined) {
      const data1 = dataOrder.createOrder;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (data1.id) {
        if (payNow) {
          history.push(`/mypage/pay/${data1.id}`); // 즉시 구매
        } else {
          swal(t("쇼핑백에 잘 추가 하였습니다"), {
            button: false,
            timer: 3000,
          });
          setting.shoppingBagUpdates = true;
        }
      } else {
        swal(t("쇼핑백에 추가 실패하였습니다"), { button: false, timer: 3000 });
      }
    }
  }, [dataOrder]);

  const handlerCreateOrder = (product) => {
    if (!id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    createOrder({
      variables: {
        memberId,
        productId: product.id,
        productCount: "1",
        productPrice: setting.domestic ? product.price : product.priceDollar,
        productTotal: setting.domestic ? product.price : product.priceDollar,
        currency: setting.domestic ? "KRW" : "USD",
      },
    });
  };

  const { loading, error, data } = useQuery(PRODUCT, { variables: { id } });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);
  if (!data || !data.product) return <p>Error</p>;
  const item = data.product;

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("상품 상세")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="flex-row-center">
                <div className="w-full mt-3 flex-row-left">
                  <SRLWrapper>
                    <img
                      src={item.mainImage}
                      className="w-full h-80 object-cover"
                      alt=""
                    />
                  </SRLWrapper>
                  <div className="mt-8  w-1/2 mb-8">
                    <table className="w-full">
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("카테고리")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.category}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("모델")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.model}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("상품명")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.name}
                        </td>
                      </tr>
                      {setting.domestic ? (
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {/* {currencyFormatter.format(Number(item.price), {
                              code: "KRW",
                            })} */}
                            {item.displayPrice}
                          </td>
                        </tr>
                      ) : (
                        <tr className="flex-row-center">
                          <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                            {t("가격")}($)
                          </th>
                          <td className="border border-black py-2 text-center text-sm w-1/2">
                            {/* {currencyFormatter.format(
                              Number(item.priceDollar),
                              { code: "USD" }
                            )} */}
                            {item.displayDollar}
                          </td>
                        </tr>
                      )}
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("브랜드")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.brand || "-"}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border border-black text-center py-2 text-sm w-1/2">
                          {t("제조국")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.manufacturer || "-"}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border text-center px-4 py-2 text-sm w-20">
                          {t("취급시 주의사항")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.warning || "-"}
                        </td>
                      </tr>
                      <tr className="flex-row-center">
                        <th className="bg-gray border text-center px-4 py-2 text-sm w-20">
                          {t("품질보증기준")}
                        </th>
                        <td className="border border-black py-2 text-center text-sm w-1/2">
                          {item.warranty || "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="mt-8 h-8 px-0 flex-row-center">
                <button
                  className="w-1/4 h-8 mx-1 table-text bg-gray-600 text-white font-bold mt-0 py-2"
                  onClick={() => history.goBack()}
                >
                  {t("취소")}
                </button>
                <button
                  className="w-1/4 h-8 mx-1 table-text bg-black text-white font-bold mt-0 py-2"
                  onClick={() => {
                    setting.currentProduct = item;
                    history.push("/playcustom_create");
                  }}
                >
                  {t("바로 주문하기")}
                </button>
                <button
                  className="w-1/3 h-8 mx-1 table-text bg-black text-white font-bold mt-0 py-2"
                  onClick={() => {
                    setPayNow(false);
                    handlerCreateOrder(item);
                  }}
                >
                  {t("쇼핑백에 담기")}
                </button>
              </div>

              <SRLWrapper>
                <div className="mt-3 flex-col-left">
                  <div className="w-20 font-bold">상세 설명</div>
                  <div className="flex-col-center">
                    <MultiImagesVertical images={item.detailImage} />
                  </div>
                </div>
              </SRLWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Product = ({ match }) => {
  const setting = useSettings();
  // const history = useHistory();
  console.log("match.params.id: ", match.params.id);
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");

  if (setting.isAdminScreenMode && setting.isAdmin && setting.isLogin) {
    return (
      <div>
        <MyPage>
          <div className="">
            <ScreenAdmin id={match.params.id} />
          </div>
        </MyPage>
      </div>
    );
  }

  const screen = setting.isMobile ? (
    <ScreenMobile id={match.params.id} />
  ) : (
    <MyPage>
      <div className="">
        <Screen id={match.params.id} />
      </div>
    </MyPage>
  );
  return <div>{screen}</div>;
};

export default Product;
