import React from "react";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";

import MyPage from "./MyPage";

const templateItems = [
  {
    img: "../../images/home_layout8_template1_bg@2x.png",
    templateName: "켈리그래피",
  },
  {
    img: "../../images/home_layout8_template2_bg@2x.png",
    templateName: "베이직",
  },
  {
    img: "../../images/home_layout8_template3_bg@2x.png",
    templateName: "고딕",
  },
  {
    img: "../../images/home_layout8_template4_bg@2x.png",
    templateName: "명조",
  },
  {
    img: "../../images/home_layout8_template5_bg@2x.png",
    templateName: "이니셜",
  },
  {
    img: "../../images/home_layout8_template6_bg@2x.png",
    templateName: "빈티지",
  },
  {
    img: "../../images/home_layout8_template7_bg@2x.png",
    templateName: "이미지",
  },
  {
    img: "../../images/home_layout8_template8_bg@2x.png",
    templateName: "인사말",
  },
  {
    img: "../../images/home_layout8_template9_bg@2x.png",
    templateName: "유행어",
  },
  {
    img: "../../images/home_layout8_template10_bg@2x.png",
    templateName: "명조",
  },
  {
    img: "../../images/home_layout8_template11_bg@2x.png",
    templateName: "이니셜",
  },
  {
    img: "../../images/home_layout8_template12_bg@2x.png",
    templateName: "빈티지",
  },
];
// const playTvItems = [
//   {
//     img: "../../images/home_layout8_template1_bg@2x.png",
//     playTvName: "고양이",
//   },
//   {
//     img: "../../images/home_layout8_template1_bg@2x.png",
//     playTvName: "클래식",
//   },
//   {
//     img: "../../images/home_layout8_template1_bg@2x.png",
//     playTvName: "영화",
//   },
//   {
//     img: "../../images/home_layout8_template1_bg@2x.png",
//     playTvName: "베이직",
//   },
//   {
//     img: "../../images/home_layout8_template1_bg@2x.png",
//     playTvName: "레트로",
//   },
//   {
//     img: "../../images/home_layout8_template1_bg@2x.png",
//     playTvName: "레트로",
//   },
// ];

const Likes = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">ALL LIKES</div>
      <div className="blackline2 mt-1" />
      <div className="mt-4 text-bold-15">템플릿</div>
      <div className="mt-4 flex flex-row flex-wrap">
        {templateItems &&
          templateItems.length > 0 &&
          templateItems.map((item) => (
            <div className="flex-col-center mr-4 mb-4">
              <img
                className="w-32 h-32 border-black border"
                src={item.img}
                alt=""
              />
              <div className="mt-2 text-xs text-bold-tight-15">
                {item.templateName}
              </div>
            </div>
          ))}
        {templateItems.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const AllLikes = () => {
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");

  return (
    <div>
      <MyPage>
        <Likes />
      </MyPage>
    </div>
  );
};

export default AllLikes;
