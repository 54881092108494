import gql from "graphql-tag";

// 로그인 하기
export const SIGNIN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      error
      token
    }
  }
`;

// 로그인 하기
export const SNS_SIGNIN = gql`
  mutation(
    $email: String!
    $name: String!
    $phone: String
    $img: String
    $snsProvider: String
  ) {
    snsSignIn(
      email: $email
      name: $name
      phone: $phone
      img: $img
      snsProvider: $snsProvider
    ) {
      error
      token
    }
  }
`;

// 로그인 하기
export const SIGNIN_ADMIN = gql`
  mutation($email: String!, $password: String!) {
    signInAdmin(email: $email, password: $password) {
      error
      token
    }
  }
`;

// id 찾기
export const FIND_ID = gql`
  mutation($email: String!) {
    findId(where: { email: $email }) {
      error
      msg
    }
  }
`;

export const FIND_REVIEW = gql`
  mutation($id: String) {
    findReview(where: { id: $id }) {
      id
    }
  }
`;

// password 찾기
export const FIND_PASSWORD = gql`
  mutation($email: String!) {
    findPassword(where: { email: $email }) {
      error
      msg
    }
  }
`;

// 회원가입 하기
export const SIGNUP = gql`
  mutation(
    $email: String!
    $password: String!
    $name: String!
    $snsProvider: String
    $img: String
  ) {
    signup(
      email: $email
      password: $password
      name: $name
      snsProvider: $snsProvider
      img: $img
    ) {
      error
      token
    }
  }
`;

export const SIGNUP_ADMIN = gql`
  mutation($email: String!, $password: String!, $name: String!) {
    signupAdmin(email: $email, password: $password, name: $name) {
      error
      token
    }
  }
`;

// Member 정보
export const MEMBER = gql`
  query($id: String!) {
    member(where: { id: $id }) {
      id
      email
      password
      name
      phone
      img
      createdAt
      birthDay
      SEX
      height
      weight
      zip
      address
      address2
      receiverName
      receiverPhone1
      receiverPhone2
      receiverZip
      receiverAddr1
      receiverAddr2
      receiverMemo
      receiveSMS
      receiveEmail
      withdrawal
    }
  }
`;
// Member 정보 저장
export const CREATE_MEMBER = gql`
  mutation($name: String!, $phone: String, $email: String!, $img: String) {
    createMember(
      data: { name: $name, phone: $phone, email: $email, img: $img }
    ) {
      id
      email
      password
      name
      phone
      img
      createdAt
      birthDay
      SEX
      height
      weight
      zip
      address
      receiveSMS
      receiveEmail
      withdrawal
    }
  }
`;

// Member 정보 저장
export const UPDATE_MEMBER = gql`
  mutation(
    $id: String!
    $name: String
    $phone: String
    $email: String
    $img: String
    $birthDay: String
    $SEX: String
    $height: String
    $weight: String
    $zip: String
    $address: String
    $address2: String
    $receiverName: String
    $receiverPhone1: String
    $receiverPhone2: String
    $receiverZip: String
    $receiverAddr1: String
    $receiverAddr2: String
    $receiverMemo: String
    $receiveSMS: Boolean
    $receiveEmail: Boolean
    $withdrawal: Boolean
  ) {
    updateMember(
      where: { id: $id }
      data: {
        name: $name
        phone: $phone
        email: $email
        img: $img
        birthDay: $birthDay
        SEX: $SEX
        height: $height
        weight: $weight
        zip: $zip
        address: $address
        address2: $address2
        receiverName: $receiverName
        receiverPhone1: $receiverPhone1
        receiverPhone2: $receiverPhone2
        receiverZip: $receiverZip
        receiverAddr1: $receiverAddr1
        receiverAddr2: $receiverAddr2
        receiverMemo: $receiverMemo
        receiveSMS: $receiveSMS
        receiveEmail: $receiveEmail
        withdrawal: $withdrawal
      }
    ) {
      id
    }
  }
`;

// Member 정보 저장
export const DELETE_MEMBER = gql`
  mutation($id: String!) {
    deleteMember(where: { id: $id }) {
      id
    }
  }
`;

// Member 정보
export const ADMIN = gql`
  query($memberId: Int!) {
    admin(id: $memberId) {
      id
      email
      password
      name
      phone
      authorized
      createdAt
    }
  }
`;

// Admin 정보 저장
export const UPDATE_ADMIN = gql`
  mutation(
    $id: String!
    $name: String
    $phone: String
    $email: String
    $authorized: Boolean
  ) {
    updateAdmin(
      where: { id: $id }
      data: {
        name: $name
        phone: $phone
        email: $email
        authorized: $authorized
      }
    ) {
      id
      email
      password
      name
      phone
      authorized
      createdAt
    }
  }
`;

// Admin 정보 저장
export const DELETE_ADMIN = gql`
  mutation($id: String!) {
    deleteAdmin(where: { id: $id }) {
      id
    }
  }
`;

// 특수 주문하기
export const CREATE_SPECIAL_ORDER = gql`
  mutation(
    $memberId: String!
    $productType: String
    $contents: String
    $detailImage: String
  ) {
    createSpecialOrder(
      data: {
        memberId: $memberId
        productType: $productType
        contents: $contents
        detailImage: $detailImage
      }
    ) {
      id
      productType
      contents
      detailImage
    }
  }
`;

// 특수 주문하기
export const UPDATE_SPECIAL_ORDER = gql`
  mutation(
    $id: String!
    $productType: String
    $contents: String
    $status: String
    $processMemo: String
    $detailImage: String
  ) {
    updateSpecialOrder(
      where: { id: $id }
      data: {
        productType: $productType
        contents: $contents
        status: $status
        processMemo: $processMemo
        detailImage: $detailImage
      }
    ) {
      id
      productType
      contents
      status
      processMemo
      detailImage
    }
  }
`;

// 특수 주문 목록
export const ALL_SPECIAL_ORDERS = gql`
  query($memberId: String!) {
    allSpecialOrders(where: { memberId: $memberId }) {
      id
      productType
      contents
      status
      createdAt
      replyAt
      processMemo
      detailImage
    }
  }
`;

// 특수 주문 목록
export const ALL_SPECIAL_ORDERS_ADMIN = gql`
  query {
    allSpecialOrdersAdmin {
      id
      productType
      contents
      status
      createdAt
      replyAt
      processMemo
      detailImage
      member {
        id
        name
      }
    }
  }
`;

// 특수 주문 1개
export const SPECIAL_ORDER = gql`
  query($id: String!) {
    specialOrder(where: { id: $id }) {
      id
      productType
      contents
      createdAt
      processMemo
      detailImage
      member {
        id
        email
        name
      }
    }
  }
`;
// 특수 주문 삭제
export const DELETE_SPECIAL_ORDER = gql`
  mutation($id: String!) {
    deleteSpecialOrder(where: { id: $id }) {
      id
    }
  }
`;

// 노티스 만들기
export const CREATE_NOTICE = gql`
  mutation(
    $memberId: String!
    $title: String
    $contents: String
    $img: String
    $orderNumber: String
    $price: String
    $read: Boolean
  ) {
    createNotice(
      data: {
        memberId: $memberId
        title: $title
        contents: $contents
        img: $img
        orderNumber: $orderNumber
        price: $price
        read: $read
      }
    ) {
      id
      title
      contents
      img
      orderNumber
      price
    }
  }
`;

// 노티스 목록
export const ALL_NOTICES = gql`
  query($memberId: String!) {
    allNotices(where: { id: $memberId }) {
      id
      title
      contents
      img
      orderNumber
      price
      read
      createdAt
      updatedAt
      member {
        id
        name
        email
      }
    }
  }
`;

// 노티스 전체 목록 (Admin)
export const ALL_NOTICES_ADMIN = gql`
  query {
    allNoticesAdmin {
      id
      title
      contents
      img
      orderNumber
      price
      read
      createdAt
      updatedAt
      member {
        name
        email
      }
    }
  }
`;

// 노티스 1개
export const NOTICE = gql`
  query($id: String!) {
    notice(where: { id: $id }) {
      id
      title
      contents
      img
      orderNumber
      price
      read
      createdAt
      updatedAt
    }
  }
`;
// 노티스 삭제
export const DELETE_NOTICE = gql`
  mutation($id: String!) {
    deleteNotice(where: { id: $id }) {
      id
    }
  }
`;

// 노티스 수정
export const UPDATE_NOTICE = gql`
  mutation(
    $id: String!
    $title: String
    $contents: String
    $img: String
    $orderNumber: String
    $price: String
    $read: Boolean
  ) {
    updateNotice(
      where: { id: $id }
      data: {
        title: $title
        contents: $contents
        img: $img
        orderNumber: $orderNumber
        price: $price
        read: $read
      }
    ) {
      id
    }
  }
`;

// FAQ 목록
export const ALL_FAQ = gql`
  query {
    allFAQ {
      id
      no
      lang
      category
      title
      contents
    }
  }
`;

// FAQ 수정
export const UPDATE_FAQ = gql`
  mutation(
    $id: Int!
    $category: String
    $lang: String
    $no: Int!
    $title: String
    $contents: String
  ) {
    updateFAQ(
      where: { id: $id }
      data: {
        category: $category
        lang: $lang
        no: $no
        title: $title
        contents: $contents
      }
    ) {
      id
      category
      lang
      no
      title
      contents
    }
  }
`;
// FAQ 추가
export const CREATE_FAQ = gql`
  mutation(
    $category: String
    $lang: String
    $no: Int!
    $title: String
    $contents: String
  ) {
    createFAQ(
      data: {
        category: $category
        lang: $lang
        no: $no
        title: $title
        contents: $contents
      }
    ) {
      id
      category
      lang
      no
      title
      contents
    }
  }
`;

// FAQ 삭제
export const DELETE_FAQ = gql`
  mutation($id: Int) {
    deleteFAQ(where: { id: $id }) {
      id
      category
      lang
      no
      title
      contents
    }
  }
`;

export const UPDATE_CMS_TV = gql`
  mutation(
    $id: String
    $category: String
    $videoUrl: String
    $mainImg: String
    $visible: Boolean
  ) {
    updateCmsTv(
      where: { id: $id }
      data: {
        category: $category
        videoUrl: $videoUrl
        mainImg: $mainImg
        visible: $visible
      }
    ) {
      id
      category
      videoUrl
      mainImg
      visible
    }
  }
`;

// FAQ 수정
export const UPDATE_CMS = gql`
  mutation(
    $id: Int!
    $category: String
    $name: String
    $img: String
    $no: String
    $targetUrl: String
    $targetId: String
    $title: String
    $contents: String
    $visible: Boolean
  ) {
    updateCms(
      where: { id: $id }
      data: {
        category: $category
        name: $name
        img: $img
        no: $no
        targetUrl: $targetUrl
        targetId: $targetId
        title: $title
        contents: $contents
        visible: $visible
      }
    ) {
      id
      category
      name
      img
      no
      targetUrl
      targetId
      title
      contents
      visible
    }
  }
`;

export const CREATE_CMS_TV = gql`
  mutation(
    $category: String
    $videoUrl: String
    $mainImg: String
    $visible: Boolean
  ) {
    createCmsTv(
      data: {
        category: $category
        videoUrl: $videoUrl
        mainImg: $mainImg
        visible: $visible
      }
    ) {
      category
      videoUrl
      mainImg
      visible
    }
  }
`;

// CMS 추가
export const CREATE_CMS = gql`
  mutation(
    $category: String
    $name: String
    $img: String
    $no: String
    $targetUrl: String
    $targetId: String
    $title: String
    $contents: String
    $visible: Boolean
  ) {
    createCms(
      data: {
        category: $category
        name: $name
        img: $img
        no: $no
        targetUrl: $targetUrl
        targetId: $targetId
        title: $title
        contents: $contents
        visible: $visible
      }
    ) {
      id
      category
      name
      img
      no
      targetUrl
      targetId
      title
      contents
      visible
    }
  }
`;

// CMS 삭제
export const DELETE_CMS = gql`
  mutation($id: Int) {
    deleteCms(where: { id: $id }) {
      id
    }
  }
`;

export const DELETE_CMS_TV = gql`
  mutation($id: String) {
    deleteCmsTv(where: { id: $id }) {
      id
    }
  }
`;

// ADMIN 목록
export const ALL_ADMINS = gql`
  query {
    allAdmins {
      id
      email
      password
      name
      phone
      authorized
      createdAt
    }
  }
`;

// main 상품
export const MAIN_PRODUCT = gql`
  query($isMain: Boolean) {
    allProducts(where: { isMain: $isMain }) {
      id
      name
      price
      priceDollar
      displayPrice
      displayDollar
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      warning
      warranty
      createdAt
      visible
    }
  }
`;

// 상품 목록
export const ALL_PRODUCTS = gql`
  query {
    allProducts {
      id
      name
      price
      priceDollar
      displayPrice
      displayDollar
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      warning
      warranty
      createdAt
      blackLabel5Cm
      blackLabel10Cm
      whiteLabel5Cm
      whiteLabel10Cm
      visible
    }
  }
`;

// 상품 목록
export const ALL_PRODUCTS_CATEGORY = gql`
  query($category: String) {
    allProducts(where: { category: $category }) {
      id
      name
      price
      priceDollar
      displayPrice
      displayDollar
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      warning
      warranty
      createdAt
      blackLabel5Cm
      blackLabel10Cm
      whiteLabel5Cm
      whiteLabel10Cm
      visible
    }
  }
`;

export const PRODUCT = gql`
  query($id: String) {
    product(where: { id: $id }) {
      id
      name
      price
      priceDollar
      displayPrice
      displayDollar
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      warning
      warranty
      createdAt
      wearingPic
      isMain
      blackLabel5Cm
      blackLabel10Cm
      whiteLabel5Cm
      whiteLabel10Cm
      visible
    }
  }
`;

export const PRODUCT_LIST = gql`
  query($idList: String) {
    productList(idList: $idList) {
      id
      name
      price
      priceDollar
      displayPrice
      displayDollar
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      warning
      warranty
      createdAt
      blackLabel5Cm
      blackLabel10Cm
      whiteLabel5Cm
      whiteLabel10Cm
      visible
    }
  }
`;

// PRODUCTS 추가
export const CREATE_PRODUCT = gql`
  mutation(
    $name: String
    $price: String
    $priceDollar: String
    $displayPrice: String
    $displayDollar: String
    $comment: String
    $brand: String
    $manufacturer: String
    $model: String
    $option: String
    $mainImage: String
    $backImage: String
    $detailImage: String
    $category: String
    $keyword: String
    $colorOption: String
    $sizeOption: String
    $blackLabel5Cm: String
    $blackLabel10Cm: String
    $whiteLabel5Cm: String
    $whiteLabel10Cm: String
    $visible: Boolean
  ) {
    createProduct(
      data: {
        name: $name
        price: $price
        priceDollar: $priceDollar
        displayPrice: $displayPrice
        displayDollar: $displayDollar
        comment: $comment
        brand: $brand
        manufacturer: $manufacturer
        model: $model
        option: $option
        mainImage: $mainImage
        backImage: $backImage
        detailImage: $detailImage
        category: $category
        keyword: $keyword
        colorOption: $colorOption
        sizeOption: $sizeOption
        blackLabel5Cm: $blackLabel5Cm
        blackLabel10Cm: $blackLabel10Cm
        whiteLabel5Cm: $whiteLabel5Cm
        whiteLabel10Cm: $whiteLabel10Cm
        visible: $visible
      }
    ) {
      id
      name
      price
      priceDollar
      displayPrice
      displayDollar
      comment
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      createdAt
      blackLabel5Cm
      blackLabel10Cm
      whiteLabel5Cm
      whiteLabel10Cm
      visible
    }
  }
`;

export const CHANGE_MAIN_PRODUCT = gql`
  mutation($isMain2: Boolean) {
    updateProduct(where: { isMain: $isMain2 }, data: { isMain: false }) {
      id
      isMain
    }
  }
`;

// PRODUCTS 수정
export const UPDATE_PRODUCT = gql`
  mutation(
    $id: String
    $name: String
    $price: String
    $priceDollar: String
    $displayPrice: String
    $displayDollar: String
    $comment: String
    $brand: String
    $manufacturer: String
    $model: String
    $option: String
    $mainImage: String
    $backImage: String
    $detailImage: String
    $category: String
    $keyword: String
    $colorOption: String
    $sizeOption: String
    $warning: String
    $warranty: String
    $wearingPic: String
    $isMain: Boolean
    $blackLabel5Cm: String
    $blackLabel10Cm: String
    $whiteLabel5Cm: String
    $whiteLabel10Cm: String
    $visible: Boolean
  ) {
    updateProduct(
      where: { id: $id }
      data: {
        name: $name
        price: $price
        priceDollar: $priceDollar
        displayPrice: $displayPrice
        displayDollar: $displayDollar
        comment: $comment
        brand: $brand
        manufacturer: $manufacturer
        model: $model
        option: $option
        mainImage: $mainImage
        backImage: $backImage
        detailImage: $detailImage
        category: $category
        keyword: $keyword
        colorOption: $colorOption
        sizeOption: $sizeOption
        warning: $warning
        warranty: $warranty
        wearingPic: $wearingPic
        isMain: $isMain
        blackLabel5Cm: $blackLabel5Cm
        blackLabel10Cm: $blackLabel10Cm
        whiteLabel5Cm: $whiteLabel5Cm
        whiteLabel10Cm: $whiteLabel10Cm
        visible: $visible
      }
    ) {
      id
      name
      price
      priceDollar
      comment
      brand
      manufacturer
      model
      option
      mainImage
      backImage
      detailImage
      category
      keyword
      colorOption
      sizeOption
      warning
      warranty
      createdAt
      wearingPic
      isMain
      blackLabel5Cm
      blackLabel10Cm
      whiteLabel5Cm
      whiteLabel10Cm
      visible
    }
  }
`;

// PRODUCTS 수정
export const DELETE_PRODUCT = gql`
  mutation($id: String) {
    deleteProduct(where: { id: $id }) {
      id
    }
  }
`;

// 싱글 파일 업로드
//

export const UPLOAD_FILE = gql`
  mutation SingleUpload($file: Upload!) {
    singleUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

export const UPLOAD_SVG = gql`
  mutation svgUpload($file: Upload!) {
    svgUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

// 멀티 파일 업로드
export const MULTI_UPLOAD_FILES = gql`
  mutation MultiUpload($files: [Upload!]!) {
    multiUpload(files: $files) {
      filename
      mimetype
      encoding
    }
  }
`;

// 전체 멤버 목록
export const ALL_MEMBERS = gql`
  query {
    allMembers {
      id
      email
      password
      name
      phone
      img
      createdAt
      specialOrders {
        id
        productType
        contents
        status
      }
    }
  }
`;

// CMS 목록
export const ALL_CMS = gql`
  query($category: String, $name: String, $visible: Boolean) {
    allCms(where: { category: $category, name: $name, visible: $visible }) {
      id
      category
      name
      no
      img
      targetUrl
      targetId
      title
      contents
      visible
    }
  }
`;

export const ALL_CMS_TV = gql`
  query($category: String) {
    allCmsTv(where: { category: $category }) {
      id
      category
      mainImg
      videoUrl
      visible
    }
  }
`;

// CMS 1개
export const CMS = gql`
  query($id: Int) {
    cms(where: { id: $id }) {
      id
      category
      name
      no
      img
      targetUrl
      targetId
      title
      contents
      visible
    }
  }
`;

export const CMS_TV = gql`
  query($id: String) {
    cmsTv(where: { id: $id }) {
      id
      category
      mainImg
      videoUrl
      visible
    }
  }
`;

export const ALL_NEW_TEMPLATE = gql`
  query($category: String) {
    allNewTemplate(where: { category: $category }) {
      id
      category
      translate
      explain
      template
      visible
    }
  }
`;

export const UPDATE_NEW_TEMPLATE = gql`
  mutation(
    $id: String!
    $category: String
    $translate: String
    $explain: String
    $template: String
    $visible: Boolean
  ) {
    updateNewTemplate(
      where: { id: $id }
      data: {
        category: $category
        translate: $translate
        explain: $explain
        template: $template
        visible: $visible
      }
    ) {
      id
      category
      translate
      explain
      template
      visible
    }
  }
`;

export const DELETE_NEW_TEMPLATE = gql`
  mutation($id: String!) {
    deleteNewTemplate(where: { id: $id }) {
      id
    }
  }
`;

export const CREATE_LABEL = gql`
  mutation($contents: String, $labelMessage: String) {
    createLabel(data: { contents: $contents, labelMessage: $labelMessage })
  }
`;

export const UPDATE_LABEL = gql`
  mutation($id: String, $contents: String, $labelMessage: String) {
    updateLabel(
      where: { id: $id }
      data: { contents: $contents, labelMessage: $labelMessage }
    ) {
      id
      contents
      labelMessage
    }
  }
`;

export const DELETE_LABEL = gql`
  mutation($id: String!) {
    deleteLabel(where: { id: $id }) {
      id
    }
  }
`;

export const ALL_LABEL = gql`
  query {
    allLabel {
      id
      contents
      labelMessage
    }
  }
`;

export const LABEL = gql`
  query($id: String) {
    label(where: { id: $id }) {
      id
      contents
      labelMessage
    }
  }
`;

// template 목록
export const ALL_TEMPLATE = gql`
  query($category: String) {
    allTemplate(where: { category: $category }) {
      id
      category
      img
      title
      descKo
      descEn
    }
  }
`;

export const TEMPLATES = gql`
  query($ids: String) {
    templates(where: { ids: $ids }) {
      id
      category
      img
      title
      descKo
      descEn
    }
  }
`;

// template 1개
export const TEMPLATE = gql`
  query($id: String) {
    template(where: { id: $id }) {
      id
      category
      img
      title
      descKo
      descEn
    }
  }
`;

export const NEW_TEMPLATE = gql`
  query($id: String) {
    newTemplate(where: { id: $id }) {
      id
      category
      translate
      explain
      template
      visible
    }
  }
`;

// template 1개
export const UPLOAD_BASE64 = gql`
  mutation singleBase64Upload($imageStr: String!) {
    singleBase64Upload(imageStr: $imageStr) {
      filename
      mimetype
      encoding
    }
  }
`;

export const CREATE_NEW_TEMPLATE = gql`
  mutation(
    $category: String
    $translate: String
    $explain: String
    $template: String
    $visible: Boolean
  ) {
    createNewTemplate(
      data: {
        category: $category
        translate: $translate
        explain: $explain
        template: $template
        visible: $visible
      }
    ) {
      id
      category
      translate
      explain
      template
      visible
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation(
    $img: String!
    $category: String
    $title: String
    $descKo: String
    $descEn: String
  ) {
    createTemplate(
      data: {
        img: $img
        category: $category
        title: $title
        descKo: $descKo
        descEn: $descEn
      }
    ) {
      id
      img
      category
      title
      descKo
      descEn
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation(
    $id: String!
    $img: String
    $category: String
    $title: String
    $descKo: String
    $descEn: String
  ) {
    updateTemplate(
      where: { id: $id }
      data: {
        img: $img
        category: $category
        title: $title
        descKo: $descKo
        descEn: $descEn
      }
    ) {
      id
      img
      category
      title
      descKo
      descEn
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation($id: String!) {
    deleteTemplate(where: { id: $id }) {
      id
    }
  }
`;

// 상품 주문 목록
export const ALL_ORDERS = gql`
  query($memberId: String) {
    allOrders(where: { memberId: $memberId }) {
      id
      createdAt
      productSize
      productColor
      productCount
      productPrice
      productTotal
      currency

      reviewId
      reviewStar
      reviewImage
      reviewVideo
      reviewText

      labelContents
      labelMessage

      state

      product {
        id
        mainImage
        name
        price
        category
      }
    }
  }
`;

// 상품 주문 목록
export const ALL_ORDERS_SHOPPING_BAG = gql`
  query($memberId: String) {
    allOrdersShoppingBag(where: { memberId: $memberId }) {
      id
      createdAt
      payId
      productSize
      productColor
      productCount
      productPrice
      productTotal
      currency

      reviewStar
      reviewImage
      reviewVideo
      reviewText

      member {
        id
        name
      }
      product {
        id
        mainImage
        name
        price
        priceDollar
        colorOption
        sizeOption
      }
    }
  }
`;
export const ORDER = gql`
  query($id: String) {
    order(where: { id: $id }) {
      labelContents
      labelMessage
    }
  }
`;

export const CREATE_SVG = gql`
  mutation($id: String, $dstCreateTime: String) {
    createSvg(where: { id: $id }, data: { dstCreateTime: $dstCreateTime }) {
      id
      dstCreateTime
    }
  }
`;

// 상품 주문 목록
export const ORDER_LIST = gql`
  query($idList: String) {
    orderList(idList: $idList) {
      id
      createdAt
      productSize
      productColor
      productCount
      productPrice
      productTotal
      currency

      reviewStar
      reviewImage
      reviewVideo
      reviewText

      graphicList
      graphicBackList

      member {
        id
        name
      }
      product {
        id
        mainImage
        name
        price
        priceDollar
      }
    }
  }
`;

export const ALL_ORDERS_ADMIN = gql`
  query {
    allOrders {
      id
      createdAt
      productSize
      productColor
      productCount
      productPrice
      productTotal
      currency

      reviewStar
      reviewImage
      reviewVideo
      reviewText

      labelContents
      labelMessage

      state

      payId

      reviewId

      product {
        id
        mainImage
        name
        price
        category
      }
    }
  }
`;

// 상품 주문하기 (쇼핑백)
export const CREATE_ORDER = gql`
  mutation(
    $memberId: String!
    $productId: String
    $payId: String
    $productSize: String
    $productColor: String
    $productCount: String
    $productPrice: String
    $productTotal: String
    $currency: String
    $reviewStar: String
    $reviewImage: String
    $reviewVideo: String
    $reviewText: String
    $labelContents: String
    $labelMessage: String
    $graphicList: String
    $graphicBackList: String
  ) {
    createOrder(
      data: {
        memberId: $memberId
        productId: $productId
        payId: $payId
        productSize: $productSize
        productColor: $productColor
        productCount: $productCount
        productPrice: $productPrice
        productTotal: $productTotal
        currency: $currency
        reviewStar: $reviewStar
        reviewImage: $reviewImage
        reviewVideo: $reviewVideo
        reviewText: $reviewText
        labelContents: $labelContents
        labelMessage: $labelMessage
        graphicList: $graphicList
        graphicBackList: $graphicBackList
      }
    ) {
      id
    }
  }
`;

// 상품 주문하기
export const UPDATE_ORDER = gql`
  mutation(
    $id: String!
    $payId: String
    $productSize: String
    $productColor: String
    $productCount: String
    $productPrice: String
    $productTotal: String
    $currency: String
    $reviewStar: String
    $reviewImage: String
    $reviewVideo: String
    $reviewText: String
    $labelContents: String
    $labelMessage: String
    $state: String
  ) {
    updateOrder(
      where: { id: $id }
      data: {
        payId: $payId
        productSize: $productSize
        productColor: $productColor
        productCount: $productCount
        productPrice: $productPrice
        productTotal: $productTotal
        currency: $currency
        reviewStar: $reviewStar
        reviewImage: $reviewImage
        reviewVideo: $reviewVideo
        reviewText: $reviewText
        labelContents: $labelContents
        labelMessage: $labelMessage
        state: $state
      }
    ) {
      id
      productSize
      productColor
      productCount
      productPrice
      productTotal
      currency
      reviewStar
      reviewImage
      reviewVideo
      reviewText
      labelContents
      labelMessage
      state
    }
  }
`;

// 상품 주문 삭제
export const DELETE_ORDER = gql`
  mutation($id: String!) {
    deleteOrder(where: { id: $id }) {
      id
    }
  }
`;

export const PAY = gql`
  query($id: String) {
    pay(where: { id: $id }) {
      id
      orderList
      status
      createdAt
      process1
      process2
      process3
      process4
      process5
      processMemo
      name
      phone
      email
      receiveType
      receiverName
      receiverPhone1
      receiverPhone2
      receiverZip
      receiverAddr1
      receiverAddr2
      city
      state
      country
      memo

      paymentType

      productTotal
      feeTotal
      payTotal
      currency

      invoiceNumber
      invoiceCompany
      invoicePhone
    }
  }
`;

// 상품 주문 목록 (결제-배송)
export const ALL_PAYS = gql`
  query($memberId: String!) {
    allPays(where: { memberId: $memberId }) {
      id
      orderList
      status
      createdAt
      process1
      process2
      process3
      process4
      process5
      processMemo
      name
      phone
      email
      receiveType
      receiverName
      receiverPhone1
      receiverPhone2
      receiverZip
      receiverAddr1
      receiverAddr2
      city
      state
      country
      memo

      paymentType

      productTotal
      feeTotal
      payTotal
      currency

      invoiceNumber
      invoiceCompany
      invoicePhone
    }
  }
`;

// 상품 주문 목록 (결제-배송)
export const ALL_PAYS_ADMIN = gql`
  query {
    allPaysAdmin {
      id
      orderList
      status
      createdAt
      process1
      process2
      process3
      process4
      process5
      processMemo
      name
      phone
      email
      receiveType
      receiverName
      receiverPhone1
      receiverPhone2
      receiverZip
      receiverAddr1
      receiverAddr2
      city
      state
      country
      memo

      paymentType

      productTotal
      feeTotal
      payTotal
      currency

      invoiceNumber
      invoiceCompany
      invoicePhone
    }
  }
`;

// 상품 주문하기 (결제-배송)
export const CREATE_PAY = gql`
  mutation(
    $memberId: String!
    $orderList: String
    $status: String
    $process1: String
    $process2: String
    $process3: String
    $process4: String
    $process5: String
    $processMemo: String
    $name: String
    $phone: String
    $email: String
    $receiveType: String
    $receiverName: String
    $receiverPhone1: String
    $receiverPhone2: String
    $receiverZip: String
    $receiverAddr1: String
    $receiverAddr2: String
    $city: String
    $state: String
    $country: String
    $memo: String
    $paymentType: String
    $productTotal: String
    $feeTotal: String
    $payTotal: String
    $currency: String
    $invoiceNumber: String
    $invoiceCompany: String
    $invoicePhone: String
  ) {
    createPay(
      data: {
        memberId: $memberId
        orderList: $orderList
        status: $status
        process1: $process1
        process2: $process2
        process3: $process3
        process4: $process4
        process5: $process5
        processMemo: $processMemo
        name: $name
        phone: $phone
        email: $email
        receiveType: $receiveType
        receiverName: $receiverName
        receiverPhone1: $receiverPhone1
        receiverPhone2: $receiverPhone2
        receiverZip: $receiverZip
        receiverAddr1: $receiverAddr1
        receiverAddr2: $receiverAddr2
        city: $city
        state: $state
        country: $country
        memo: $memo
        paymentType: $paymentType
        productTotal: $productTotal
        feeTotal: $feeTotal
        payTotal: $payTotal
        currency: $currency
        invoiceNumber: $invoiceNumber
        invoiceCompany: $invoiceCompany
        invoicePhone: $invoicePhone
      }
    ) {
      id
    }
  }
`;

// 상품 주문하기
export const UPDATE_PAY = gql`
  mutation(
    $id: String!
    $memberId: String
    $status: String
    $process1: String
    $process2: String
    $process3: String
    $process4: String
    $process5: String
    $processMemo: String
    $name: String
    $phone: String
    $email: String
    $receiveType: String
    $receiverName: String
    $receiverPhone1: String
    $receiverPhone2: String
    $receiverZip: String
    $receiverAddr1: String
    $receiverAddr2: String
    $city: String
    $state: String
    $country: String
    $memo: String
    $paymentType: String
    $productTotal: String
    $feeTotal: String
    $payTotal: String
    $currency: String
    $invoiceNumber: String
    $invoiceCompany: String
    $invoicePhone: String
  ) {
    updatePay(
      where: { id: $id }
      data: {
        memberId: $memberId
        status: $status
        process1: $process1
        process2: $process2
        process3: $process3
        process4: $process4
        process5: $process5
        processMemo: $processMemo
        name: $name
        phone: $phone
        email: $email
        receiveType: $receiveType
        receiverName: $receiverName
        receiverPhone1: $receiverPhone1
        receiverPhone2: $receiverPhone2
        receiverZip: $receiverZip
        receiverAddr1: $receiverAddr1
        receiverAddr2: $receiverAddr2
        city: $city
        state: $state
        country: $country
        memo: $memo
        paymentType: $paymentType
        productTotal: $productTotal
        feeTotal: $feeTotal
        payTotal: $payTotal
        currency: $currency
        invoiceNumber: $invoiceNumber
        invoiceCompany: $invoiceCompany
        invoicePhone: $invoicePhone
      }
    ) {
      id
      memberId
      status
      process1
      process2
      process3
      process4
      process5
      processMemo
      name
      phone
      email
      receiveType
      receiverName
      receiverPhone1
      receiverPhone2
      receiverZip
      receiverAddr1
      receiverAddr2
      city
      state
      country
      memo
      paymentType
      productTotal
      feeTotal
      payTotal
      currency
      invoiceNumber
      invoiceCompany
      invoicePhone
    }
  }
`;

// 상품 주문 삭제
export const DELETE_PAY = gql`
  mutation($id: String!) {
    deletePay(where: { id: $id }) {
      id
    }
  }
`;

// 1:1  전체
export const ALL_QA = gql`
  query($memberId: String) {
    allQA(where: { memberId: $memberId }) {
      id
      createdAt
      order {
        id
        createdAt
        productSize
        productColor
        productCount
        productPrice
        productTotal
        currency

        reviewStar
        reviewImage
        reviewVideo
        reviewText

        product {
          id
          mainImage
          name
          price
          category
        }
      }
      contents
      images
      replyAt
      replyContents
    }
  }
`;

// 1:1문의 생성
export const CREATE_QA = gql`
  mutation(
    $memberId: String!
    $orderId: String
    $contents: String
    $images: String
  ) {
    createQA(
      data: {
        memberId: $memberId
        orderId: $orderId
        contents: $contents
        images: $images
      }
    ) {
      id
    }
  }
`;

// 1:1문의 생성
export const UPDATE_QA = gql`
  mutation($id: String, $replyContents: String) {
    updateQA(where: { id: $id }, data: { replyContents: $replyContents }) {
      id
      createdAt
      contents
      images
      replyAt
      replyContents
    }
  }
`;

// 1:1문의 삭제
export const DELETE_QA = gql`
  mutation($id: String!) {
    deleteQA(where: { id: $id }) {
      id
    }
  }
`;

// 1:1문의 관리자 전체
export const ALL_QA_ADMIN = gql`
  query {
    allQAAdmin {
      id
      createdAt
      order {
        id
        createdAt
        productSize
        productColor
        productCount
        productPrice
        productTotal
        currency

        reviewStar
        reviewImage
        reviewVideo
        reviewText

        product {
          id
          mainImage
          name
          price
          category
        }
      }
      contents
      images
      replyAt
      replyContents
    }
  }
`;

export const ALL_REVIEW = gql`
  query {
    allReview {
      id
      mainImg
      comment
      createdAt
      starPoint
      visible
      member {
        id
        email
        name
        img
      }
    }
  }
`;

export const REVIEW = gql`
  query($id: String!) {
    review(where: { id: $id }) {
      id
      createdAt
      mainImg
      comment
      starPoint
      hashTag
      visible
      member {
        id
        email
        name
        img
      }
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation(
    $memberId: String!
    $orderId: String
    $mainImg: String
    $comment: String
    $starPoint: String
    $hashTag: String
    $visible: Boolean
  ) {
    createReview(
      data: {
        memberId: $memberId
        orderId: $orderId
        mainImg: $mainImg
        comment: $comment
        starPoint: $starPoint
        hashTag: $hashTag
        visible: $visible
      }
    ) {
      id
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation(
    $id: String!
    $memberId: String
    $mainImg: String
    $comment: String
    $starPoint: String
    $hashTag: String
    $visible: Boolean
  ) {
    updateReview(
      where: { id: $id }
      data: {
        memberId: $memberId
        mainImg: $mainImg
        comment: $comment
        starPoint: $starPoint
        visible: $visible
        hashTag: $hashTag
      }
    ) {
      id
      mainImg
      comment
      starPoint
      visible
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation($id: String!) {
    deleteReview(where: { id: $id }) {
      id
    }
  }
`;

export const REVIEW_LIKE = gql`
  query($memberId: String, $reviewId: String) {
    reviewLike(where: { memberId: $memberId, reviewId: $reviewId }) {
      id
    }
  }
`;

export const FIND_REVIEW_LIKE = gql`
  mutation($memberId: String, $reviewId: String) {
    findId(where: { memberId: $memberId, reviewId: $reviewId }) {
      error
      msg
    }
  }
`;

export const CREATE_REVIEW_LIKES = gql`
  mutation($memberId: String, $reviewId: String) {
    createReviewLike(data: { memberId: $memberId, reviewId: $reviewId }) {
      id
    }
  }
`;

export const DELETE_REVIEW_LIKES = gql`
  mutation($memberId: String!, $reviewId: String!) {
    deleteReviewLike(where: { memberId: $memberId, reviewId: $reviewId }) {
      id
    }
  }
`;

export const CREATE_IS_LIKE = gql`
  mutation($reviewId: String) {
    createIsLike(data: { reviewId: $reviewId }) {
      id
    }
  }
`;

export const DELETE_IS_LIKE = gql`
  mutation($id: Int) {
    deleteIsLike(where: { id: $id }) {
      id
    }
  }
`;

export const REVIEW_LIKES = gql`
  query($memberId: String) {
    reviewLike(where: { memberId: $memberId }) {
      id
      review {
        id
      }
    }
  }
`;

export const ALL_REVIEW_LIKE = gql`
  query {
    allReviewLike {
      id
      review {
        id
      }
      member {
        id
      }
    }
  }
`;

export const FIND_REVIEW_LIKE_LIST = gql`
  query($memberId: String) {
    findReviewLikeList(where: { memberId: $memberId }) {
      allReview {
        id
        mainImg
        comment
        starPoint
        visible
        hashTag
        member {
          id
          email
          name
          img
        }
      }
      myReviewLike {
        review {
          id
        }
        member {
          id
        }
      }
    }
  }
`;

// todo: data 추가 (template 정보는 전부)
export const FIND_NEW_TEMPLATE_LIKE_LIST = gql`
  query($category: String, $memberId: String) {
    findNewTemplateLikeList(
      where: { category: $category, memberId: $memberId }
    ) {
      allNewTemplate {
        id
        category
        translate
        explain
        template
        visible
      }
      myNewTemplate {
        id
        member {
          id
        }
        newTemplate {
          id
          category
          translate
          explain
          template
          visible
        }
      }
    }
  }
`;

// todo: data 추가 (product 정보는 전부)
export const FIND_PRODUCT_LIKE_LIST = gql`
  query($category: String, $memberId: String) {
    findProductLikeList(where: { category: $category, memberId: $memberId }) {
      allProduct {
        id
        name
        price
        priceDollar
        displayPrice
        displayDollar
        brand
        manufacturer
        model
        option
        mainImage
        backImage
        detailImage
        category
        keyword
        colorOption
        sizeOption
        warning
        warranty
        wearingPic
        createdAt
        visible
      }
      myProduct {
        id
        product {
          id
        }
        member {
          id
        }
      }
    }
  }
`;

export const FIND_ALL_PRODUCT_LIKE_LIST = gql`
  query($memberId: String) {
    findAllProductLikeList(where: { memberId: $memberId }) {
      allProduct {
        id
        mainImage
        name
        price
        category
      }
      myProduct {
        id
        product {
          id
        }
        member {
          id
        }
      }
    }
  }
`;

export const CREATE_NEW_TEMPLATE_LIKE = gql`
  mutation($memberId: String, $templateId: String) {
    createNewTemplateLike(
      data: { memberId: $memberId, templateId: $templateId }
    ) {
      id
    }
  }
`;

export const DELETE_NEW_TEMPLATE_LIKE = gql`
  mutation($memberId: String, $templateId: String) {
    deleteNewTemplateLike(
      where: { memberId: $memberId, templateId: $templateId }
    ) {
      id
    }
  }
`;

export const CREATE_PRODUCT_LIKE = gql`
  mutation($memberId: String, $productId: String) {
    createProductLike(data: { memberId: $memberId, productId: $productId }) {
      id
    }
  }
`;

export const DELETE_PRODUCT_LIKE = gql`
  mutation($memberId: String, $productId: String) {
    deleteProductLike(where: { memberId: $memberId, productId: $productId }) {
      id
    }
  }
`;

export const ALL_PRODUCT_LIKE = gql`
  query {
    allProductLike {
      id
      member {
        id
      }
      product {
        id
      }
    }
  }
`;

export const FIND_PRODUCT_LIKE = gql`
  query($memberId: String) {
    findProductLike(where: { memberId: $memberId }) {
      id
      product {
        id
        mainImage
        name
        price
        category
      }
    }
  }
`;

export const FIND_NEW_TEMPLATE_LIKE = gql`
  query($memberId: String) {
    findNewTemplateLike(where: { memberId: $memberId }) {
      id
      newTemplate {
        id
        category
        translate
        explain
        template
        visible
      }
    }
  }
`;

export const ALL_KIND_TEMPLATE_LIST = gql`
  query($category: String) {
    allKindTemplate(where: { category: $category }) {
      templateImage {
        id
        category
        img
        title
        descKo
        descEn
      }
      template {
        id
        category
        translate
        explain
        template
        visible
      }
    }
  }
`;

export const CREATE_DESIGN = gql`
  mutation($productId: String, $front: String, $back: String) {
    createDesign(data: { productId: $productId, front: $front, back: $back }) {
      id
      front
      back
    }
  }
`;

export const DELETE_DESIGN = gql`
  mutation($id: Int) {
    deleteDesign(where: { id: $id }) {
      id
    }
  }
`;

export const ALL_DESIGN = gql`
  query {
    allDesign {
      id
      front
      back
      product {
        id
        name
        price
        priceDollar
        displayPrice
        displayDollar
        brand
        manufacturer
        model
        option
        mainImage
        backImage
        detailImage
        category
        keyword
        colorOption
        sizeOption
        warning
        warranty
        createdAt
        visible
      }
    }
  }
`;
