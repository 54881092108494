import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { ALL_ORDERS } from "graphql/gql/select";
import { useQuery, useMutation } from "@apollo/client";
import Moment from "react-moment";

import useSettings from "stores/settings";
import { SRLWrapper } from "simple-react-lightbox";
import MyPage from "./MyPage";
import ReviewHeader from "../../components/ReviewHeader";
import MultiImages from "../../components/MultiImages";
import MultiVideos from "../../components/MultiVideos";
import Star from "../../components/Star";

const Reviews = () => {
  const setting = useSettings();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: { memberId: setting.id },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.allOrders) return <p>Error</p>;
  const items = data.allOrders;

  console.log("My Reviews : items", items);
  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Review</div>
      <div className="blackline2 mt-1" />
      <div className="flex-row-center text-medium-15 ">
        <ReviewHeader />
        <div className="blackline" />

        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div
              className="mt-2 mb-2 w-full flex-row-center table-text"
              key={item.id}
            >
              <div className="w-1/12 text-center">
                <Moment unix format="YYYY.MM.DD">
                  {Number(item.createdAt) / 1000}
                </Moment>
              </div>

              <div className="w-4/12 ">
                <div className="flex-row-left ">
                  <img
                    className="w-12 h-12 border-black border"
                    src={item.product.mainImage}
                    alt=""
                  />
                  <div className="ml-4  font-mont fontsize-12 tracking-tight ">
                    {item.product.name}
                  </div>
                </div>
              </div>
              <div className="w-1/12 flex-center">
                <Star star={item.reviewStar} />
              </div>
              <div className="w-2/12 flex-center">
                <MultiImages images={item.reviewImage} />
              </div>
              <div className="w-2/12 flex-center">
                <MultiVideos videos={item.reviewVideo} />
              </div>
              <div className="w-2/12 text-center">{item.reviewText}</div>
              <div className="mt-2 grayline" />
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ReviewsMobile = () => {
  const setting = useSettings();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: { memberId: setting.id },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.allOrders) return <p>Error</p>;
  const items = data.allOrders;

  console.log("My Reviews : items", items);
  return (
    <div className="w-full mt-6 px-2">
      <div className="content-title">My Review</div>
      <div className="blackline2 mt-1" />
      <div className="flex-row-center text-medium-15 ">
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="w-full py-2" key={item.id}>
              <div className="w-full flex flex-col table-text">
                <div className="w-full pl-0">
                  <div className="flex flex-row ">
                    <img
                      className="w-32 h-32 border-none"
                      src={item.product.mainImage}
                      alt=""
                    />
                    <div className="ml-4 flex flex-col">
                      <div className="font-bold ">
                        {item.product.name}({item.product.category})
                      </div>
                      <div className="text-left">
                        <Moment unix format="YYYY.MM.DD HH:mm">
                          {Number(item.createdAt) / 1000}
                        </Moment>
                      </div>

                      <MultiImages images={item.reviewImage} />

                      <MultiVideos videos={item.reviewVideo} />
                      <Star star={item.reviewStar} />
                      <div className="w-full text-center">
                        {item.reviewText}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 grayline" />
                </div>
              </div>
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const MyReviews = () => {
  const setting = useSettings();
  const history = useHistory();
  // 로그인 안한 경우
  if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";
  if (setting.isAdminScreenMode && setting.isAdmin && setting.isLogin)
    return "";
  const screen = setting.isMobile ? (
    <ReviewsMobile />
  ) : (
    <MyPage>
      <SRLWrapper>
        <Reviews />
      </SRLWrapper>
    </MyPage>
  );
  return <div>{screen}</div>;
};

export default MyReviews;
