import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";

import { useQuery } from "@apollo/client";

import { observer } from "mobx-react-lite";
import swal from "@sweetalert/with-react";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import CmsPage from "./CmsPage";
import { ALL_NEW_TEMPLATE } from "../../../graphql/gql/select";
import CmsTemplateHeader from "./CmsTemplateHeader";
import CheckBox from "../../../components/CheckBox";
import PlayCustomTemplate from "containers/playcustom/PlayCustomTemplate";

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;

  const { loading, error, data, refetch } = useQuery(ALL_NEW_TEMPLATE);
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (setting.cmsTemplateUpdates) {
    setting.cmsTemplateUpdates = false;
    refetch();
  }

  if (!data || !data.allNewTemplate) return <p>Error</p>;

  const sortFunc = (a, b) => {
    const a1 = Number(a.no) || 0;
    const b1 = Number(b.no) || 0;
    if (Number(a1) > b1) {
      return 1;
    }
    if (a1 < b1) {
      return -1;
    }
    return 0;
  };

  const itemsBasic = data.allNewTemplate
    .filter((item) => item.category === "basic")
    .sort(sortFunc);
  const itemsRetro = data.allNewTemplate
    .filter((item) => item.category === "retro")
    .sort(sortFunc);
  const itemsLovely = data.allNewTemplate
    .filter((item) => item.category === "lovely")
    .sort(sortFunc);
  const itemsCalligraphy = data.allNewTemplate
    .filter((item) => item.category === "calligraphy")
    .sort(sortFunc);
  const itemsGraphic = data.allNewTemplate
    .filter((item) => item.category === "graphic")
    .sort(sortFunc);
  const itemsLogoStyle = data.allNewTemplate
    .filter((item) => item.category === "logo_style")
    .sort(sortFunc);

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">BASIC</div>
            {/* 추가 링크 페이지 */}
            <Link to="/mypage/cms/new/template">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <CmsTemplateHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsBasic &&
              itemsBasic.length > 0 &&
              itemsBasic.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-4/20 items-center flex-center">
                    <Link to={`/mypage/cms/edit/template/${item.id}`}>
                      <div
                        className="border"
                        style={{ width: 100, height: 100 }}
                      >
                        {console.log("itemplate : ", JSON.parse(item.template))}
                        {item && item.template && (
                          <PlayCustomTemplate
                            widthR={100}
                            heightR={100}
                            template={JSON.parse(item.template)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center"> </div>
                  <div className="w-5/20 text-center">{item.translate}</div>
                  <div className="w-8/20 text-center">{item.explain}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <div className="flex flex-row items-center justify-between mt-16">
            <div className="content-title">RETRO</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <CmsTemplateHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsRetro &&
              itemsRetro.length > 0 &&
              itemsRetro.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-4/20 items-center flex-center">
                    <Link to={`/mypage/cms/edit/template/${item.id}`}>
                      <div
                        className="border"
                        style={{ width: 100, height: 100 }}
                      >
                        {item && item.template && (
                          <PlayCustomTemplate
                            widthR={100}
                            heightR={100}
                            template={JSON.parse(item.template)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center"> </div>
                  <div className="w-5/20 text-center">{item.translate}</div>
                  <div className="w-8/20 text-center">{item.explain}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <div className="flex flex-row items-center justify-between mt-16">
            <div className="content-title">LOVELY</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <CmsTemplateHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsLovely &&
              itemsLovely.length > 0 &&
              itemsLovely.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-4/20 items-center flex-center">
                    <Link to={`/mypage/cms/edit/template/${item.id}`}>
                      <div
                        className="border"
                        style={{ width: 100, height: 100 }}
                      >
                        {item && item.template && (
                          <PlayCustomTemplate
                            widthR={100}
                            heightR={100}
                            template={JSON.parse(item.template)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center"> </div>
                  <div className="w-5/20 text-center">{item.translate}</div>
                  <div className="w-8/20 text-center">{item.explain}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <div className="flex flex-row items-center justify-between mt-16">
            <div className="content-title">CALLIGRAPHY</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <CmsTemplateHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsCalligraphy &&
              itemsCalligraphy.length > 0 &&
              itemsCalligraphy.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-4/20 items-center flex-center">
                    <Link to={`/mypage/cms/edit/template/${item.id}`}>
                      <div
                        className="border"
                        style={{ width: 100, height: 100 }}
                      >
                        {item && item.template && (
                          <PlayCustomTemplate
                            widthR={100}
                            heightR={100}
                            template={JSON.parse(item.template)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center"> </div>
                  <div className="w-5/20 text-center">{item.translate}</div>
                  <div className="w-8/20 text-center">{item.explain}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <div className="flex flex-row items-center justify-between mt-16">
            <div className="content-title">GRAPHIC & TYPO</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <CmsTemplateHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsGraphic &&
              itemsGraphic.length > 0 &&
              itemsGraphic.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-4/20 items-center flex-center">
                    <Link to={`/mypage/cms/edit/template/${item.id}`}>
                      <div
                        className="border"
                        style={{ width: 100, height: 100 }}
                      >
                        {item && item.template && (
                          <PlayCustomTemplate
                            widthR={100}
                            heightR={100}
                            template={JSON.parse(item.template)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center"> </div>
                  <div className="w-5/20 text-center">{item.translate}</div>
                  <div className="w-8/20 text-center">{item.explain}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <div className="flex flex-row items-center justify-between mt-16">
            <div className="content-title">LOGO</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <CmsTemplateHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsLogoStyle &&
              itemsLogoStyle.length > 0 &&
              itemsLogoStyle.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-4/20 items-center flex-center">
                    <Link to={`/mypage/cms/edit/template/${item.id}`}>
                      <div
                        className="border"
                        style={{ width: 100, height: 100 }}
                      >
                        {item && item.template && (
                          <PlayCustomTemplate
                            widthR={100}
                            heightR={100}
                            template={JSON.parse(item.template)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/20 text-center"> </div>
                  <div className="w-5/20 text-center">{item.translate}</div>
                  <div className="w-8/20 text-center">{item.explain}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

const CmsTemplate = () => {
  const setting = useSettings();
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );
  return (
    <div>
      <CmsPage>
        <div>{screen}</div>
      </CmsPage>
    </div>
  );
};

export default CmsTemplate;
