import React, { useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "sweetalert";
import Moment from "react-moment";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { ALL_ORDERS_ADMIN } from "graphql/gql/select";
import { useQuery } from "@apollo/client";
import MyPage from "./MyPage";
import OrderHeader from "../../components/OrderHeader";
import DateRange from "../../components/DateRange";
import DeliveryModal from "../../components/DeliveryModal";
import MyOrderModal from "../../components/MyOrderModal";
import StateModal from "../../components/StateModal";
import ReviewModalAdmin from "../../components/ReviewModalAdmin";

const Orders = () => {
  const [currentRange, setCurrentRange] = useState("all");
  const { loading, error, data } = useQuery(ALL_ORDERS_ADMIN, {
    fetchPolicy: "network-only",
  });

  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.allOrders) return <p>Error</p>;
  const items = data.allOrders;

  const date = new Date().getTime();
  console.log("Date : ", date);

  // 1주일
  const itemsWeek = items.filter(
    (item) => (date - item.createdAt) / 1000 < 60 * 60 * 24 * 7
  );
  const itemsMonth = items.filter(
    (item) => (date - item.createdAt) / 1000 < 60 * 60 * 24 * 30
  );
  const itemsThreeMonth = items.filter(
    (item) => (date - item.createdAt) / 1000 < 60 * 60 * 24 * 90
  );

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <DateRange
        currentRange={currentRange}
        setCurrentRange={setCurrentRange}
      />
      {currentRange === "all" && <OrderList items={items} />}
      {currentRange === "1week" && <OrderList items={itemsWeek} />}
      {currentRange === "1month" && <OrderList items={itemsMonth} />}
      {currentRange === "3months" && <OrderList items={itemsThreeMonth} />}
    </div>
  );
};

Modal.setAppElement("#root");

const AllOrders = () => {
  const setting = useSettings();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <Orders />
    ) : (
      ""
    );
  return (
    <div>
      <div className="">
        <MyPage>{screen}</MyPage>
      </div>
    </div>
  );
};

const OrderList = ({ items }) => {
  const { t } = useTranslation();
  const setting = useSettings();

  const [currentItem, setCurrentItem] = useState();
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  const [reviewModal, setReviewModal] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [stateModalOpen, setStateModalOpen] = useState(false);
  const [payId, setPayId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [tempState, setTempState] = useState("");
  const [reviewId, setReviewId] = useState("");

  return (
    <div>
      <div className="mt-4 content-title">{t("전제 주문배송 조회")}</div>
      <div className="blackline2 mt-1" />
      <SRLWrapper>
        <div className="flex-row-center text-medium-15">
          <OrderHeader />
          <div className="grayline" />
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div className="w-full py-2" key={item.id}>
                <div className="w-full flex-row-center table-text">
                  <div className="w-3/12 pl-0">
                    <div className="flex-row-left">
                      <img
                        className="w-10 h-10 border-black border"
                        src={item.product.mainImage}
                        alt=""
                      />
                      <div className="ml-4 flex-col-left">
                        <div className="font-bold">{item.product.name}</div>
                        <div className="mt-1">
                          {item.designAvailable
                            ? t("디자인 있음")
                            : t("디자인 없음")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/12 text-center">
                    {console.log("createdAt : ", item)}
                    <Moment unix format="YYYY.MM.DD">
                      {Number(item.createdAt) / 1000}
                    </Moment>
                  </div>
                  <div
                    onClick={() => {
                      setPayId(item.payId);
                      setPayModalOpen(true);
                    }}
                    className="w-2/12 text-center"
                  >
                    {item.id}
                  </div>
                  <div className="w-2/12 text-center">{item.productTotal}</div>

                  <div className="w-4/12 text-center flex flex-wrap">
                    <div className="w-1/2 text-center text-black">
                      <div
                        onClick={() => {
                          setOrderId(item.id);
                          setTempState(item.state);
                          setStateModalOpen(true);
                        }}
                        className="mx-3 text-center text-black"
                      >
                        {item.state === "" && <div>waiting for payment</div>}
                        {item.state === "Payment finished" && (
                          <div>Payment finished</div>
                        )}
                        {item.state === "Preparing Product" && (
                          <div>Preparing Product</div>
                        )}
                        {item.state === "Delivering" && <div>Delivering</div>}
                      </div>
                    </div>
                    <div className="mt-1 w-1/2 text-center text-black">
                      <div
                        className="mx-4 border border-black text-center text-black"
                        onClick={() => {
                          setCurrentItem(item);
                          setDeliveryModalOpen(true);
                        }}
                      >
                        {t("1대1문의")}
                      </div>
                    </div>
                    <div className="mt-1 w-1/2 text-center text-black">
                      <div
                        className="mx-4 border border-black text-center text-black"
                        onClick={() => {
                          setCurrentItem(item);
                          setDeliveryModalOpen(true);
                        }}
                      >
                        {t("배송조회")}
                      </div>
                    </div>
                    <div className="mt-1 w-1/2 text-center text-black">
                      <div
                        className="mx-4 border border-black text-center"
                        onClick={() => {
                          if (item.reviewId) {
                            setReviewId(item.reviewId);
                            setReviewModal(true);
                          } else {
                            swal("리뷰가 존재하지 않습니다.", {
                              button: false,
                              timer: 3000,
                            });
                          }
                        }}
                      >
                        {t("리뷰작성")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grayline" />
              </div>
            ))}
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {!setting.isLogin
              ? t("로그인을 먼저 해주세요")
              : items.length === 0
              ? t("내역이 없습니다")
              : ""}
          </div>
        </div>
      </SRLWrapper>
      <DeliveryModal
        modalIsOpen={deliveryModalOpen}
        closeModal={() => setDeliveryModalOpen(false)}
        currentItem={currentItem}
      />
      <MyOrderModal
        modalIsOpen={payModalOpen}
        closeModal={() => setPayModalOpen(false)}
        payId={payId}
      />
      <StateModal
        modalIsOpen={stateModalOpen}
        closeModal={() => setStateModalOpen(false)}
        orderId={orderId}
        state={tempState}
      />
      <ReviewModalAdmin
        modalIsOpen={reviewModal}
        closeModal={() => setReviewModal(false)}
        reviewId={reviewId}
      />
    </div>
  );
};

export default AllOrders;
