import React from "react";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";

import MyPage from "./MyPage";

const Design = () => {
  const { t } = useTranslation();

  // TODO 디자인 쿼리 넣을 것
  const items = [];

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">MY DESIGN</div>
      <div className="bg-black mt-1" style={{ height: 2 }} />
      <div className="mt-4  flex flex-row flex-wrap ">
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="flex-col-center mr-4 mb-4">
              <img className="w-32 h-32 border-black border" src={item.img} alt="" />
              <div className="mt-2 text-xs text-bold-tight-15">{item.templateName}</div>
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const DesignMobile = () => {
  // TODO Like 쿼리 만들것
  const { t } = useTranslation();

  const items = [];
  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">My Design</div>
      <div className="blackline2 mt-1" />
      <div className="mt-4 flex flex-row flex-wrap">
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="flex-col-center mr-4 mb-4" key={item.id}>
              <img className="w-32 h-32 border-black border" src={item.img} alt="" />
              <div className="mt-2 text-xs text-bold-tight-15">{item.templateName}</div>
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const MyDesign = () => {
  const setting = useSettings();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";
  const screen = setting.isMobile ? (
    <DesignMobile />
  ) : (
    <MyPage>
      <Design />
    </MyPage>
  );

  return <div>{screen}</div>;
};

export default MyDesign;
