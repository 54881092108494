import { ApolloLink, ApolloClient } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import apolloLogger from "apollo-link-logger";

const GRAPHQL_URL =
  process.env.NODE_ENV === "production"
    ? "https://enkino.xyz:9901/graphql"
    : "https://enkino.xyz:9901/graphql";

const link = createUploadLink({
  uri: GRAPHQL_URL,
  headers: {
    "keep-alive": "true",
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([apolloLogger, authLink, link]),
  cache: new InMemoryCache(),
});

export default client;
