import React, { useState } from "react";
import Modal from "react-modal";
import customStyles from "components/modalStyle";
import btn_modal_close from "assets/images/btn_modal_close.svg";
import Moment from "react-moment";
import { useQuery } from "@apollo/client";
import swal from "sweetalert";
import { REVIEW } from "../graphql/gql/select";
import ReviewUpdateModal from "./ReviewUpdateModal";

const ReviewModalTest = ({ modalIsOpen, closeModal, reviewId }) => {
  const [reviewUpdateModalOpen, setReviewUpdateModalOpen] = useState(false);

  const { loading, error, data, refetch } = useQuery(REVIEW, {
    variables: { id: reviewId },
  });

  console.log("reviewId : ", reviewId);

  const onRequestClose = () => {
    document.documentElement.style.overflowY = "visible";
    document.documentElement.removeEventListener("touchmove", preventIOSScroll);
    closeModal();
  };

  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (data) {
    console.log("order", data.review);
    console.log("review", data);
  }
  const reviewItem = data.review;
  // const hashTagList = reviewItem.hashTag;

  const preventIOSScroll = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div style={{ width: 800 }} className="flex flex-row bg-transparent">
        <div className="p-4 w-full table-text bg-white flex flex-col">
          {console.log("reviewItem : ", reviewItem)}
          <div className="w-full flex">
            <div className="w-1/2">
              {reviewItem && reviewItem.mainImg && (
                <img className="w-full" src={reviewItem.mainImg} alt="" />
              )}
            </div>
            <div className="ml-4 w-1/2 relative">
              <div className="w-full border-b absolute top-0 h-20 flex">
                <div className="my-auto w-14 h-14 rounded-full">
                  {reviewItem &&
                    (reviewItem.member.img ? (
                      <img
                        className="w-full h-full rouded-full"
                        src={reviewItem.member.img}
                        alt=""
                      />
                    ) : (
                      <div className="w-full h-full bg-black rounded-full" />
                    ))}
                </div>
                <div className="my-auto ml-4 h-14 font-bold fontsize-20 items-center mt-4">
                  {reviewItem && reviewItem.member && (
                    <p>{reviewItem.member.name}</p>
                  )}
                </div>
                <div className="ml-8 font-bold fontsize-8 mt-12">
                  {reviewItem && reviewItem.createdAt && (
                    <div className="flex">
                      <div>Created At : </div>
                      <Moment unix format="YYYY.MM.DD">
                        {Number(reviewItem.createdAt) / 1000}
                      </Moment>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-20 fontsize-16 w-full">
                {reviewItem && reviewItem.comment && (
                  <div>{reviewItem.comment}</div>
                )}
              </div>
              <div className="mt-4 font-bold fontsize-16 flex flex-wrap">
                {reviewItem && reviewItem.hashTag && (
                  <div className="flex">
                    {reviewItem.hashTag.split(",").map((item) => (
                      <p key={item} className="font-bold">
                        # {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div
                onClick={() => setReviewUpdateModalOpen(true)}
                className="w-full absolute bottom-0 h-40 bg-gray flex flex-col"
              />
            </div>
          </div>
        </div>
        <div className="w-16 bg-transparent">
          <img
            className="w-16 mt-1 w-6 h-6"
            src={btn_modal_close}
            alt=""
            onClick={onRequestClose}
          />
        </div>
        <ReviewUpdateModal
          modalIsOpen={reviewUpdateModalOpen}
          closeModal={() => setReviewUpdateModalOpen(false)}
          reviewItem={reviewItem}
          refetch={refetch}
        />
      </div>
    </Modal>
  );
};

export default ReviewModalTest;
