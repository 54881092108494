import ReactPlayer from "react-player";
/* eslint-disable react/react-in-jsx-scope */
const MultiVideos = ({ videos }) => {
  console.log("MultiVideos, iamges : ", videos);

  if (!videos || videos === "") return "";
  try {
    const detailVideos = JSON.parse(videos);
    if (!detailVideos || detailVideos.length === 0) return "";
    const MultiVideos1 = detailVideos.map((video) => {
      return <ReactPlayer className="" url={video} width="120" height="100" />;
    });
    return MultiVideos1;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export default MultiVideos;
