import { FIND_ID } from "graphql/gql/select";
import useSettings from "stores/settings";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";

const imgboxStyle = {
  width: "100% ",
  height: "100% ",
};

const Screen = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [findId, { data }] = useMutation(FIND_ID, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.findId;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(data1.msg, { button: false, timer: 3000 });
      }
    }
  }, [data]);

  // 필드 검사 후 find id 쿼리 호출
  const handlerFindIdClick = () => {
    if (!email) {
      swal(t("이메일을 입력해 주세요"), { button: false, timer: 3000 });

      return;
    }
    findId({ variables: { email } });
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen mt-4 font-noto  text-xs flex-col-center ">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white shadow-lg">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around">
            <p className="font-noto  text-black text-sm font-bold text-center ">
              {t("아이디 찾기")}
            </p>
          </div>
          <div className="mx-8 flex flex-row items-center justify-around">
            <p className="w-full bg-black height-1" />
          </div>
          <div className="mx-8 mb-4 px-8 text-center">
            {t("이메일 주소를 입력하시면 가입되어 있는지 알려드립니다")}
          </div>
          <div className="mx-8 mt-8 mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mt-8 mb-16 flex items-center justify-between">
            <button
              className="w-full bg-black text-white font-bold py-2 px-4"
              onClick={() => handlerFindIdClick()}
            >
              {t("아이디 찾기")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScreenMobile = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [findId, { data }] = useMutation(FIND_ID, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.findId;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(data1.msg, { button: false, timer: 3000 });
      }
    }
  }, [data]);

  // 필드 검사 후 find id 쿼리 호출
  const handlerFindIdClick = () => {
    if (!email) {
      swal(t("이메일을 입력해 주세요"), { button: false, timer: 3000 });

      return;
    }
    findId({ variables: { email } });
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen font-noto  text-xs flex-col-left ">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-full">
          <div className="mt-8 mx-2 flex flex-row items-center justify-around">
            <p className="font-noto fontsize-16 text-black text-sm text-center uppercase tracking-wider">
              {t("아이디 찾기")}
            </p>
          </div>
          <div className="blackline2 mx-2"> </div>
          <div className="mx-2 mt-3 px-8 text-center">
            <pre className="font-noto fontsize-12">
              {t("이메일 주소를 입력하시면 가입되어 있는지 알려드립니다")}
            </pre>
          </div>
          <div className="mx-2 mt-8">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
          </div>
          <div className="h-12 mx-2 mt-0 flex flex-wrap items-center justify-center bg-black">
            <div
              className="w-full btn-text text-center text-white fontsize-16 uppercase"
              onClick={() => handlerFindIdClick()}
            >
              {t("아이디 찾기")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FindID = () => {
  const setting = useSettings();
  const screen = setting.isMobile ? <ScreenMobile /> : <Screen />;
  return (
    <div>
      <div className="">{screen}</div>
    </div>
  );
};

export default FindID;
