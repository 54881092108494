import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { CREATE_SPECIAL_ORDER, MULTI_UPLOAD_FILES } from "graphql/gql/select";
import useSettings from "stores/settings";
import MyPage from "./MyPage";
import CheckBox from "../../components/CheckBox";
import MultiImages from "../../components/MultiImages";

const Screen = () => {
  const { t } = useTranslation();
  const setting = useSettings();
  const history = useHistory();
  const [id] = useState(setting.id);
  const [name] = useState(setting.userName);
  const [email] = useState(setting.email);
  const [productType, setProductType] = useState("");
  const [contents, setContents] = useState("");
  const [detailImage, setDetailImage] = useState("");

  /* 주문/단체제작 버튼 클릭시 , mutation 생성 */
  const [createSpecialOrder, { data }] = useMutation(CREATE_SPECIAL_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.createSpecialOrder;
      if (!data1.id) {
        swal(t("의뢰 생성에 오류가 있습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("의뢰를 요청하였습니다"), { button: false, timer: 3000 });
        setting.specialOrdersUpdates = true;
        history.push("/mypage/myspecialorders");
      }
    }
  }, [data]);

  // 상세 이미지 올리기
  const [multiUpload2, { data: multiData2 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData2 !== undefined) {
      const data1 = multiData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        // 기존 이미지가 있으면 합함
        let curImages = [];
        try {
          curImages = JSON.parse(detailImage);
        } catch (e) {
          console.log(e);
          curImages = [];
        }
        filenames.map((file1) => {
          curImages.push(file1);
        });

        setDetailImage(JSON.stringify(curImages));
      }
      console.log(data1);
    }
  }, [multiData2]);

  const handlerOrderClick = () => {
    if (!id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    if (!contents) {
      swal(t("내용을 입력해 주세요"), { button: false, timer: 3000 });
      return;
    }
    createSpecialOrder({ variables: { memberId: id, productType, contents, detailImage } });
  };

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">{t("의뢰상세")}</div>
      <div className="blackline2 mt-1" />
      <div className="flex flex-row flex-wrap items-start justify-center text-medium-15 ">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around mb-2">
            <p className="font-noto  text-sm font-bold text-center ">{t("주문/단체 제작 의뢰 폼제목")}</p>
          </div>

          <div className="mx-8 flex flex-col items-center justify-around">
            <p className="w-full bg-black height-1" />
            <div className="mx-8 mt-0 mb-0">
              <Moment unix format="YYYY-MM-DD" className="w-full text-right">
                {Date.now() / 1000}
              </Moment>
            </div>
          </div>

          <div className="mx-8 mt-4 mb-4">
            <input
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이름")}
              value={name}
              readOnly
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              id="email"
              type="email"
              className="w-full px-2 py-2  outline-none"
              placeholder={t("이메일")}
              value={email}
              readOnly
            />
            <p className="w-full bg-black  height-1" />
          </div>
          <div className="mx-8 mb-4 flex flex-row  flex-wrap">
            <div className="flex flex-row items-center mr-8" onClick={() => setProductType("의류")}>
              <CheckBox isCheck={productType === "의류"} />
              <div className="text-2xs ml-2">{t("의류")}</div>
            </div>
            <div className="flex flex-row items-center" onClick={() => setProductType("폰케이스")}>
              <CheckBox isCheck={productType === "폰케이스"} />
              <div className="text-2xs ml-2">{t("폰케이스")}</div>
            </div>
          </div>
          <p className="mx-8 bg-black  height-1" />
          <div className="mx-8 mb-4 h-32">
            <textarea
              onChange={(e) => setContents(e.target.value)}
              type="text"
              className="w-full px-2 py-2  outline-none h-full border-black border-1"
              placeholder={t("내용")}
              defaultValue={contents}
            />
          </div>
          <div className="mt-3 mx-8 flex-row-left">
            <div className="w-20 font-bold">상세이미지</div>
            <MultiImages images={detailImage} />
            <div className="ml-12 flex-row-left">
              <div className="mt-3 h-8 flex flex-row table-text relative">
                <form
                  onSubmit={() => {
                    console.log("Submitted");
                  }}
                  encType="multipart/form-data"
                >
                  <input
                    name="document"
                    multiple
                    type="file"
                    accept="image/*"
                    method="POST"
                    onChange={async ({ target: { files } }) => {
                      if (files) multiUpload2({ variables: { files } });
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="mx-8 flex items-center justify-between">
            <button
              className="w-full text-sm bg-black text-white font-bold mt-8 py-2 px-4"
              onClick={() => handlerOrderClick()}
            >
              {t("요청하기")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScreenMobile = () => {
  const { t } = useTranslation();
  const setting = useSettings();
  const history = useHistory();
  const [id] = useState(setting.id);
  const [name] = useState(setting.userName);
  const [email] = useState(setting.email);
  const [productType, setProductType] = useState("");
  const [contents, setContents] = useState("");
  const [detailImage, setDetailImage] = useState("");

  /* 주문/단체제작 버튼 클릭시 , mutation 생성 */
  const [createSpecialOrder, { data }] = useMutation(CREATE_SPECIAL_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.createSpecialOrder;
      if (!data1.id) {
        swal(t("의뢰 생성에 오류가 있습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("의뢰를 요청하였습니다"), { button: false, timer: 3000 });
        setting.specialOrdersUpdates = true;
        history.push("/mypage/myspecialorders");
      }
    }
  }, [data]);

  // 상세 이미지 올리기
  const [multiUpload2, { data: multiData2 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData2 !== undefined) {
      const data1 = multiData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        // 기존 이미지가 있으면 합함
        let curImages = [];
        try {
          curImages = JSON.parse(detailImage);
        } catch (e) {
          console.log(e);
          curImages = [];
        }
        filenames.map((file1) => {
          curImages.push(file1);
        });

        setDetailImage(JSON.stringify(curImages));
      }
      console.log(data1);
    }
  }, [multiData2]);

  const handlerOrderClick = () => {
    if (!id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    if (!contents) {
      swal(t("내용을 입력해 주세요"), { button: false, timer: 3000 });
      return;
    }
    createSpecialOrder({ variables: { memberId: id, productType, contents, detailImage } });
  };

  return (
    <div>
      <div className="w-full px-3 mt-6">
        <div className="content-title ">{t("의뢰하기")}</div>
        <div className="blackline2 mt-1" />
        <div className="flex flex-row flex-wrap items-start justify-left text-medium-15 bg-white">
          {/* 아메일 , 암호 필드 표시  */}
          <div className="w-full">
            <div className=" mt-4 mb-4 flex flex-row">
              <div className="w-48 text-left">{t("이름")}</div>
              <input
                type="text"
                className="flex-auto outline-none font-bold text-left"
                placeholder={t("이름")}
                value={name}
                readOnly
              />
            </div>

            <div className=" mt-4 mb-4 flex flex-row">
              <div className="w-48">{t("이메일")}</div>
              <input
                id="email"
                type="email"
                className="flex-auto outline-none font-bold"
                placeholder={t("이메일")}
                value={email}
                readOnly
              />
            </div>
            <div className="mb-4 flex flex-row  flex-wrap">
              <div className="flex flex-row items-center mr-8" onClick={() => setProductType("의류")}>
                <CheckBox isCheck={productType === "의류"} />
                <div className="text-2xs ml-2">{t("의류")}</div>
              </div>
              <div className="flex flex-row items-center" onClick={() => setProductType("폰케이스")}>
                <CheckBox isCheck={productType === "폰케이스"} />
                <div className="text-2xs ml-2">{t("폰케이스")}</div>
              </div>
            </div>
            <div className="w-48 text-left mb-4">{t("내용")}</div>
            <div className="mb-4 h-48">
              <textarea
                onChange={(e) => setContents(e.target.value)}
                type="text"
                className="w-full px-2 py-2  outline-none h-full border-black border-1"
                placeholder={t("내용")}
                defaultValue={contents}
              />
            </div>
            <div className="mt-3 flex-row-left">
              <div className="w-20 font-bold">{t("상세이미지")}</div>
              <MultiImages images={detailImage} />
              <div className="ml-12 flex-row-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <form
                    onSubmit={() => {
                      console.log("Submitted");
                    }}
                    encType="multipart/form-data"
                  >
                    <input
                      name="document"
                      multiple
                      type="file"
                      accept="image/*"
                      method="POST"
                      onChange={async ({ target: { files } }) => {
                        if (files) multiUpload2({ variables: { files } });
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full flex items-center justify-between">
        <button
          className="w-full h-16 text-sm bg-black text-white font-bold fontsize-20"
          onClick={() => handlerOrderClick()}
        >
          {t("요청하기")}
        </button>
      </div>
    </div>
  );
};

const SpecialOrder = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";

  const screen = setting.isMobile ? (
    <ScreenMobile />
  ) : (
    <MyPage>
      <Screen />
    </MyPage>
  );
  return <div>{screen}</div>;
};

export default SpecialOrder;
