import React from "react";
import { useTranslation } from "react-i18next";

const OrderHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header-bold">
      <div className="w-3/12 pl-8">{t("주문내역")}</div>
      <div className="w-1/12 text-center">{t("주문일")}</div>
      <div className="w-2/12 text-center">{t("주문번호")}</div>
      <div className="w-2/12 text-center">{t("금액")}</div>
      <div className="w-4/12 text-center">{t("진행상태")}</div>
    </div>
  );
};

export default OrderHeader;
