import React, { Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import jwtDecode from "jwt-decode";
import useSettings from "stores/settings";
import { Route, BrowserRouter as Router } from "react-router-dom";
import {
  AllDesigns,
  AllLikes,
  AllMembers,
  AllNotices,
  AllOrders,
  AllPoints,
  AllProducts,
  AllQA,
  AllReviews,
  AllSpecialOrders,
  AuthorizeAdmin,
  Category,
  EditCms,
  EditCmsSnsTv,
  EditProduct,
  FAQ,
  FindID,
  FindPassword,
  Member,
  MyDesigns,
  MyLikes,
  MyNotices,
  MyOrders,
  MyDelivery,
  MyPoints,
  MyProfile,
  MyQA,
  MyReviews,
  MySpecialOrders,
  NewCms,
  NewCmsSnsTv,
  NewCmsTemplate,
  NewProduct,
  Order,
  Pay,
  Product,
  SelectProduct,
  SignIn,
  SignOut,
  SignUp,
  SpecialOrder,
  SpecialOrderDetail,
  EditTemplate,
  EditTemplateImage,
  ChangeProduct,
  NewTemplateImage,
} from "containers/mypage";
import SimpleReactLightbox from "simple-react-lightbox";
import {
  CmsHangul,
  CmsHome,
  CmsGround,
  CmsAbout,
  CmsTV,
  CmsCategory,
  CmsInstagram,
  CmsReview,
  CmsRecommend,
  CmsTemplate,
  CmsTemplateImage,
} from "containers/mypage/cms";
import "firebase/auth";
const About = React.lazy(() => import("containers/about/About"));
const PlayCustom = React.lazy(() => import("containers/playcustom/PlayCustom"));
const PlayCustomCategory = React.lazy(() =>
  import("containers/playcustom/PlayCustomCategory")
);
const PlayCustomCategory2 = React.lazy(() =>
  import("containers/playcustom/PlayCustomCategory2")
);
const PlayHangul = React.lazy(() => import("containers/playhangul/PlayHangul"));
const PlayGround = React.lazy(() => import("containers/playground/PlayGround"));
const PlayTV = React.lazy(() => import("containers/playtv/PlayTV"));
const ShoppingBag = React.lazy(() => import("containers/cart/ShoppingBag"));
const Header = React.lazy(() => import("containers/header/Header"));
const Footer = React.lazy(() => import("containers/footer/Footer"));
const Home = React.lazy(() => import("containers/home/Home"));
const Agreements = React.lazy(() => import("containers/footer/Agreements"));

// 원본
const PlayCustomCreate = React.lazy(() =>
  import("containers/playcustom/PlayCustomCreate")
);

const PlayCustomPreview = React.lazy(() =>
  import("containers/playcustom/PlayCustomPreview")
);

const Upload1 = React.lazy(() => "containers/test/upload1");

const Test = React.lazy(() => import("containers/about/Test"));
const JsonCategory = React.lazy(() => import("containers/test/JsonCategory"));
const PlayCustomMobileAddGraphic = React.lazy(() =>
  import("containers/playcustom/PlayCustomMobileAddGraphic")
);
const PlayCustomMobileRecommend = React.lazy(
  () => "containers/playcustom/PlayCustomMobileRecommend"
);
const PlayCustomMobileProduct = React.lazy(() =>
  import("containers/playcustom/PlayCustomMobileProduct")
);
const PlayCustomMobile = React.lazy(() =>
  import("containers/playcustom/PlayCustomMobile")
);
const PlayCustomCreateMobile = React.lazy(() =>
  import("containers/playcustom/PlayCustomCreateMobile")
);
const PlayCustomOrderAbout = React.lazy(() =>
  import("containers/playcustom/PlayCustomOrderAbout")
);
const PlayCustomAmountMobile = React.lazy(() =>
  import("containers/playcustom/PlayCustomAmountMobile")
);
const PlayCustomAboutMobile = React.lazy(() =>
  import("containers/playcustom/PlayCustomAboutMobile")
);
const PlayCustomMobileOrder2 = React.lazy(() =>
  import("containers/playcustom/PlayCustomMobileOrder2")
);
const PlayCustomMobileOrderInfo = React.lazy(() =>
  import("containers/playcustom/PlayCustomMobileOrderInfo")
);
const PlayLabelCreate = React.lazy(() =>
  import("containers/playcustom/PlayLabelCreate")
);
const PlayCustomMobileAddGraphicCategory = React.lazy(() =>
  import("containers/playcustom/PlayCustomMobileAddGraphicCategory")
);
const PlayCustomBackCreate = React.lazy(() =>
  import("containers/playcustom/PlayCustomBackCreate")
);

const PlayCustomOrderAmount = React.lazy(() =>
  import("containers/playcustom/PlayCustomOrderAmount")
);
const PlayCustomOrder = React.lazy(() =>
  import("containers/playcustom/PlayCustomOrder")
);
const PlayCustomOrderInfo = React.lazy(() =>
  import("containers/playcustom/PlayCustomOrderInfo")
);
const DesignList = React.lazy(() => import("./containers/mypage/DesignList"));
const OAuth = React.lazy(() => import("./containers/oauth/OAuth"));
const Version = React.lazy(() => import("./containers/about/Version"));

const PlayHangulMenu = React.lazy(() =>
  import("containers/playhangul3/PlayHangulMenu")
);

const PlayHangul3_1 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_1")
);
const PlayHangul3_2 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_2")
);
const PlayHangul3_3 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_3")
);
const PlayHangul3_4 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_4")
);
const PlayHangul3_5 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_5")
);
const PlayHangul3_6 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_6")
);
const PlayHangul3_7 = React.lazy(() =>
  import("containers/playhangul3/PlayHangul3_7")
);

const MyPageMobile = React.lazy(() =>
  import("./containers/mypage/MyPageMobile")
);
const LayoutOne = React.lazy(() => import("./LayoutOne"));

const PlayCustomLabel = React.lazy(() =>
  import("containers/playcustom/PlayCustomLabel")
);

const Pay2 = React.lazy(() => import("containers/mypage/Pay2"));
const Pay3 = React.lazy(() => import("containers/mypage/Pay3"));

const PayCompleted = React.lazy(() => import("containers/mypage/PayComplete"));

const PaySample = React.lazy(() => import("containers/mypage/PaySample"));

const tempPage = React.lazy(() =>
  import("containers/playcustom/PlayCustomCreate/PlayCustomCreateLabel2")
);

const PlayCustomTvProductCategory = React.lazy(() =>
  import(
    "containers/playcustom/CMSPlayCustomTv/PlayCustomTvProductCategory/PlayCustomTvProductCategory"
  )
);

const PlayCustomTvCreate = React.lazy(() =>
  import("containers/playcustom/CMSPlayCustomTv/PlayCustomTvCreate")
);

console.log("process.env.REACT_APP_TEST : ", process.env.REACT_APP_TEST);
console.log(
  "process.env.REACT_APP_GRAPHQL_URL : ",
  process.env.REACT_APP_GRAPHQL_URL
);

const App = () => {
  const setting = useSettings();
  let json = localStorage.getItem("store");
  if (json) {
    Object.assign(setting, JSON.parse(json));
  }
  window.localStorage.setItem("token", "");

  const token = window.localStorage.getItem("token");
  if (token && token !== "") {
    // token expire 여부 확인하기
    const member = jwtDecode(token);
    const current_time = Date.now() / 1000;
    if (member.exp < current_time) {
      /* expired */
      setting.isLogin = false;
      setting.email = "";
      setting.id = "";
      setting.phone = "";
      setting.userName = "";
      setting.token = "";
      window.localStorage.setItem("token", "");
      // console.error("토큰 종료 , no token");
    } else {
      setting.isLogin = true;
      setting.email = member.user.email;
      setting.id = member.user.id;
      setting.phone = member.user.phone;
      setting.userName = member.user.name;
      setting.token = token;
    }
  }

  setting.isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  setting.isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  setting.isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {/* <SimpleReactLightbox> */}
        <div className="relative flex flex-col">
          <Router onUpdate={() => window.scrollTo(0, 0)}>
            {/* 내용 시작 */}
            <Header className="flex-1" />
            <LayoutOne headerTop="visible">
              <div className="flex-grow minxx-h-screen">
                <Route exact path="/" component={Home} />
                <Route exact path="/__/auth/action" component={OAuth} />
                <Route exact path="/oauth_login" component={OAuth} />
                <Route exact path="/mypage/cms" component={CmsHome} />
                <Route exact path="/paySample/sample" component={PaySample} />
                <Route
                  exact
                  path="/mypage/product/new"
                  component={NewProduct}
                />
                <Route
                  exact
                  path="/mypage/product/edit/:id"
                  component={EditProduct}
                />
                <Route
                  exact
                  path="/playhangul/menu"
                  component={PlayHangulMenu}
                />
                <Route exact path="/playhangul/1" component={PlayHangul3_1} />
                <Route exact path="/playhangul/2" component={PlayHangul3_2} />
                <Route exact path="/playhangul/3" component={PlayHangul3_3} />
                <Route exact path="/playhangul/4" component={PlayHangul3_4} />
                <Route exact path="/playhangul/5" component={PlayHangul3_5} />
                <Route exact path="/playhangul/6" component={PlayHangul3_6} />
                <Route exact path="/playhangul/7" component={PlayHangul3_7} />
                <Route exact path="/about" component={About} />
                <Route exact path="/agreements" component={Agreements} />
                <Route exact path="/category/:category" component={Category} />
                <Route
                  exact
                  path="/cmscategory/:category"
                  component={PlayCustomTvProductCategory}
                />
                <Route exact path="/shopping_bag" component={ShoppingBag} />
                <Route exact path="/mypage" component={MyPageMobile} />
                <Route
                  exact
                  path="/mypage/all_designs"
                  component={AllDesigns}
                />
                <Route
                  exact
                  path="/mypage/all_designs/:id"
                  component={DesignList}
                />
                <Route exact path="/mypage/all_likes" component={AllLikes} />
                <Route
                  exact
                  path="/mypage/all_members"
                  component={AllMembers}
                />
                <Route
                  exact
                  path="/mypage/all_notices"
                  component={AllNotices}
                />
                <Route exact path="/mypage/all_orders" component={AllOrders} />
                <Route exact path="/mypage/all_points" component={AllPoints} />
                <Route
                  exact
                  path="/mypage/all_products"
                  component={AllProducts}
                />
                <Route exact path="/mypage/all_qa" component={AllQA} />
                <Route
                  exact
                  path="/mypage/all_reviews"
                  component={AllReviews}
                />
                <Route
                  exact
                  path="/mypage/all_specialorders"
                  component={AllSpecialOrders}
                />
                <Route
                  exact
                  path="/mypage/authorize_admin"
                  component={AuthorizeAdmin}
                />
                <Route exact path="/mypage/cms/about" component={CmsAbout} />
                <Route
                  exact
                  path="/mypage/cms/category"
                  component={CmsCategory}
                />
                <Route
                  exact
                  path="/mypage/cms/edit/:category/:name/:id"
                  component={EditCms}
                />
                <Route
                  exact
                  path="/mypage/cms/tv/edit/:id"
                  component={EditCmsSnsTv}
                />
                <Route
                  exact
                  path="/mypage/cms/edit/template/:id"
                  component={EditTemplate}
                />
                <Route
                  exact
                  path="/mypage/cms/edit/templateimage/:id"
                  component={EditTemplateImage}
                />
                <Route exact path="/mypage/cms/ground" component={CmsGround} />
                <Route exact path="/mypage/cms/hangul" component={CmsHangul} />
                <Route exact path="/mypage/cms/home" component={CmsHome} />
                <Route
                  exact
                  path="/mypage/cms/recommend"
                  component={CmsRecommend}
                />
                <Route
                  exact
                  path="/mypage/cms/instagram/:category/:name"
                  component={CmsInstagram}
                />
                <Route exact path="/mypage/cms/newtv" component={NewCmsSnsTv} />
                <Route
                  exact
                  path="/mypage/cms/new/:category/:name"
                  component={NewCms}
                />
                <Route
                  exact
                  path="/mypage/cms/new/template"
                  component={NewCmsTemplate}
                />
                <Route
                  exact
                  path="/mypage/cms/new/templateimage"
                  component={NewTemplateImage}
                />
                <Route
                  exact
                  path="/mypage/cms/review/:category/:name"
                  component={CmsReview}
                />
                <Route
                  exact
                  path="/mypage/cms/select_product/:category/:name"
                  component={SelectProduct}
                />
                <Route
                  exact
                  path="/mypage/cms/change_product/:id/:category/:name"
                  component={ChangeProduct}
                />
                <Route
                  exact
                  path="/mypage/cms/template"
                  component={CmsTemplate}
                />
                <Route
                  exact
                  path="/mypage/cms/templateimg"
                  component={CmsTemplateImage}
                />
                <Route exact path="/mypage/cms/tv" component={CmsTV} />
                <Route exact path="/mypage/faq" component={FAQ} />
                <Route exact path="/mypage/findid" component={FindID} />
                <Route
                  exact
                  path="/mypage/findpassword"
                  component={FindPassword}
                />
                <Route exact path="/mypage/member/:id" component={Member} />
                <Route exact path="/mypage/mydesigns" component={MyDesigns} />
                <Route exact path="/mypage/mylikes" component={MyLikes} />
                <Route exact path="/mypage/mynotices" component={MyNotices} />
                <Route exact path="/mypage/myorders" component={MyOrders} />
                <Route exact path="/mypage/mydelivery" component={MyDelivery} />
                <Route exact path="/mypage/mypoints" component={MyPoints} />
                <Route exact path="/mypage/myprofile" component={MyProfile} />
                <Route exact path="/mypage/myreview" component={MyReviews} />
                <Route
                  exact
                  path="/mypage/myspecialorders"
                  component={MySpecialOrders}
                />
                <Route
                  exact
                  path="/mypage/product/info/:id"
                  component={Product}
                />
                <Route exact path="/mypage/order/:id" component={Order} />
                {/* <Route exact path="/mypage/Pay/:id" component={Pay} /> */}
                <Route exact path="/mypage/Pay/:id" component={Pay2} />
                <Route exact path="/mypage/testPay2" component={Pay3} />
                <Route
                  exact
                  path="/mypage/paycompleted/:id"
                  component={PayCompleted}
                />
                <Route exact path="/mypage/my_qa" component={MyQA} />
                <Route exact path="/mypage/signin" component={SignIn} />
                <Route exact path="/mypage/signout" component={SignOut} />
                <Route exact path="/mypage/signup" component={SignUp} />
                <Route
                  exact
                  path="/mypage/specialorder"
                  component={SpecialOrder}
                />
                <Route
                  exact
                  path="/mypage/specialorderdetail/:id"
                  component={SpecialOrderDetail}
                />
                <Route exact path="/playcustom" component={PlayCustom} />
                <Route
                  exact
                  path="/playcustom/:template"
                  component={PlayCustomCategory}
                />
                <Route
                  exact
                  path="/playcustom2/:categoryMenu"
                  component={PlayCustomCategory2}
                />

                <Route
                  exact
                  path="/playcustom_create"
                  component={PlayCustomCreate}
                />

                <Route
                  exact
                  path="/playcustom_back"
                  component={PlayCustomBackCreate}
                />
                <Route
                  exact
                  path="/playcustom_preview"
                  component={PlayCustomPreview}
                />
                <Route exact path="/playground" component={PlayGround} />
                <Route exact path="/playtv" component={PlayTV} />
                <Route exact path="/test/upload1" component={Upload1} />
                <Route exact path="/version" component={Version} />
                <Route exact path="/test" component={Test} />
                <Route
                  exact
                  path="/test/json_category"
                  component={JsonCategory}
                />

                <Route
                  exact
                  path="/mobile/recommend"
                  component={PlayCustomMobileRecommend}
                />
                <Route
                  exact
                  path="/mobile/addgraphic"
                  component={PlayCustomMobileAddGraphic}
                />
                <Route
                  exact
                  path="/mobile/product"
                  component={PlayCustomMobileProduct}
                />
                <Route
                  exact
                  path="/mobile/create/:id"
                  component={PlayCustomCreateMobile}
                />
                <Route
                  exact
                  path="/mobile/create_label"
                  component={PlayCustomMobile}
                />
                <Route
                  exact
                  path="/mobile/create_label/custom"
                  component={PlayLabelCreate}
                />

                <Route
                  exact
                  path="/mobile/order"
                  component={PlayCustomMobileOrder2}
                />
                <Route
                  exact
                  path="/mobile/orderinfo"
                  component={PlayCustomMobileOrderInfo}
                />
                <Route
                  exact
                  path="/template/:category"
                  component={PlayCustomMobileAddGraphicCategory}
                />
                <Route
                  exact
                  path="/about/mobile"
                  component={PlayCustomAboutMobile}
                />

                {/* mobile 기기에서 접속인지 웹으로 접속인지 하나의 링크로 묶음  */}
                <Route
                  exact
                  path="/order/about"
                  component={PlayCustomOrderAbout}
                />

                {/* mobile 기기에서 접속인지 웹으로 접속인지 하나의 링크로 묶음  */}
                <Route
                  exact
                  path="/order/amount"
                  component={PlayCustomOrderAmount}
                />
                <Route exact path="/order/order" component={PlayCustomOrder} />
                <Route
                  exact
                  path="/order/orderinfo"
                  component={PlayCustomOrderInfo}
                />
                <Route exact path="/temp/test" component={Order} />

                <Route
                  exact
                  path="/playcustom_create/label/:id"
                  component={PlayCustomLabel}
                />
                <Route exact path="/temp_page" component={tempPage} />
                <Route
                  exact
                  path="/cms_sample_create"
                  component={PlayCustomTvCreate}
                />
              </div>
              {!setting.isMobile ? <Footer className="flex-1" /> : ""}
            </LayoutOne>
          </Router>
        </div>
        {/* </SimpleReactLightbox> */}
      </Suspense>
    </>
  );
};

export default App;
