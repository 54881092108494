import React from "react";
import { ALL_QA } from "graphql/gql/select";
import { useQuery } from "@apollo/client";
import swal from "sweetalert";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import { SRLWrapper } from "simple-react-lightbox";
import { useHistory } from "react-router-dom";
import MyPage from "./MyPage";
import MultiImages from "../../components/MultiImages";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/12 text-center">{t("주문일")}</div>
      <div className="w-2/12 ">{t("주문내역")}</div>
      <div className="w-1/12 text-center">{t("고객명")}</div>
      <div className="w-5/12 text-center">{t("문의 내용")}</div>
      <div className="w-2/12 text-left">{t("문의 사진")}</div>
      <div className="w-1/12 text-center" />
    </div>
  );
};

const Screen = () => {
  const setting = useSettings();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(ALL_QA, {
    fetchPolicy: "network-only",
    variables: { memberId: setting.id },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (!data || !data.allQA) return <p>Error</p>;
  const items = data.allQA;
  console.log("MyQA , items: ", items);

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">{t("11문의")}</div>
      <div className="blackline2 mt-1" />
      <div className="flex-row-center text-medium-15 ">
        <Header />
        <div className="blackline" />

        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="w-full flex-col-center" key={item.id}>
              <div className="w-full flex-row-center table-text">
                <div className="w-1/12 text-center">
                  <Moment unix format="YYYY.MM.DD">
                    {Number(item.order.createdAt) / 1000}
                  </Moment>
                </div>
                <div className="w-2/12 ">
                  <div className="flex-row-left ">
                    <img
                      className="w-10 h-10 border-black border"
                      src={item.order.product.mainImage}
                      alt=""
                    />
                    <div className="ml-4  font-mont fontsize-12 tracking-tight ">
                      {item.order.product.name}
                    </div>
                  </div>
                </div>
                <div className="w-1/12 text-center">{item.order.name}</div>
                <div className="w-5/12 text-center">{item.contents}</div>
                <div className="w-3/12 text-center flex-row-left">
                  <MultiImages images={item.images} />
                </div>
              </div>
              <div className="grayline mt-2" />
              <div className="mt-1 w-full flex-row-center table-text">
                <div className="w-4/12" />
                <div className="w-5/12">
                  {item.replyContents ||
                    t("답변이 아직 없습니다. 조금만 기다려 주세요")}
                </div>
                <div className="w-3/12" />
              </div>

              <div className="blackline mt-1" />
            </div>
          ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const MyQA = () => {
  const setting = useSettings();
  const history = useHistory();
  // 로그인 안한 경우
  if (!setting.isLogin) history.push("/mypage/signin");

  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      ""
    ) : (
      <MyPage>
        <SRLWrapper>
          <Screen />
        </SRLWrapper>
      </MyPage>
    );
  return <div>{screen}</div>;
};

export default MyQA;
