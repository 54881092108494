import React from "react";
import { Textfit } from "react-textfit";

const PlayCustomTemplate = ({ widthR, heightR, template }) => {
  return (
    <div className="relative" style={{ width: widthR, height: heightR }}>
      {template.map((item) => (
        <div
          className="absolute"
          style={{
            top: (item.topR * heightR) / 300,
            left: (item.leftR * widthR) / 300,
            width: (item.widthR * widthR) / 300,
            height: (item.heightR * heightR) / 300,
          }}
        >
          <Textfit
            mode="single"
            forceSingleModeWidth={false}
            className="w-full h-full"
          >
            <p
              className={`w-full h-full bg-transparent ${item.fontStyle}`}
              style={{ color: `${item.textColor}` }}
            >
              {item.txtSrc}
            </p>
          </Textfit>
        </div>
      ))}
    </div>
  );
};

export default PlayCustomTemplate;
