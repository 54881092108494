import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import MyPage from "./MyPage";

const Points = observer(({ points }) => {
  const setting = useSettings();
  const { t } = useTranslation();

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">{t("나의 포인트 확인")}</div>
      <div className="blackline2 mt-1" />
      <div className="mt-3 flex relative table-text ">
        <div className="w-20">{t("보유 포인트")}</div>
        <div
          className="absolute w-24 right-0 text-right text-2xl"
          onClick={() => {
            setting.myPoints += 10;
          }}
        >
          {`${setting.myPoints}P`}
        </div>
      </div>
    </div>
  );
});

const MyPoints = () => {
  const setting = useSettings();
  const history = useHistory();
  // 로그인 안한 경우
  if (!setting.isLogin) history.push("/mypage/signin");
  return (
    <div>
      <MyPage>
        <Points />
      </MyPage>
    </div>
  );
};

export default MyPoints;
