import { FIND_PASSWORD } from "graphql/gql/select";
import useSettings from "stores/settings";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";
import firebase from "firebase/app";
import "firebase/auth";
import initFirebase from "../../firebase";
// import { messaging } from "../../shared/Firebase";
// import firebase from "firebase/app";

initFirebase();

const auth = firebase.auth();

const imgboxStyle = {
  width: "100% ",
  height: "100% ",
};

const Screen = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [findPassword, { data }] = useMutation(FIND_PASSWORD, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.findPassword;
      console.log("data1 : ", data1);
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        if (data1.msg === "메일이 존재하지 않습니다") {
          swal("메일이 존재하지 않습니다", { button: false, timer: 3000 });
        } else {
          auth
            .sendPasswordResetEmail(email)
            .then(() => {
              swal("비밀번호 변경 이메일을 보냈습니다", {
                button: false,
                timer: 3000,
              });
            })
            // .catch(function (error) {
            //   console.log(error);
            // });
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
  }, [data]);

  // messaging.onMessage((payload) => {
  //   console.log(payload.notification.title);
  //   console.log(payload.notification.body);
  // });

  // 필드 검사 후 find password 쿼리 호출
  const handlerFindPasswordClick = () => {
    if (!email) {
      swal(t("이메일을 입력해 주세요"), { button: false, timer: 3000 });
      return;
    }
    findPassword({ variables: { email } });
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen mt-4 font-noto  text-xs flex-col-center">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white shadow-lg">
          <div className="mt-8 mx-8 flex flex-col items-center justify-around">
            <p className="content-title text-center ">{t("비밀번호 찾기")}</p>
            <div className="blackline2 mx-8"> </div>
          </div>

          <div className="mx-8 mt-3 px-8 text-center">
            {t(
              "이메일 주소를 입력하시면 새로운 비밀번호를 설정할 수 있도록 링크를 전송합니다"
            )}
          </div>
          <div className="mx-8 mt-8 mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이메일")}
            />
            <p className="w-full bg-black  height-1" />
          </div>
          <div className="mx-8 mt-8 mb-16 flex items-center justify-between">
            <button
              className="w-full bg-black text-white font-bold py-2 px-4"
              onClick={() => handlerFindPasswordClick()}
            >
              {t("비밀번호 변경 이메일 받기")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScreenMobile = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [findPassword, { data }] = useMutation(FIND_PASSWORD, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.findPassword;
      console.log("data1 : ", data1);
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        if (data1.msg === "메일이 존재하지 않습니다") {
          swal(data1.error, { button: false, timer: 3000 });
        } else {
          auth
            .sendPasswordResetEmail(email)
            .then(() => {
              swal("비밀번호 변경 이메일을 보냈습니다", {
                button: false,
                timer: 3000,
              });
            })
            // .catch(function (error) {
            //   console.log(error);
            // });
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
  }, [data]);

  // 필드 검사 후 find password 쿼리 호출
  const handlerFindPasswordClick = () => {
    if (!email) {
      swal(t("이메일을 입력해 주세요"), { button: false, timer: 3000 });
      return;
    }
    findPassword({ variables: { email } });
  };

  return (
    <div className="h-screen font-noto  text-xs flex-col-left">
      {/* 아메일 , 암호 필드 표시  */}
      <div className="w-full">
        <div className="mt-8 mx-2  flex flex-row items-center justify-around">
          <p className="font-noto fontsize-16 text-black text-sm text-center uppercase tracking-wider">
            {t("비밀번호 찾기")}
          </p>
        </div>
        <div className="blackline2 mx-2 "> </div>

        <div className="mx-2  mt-3 px-8 text-center">
          <pre className="font-noto fontsize-12">
            {t(
              "이메일 주소를 입력하시면 새로운 비밀번호를 설정할 수 있도록 링크를 전송합니다"
            )}
          </pre>
        </div>
        <div className="mx-2  mt-8 ">
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className="w-full px-2 py-2 outline-none"
            placeholder={t("이메일")}
          />
          <p className="w-full bg-black  height-1" />
        </div>
        <div className="h-12 mx-2 mt-0 flex flex-wrap items-center justify-center bg-black">
          <div
            className="w-full btn-text  text-center text-white fontsize-16 uppercase"
            onClick={() => handlerFindPasswordClick()}
          >
            {t("비밀번호 변경 이메일 받기")}
          </div>
        </div>
      </div>
    </div>
  );
};

const FindPassword = () => {
  const setting = useSettings();
  console.log("setting.isMobile : ", setting.isMobile);
  setting.headerTitle = "logo";
  const screen = setting.isMobile ? <ScreenMobile /> : <Screen />;
  return <div>{screen}</div>;
};

export default FindPassword;
