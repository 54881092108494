import React from "react";
// import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { ALL_NOTICES } from "graphql/gql/select";
import Moment from "react-moment";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "@sweetalert/with-react";
import MyPage from "./MyPage";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/6 text-center">{t("날짜")}</div>
      <div className="w-4/6 ">{t("내용")}</div>
      <div className="w-1/6 text-center">{t("상세보기")}</div>
    </div>
  );
};

const Notices = () => {
  const setting = useSettings();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(ALL_NOTICES, {
    variables: { memberId: setting.id },
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
    return <p />;
  }

  if (!data || !data.allNotices) return <p>Error</p>;
  const items = data.allNotices;
  items.sort((a, b) => {
    return a.no - b.no;
  });
  console.log("data.allNotices=", items);

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">MY NOTICES</div>
      <div className="blackline2 mt-1" />
      <SRLWrapper>
        <div className="flex-row-center text-medium-15 ">
          <Header />
          <div className="blackline" />

          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div className="w-full flex-row-center table-text" key={item.createdAt}>
                <Moment className="w-1/6 text-center" unix format="YYYY.MM.DD">
                  {Number(item.createdAt) / 1000}
                </Moment>
                <div className="w-4/6 ">
                  <div className="flex-row-left ">
                    <img className="w-10 h-10 border-black border" src={item.img} alt="" />
                    <div className="ml-4  font-mont fontsize-12 tracking-tight ">{item.title}</div>
                  </div>
                </div>
                <div className="w-1/6 text-center">
                  <button className="border p-1 rounded-lg">{item.orderNumber}</button>
                </div>
              </div>
            ))}
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {!setting.isLogin ? t("로그인을 먼저 해주세요") : items.length === 0 ? t("내역이 없습니다") : ""}
          </div>
        </div>
      </SRLWrapper>
    </div>
  );
};

const MyNotices = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";
  const screen = setting.isAdminScreenMode ? "" : <Notices />;
  return (
    <div>
      <MyPage>{screen}</MyPage>
    </div>
  );
};

export default MyNotices;
