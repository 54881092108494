import React from "react";
import useSettings from "stores/settings";
import MyPageLeftMenu from "./MyPageLeftMenu";

const ScreenWide = ({ children }) => {
  return (
    <div>
      <div className="flex">
        <div className="w-64 mt-6 ">
          <MyPageLeftMenu />
        </div>
        <div className="w-full mt-6 ">{children}</div>
      </div>
    </div>
  );
};

const ScreenMobile = ({ children }) => {
  return (
    <div>
      <div className="w-full mt-6">{children}</div>
    </div>
  );
};

const MyPage = ({ children }) => {
  const setting = useSettings();
  console.log("My Page, setting.isMobile : ", setting.isMobile);

  const Screen1 = !setting.isMobile ? <ScreenWide>{children}</ScreenWide> : <ScreenMobile>{children}</ScreenMobile>;
  return <div>{Screen1}</div>;
};

export default MyPage;
