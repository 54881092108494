/* eslint-disable react/react-in-jsx-scope */
import React from "react";

const MultiImages = ({ images }) => {
  console.log("MultiImages, iamges : ", images);

  if (!images || images === "") return "";
  try {
    const detailImages = JSON.parse(images);
    if (!detailImages || detailImages.length === 0) return "";
    const MultiImages1 = detailImages.map((image) => {
      return <img src={image} key={image} className="w-12 h-12 mr-2" alt="" />;
    });
    return MultiImages1;
  } catch (e) {
    return "";
  }
};

export default MultiImages;
