import React, { useState, useEffect } from "react";
import ResizableRect from "components/ResizableRect";
import { Textfit } from "react-textfit";

const PlayCustomResizableTemplateText = ({
  graphicItem,
  currentItem,
  setCurrentItem,
  setSelectedOption,
  graphicItems,
  setGraphicItems,
  width,
  height,
  top,
  left,
}) => {
  const [widthR, setWidthR] = useState(width);
  const [heightR, setHeightR] = useState(height);
  const [leftR, setLeftR] = useState(left);
  const [topR, setTopR] = useState(top);
  const [resizableRectVisible, setResizableRectVisible] = useState(true);
  const [rotateAngle, setRotateAngle] = useState(0);

  useEffect(() => {
    if (currentItem !== graphicItem) setResizableRectVisible(false);
  }, [currentItem]);

  const handleResize = (style) => {
    const { top, left, width, height } = style;
    setTopR(Math.round(top));
    setLeftR(Math.round(left));
    setWidthR(Math.round(width + 2));
    setHeightR(Math.round(height + 2));
    const tempArray = graphicItems.map((item) => {
      if (item.id !== currentItem.id) return item;
      const tempItem = item;
      tempItem.topR = topR;
      tempItem.leftR = leftR;
      tempItem.widthR = widthR;
      tempItem.heightR = heightR;
      return tempItem;
    });
    setGraphicItems(tempArray);
  };
  const handleRotate = (rotateAngle) => {
    setRotateAngle(rotateAngle);
  };

  const handleDrag = (deltaX, deltaY) => {
    setLeftR(leftR + deltaX);
    setTopR(topR + deltaY);
    if (currentItem) {
      const tempArray = graphicItems.map((item) => {
        if (item.id !== currentItem.id) return item;
        const tempItem = item;
        tempItem.topR = topR;
        tempItem.leftR = leftR;
        return tempItem;
      });
      setGraphicItems(tempArray);
    }
  };
  return (
    <div>
      <ResizableRect
        visible={resizableRectVisible}
        left={leftR}
        top={topR}
        width={widthR}
        height={heightR}
        rotateAngle={rotateAngle}
        zoomable="se"
        onRotate={handleRotate}
        onResize={handleResize}
        onDrag={handleDrag}
        graphicItem={graphicItem}
        graphicItems={graphicItems}
        setGraphicItems={setGraphicItems}
        setCurrentItem={setCurrentItem}
        // aspectRatio={true}
      >
        <div
          onClick={() => {
            console.log("top : ", topR, "left : ", leftR);
            setCurrentItem(graphicItem);
            setSelectedOption(graphicItem.fontStyle);
            setResizableRectVisible(true);
          }}
          style={{ width: widthR, height: heightR }}
        >
          <Textfit
            mode="single"
            forceSingleModeWidth={false}
            className="w-full h-full"
          >
            <p
              className={`w-full h-full bg-transparent ${graphicItem.fontStyle}`}
              style={{ color: `${graphicItem.textColor}` }}
            >
              {graphicItem.txtSrc}
              {console.log("graphicItem : ", graphicItem)}
            </p>
          </Textfit>
        </div>
      </ResizableRect>
    </div>
  );
};

export default PlayCustomResizableTemplateText;
