import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import customStyles from "components/modalStyle";
import btn_modal_close from "assets/images/btn_modal_close.svg";

const preventIOSScroll = (e) => {
  e.preventDefault();
};

const DeliveryModal = ({ modalIsOpen, closeModal, currentItem }) => {
  const { t } = useTranslation();

  if (!currentItem) return "";
  const item = currentItem;
  console.log("DeliveryModal item: ", item);

  const Header = () => {
    return (
      <div className="w-full flex-row-center table-header-bold ">
        <div className="w-1/4 text-center">{t("처리일시")}</div>
        <div className="w-2/4 text-center">{t("현재위치")}</div>
        <div className="w-1/4 text-center">{t("상태")}</div>
      </div>
    );
  };

  // TODO 실시간 조회 코드 넣을 것
  const Contents = () => {
    const delivers = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }];
    return delivers.map((item) => (
      <div className="min-h" key={item.id}>
        <div className="w-full h-6 flex-row-center table-text ">
          <div className="w-1/4 text-center">{item.date}</div>
          <div className="w-2/4 text-center">{item.location}</div>
          <div className="w-1/4 text-center">{item.status}</div>
        </div>
        <div className="mt-0 grayline" />
      </div>
    ));
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",
          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="w-140 flex flex-row bg-transparent">
        <div className="p-4 w-120 table-text bg-white">
          <div className="dialog-header-bold">{t("배송조회")}</div>
          <div className="mt-2 blackline2" />

          <div className="mt-3 dialog-header-bold text-center">
            {item.invoiceNumber ? t("배송중 입니다") : t("배송전 입니다")}
          </div>
          <div className="mt-3 grayline" />

          <div className="mt-3 flex-row-left ">
            <img
              className="w-10 h-10 border-black border"
              src={item.product.mainImage}
              alt=""
            />
            <div className="ml-4 flex-col-left">
              <div className="font-bold ">{item.product.name}</div>
              <div className="mt-1 ">
                {item.designAvailable ? t("디자인 있음") : t("디자인 없음")}
              </div>
            </div>
          </div>

          <div className="mt-3 blackline2" />

          <div>
            <div className="mt-2 table-text-bold">{t("송장정보")}</div>
            <div className="mt-2 grayline" />

            <div className="ml-0 mt-1 flex-row-left">
              <div className="w-12 table-text">{t("송장번호")}</div>
              <div className="ml-2 table-text">{item.invoiceNumber}</div>
            </div>
            <div className="ml-0 mt-0 flex-row-left">
              <div className="w-12 table-text">{t("택배사")}</div>
              <div className="ml-2 table-text">{item.invoiceCompany}</div>
            </div>
            <div className="ml-0 mt-0 flex-row-left">
              <div className="w-12 table-text">{t("대표번호")}</div>
              <div className="ml-2 table-text">{item.invoicePhone}</div>
            </div>
          </div>
          <div className="mt-3 blackline2" />
          <div>
            <Header />
            <div className="mt-2 grayline" />
            <Contents />
          </div>
        </div>
        {/* 대화상자 종료 버튼 */}
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6 "
            src={btn_modal_close}
            alt=""
            onClick={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeliveryModal;
