import React from "react";
import icon_rect_checked from "assets/images/icon_rect_checked.svg";
import icon_rect_white from "assets/images/icon_rect_white.svg";

const CheckBox = ({ isCheck, onClick }) => {
  return (
    <img src={isCheck ? icon_rect_checked : icon_rect_white} className="w-4 h-4" alt="selected" onClick={onClick} />
  );
};

export default CheckBox;
