import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";
import { useQuery, useMutation } from "@apollo/client";
import MyPage from "../MyPage";
import { CREATE_CMS, ALL_ORDERS } from "../../../graphql/gql/select";

const ScreenAdmin = ({ category, name }) => {
  const { t } = useTranslation();
  console.log(category, name);
  const setting = useSettings();
  const history = useHistory();

  const [createCms, { data: createdData }] = useMutation(CREATE_CMS, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (createdData !== undefined) {
      const data1 = createdData.createCms;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.cmsGroundUpdates = true;
      }
    }
  }, [createdData]);

  const createCmsHandler = ({
    category,
    name,
    img,
    no,
    targetUrl,
    targetId,
    title,
    contents,
    visible,
  }) => {
    if (targetUrl) {
      console.log("targetUrl: ", targetUrl);
      createCms({
        variables: {
          category,
          name,
          img,
          no,
          targetUrl,
          targetId,
          title,
          contents,
          visible,
        },
      });
    } else {
      swal("이동URL : 빈 값이 올 수 없습니다", { button: false, timer: 3000 });
    }
  };

  const { loading, error, data } = useQuery(ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: { memberId: setting.id },
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (!data || !data.allOrders) return <p>Error</p>;
  const items = data.allOrders;
  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{`리뷰에서 CMS 등록하기 [/${category}/${name}]`}</div>
            <button
              className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
              onClick={() => history.replace(`/mypage/cms/${category}`)}
            >
              뒤로가기
            </button>
          </div>
          <div className="blackline2 mt-1" />

          {/* review data */}
          <div className="mt-8 flex flex-wrap overflow-auto text-xs ">
            {items &&
              items.length > 0 &&
              items.map((item) => (
                <div className="w-1/5 pr-2 mb-4 " key={item.targetId}>
                  <div className="relative">
                    <img
                      src={item.img}
                      className="w-full  object-cover pr-2"
                      alt=""
                    />
                    <div
                      className="ml-6 pr-2 w-full"
                      onClick={() => {
                        item = {
                          category,
                          name,
                          img: item.reviewImage,
                          no: "",
                          targetUrl: "review",
                          targetId: item.id,
                          title: "",
                          contents: item.reviewText,
                          visible: true,
                        };
                        console.log(item);
                        createCmsHandler(item);
                      }}
                    >
                      <div className="absolute right-0 bottom-0 w-20 btn-big-text h-8 mx-auto">
                        추가
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 flex-no-wrap">{item.reviewText}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const CmsReview = ({ match }) => {
  const setting = useSettings();
  // const history = useHistory();

  const { category, name } = match.params;

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin category={category} name={name} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default CmsReview;
