import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";

import { useQuery } from "@apollo/client";

import { observer } from "mobx-react-lite";
import ReactPlayer from "react-player";
import swal from "@sweetalert/with-react";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import CmsPage from "./CmsPage";
import { ALL_CMS } from "../../../graphql/gql/select";
import CmsHeader from "./CmsHeader";
import CheckBox from "../../../components/CheckBox";

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  console.log(`memberId : ${memberId}`);

  // const [currentItem, setCurrentItem] = useState({});
  const { loading, error, data, refetch } = useQuery(ALL_CMS, {
    variables: { category: "home" },
  });

  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }

  if (setting.cmsHomeUpdates) {
    setting.cmsHomeUpdates = false;
    refetch();
  }

  const sortFunc = (a, b) => {
    const a1 = Number(a.no) || 0;
    const b1 = Number(b.no) || 0;
    if (Number(a1) > b1) {
      return 1;
    }
    if (a1 < b1) {
      return -1;
    }
    // a must be equal to b
    return 0;
  };
  if (!data || !data.allCms) return <p>Error</p>;

  const itemsVideo = data.allCms
    .filter((item) => item.name === "video")
    .sort(sortFunc);
  const itemsLeft = data.allCms
    .filter((item) => item.name === "left")
    .sort(sortFunc);
  const itemsKorRight = data.allCms
    .filter((item) => item.name === "right")
    .sort(sortFunc);
  const itemsEnRight = data.allCms
    .filter((item) => item.name === "right_en")
    .sort(sortFunc);
  const itemsRight = setting.lang === "en" ? itemsEnRight : itemsKorRight;

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Home / Video")}</div>
            <Link to="/mypage/cms/new/home/video">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsVideo &&
              itemsVideo.length > 0 &&
              itemsVideo.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        <ReactPlayer
                          className=""
                          url={item.img}
                          width="120"
                          height="100"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>

                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Home / Left 4 Images")}</div>
            <Link to="/mypage/cms/new/home/left">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />

          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsLeft &&
              itemsLeft.length > 0 &&
              itemsLeft.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("Home / Right 10 Images")}</div>
            <Link to="/mypage/cms/new/home/right">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsKorRight &&
              itemsKorRight.length > 0 &&
              itemsKorRight.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className=" font-mont fontsize-12 tracking-tight  text-black ">
                        <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20 ">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20 ">
                    <div className="w-full text-center">{item.no}</div>
                  </div>

                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>

                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">
              {t("Home / Right 10 Images Eng")}
            </div>
            <Link to="/mypage/cms/new/home/right_en">
              <i className="ml-5 fa fa-plus-square">추가</i>
            </Link>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium15">
            <CmsHeader />
            <div className="grayline" />
            {memberId === undefined && (
              <div className="mt-8">{t("로그인을 하지 않았습니다")}</div>
            )}
            {memberId !== undefined &&
              memberId !== "" &&
              itemsEnRight &&
              itemsEnRight.length > 0 &&
              itemsEnRight.map((item) => (
                <div
                  className="w-full flex-row-center table-text"
                  key={item.id}
                >
                  <div className="w-2/20">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="font-mont fontsize-12 tracking-tight text-black">
                        <img
                          src={item.img}
                          className="w-12 h-12 border mx-auto"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="w-2/20">
                    <Link
                      to={`/mypage/cms/edit/${item.category}/${item.name}/${item.id}`}
                      onClick={() => {
                        setting.cmsItem = item;
                      }}
                    >
                      <div className="w-full text-center">{item.name}</div>
                    </Link>
                  </div>
                  <div className="w-1/20">
                    <div className="w-full text-center">{item.no}</div>
                  </div>
                  <div className="w-3/20 text-center">{item.targetUrl}</div>
                  <div className="w-2/20 text-center overflow-auto">
                    <Tooltip title={item.targetId} position="bottom">
                      {item.targetId}
                    </Tooltip>
                  </div>
                  <div className="w-4/20 text-center">{item.title}</div>
                  <div className="w-4/20 text-center">{item.contents}</div>
                  <div className="w-2/20 text-center flex-center">
                    <CheckBox isCheck={item.visible} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

const CmsHome = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );
  return (
    <div>
      <CmsPage>
        <div className="">{screen}</div>
      </CmsPage>
    </div>
  );
};

export default CmsHome;
