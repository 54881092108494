import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import icon_search from "assets/images/icon_search.svg";
import swal from "@sweetalert/with-react";
import { useMutation } from "@apollo/client";
import MyPage from "../MyPage";
import { CREATE_CMS } from "../../../graphql/gql/select";

const ScreenAdmin = ({ category, name }) => {
  const { t } = useTranslation();

  console.log(category, name);
  const setting = useSettings();
  const history = useHistory();
  const [instaID, setInstaID] = useState("teenytiger_korea");
  const [items, setItems] = useState([]);

  const [createCms, { data: createdData }] = useMutation(CREATE_CMS, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (createdData !== undefined) {
      const data1 = createdData.createCms;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.cmsGroundUpdates = true;
      }
    }
  }, [createdData]);

  const createCmsHandler = ({ category, name, img, no, targetUrl, targetId, title, contents, visible }) => {
    if (targetUrl) {
      console.log("targetUrl: ", targetUrl);
      createCms({
        variables: {
          category,
          name,
          img,
          no,
          targetUrl,
          targetId,
          title,
          contents,
          visible,
        },
      });
    } else {
      swal("이동URL : 빈 값이 올 수 없습니다", { button: false, timer: 3000 });
    }
  };

  const searchInstagram = () => {
    console.log("insraID or hashtag: ", instaID);
    const isProduction = process.env.NODE_ENV === "production";
    const hashtag = instaID.replace("#", "");

    const URL = isProduction ? (
      instaID.startsWith("#")
      ? `https://enkino.xyz:9901/hashtag/${hashtag}`
      : `https://enkino.xyz:9901/instagram/${instaID}`
      )
    : (
      instaID.startsWith("#")
      ? `https://enkino.xyz:9901/hashtag/${hashtag}`
      : `https://enkino.xyz:9901/instagram/${instaID}`
    );

    axios.get(URL)
      .then((res) => {
        console.log(res.data);
        const {
          data: { posts },
        } = res;
        if (posts && posts.length > 0) {
          const tempItems = [];
          posts.map((gram) => {
            tempItems.push({
              img: gram.img,
              contents: gram.caption,
              targetId: gram.url,
            });
          });
          setItems(tempItems);
        } else {
          swal(t("자료가 없습니다. 아이디를 확인하세요"));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{`인스타그램에서 CMS 등록하기 [/${category}/${name}]`}</div>
            <button
              className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
              onClick={() => history.replace(`/mypage/cms/${category}`)}
            >
              뒤로가기
            </button>
          </div>
          <div className="blackline2 mt-1" />
          {/* search */}
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="flex flex-row-center">
                <div>Instagram ID or #Hashtag</div>
                <div className="ml-6 w-64 h-16 flex items-center relative">
                  <input
                    className="absolute w-full pl-6 outline-none"
                    value={instaID}
                    onChange={(e) => setInstaID(e.target.value)}
                  />
                  <img src={icon_search} className="absolute w-5 h-5" alt="" />
                  <p className="absolute bottom-0 mt-3  w-full bg-black  height-1" alt="" />
                </div>
                <div className="ml-6 w-20" onClick={searchInstagram}>
                  <div className="btn-big-text mx-auto">확인</div>
                </div>
              </div>
            </div>
          </div>
          {/* insta data */}
          <div className="mt-8 flex flex-wrap overflow-auto text-xs ">
            {items &&
              items.length > 0 &&
              items.map((item) => (
                <div className="w-1/5 pr-2 mb-4 " key={item.targetId}>
                  <div className="relative">
                    <img src={item.img} className="w-full  object-cover pr-2" alt="" />
                    <div
                      className="ml-6 pr-2 w-full"
                      onClick={() => {
                        item = {
                          category,
                          name,
                          img: item.img,
                          no: "",
                          targetUrl: "instagram",
                          targetId: item.targetId,
                          title: "",
                          contents: item.contents,
                          visible: true,
                        };
                        console.log(item);
                        createCmsHandler(item);
                      }}
                    >
                      <div className="absolute right-0 bottom-0 w-20 btn-big-text h-8 mx-auto">추가</div>
                    </div>
                  </div>
                  <div className="mt-2 flex-no-wrap">{item.contents}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const CmsInstagram = ({ match }) => {
  const setting = useSettings();
  // const history = useHistory();

  const { category, name } = match.params;

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin category={category} name={name} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default CmsInstagram;
