/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import icon_star_white from "assets/images/icon_star_white.svg";
import icon_star_black from "assets/images/icon_star_black.svg";

const StarEdit = ({ currentItem, setCurrentItem }) => {
  const starCount = Number(currentItem.reviewStar);
  return (
    <div className="mt-3 flex flex-row ">
      <img
        className="w-4 h-4"
        src={starCount >= 1 ? icon_star_black : icon_star_white}
        alt=""
        onClick={() => setCurrentItem({ ...currentItem, reviewStar: "1" })}
      />
      <img
        className="w-4 h-4"
        src={starCount >= 2 ? icon_star_black : icon_star_white}
        alt=""
        onClick={() => setCurrentItem({ ...currentItem, reviewStar: "2" })}
      />
      <img
        className="w-4 h-4"
        src={starCount >= 3 ? icon_star_black : icon_star_white}
        alt=""
        onClick={() => setCurrentItem({ ...currentItem, reviewStar: "3" })}
      />
      <img
        className="w-4 h-4"
        src={starCount >= 4 ? icon_star_black : icon_star_white}
        alt=""
        onClick={() => setCurrentItem({ ...currentItem, reviewStar: "4" })}
      />
      <img
        className="w-4 h-4"
        src={starCount >= 5 ? icon_star_black : icon_star_white}
        alt=""
        onClick={() => setCurrentItem({ ...currentItem, reviewStar: "5" })}
      />
    </div>
  );
};

export default StarEdit;
