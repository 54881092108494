import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import { SRLWrapper } from "simple-react-lightbox";
import Select from "react-select";
import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { useMutation } from "@apollo/client";
import ReactPlayer from "react-player";
import MyPage from "../MyPage";
import { CREATE_CMS, MULTI_UPLOAD_FILES } from "../../../graphql/gql/select";
import CheckBox from "../../../components/CheckBox";
import SingleImage from "../../../components/SingleImage";

const ScreenAdmin = observer(({ category, name }) => {
  const { t } = useTranslation();
  console.log(category, name);
  const setting = useSettings();
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState(null);
  const [isTargetProduct, setTargetProduct] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    category,
    name,
    img: "",
    no: "",
    targetUrl: "",
    targetId: "",
    title: "",
    contents: "",
    visible: true,
  });
  console.log("currentItem: ", currentItem);

  const [tempImgVideoUrl, setTempImgVideoUrl] = useState("");
  const [saveAndStay, setSaveAndStay] = useState(false);

  const [createCms, { data: createdData }] = useMutation(CREATE_CMS, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (createdData !== undefined) {
      const data1 = createdData.createCms;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        switch (category) {
          case "home":
            setting.cmsHomeUpdates = true;
            break;
          case "about":
            setting.cmsAboutUpdates = true;
            break;
          case "hangul":
            setting.cmsHangulUpdates = true;
            break;
          case "ground":
            setting.cmsGroundUpdates = true;
            break;
          case "tv":
            setting.cmsTVUpdates = true;
            break;
          case "category":
            setting.cmsCategoryUpdates = true;
            break;
          case "recommend":
            setting.cmsRecommendUpdates = true;
            break;
          default:
            setting.cmsHomeUpdates = true;
            break;
        }
        if (saveAndStay) {
          setCurrentItem({
            category,
            name,
            img: "",
            no: "",
            targetUrl: "",
            targetId: "",
            title: "",
            contents: "",
            visible: true,
          });
        } else {
          history.push(`/mypage/cms/${category}`);
        }
      }
    }
  }, [createdData]);

  const createCmsHandler = ({
    category,
    name,
    img,
    no,
    targetUrl,
    targetId,
    title,
    contents,
    visible,
  }) => {
    if (targetUrl) {
      console.log("targetUrl: ", targetUrl);
      createCms({
        variables: {
          category,
          name,
          img,
          no,
          targetUrl,
          targetId,
          title,
          contents,
          visible,
        },
      });
    } else {
      swal("이동URL : 빈 값이 올 수 없습니다", { button: false, timer: 3000 });
    }
  };

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, img: filenames[0] });
      }
    }
  }, [multiData]);

  const options = [
    { value: "product", label: "product" },
    { value: "category", label: "category" },
    { value: "event", label: "event" },
    { value: "video", label: "video" },
    { value: "instagram", label: "instagram" },
    { value: "web", label: "web" },
    { value: "noaction", label: "noaction" },
    { value: "recommend", label: "recommend" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setCurrentItem({ ...currentItem, targetUrl: selectedOption.value });
    // 상품인 경우만 이동ID는 선택버튼으로 바뀜
    if (selectedOption.value === "product") {
      setTargetProduct(true);
    } else {
      setTargetProduct(false);
    }
  };

  if (selectedOption === undefined) {
    setSelectedOption({ label: "product", value: "product" });
  }
  const accept =
    category === "recommend"
      ? " .json"
      : category === "home" && name === "video"
      ? "video/*"
      : "image/*";

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{`CMS 등록하기 [/${category}/${name}]`}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">
                  {/* {name === "video" ? "비디오" : "이미지"} */}
                  {category === "recommend"
                    ? "Json파일"
                    : name === "video"
                    ? "비디오"
                    : "이미지"}
                </div>
                {category === "recommend" ? (
                  <div> </div>
                ) : category === "home" && name === "video" ? (
                  <ReactPlayer
                    className=""
                    url={currentItem.img}
                    width="120"
                    height="100"
                  />
                ) : (
                  <SRLWrapper>
                    <SingleImage image={currentItem.img} />
                  </SRLWrapper>
                )}
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form onSubmit={() => {}} encType="multipart/form-data">
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept={accept}
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
                <div className="ml-20 w-full px-2 ">
                  <div className="flex flex-row">
                    <input
                      onChange={(e) => setTempImgVideoUrl(e.target.value)}
                      type="text"
                      className="flex-1 px-2 outline-none"
                      placeholder="파일 선택 또는 여기에 웹주소를 붙여넣은 후 변경하기 버튼을 누르세요"
                      value={tempImgVideoUrl}
                    />
                    <button
                      className=" w-24 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        setCurrentItem({
                          ...currentItem,
                          img: tempImgVideoUrl,
                        });
                      }}
                    >
                      변경하기
                    </button>
                  </div>
                  <div className="blackline" />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="mt-3 flex-row-left">
                  <div className="w-20 font-bold">번호</div>
                  <input
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, no: e.target.value })
                    }
                    type="text"
                    className="w-80 px-2 outline-none"
                    placeholder="번호"
                    value={currentItem.no}
                  />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">이동URL</div>
                <Select
                  className="w-64 h-8 px-0 outline-none text-normal-13"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                  theme={{ borderRadius: 0, spacing: { baseUnit: 2 } }}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">이동ID</div>
                {isTargetProduct ? (
                  <div className="flex flex-col">
                    <div className="">{currentItem.targetId}</div>ㄴ
                    <button
                      className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 "
                      onClick={() => {
                        history.push(
                          `/mypage/cms/select_product/${category}/${name}`
                        );
                      }}
                    >
                      상품 선택하기
                    </button>
                  </div>
                ) : (
                  <input
                    onChange={(e) =>
                      setCurrentItem({
                        ...currentItem,
                        targetId: e.target.value,
                      })
                    }
                    type="text"
                    className="w-80 px-2 outline-none"
                    placeholder="이동ID"
                    value={currentItem.targetId}
                  />
                )}
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">{t("제목")}</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, title: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="제목"
                  value={currentItem.title}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">{t("내용")}</div>
                <textarea
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, contents: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder={t("내용")}
                  cols="80"
                  rows="5"
                  value={currentItem.contents}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">보이기</div>
                <CheckBox
                  isCheck={currentItem.visible}
                  onClick={() =>
                    setCurrentItem({
                      ...currentItem,
                      visible: !currentItem.visible,
                    })
                  }
                />
              </div>
              <div className="mt-4 flex-col-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <div className="absolute right-0 w-96 h-8 ml-4 flex flex-row">
                    <button
                      className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                      onClick={() => history.push(`/mypage/cms/${category}`)}
                    >
                      취소
                    </button>
                    <button
                      className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        setSaveAndStay(false);
                        createCmsHandler(currentItem);
                      }}
                    >
                      {t("등록하기")}
                    </button>
                    <button
                      className="w-48 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        setSaveAndStay(true);
                        createCmsHandler(currentItem);
                      }}
                    >
                      등록하고 계속 추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const NewCms = ({ match }) => {
  const setting = useSettings();
  // const history = useHistory();

  const { category, name } = match.params;

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin category={category} name={name} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default NewCms;
