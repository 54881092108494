import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import useSettings from "stores/settings";
import swal from "@sweetalert/with-react";
import { useQuery, useMutation } from "@apollo/client";
import { ALL_ADMINS, UPDATE_ADMIN, DELETE_ADMIN } from "graphql/gql/select";
import { observer } from "mobx-react-lite";
import customStyles from "components/modalStyle";
import btn_modal_close from "assets/images/btn_modal_close.svg";
import MyPage from "./MyPage";

const preventIOSScroll = (e) => {
  e.preventDefault();
};

const ScreenAdmin = observer(() => {
  // const setting = useSettings();
  const { t } = useTranslation();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  const openUpdateAdminModal = (item) => {
    console.log("openUpdateAdminModal:item=", item);
    setCurrentItem(item);
    setUpdateModalOpen(true);
  };

  const [updateAdmin, { data: updatedData }] = useMutation(UPDATE_ADMIN, {
    onError(error) {
      console.log("updateAdmin: error=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updatedData !== undefined) {
      const data1 = updatedData.updateAdmin;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });

        setUpdateModalOpen(false);
        refetch();
      }
    }
  }, [updatedData]);

  const [deleteAdmin, { data: deletedData }] = useMutation(DELETE_ADMIN, {
    onError(error) {
      console.log("deleteAdmin: err=", error);

      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deletedData !== undefined) {
      const data1 = deletedData.deleteAdmin;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        swal(t("삭제가 잘 되었습니다"), { button: false, timer: 3000 });

        setUpdateModalOpen(false);
        refetch();
      }
    }
  }, [deletedData]);

  const { loading, error, data, refetch } = useQuery(ALL_ADMINS, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
    return <p />;
  }

  if (!data || !data.allAdmins) return <p>Error</p>;
  const items = data.allAdmins;
  console.log("data.allAdmins=", items);
  const unAuthorizedItems = items.filter((item) => !item.authorized);
  const authorizedItems = items.filter((item) => item.authorized);

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="content-title">관리자 권한 설정 화면</div>
        <div className="blackline2 mt-1" />
        <div className="table-header">대기중인 관리자 회원</div>
        <div className="grayline mt-1" />
        {unAuthorizedItems !== undefined && unAuthorizedItems.length > 0 ? (
          unAuthorizedItems.map((item) => (
            <div className="mt-2 table-text flex-row-left" key={item.id}>
              <div className="flex-1">{`${item.name}`}</div>
              <div className="flex-1">{`${item.email}`}</div>
              <div className="flex-1">
                <i className="ml-5 fa fa-pencil" onClick={() => openUpdateAdminModal(item)} />
              </div>
            </div>
          ))
        ) : (
          <div className="mt-2 table-text flex-row-left">현재 대기중인 관리자 요청이 없습니다</div>
        )}

        <div className={data !== undefined ? "blackline2 mt-8" : "blackline2 mt-32"} />
        <div className="table-header">현재 관리자</div>
        <div className="grayline mt-1" />
        {authorizedItems !== undefined && authorizedItems.length > 0 ? (
          authorizedItems.map((item) => (
            <div className="mt-2 table-text flex-row-left" key={item.id}>
              <div className="flex-1">{`${item.name}`}</div>
              <div className="flex-1">{`${item.email}`}</div>
              <div className="flex-1">
                <i className="ml-5 fa fa-pencil" onClick={() => openUpdateAdminModal(item)} />
              </div>
            </div>
          ))
        ) : (
          <div className="mt-2 table-text flex-row-left">현재 관리자가 없습니다</div>
        )}
      </div>
      {/* 업데이트 모달 */}
      <Modal
        isOpen={updateModalOpen}
        style={customStyles}
        ariaHideApp={false}
        onAfterOpen={() => {
          document.documentElement.style.overflowY = "hidden";
          document.documentElement.addEventListener("touchmove", preventIOSScroll);
        }}
        onRequestClose={() => {
          document.documentElement.style.overflowY = "visible";
          document.documentElement.removeEventListener("touchmove", preventIOSScroll);
          setUpdateModalOpen(false);
        }}
        shouldCloseOnOverlayClick
      >
        {currentItem !== undefined ? (
          <div className="w-140 flex flex-row bg-transparent">
            <div className="p-4 w-120 table-text bg-white">
              <div className="dialog-header-bold">권한 설정</div>
              <div className="mt-2 blackline2" />
              <div className="w-full bg-white">
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">{t("이름")}</div>
                  <input
                    type="text"
                    className="w-auto px-2 outline-none"
                    placeholder="카테고리"
                    value={currentItem.name}
                    readOnly
                  />
                </div>

                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">{t("이메일")}</div>
                  <input
                    id="lang"
                    type="text"
                    className="w-auto px-2 outline-none"
                    placeholder="언어"
                    readOnly
                    value={currentItem.email}
                  />
                </div>

                <div className="mt-4 flex-col-left">
                  <div className="mt-3 h-8 flex flex-row table-text">
                    <div className="flex-1">
                      <button
                        className="w-26 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                        onClick={() => {
                          console.log("권한 허용 click: ");
                          updateAdmin({
                            variables: {
                              id: currentItem.id,
                              authorized: true,
                            },
                          });
                        }}
                      >
                        {t("권한 허용")}
                      </button>
                    </div>
                    <div className="flex-1">
                      <button
                        className="w-26 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                        onClick={() => {
                          console.log("권한 해제 click: ");
                          updateAdmin({
                            variables: {
                              id: currentItem.id,
                              authorized: false,
                            },
                          });
                        }}
                      >
                        {t("권한 해제")}
                      </button>
                    </div>
                    <div className="flex-1">
                      <button
                        className="w-26 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                        onClick={() => {
                          console.log("수정완료 click: ");
                          deleteAdmin({
                            variables: {
                              id: currentItem.id,
                            },
                          });
                        }}
                      >
                        {t("삭제")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 대화상자 종료 버튼 */}
            <div className="w-16 bg-transparent">
              <img
                className="ml-4 mt-1 w-6 h-6 "
                src={btn_modal_close}
                alt=""
                onClick={() => setUpdateModalOpen(false)}
              />
            </div>
          </div>
        ) : (
          <div>{JSON.stringify(currentItem)}</div>
        )}
      </Modal>
    </div>
  );
});

const AuthorizeAdmin = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen = setting.isAdminScreenMode ? <ScreenAdmin /> : <div />;
  return (
    <div>
      <MyPage>{screen}</MyPage>
    </div>
  );
};

export default AuthorizeAdmin;
