import React from "react";
import icon_calendar from "assets/images/icon_calendar.svg";

const DateRange = ({ currentRange, setCurrentRange }) => {
  const selected = "w-16 bg-black text-center text-white py-1 px-1 ";
  const unSelected = "w-16 border bg-white text-center text-black py-1 px-1 ";

  return (
    <div className="mt-2 h-6 flex flex-row table-text">
      <div
        className={currentRange === "1week" ? selected : unSelected}
        onClick={() => {
          setCurrentRange("1week");
        }}
      >
        1주일
      </div>
      <div
        className={currentRange === "1month" ? selected : unSelected}
        onClick={() => {
          setCurrentRange("1month");
        }}
      >
        1개월
      </div>
      <div
        className={currentRange === "3months" ? selected : unSelected}
        onClick={() => {
          setCurrentRange("3months");
        }}
      >
        3개월
      </div>
      <div
        className={currentRange === "all" ? selected : unSelected}
        onClick={() => {
          setCurrentRange("all");
        }}
      >
        전체시기
      </div>
      <div className="w-16 ml-4 border border-black text-center text-black py-1 px-1 ">
        <div className="h-full flex flex-wrap items-center justify-end ">
          <img className="my-auto" src={icon_calendar} alt="" />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-16 ml-1 bg-black" style={{ width: "4px", height: "1px" }} />
      </div>
      <div className="w-16 ml-1 border border-black text-center text-black py-1 px-1 ">
        <div className="h-full flex flex-wrap items-center justify-end ">
          <img className="my-auto" src={icon_calendar} alt="" />
        </div>
      </div>
      <div className="w-16 ml-4 bg-black text-center text-white py-1 px-1 ">조회</div>
    </div>
  );
};

export default DateRange;
