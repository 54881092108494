import React from "react";
import { useTranslation } from "react-i18next";

const CmsTemplateHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header">
      <div className="w-4/20 text-center">{t("이미지")}</div>
      <div className="w-1/20 text-center"> </div>
      <div className="w-5/20 text-center">{t("번역")}</div>
      <div className="w-8/20 text-center">{t("설명")}</div>
      <div className="w-2/20 text-center">{t("보이기")}</div>
    </div>
  );
};
export default CmsTemplateHeader;
