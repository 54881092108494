// import React from "react";
// import { Link } from "react-router-dom";
// import swal from "@sweetalert/with-react";
// import useSettings from "stores/settings";
// import currencyFormatter from "currency-formatter";
// import { useTranslation } from "react-i18next";
// import { useQuery } from "@apollo/client";
// import { useHistory } from "react-router-dom";
// // import { ALL_PRODUCTS_CATEGORY } from "graphql/gql/select";
// import { FIND_PRODUCT_LIKE_LIST } from "graphql/gql/select";
// import CategoryProductItem from "./CategoryProductItem";
// import PlayCustomCategoryLeftMenu from "../playcustom/PlayCustomCategoryLeftMenu";

// const ScreenWide = ({ category }) => {
//   const setting = useSettings();
//   const { t } = useTranslation();

//   const { loading, error, data, refetch } = useQuery(FIND_PRODUCT_LIKE_LIST, {
//     variables: { category, memberId: setting.id },
//   });
//   if (loading) return <p />;
//   if (error) {
//     swal(error.message || "Error", { button: false, timer: 3000 });
//   }
//   if (!data || !data.findProductLikeList) return <p>Error</p>;
//   // const items = data.allProducts;
//   const items1 = data.findProductLikeList.allProduct;
//   console.log("items : ", items1);
//   const items2 = data.findProductLikeList.myProduct;
//   console.log("items2ㄴ : ", items2);
//   // const items = items1.map((item1) => {
//   //   for (const i of items2) {
//   //     if (item1.id === i.)
//   //   }
//   // })

//   const items = items1.map((item1) => {
//     for (const i of items2) {
//       if (item1.id === i.product.id) {
//         if (setting.id === i.member.id) {
//           return {
//             ...item1,
//             heart: "black",
//           };
//         }
//       }
//     }
//     return {
//       ...item1,
//       heart: "white",
//     };
//   });

//   return (
//     <div>
//       <div className="mx-auto px-10  mt-10">
//         <div className="w-full flex text-left font-mont">
//           <div className="w-1/5">
//             <PlayCustomCategoryLeftMenu />
//           </div>
//           <div className="w-4/5">
//             <div className="flex flex-wrap">
//               <div className="w-3/4 h-16 flex flex-row items-center text-left">
//                 <div className="w-auto font-bold title-font">{category}</div>
//               </div>
//             </div>
//             <div className=" mt-4 ">
//               <div className="flex flex-wrap ">
//                 {items &&
//                   items.length > 0 &&
//                   items.map((item) => (
//                     <div className="w-1/5 pr-2 mb-4" key={item.id}>
//                       <CategoryProductItem
//                         productItem={item}
//                         refetch={refetch}
//                       />
//                     </div>
//                   ))}
//                 {!items || items.length === 0 ? (
//                   <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
//                     {t("상품 준비중입니다")}
//                   </div>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Category = ({ match }) => {
//   let { category } = match.params;
//   console.log("category: ", category.toUpperCase());
//   if (!category) {
//     category = "WOMEN";
//   }
//   const history = useHistory();

//   switch (category) {
//     case "REVIEW":
//       history.replace("/playground");
//       return "";
//     case "PALYTV":
//       history.replace("/playtv");
//       return "";
//     case "ABOUT":
//       history.replace("/about");
//       return "";
//     default:
//       break;
//   }

//   const screen = <ScreenWide category={category.toUpperCase()} />;
//   return <div>{screen}</div>;
// };

// export default Category;

import React from "react";
import { Link } from "react-router-dom";
import swal from "@sweetalert/with-react";
import useSettings from "stores/settings";
import currencyFormatter from "currency-formatter";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
// import { ALL_PRODUCTS_CATEGORY } from "graphql/gql/select";
// import { FIND_PRODUCT_LIKE_LIST } from "graphql/gql/select";
import { FIND_ALL_PRODUCT_LIKE_LIST } from "graphql/gql/select";
import CategoryProductItem from "./CategoryProductItem";
import PlayCustomCategoryLeftMenu from "../playcustom/PlayCustomCategoryLeftMenu";

const ScreenWide = ({ category }) => {
  const setting = useSettings();
  const { t } = useTranslation();

  const { loading, error, data, refetch } = useQuery(
    FIND_ALL_PRODUCT_LIKE_LIST,
    {
      variables: { memberId: setting.id },
    }
  );
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (!data || !data.findAllProductLikeList) return <p>Error</p>;
  // const items = data.allProducts;
  const items1 = data.findAllProductLikeList.allProduct;
  console.log("items : ", items1);
  const items2 = data.findAllProductLikeList.myProduct;
  console.log("items2ㄴ : ", items2);
  // const items = items1.map((item1) => {
  //   for (const i of items2) {
  //     if (item1.id === i.)
  //   }
  // })
  const categoryList = setting.categoryList;

  const items = items1.map((item1) => {
    for (const i of items2) {
      if (item1.id === i.product.id) {
        if (setting.id === i.member.id) {
          return {
            ...item1,
            heart: "black",
          };
        }
      }
    }
    return {
      ...item1,
      heart: "white",
    };
  });

  return (
    <div>
      <div className="mx-auto px-10  mt-10">
        <div className="w-full flex text-left font-mont">
          <div className="w-1/5">
            <PlayCustomCategoryLeftMenu />
          </div>
          <div className="w-4/5">
            <div className="flex flex-wrap">
              <div className="w-3/4 h-16 items-center text-left">
                {categoryList.map((category) => (
                  <div>
                    <div className="w-auto font-bold title-font">
                      {category.targetId}
                    </div>
                    <div className="w-full">
                      {items1.map((item) => (
                        <div className="w-full">
                          {item.category === category.targetId && (
                            <div
                              className="w-1/5 pr-2 mb-4 flex flex-wrap"
                              key={item.id}
                            >
                              <CategoryProductItem
                                productItem={item}
                                refetch={refetch}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {/* <div className="w-auto font-bold title-font">{category}</div> */}
              </div>
            </div>
            <div className=" mt-4 ">
              <div className="flex flex-wrap ">
                {/* {items &&
                  items.length > 0 &&
                  items.map((item) => (
                    <div className="w-1/5 pr-2 mb-4" key={item.id}>
                      <CategoryProductItem
                        productItem={item}
                        refetch={refetch}
                      />
                    </div>
                  ))} */}
                {!items || items.length === 0 ? (
                  <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
                    {t("상품 준비중입니다")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Category = ({ match }) => {
  let { category } = match.params;
  console.log("category: ", category.toUpperCase());
  if (!category) {
    category = "WOMEN";
  }
  const history = useHistory();

  switch (category) {
    case "REVIEW":
      history.replace("/playground");
      return "";
    case "PALYTV":
      history.replace("/playtv");
      return "";
    case "ABOUT":
      history.replace("/about");
      return "";
    default:
      break;
  }

  const screen = <ScreenWide category={category.toUpperCase()} />;
  return <div>{screen}</div>;
};

export default Category;
