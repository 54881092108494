import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-10">
      <div className="flex flex-wrap items-end justify-between text-medium-13 ">
        <div className="mt-2 text-medium-15  max-w-5xl">
          <div className="content-title text-left">
            {t("CMS 관리 화면입니다.")}
          </div>
          <Link to="/mypage/cms/home">
            <span className="ml-0 hover:text-gray-600  text-black">Home</span>
          </Link>
          <Link to="/mypage/cms/about">
            <span className="ml-4 hover:text-gray-600  text-black">About</span>
          </Link>
          <Link to="/mypage/cms/hangul">
            <span className="ml-4 hover:text-gray-600  text-black">
              Play Hangul
            </span>
          </Link>
          <Link to="/mypage/cms/ground">
            <span className="ml-4 hover:text-gray-600  text-black">
              Play Ground
            </span>
          </Link>
          <Link to="/mypage/cms/tv">
            <span className="ml-4 hover:text-gray-600  text-black">
              Play TV
            </span>
          </Link>
          <Link to="/mypage/cms/category">
            <span className="ml-4 hover:text-gray-600  text-black">
              Category
            </span>
          </Link>
          <Link to="/mypage/cms/recommend">
            <span className="ml-4 hover:text-gray-600  text-black">
              Recommend
            </span>
          </Link>
          <Link to="/mypage/cms/template">
            <span className="ml-4 hover:text-gray-600  text-black">
              Template
            </span>
          </Link>
          <Link to="/mypage/cms/templateimg">
            <span className="ml-4 hover:text-gray-600 text-black">
              Template Image
            </span>
          </Link>
        </div>
      </div>
      <p className="max-w-5xl bg-black  height-1" />
    </div>
  );
});

const CmsPageTopMenu = () => {
  const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );

  return <div>{screen}</div>;
};
export default CmsPageTopMenu;
