import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { UPDATE_ORDER } from "graphql/gql/select";
import { useMutation } from "@apollo/client";
import btn_modal_close from "assets/images/btn_modal_close.svg";
import customStyles from "components/modalStyle";
import swal from "sweetalert";

const StateModal = ({ modalIsOpen, closeModal, orderId, state }) => {
  const [tempState, setTempState] = useState(state);

  useEffect(() => {
    console.log("state : ", state);
    console.log("tempState : ", tempState);
  }, [tempState]);

  const [updateOrder, { data: updatedData }] = useMutation(UPDATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  const handlerUpdateOrder = ({ id, state }) => {
    const data = {
      id,
      state,
    };
    updateOrder({ variables: data });
  };

  const preventIOSScroll = (e) => {
    e.preventDefault();
  };

  const onRequestClose = () => {
    document.documentElement.style.overflowY = "visible";
    document.documentElement.removeEventListener("touchmove", preventIOSScroll);
    closeModal();
  };

  const waitingForPaymentButton =
    tempState === ""
      ? "w-1/5 h-8 border text-center bg-black text-white"
      : "w-1/5 h-8 border text-center bg-white";

  const paymentFinished =
    tempState === "Payment finished"
      ? "w-1/5 h-8 border text-center bg-black text-white"
      : "w-1/5 h-8 border text-center bg-white";

  const preparingProduct =
    tempState === "Preparing Product"
      ? "w-1/5 h-8 border text-center bg-black text-white"
      : "w-1/5 h-8 border text-center bg-white";

  const delivering =
    tempState === "Delivering"
      ? "w-1/5 h-8 border text-center bg-black text-white"
      : "w-1/5 h-8 border text-center bg-white";

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onAfterOpen={() => {
        document.documentElement.style.overflowY = "hidden";
        document.documentElement.addEventListener(
          "touchmove",

          preventIOSScroll
        );
      }}
      onRequestClose={() => {
        document.documentElement.style.overflowY = "visible";
        document.documentElement.removeEventListener(
          "touchmove",
          preventIOSScroll
        );
        closeModal();
      }}
      shouldCloseOnOverlayClick
    >
      <div style={{ width: 800 }} className="flex flex-row bg-transparent">
        <div className="p-4 w-full bg-white">
          <div className="flex-col-left">
            <div>주문상태 변경</div>
            <div className="mt-8 flex justify-content-between">
              <div
                className={waitingForPaymentButton}
                onClick={() => setTempState("")}
              >
                waiting for payment
              </div>
              <div
                className={paymentFinished}
                onClick={() => setTempState("Payment finished")}
              >
                Payment finished
              </div>
              <div
                className={preparingProduct}
                onClick={() => setTempState("Preparing Product")}
              >
                Preparing Product
              </div>
              <div
                className={delivering}
                onClick={() => setTempState("Delivering")}
              >
                Delivering
              </div>
            </div>
            <div className="mt-8 flex flex-row-right">
              <button
                onClick={() => {
                  handlerUpdateOrder({
                    id: orderId,
                    state: tempState,
                  });
                  onRequestClose();
                }}
              >
                변경
              </button>
            </div>
          </div>
        </div>
        <div className="w-16 bg-transparent">
          <img
            className="ml-4 mt-1 w-6 h-6"
            src={btn_modal_close}
            alt=""
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default StateModal;
