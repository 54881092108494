import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import client from "./apolloClient";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";
import "./assets/scss/style.scss";
import "core-js/features/string/repeat";
import "./index.css";
import "./assets/main.css";

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,

  document.getElementById("root")
);

// serviceWorker.register();
