import React from "react";
import { useTranslation } from "react-i18next";

const CmsHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-2/20 text-center">{t("이미지")}</div>
      <div className="w-2/20 text-center">{t("항목명")}</div>
      <div className="w-1/20 text-center">{t("번호")}</div>
      <div className="w-3/20 text-center">{t("이동URL")}</div>
      <div className="w-2/20 text-center">{t("이동ID")}</div>
      <div className="w-4/20 text-center">{t("타이틀")}</div>
      <div className="w-4/20 text-center">{t("내용")}</div>
      <div className="w-2/20 text-center">{t("보이기")}</div>
    </div>
  );
};

export default CmsHeader;
