import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import useSettings from "stores/settings";
import Select from "react-select";
import swal from "@sweetalert/with-react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import ReactPlayer from "react-player";
import MyPage from "../MyPage";
import {
  DELETE_CMS,
  UPDATE_CMS,
  MULTI_UPLOAD_FILES,
} from "../../../graphql/gql/select";
import CheckBox from "../../../components/CheckBox";
import SingleImage from "../../../components/SingleImage";

const ScreenAdmin = ({
  id,
  category,
  name,
  currentItem,
  setCurrentItem,
  tempImgVideoUrl,
  setTempImgVideoUrl,
}) => {
  const { t } = useTranslation();

  console.log(category, name, id);
  const history = useHistory();
  const setting = useSettings();

  const [selectedOption, setSelectedOption] = useState();

  const [updateCms, { data: updatedData }] = useMutation(UPDATE_CMS, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updatedData !== undefined) {
      const data1 = updatedData.updateCms;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal("잘 수정 되었습니다.", { button: false, timer: 3000 });

        switch (category) {
          case "home":
            setting.cmsHomeUpdates = true;
            break;
          case "about":
            setting.cmsAboutUpdates = true;
            break;
          case "hangul":
            setting.cmsHangulUpdates = true;
            break;
          case "ground":
            setting.cmsGroundUpdates = true;
            break;
          case "tv":
            setting.cmsTVUpdates = true;
            break;
          case "category":
            setting.cmsCategoryUpdates = true;
            break;
          case "recommend":
            setting.cmsRecommendUpdates = true;
            break;
          default:
            setting.cmsHomeUpdates = true;
            break;
        }
        history.replace(`/mypage/cms/${category}`);
      }
    }
  }, [updatedData]);

  const [deleteCms, { data: deleteData }] = useMutation(DELETE_CMS, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      const data1 = deleteData.deleteCms;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal("잘 삭제 되었습니다.", { button: false, timer: 3000 });
        switch (category) {
          case "home":
            setting.cmsHomeUpdates = true;
            break;
          case "about":
            setting.cmsAboutUpdates = true;
            break;
          case "hangul":
            setting.cmsHangulUpdates = true;
            break;
          case "ground":
            setting.cmsGroundUpdates = true;
            break;
          case "tv":
            setting.cmsTVUpdates = true;
            break;
          case "category":
            setting.cmsCategoryUpdates = true;
            break;
          case "recommend":
            setting.cmsRecommendUpdates = true;
            break;
          default:
            setting.cmsHomeUpdates = true;
            break;
        }
        history.replace(`/mypage/cms/${category}`);
      }
    }
  }, [deleteData]);

  const updateCmsHandler = ({
    id,
    category,
    name,
    img,
    no,
    targetUrl,
    targetId,
    title,
    contents,
    visible,
  }) => {
    if (targetUrl) {
      updateCms({
        variables: {
          id,
          category,
          name,
          img,
          no,
          targetUrl,
          targetId,
          title,
          contents,
          visible,
        },
      });
    } else {
      swal("이동URL : 빈 값이 올 수 없습니다", { button: false, timer: 3000 });
    }
  };

  const deleteCmsHandler = ({ id }) => {
    // swal({
    //   text: "정말 삭제를 하시겠습니까",
    //   buttons: false,
    //   content: (
    //     <div className="max-w-xs flex flex-row-center mx-auto mx-2">
    //       <button
    //         className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
    //         onClick={() => {
    //           swal.close();
    //         }}
    //       >
    //         취소
    //       </button>
    //       <button
    //         className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
    //         onClick={() => {
    //           if (id) {
    //             deleteCms({
    //               variables: {
    //                 id: Number(id),
    //               },
    //             });
    //           } else {
    //             swal("id : 빈 값이 올 수 없습니다", {
    //               button: false,
    //               timer: 3000,
    //             });
    //           }
    //           swal.close();
    //         }}
    //       >
    //         삭제
    //       </button>
    //     </div>
    //   ),
    // });
    swal("정말 삭제를 하시겠습니까?", {
      buttons: {
        cancel: "취소",
        catch: {
          text: "삭제",
          value: "delete",
        },
      },
    }).then((value) => {
      switch (value) {
        case "delete":
          if (id) {
            deleteCms({
              variables: {
                id: Number(id),
              },
            });
          } else {
            swal("id :  빈 값이 올 수 없습니다", {
              button: false,
              timer: 3000,
            });
            swal.close();
          }
      }
    });
  };

  // 파일 선택시 자동 업로드

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, img: filenames[0] });
        setTempImgVideoUrl(filenames[0]);
      }
    }
  }, [multiData]);

  const options = [
    { value: "product", label: "product" },
    { value: "category", label: "category" },
    { value: "event", label: "event" },
    { value: "video", label: "video" },
    { value: "instagram", label: "instagram" },
    { value: "web", label: "web" },
    { value: "noaction", label: "noaction" },
    { value: "recommend", label: "recommend" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setCurrentItem({ ...currentItem, targetUrl: selectedOption.value });
    if (!selectedOption || !selectedOption.value) return "";
    switch (selectedOption.value) {
      case "product":
        // 프로덕트 선택
        swal(t("웹주소를 넣어 주세요"), {
          content: {},
        }).then((value) => {
          if (!value || value === "") swal.close();
          else setCurrentItem({ ...currentItem, targetId: value });
        });
        return "";
      case "category":
        return <button>{selectedOption.value}</button>;
      case "event":
        return <button>{selectedOption.value}</button>;
      case "video":
        return <button>{selectedOption.value}</button>;
      case "instagram":
        return <button>{selectedOption.value}</button>;
      case "web":
        swal(t("웹주소를 넣어 주세요"), {
          content: {
            element: "input",
            attributes: {
              placeholder: "Type web URL",
              type: "text",
            },
          },
        }).then((value) => {
          if (!value || value === "") swal.close();
          else setCurrentItem({ ...currentItem, targetId: value });
        });
        break;
      case "noaction":
        return <button>{selectedOption.value}</button>;
      default:
        break;
    }
    return "";
  };

  if (selectedOption === undefined && setting.cmsItem.targetUrl) {
    setSelectedOption({
      label: setting.cmsItem.targetUrl,
      value: setting.cmsItem.targetUrl,
    });
  }

  const accept =
    category === "recommend"
      ? " .json"
      : category === "home" && name === "video"
      ? "video/*"
      : "image/*";

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{`CMS 수정하기 [/${category}/${name}]`}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">
                  {/* {name === "video" ? "비디오" : "이미지"} */}
                  {category === "recommend"
                    ? "Json파일"
                    : name === "video"
                    ? "비디오"
                    : "이미지"}
                </div>
                {category === "recommend" ? (
                  <div> </div>
                ) : category === "home" && name === "video" ? (
                  <ReactPlayer
                    className=""
                    url={currentItem.img}
                    width="120"
                    height="100"
                  />
                ) : (
                  <SRLWrapper>
                    <SingleImage image={currentItem.img} />
                  </SRLWrapper>
                )}
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form onSubmit={() => {}} encType="multipart/form-data">
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept={accept}
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
                <div className="ml-20 w-full px-2 ">
                  <div className="flex flex-row">
                    <input
                      onChange={(e) => setTempImgVideoUrl(e.target.value)}
                      type="text"
                      className="flex-1 px-2 outline-none"
                      placeholder="파일 선택 또는 여기에 웹주소를 붙여넣은 후 변경하기 버튼을 누르세요"
                      value={tempImgVideoUrl}
                    />
                    <button
                      className=" w-24 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        setCurrentItem({
                          ...currentItem,
                          img: tempImgVideoUrl,
                        });
                      }}
                    >
                      변경하기
                    </button>
                  </div>
                  <div className="blackline" />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="mt-3 flex-row-left">
                  <div className="w-20 font-bold">번호</div>
                  <input
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, no: e.target.value })
                    }
                    type="text"
                    className="w-80 px-2 outline-none"
                    placeholder="번호"
                    value={currentItem.no}
                  />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">이동URL</div>
                <Select
                  className="w-64 h-8 px-0 outline-none text-normal-13"
                  defaultValue={{
                    label: selectedOption,
                    value: selectedOption,
                  }}
                  value={selectedOption}
                  label={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                  theme={{ borderRadius: 0, spacing: { baseUnit: 2 } }}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">이동ID</div>
                <input
                  // onChange={(e) => setCurrentItem({ ...currentItem, targetId: e.target.value })}
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="targetId"
                  value={currentItem.targetId}
                  readOnly
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">{t("제목")}</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, title: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="제목"
                  value={currentItem.title}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">{t("내용")}</div>
                <textarea
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, contents: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder={t("내용")}
                  cols="80"
                  rows="5"
                  value={currentItem.contents}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">보이기</div>
                <CheckBox
                  isCheck={currentItem.visible}
                  onClick={() =>
                    setCurrentItem({
                      ...currentItem,
                      visible: !currentItem.visible,
                    })
                  }
                />
              </div>
              <div className="mt-4 flex-col-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <div className="absolute right-0 w-96 h-8 ml-4 flex flex-row">
                    <button
                      className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                      onClick={() => history.replace(`/mypage/cms/${category}`)}
                    >
                      취소
                    </button>
                    <button
                      className="w-32 h-8 ml-1 table-text bg-red-500 text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        deleteCmsHandler(currentItem);
                      }}
                    >
                      삭제
                    </button>
                    <button
                      className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        updateCmsHandler(currentItem);
                      }}
                    >
                      수정하기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditCms = ({ match }) => {
  const setting = useSettings();
  const { id, category, name } = match.params;
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");

  const [currentItem, setCurrentItem] = useState(setting.cmsItem);
  const [tempImgVideoUrl, setTempImgVideoUrl] = useState(setting.cmsItem.img);

  if (!setting.cmsItem || !setting.cmsItem.id) return <p>No data</p>;

  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin
        id={id}
        category={category}
        name={name}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        tempImgVideoUrl={tempImgVideoUrl}
        setTempImgVideoUrl={setTempImgVideoUrl}
      />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default EditCms;
