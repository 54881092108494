import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import CheckBox from "components/CheckBox";
import PlayCustomResizableTemplateText from "containers/playcustom/PlayCustomResizableTemplateText";
import MyPage from "../MyPage";
import swal from "@sweetalert/with-react";
import { CREATE_NEW_TEMPLATE } from "../../../graphql/gql/select";

const ScreenAdmin = observer(({ category, name }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const setting = useSettings();
  const [templateId, setTemplateId] = useState(1);
  const [saveAndStay, setSaveAndStay] = useState(false);
  const [templateList, setTemplateList] = useState([
    {
      id: 0,
      txtSrc: "샘플",
      fontStyle: "normal",
      textColor: "#000000",
      topR: 80,
      leftR: 80,
      widthR: 100,
      heightR: 90,
    },
  ]);
  const [currentItem, setCurrentItem] = useState(templateList[0]);
  const [cmsItem, setCmsItem] = useState({
    category: "",
    translate: "",
    explain: "",
    visible: true,
    template: [],
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [tempText, setTempText] = useState(null);
  const [tempFont, setTempFont] = useState("normal");
  const [tempInputValue, setTempInputValue] = useState(null);
  const [jsonArray, setJsonArray] = useState(null);
  const [categorySelect, setCategorySelect] = useState("BASIC");

  const [createNewTemplate, { data: createdData }] = useMutation(
    CREATE_NEW_TEMPLATE,
    {
      onError(error) {
        console.log("CREATE_NEW_TEMPLATE: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    setCmsItem({
      ...cmsItem,
      template: JSON.stringify(templateList),
    });
  }, [templateList]);

  useEffect(() => {
    if (createdData !== undefined) {
      const data1 = createdData.createNewTemplate;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.cmsTemplateUpdates = true;
      }
      if (saveAndStay) {
        setCmsItem({
          category: "",
          translate: "",
          explain: "",
          visible: true,
          template: [],
        });
        setTemplateList([
          {
            id: 0,
            txtSrc: "샘플",
            fontStyle: "normal",
            textColor: "#000000",
            topR: 80,
            leftR: 80,
            widthR: 100,
            heightR: 90,
          },
        ]);
        setCurrentItem(templateList[0]);
      } else {
        history.push(`/mypage/cms/template`);
      }
    }
  }, [createdData]);

  const createNewTemplateHandler = ({
    category,
    translate,
    explain,
    template,
    visible,
  }) => {
    createNewTemplate({
      variables: {
        category,
        translate,
        explain,
        template,
        visible,
      },
    });
  };

  useEffect(() => {
    if (currentItem) setTempInputValue(currentItem.txtSrc);
  }, [currentItem]);

  const onTextChange = (e) => {
    const tempItem = currentItem;
    tempItem.txtSrc = e.target.value;
    const tempArray = templateList.map((item) => {
      if (item.id !== currentItem.id) return item;
      const temp1 = item;
      temp1.txtSrc = e.target.value;
      return temp1;
    });
    setTemplateList(tempArray);
  };

  const options = [
    { value: "basic", label: "BASIC" },
    { value: "retro", label: "RETRO" },
    { value: "lovely", label: "LOVELY" },
    { value: "calligraphy", label: "CALLIGRAPHY" },
    { value: "graphic", label: "GRAPHIC & TYPO" },
    { value: "logo_style", label: "LOGO STYLE" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{`CMS 등록하기 [/${category}/${name}]`}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="mt-4 flex-row-left">
                <div className="ml-20 w-full px-2 ">
                  <div className="flex">
                    <div
                      // onClick={() => setCurrentItem(null)}
                      id="capture"
                      className="border relative"
                      style={{ width: 300, height: 300 }}
                    >
                      {templateList.map((template) => (
                        <PlayCustomResizableTemplateText
                          key={template.id}
                          width={template.widthR}
                          height={template.heightR}
                          top={template.topR}
                          left={template.leftR}
                          setCurrentItem={setCurrentItem}
                          setSelectedOption={setSelectedOption}
                          graphicItem={template}
                          currentItem={currentItem}
                          graphicItems={templateList}
                          setGraphicItems={setTemplateList}
                        />
                      ))}
                      <div className="">{tempText}</div>
                    </div>
                    {currentItem ? (
                      <div className=" ml-4 flex-row">
                        <div className="w-full flex justify-between">
                          <input
                            value={currentItem.txtSrc}
                            onChange={(e) => {
                              const tempItem = currentItem;
                              tempItem.txtSrc = e.target.value;
                              const tempArray = templateList.map((item) => {
                                if (item.id !== currentItem.id) return item;
                                const temp1 = item;
                                temp1.txtSrc = e.target.value;
                                return temp1;
                              });
                              setTemplateList(tempArray);
                            }}
                            className="w-64 h-8 border"
                          />
                          <div
                            onClick={() => {
                              const tempTemplate = {
                                id: templateId,
                                txtSrc: "샘플",
                                fontStyle: "normal",
                                textColor: "#000000",
                                topR: 80,
                                leftR: 80,
                                widthR: 100,
                                heightR: 90,
                              };
                              setTemplateList(
                                templateList.concat(tempTemplate)
                              );
                              setCurrentItem(tempTemplate);
                              setTemplateId(templateId + 1);
                            }}
                            className=" fa fa-plus-square"
                          >
                            추가
                          </div>
                        </div>
                        <Select
                          className="mt-3 w-64 h-8"
                          options={fontOptions}
                          value={tempFont}
                          onChange={(tempFont) => {
                            setTempFont(tempFont);
                            const tempItem = currentItem;
                            tempItem.fontStyle = tempFont.value;
                            const tempArray = templateList.map((item) => {
                              if (item.id !== currentItem.id) return item;
                              const temp1 = item;
                              temp1.fontStyle = tempFont.value;
                              return temp1;
                            });
                            setTemplateList(tempArray);
                          }}
                          theme={{ borderRadius: 0, spacing: { baseUnit: 2 } }}
                        />
                        <div className="mt-4">{t("색상")}</div>
                        <div className="mt-2 w-full flex flex-wrap">
                          {colors.map((color) => (
                            <div className="w-1/10" key={color}>
                              <div className="mt-1">
                                <div
                                  className="w-5 h-5"
                                  style={{ backgroundColor: `${color}` }}
                                  onClick={() => {
                                    const tempItem = currentItem;
                                    tempItem.textColor = color;
                                    const tempArray = templateList.map(
                                      (item) => {
                                        if (item.id !== currentItem.id)
                                          return item;
                                        const temp1 = item;
                                        temp1.textColor = color;
                                        return temp1;
                                      }
                                    );
                                    setTemplateList(tempArray);
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      "" // 선택 안되었을때 추가 가능
                    )}
                  </div>
                  <div className="blackline mt-4" />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">Category</div>
                <Select
                  className="w-64 h-8 px-0 outline-none text-normal-13"
                  value={categorySelect}
                  onChange={(categorySelect) => {
                    setCategorySelect(categorySelect);
                    setCmsItem({
                      ...cmsItem,
                      category: categorySelect.value,
                    });
                  }}
                  options={options}
                  theme={{ borderRadius: 0, spacing: { baseUnit: 2 } }}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">번역</div>
                <input
                  value={cmsItem.translate}
                  onChange={(e) => {
                    setCmsItem({
                      ...cmsItem,
                      translate: e.target.value,
                    });
                  }}
                  className="w-64 h-8 px-0 border"
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">설명</div>
                <input
                  value={cmsItem.explain}
                  onChange={(e) => {
                    setCmsItem({
                      ...cmsItem,
                      explain: e.target.value,
                    });
                  }}
                  className="w-64 h-8 px-0 border"
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">보이기</div>
                <CheckBox
                  isCheck={cmsItem.visible}
                  onClick={() =>
                    setCmsItem({
                      ...cmsItem,
                      visible: !cmsItem.visible,
                    })
                  }
                />
              </div>
              <div className="w-full h-64">{jsonArray}</div>
              <div className="mt-4 flex-col-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <div className="absolute right-0 w-96 h-8 ml-4 flex flex-row">
                    <button
                      className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                      onClick={() => history.push(`/mypage/cms/${category}`)}
                    >
                      취소
                    </button>
                    <button
                      className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        console.log("cmsItem : ", cmsItem);
                        setSaveAndStay(false);
                        createNewTemplateHandler(cmsItem);
                      }}
                    >
                      {t("등록하기")}
                    </button>
                    <button
                      className="w-48 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        setSaveAndStay(true);
                        createNewTemplateHandler(cmsItem);
                      }}
                    >
                      등록하고 계속 추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const NewCmsTemplate = ({ match }) => {
  const setting = useSettings();
  const { category, name } = match.params;
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin category={category} name={name} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

const colors = [
  "#B02418",
  "#C94966",
  "#E7495A",
  "#F1CBD4",
  "#EA8031",
  "#F6D246",
  "#F5E15F",
  "#A1EF6E",
  "#A1EE6D",
  "#4B973E",
  "#442816",
  "#E4FDFF",
  "#5AA6F7",
  "#3638A5",
  "#202085",
  "#DBCCE0",
  "#090D3B",
  "#080C3E",
  "#432816",
  "#CBB894",
  "#EBE7CE",
  "#D4C283",
  "#D3D3D3",
  "#000000",
];

const fontOptions = [
  { value: "normal", label: "기본" },
  { value: "font-netmable-bold", label: "넷마블체1" },
  { value: "font-netmable-medium", label: "넷마블체2" },
  { value: "font-netmable-light", label: "넷마블체3" },
  { value: "font-museum-classic-bold", label: "국립박물관문화재단클래식1" },
  { value: "font-museum-classic-medium", label: "국립박물관문화재단클래식2" },
  { value: "font-museum-classic-light", label: "국립박물관문화재단클래식3" },
  { value: "font-doveMayo", label: "둘기마요1" },
  { value: "font-doveMayo-bold", label: "둘기마요2" },
  { value: "font-miraero-gothic", label: "도스고딕" },
  { value: "font-miraero-myungjo", label: "도스명조" },
  { value: "font-miraero-saemmul", label: "도스샘물" },
  { value: "font-miraero-normal", label: "도스고딕" },
  { value: "font-miraero-sam", label: "도스삼국지" },
  { value: "font-bm-euliro", label: "배달의 민족 을지로" },
  { value: "font-bm-kirang", label: "배달의 민족 기랑해랑체" },
  { value: "font-bm-dohyeon", label: "배달의 민족 도현" },
  { value: "font-jalnan", label: "잘난체" },
  { value: "font-blackHansans", label: "검은고딕" },
  { value: "font-cafe24-danjunghae", label: "cafe24단정해" },
  { value: "font-cafe24-dongdong", label: "cafe24동동" },
  { value: "font-cafe24-ohsquare", label: "cafe24아네모네" },
  { value: "font-cafe24-shiningstar", label: "cafe24빛나는별" },
  { value: "font-cafe24-ssuckssuck", label: "쑥쑥" },
  { value: "font-cafe24-syoungsyong", label: "숑숑" },
  { value: "font-ebs-humin-light", label: "ebs훈민정음1" },
  { value: "font-ebs-humin-regular", label: "ebs훈민정음2" },
  { value: "font-ebs-humin-semi-bold", label: "ebs훈민정음3" },
  { value: "font-ebs-saeron-light", label: "ebs훈민정음새론1" },
  { value: "font-ebs-saeron-regular", label: "ebs훈민정음새론2" },
  { value: "font-ebs-saeron-semi-bold", label: "ebs훈민정음새론3" },
  { value: "font-ebs-jusigyung-light", label: "ebs주시경1" },
  { value: "font-ebs-jusigyung-medium", label: "ebs주시경2" },
  { value: "font-ebs-jusigyung-bold", label: "ebs주시경3" },
  { value: "font-gh-light", label: "행복고흥1" },
  { value: "font-gh-medium", label: "행복고흥2" },
  { value: "font-gh-bold", label: "행복고흥3" },
  { value: "font-gmarket-light", label: "G마켓산스1" },
  { value: "font-gmarket-medium", label: "G마켓산스2" },
  { value: "font-gmarket-bold", label: "G마켓산스3" },
  { value: "font-namyangju-dasan-medium", label: "남양주 다산1" },
  { value: "font-namyangju-dasan-bold", label: "남양주  다산2" },
  { value: "font-namyangju-gothic-light", label: "남양주 고딕1" },
  { value: "font-namyangju-gothic-medium", label: "남양주 고딕2" },
  { value: "font-namyangju-gothic-bold", label: "남양주 고딕3" },
  { value: "font-namyangju-gothic-extrabold", label: "남양주 고딕4" },
  { value: "font-timon-monsori", label: "티몬 몬소리" },
  { value: "font-timon-tium", label: "티몬 티움" },
  { value: "font-sangsang-flowerload", label: "꽃길" },
  { value: "font-sangsang-rock", label: "바위체" },
  { value: "font-sandol-kukdetopokki-light", label: "산돌 국대떡볶이1" },
  { value: "font-sandol-kukdetopokki-bold", label: "산돌 국대떡볶이2" },
];

export default NewCmsTemplate;
