import React from "react";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import swal from "@sweetalert/with-react";
import { ALL_NOTICES_ADMIN } from "graphql/gql/select";
import MyPage from "./MyPage";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/6 text-center">{t("고객명")}</div>
      <div className="w-1/6 text-center">{t("포인트")}</div>
      <div className="w-1/6 text-center">{t("내용")}</div>
      <div className="w-1/6 text-center" />
      <div className="w-1/6 text-center" />
      <div className="w-1/6 text-center" />
    </div>
  );
};

// TODO 포인트 쿼리 만들 것
const Points = observer(({ points }) => {
  const setting = useSettings();
  const { loading, error, data } = useQuery(ALL_NOTICES_ADMIN, {
    variables: { memberId: setting.id },
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
    return <p />;
  }
  if (!data || !data.allNoticesAdmin) return <p>Error</p>;
  const items = data.allNoticesAdmin;
  items.sort((a, b) => {
    return a.no - b.no;
  });
  console.log("data.allNotices=", items);

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">전체 포인트 확인</div>
      <div className="blackline2 mt-1" />
      <div className="flex-row-center text-medium-15 ">
        <Header />
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div className="w-full flex-row-center table-text">
              <div className="w-1/6 text-center">{item.member.name}</div>
              <div className="w-1/6 text-center">{points}</div>
              <div className="w-1/6 text-center" />
              <div className="w-1/6 text-center" />
              <div className="w-1/6 text-center" />
              <div className="w-1/6 text-center" />
            </div>
          ))}
        <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
          {!setting.isLogin
            ? "로그인을 먼저 해주세요"
            : items.length === 0
            ? "3 개월 이내에 포인트 내역이 없습니다."
            : ""}
        </div>
      </div>
    </div>
  );
});

const AllPoints = () => {
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const pointScreen = <Points points={1000} />;
  return (
    <div>
      <MyPage>{pointScreen}</MyPage>
    </div>
  );
};

export default AllPoints;
