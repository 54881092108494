import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import useSettings from "stores/settings";
import customStyles from "components/modalStyle";
import { useQuery, useMutation } from "@apollo/client";
import {
  ALL_FAQ,
  UPDATE_FAQ,
  CREATE_FAQ,
  DELETE_FAQ,
} from "graphql/gql/select";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import btn_modal_close from "assets/images/btn_modal_close.svg";
import swal from "sweetalert";
import MyPage from "./MyPage";

const preventIOSScroll = (e) => {
  e.preventDefault();
};

const ScreenAdmin = observer(() => {
  const setting = useSettings();
  const { t } = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  const openCreateFAQModal = ({ category, lang }) => {
    console.log(category, lang);
    const reducer = (max, currentValue) => {
      if (currentValue.category === category && currentValue.lang === lang) {
        if (currentValue.no > max) return currentValue.no;
      }
      return max;
    };
    const lastNo = items.reduce(reducer, 0) + 1;
    console.log("last: ", lastNo);

    const item1 = { category, lang, no: lastNo, title: "", contents: "" };
    setCurrentItem(item1);
    setCreateModalOpen(true);
  };

  const openUpdateFAQModal = (item) => {
    console.log("openUpdateFAQModal:item=", item);
    setCurrentItem(item);
    setUpdateModalOpen(true);
  };

  const [createFAQ, { data: createdData }] = useMutation(CREATE_FAQ, {
    onError(error) {
      console.log("updateFAQ: err=", error);

      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (createdData !== undefined) {
      const data1 = createdData.createFAQ;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });

        setCreateModalOpen(false);
        refetch();
      }
    }
  }, [createdData]);

  const [updateFAQ, { data: updatedData }] = useMutation(UPDATE_FAQ, {
    onError(error) {
      console.log("updateFAQ: err=", error);

      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updatedData !== undefined) {
      const data1 = updatedData.updateFAQ;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });

        setUpdateModalOpen(false);
        refetch();
      }
    }
  }, [updatedData]);

  const [deleteFAQ, { data: deleteData }] = useMutation(DELETE_FAQ, {
    onError(error) {
      console.log("deleteFAQ: err=", error);

      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      const data1 = deleteData.deleteFAQ;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        swal(t("삭제가 잘 되었습니다"), { button: false, timer: 3000 });

        setUpdateModalOpen(false);
        refetch();
      }
    }
  }, [deleteData]);

  const { loading, error, data, refetch } = useQuery(ALL_FAQ, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
    return <p />;
  }

  if (!data || !data.allFAQ) return <p>Error</p>;
  const items = data.allFAQ;
  items.sort((a, b) => {
    return a.no - b.no;
  });
  console.log("data.allFAQ=", items);
  const orderItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "order"
  );
  const customItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "custom"
  );
  const imageItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "image"
  );

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="content-title">FAQ</div>
        <div className="blackline2 mt-1" />
        <div className="table-header">
          ORDER / PAYMENT FAQ
          <i
            className="ml-5 fa fa-plus-square"
            onClick={() =>
              openCreateFAQModal({ category: "order", lang: setting.lang })
            }
          >
            <span className="ml-1">추가</span>
          </i>
        </div>
        <div className="grayline mt-1" />
        <Accordion>
          {orderItems !== undefined && orderItems.length > 0 ? (
            orderItems.map((item) => (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="mt-2 table-text" key={item.id}>
                      {`${item.no} ${item.title}`}
                      <i
                        className="ml-5 fa fa-pencil"
                        onClick={() => openUpdateFAQModal(item)}
                      />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="mt-2 pl-4 table-text">{item.contents}</div>
                </AccordionItemPanel>
              </AccordionItem>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
        <div
          className={
            data !== undefined ? "blackline2 mt-8" : "blackline2 mt-32"
          }
        />
        <div className="table-header">
          CUSTOM / REQUEST FAQ
          <i
            className="ml-5 fa fa-plus-square"
            onClick={() =>
              openCreateFAQModal({ category: "custom", lang: setting.lang })
            }
          >
            추가
          </i>
        </div>
        <div className="grayline mt-1" />
        <Accordion>
          {customItems !== undefined && customItems.length > 0 ? (
            customItems.map((item) => (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="mt-2 table-text" key={item.id}>
                      {`${item.no} ${item.title}`}
                      <i
                        className="ml-5 fa fa-pencil"
                        onClick={() => openUpdateFAQModal(item)}
                      />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="mt-2 pl-4 table-text">{item.contents}</div>
                </AccordionItemPanel>
              </AccordionItem>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
        <div
          className={
            data !== undefined ? "blackline2 mt-8" : "blackline2 mt-32"
          }
        />
        <div className="table-header">
          IMAGE / PRINTING FAQ
          <i
            className="ml-5 fa fa-plus-square"
            onClick={() =>
              openCreateFAQModal({ category: "image", lang: setting.lang })
            }
          >
            추가
          </i>
        </div>
        <div className="grayline mt-1" />

        <Accordion>
          {imageItems !== undefined && imageItems.length > 0 ? (
            imageItems.map((item) => (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="mt-2 table-text" key={item.id}>
                      {`${item.no} ${item.title}`}
                      <i
                        className="ml-5 fa fa-pencil"
                        onClick={() => openUpdateFAQModal(item)}
                      />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="mt-2 pl-4 table-text">{item.contents}</div>
                </AccordionItemPanel>
              </AccordionItem>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
      </div>
      {/* 업데이트 모달 */}
      <Modal
        isOpen={updateModalOpen}
        style={customStyles}
        ariaHideApp={false}
        onAfterOpen={() => {
          document.documentElement.style.overflowY = "hidden";
          document.documentElement.addEventListener(
            "touchmove",
            preventIOSScroll
          );
        }}
        onRequestClose={() => {
          document.documentElement.style.overflowY = "visible";
          document.documentElement.removeEventListener(
            "touchmove",
            preventIOSScroll
          );
          setUpdateModalOpen(false);
        }}
        shouldCloseOnOverlayClick
      >
        {currentItem !== undefined ? (
          <div className="w-140 flex flex-row bg-transparent">
            <div className="p-4 w-120 table-text bg-white">
              <div className="dialog-header-bold">FAQ 수정/삭제</div>
              <div className="mt-2 blackline2" />
              <div className="w-full bg-white">
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">카테고리</div>
                  <input
                    type="text"
                    className="w-auto px-2 outline-none"
                    placeholder="카테고리"
                    value={currentItem.category}
                    readOnly
                  />
                </div>

                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">언어</div>
                  <input
                    id="lang"
                    type="text"
                    className="w-auto px-2 outline-none"
                    placeholder="언어"
                    readOnly
                    value={currentItem.lang}
                  />
                </div>
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">번호</div>
                  <input
                    id="no"
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, no: e.target.value })
                    }
                    type="text"
                    className="flex-1 px-2 outline-none"
                    placeholder="번호"
                    value={currentItem.no}
                  />
                </div>
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">{t("제목")}</div>
                  <input
                    id="title"
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, title: e.target.value })
                    }
                    type="text"
                    className="flex-1 px-2 outline-none"
                    placeholder="제목"
                    value={currentItem.title}
                  />
                </div>
                <div className="mt-3 h-40 flex-col-left">
                  <div className="w-12 font-bold">{t("내용")}</div>
                  <textarea
                    onChange={(e) =>
                      setCurrentItem({
                        ...currentItem,
                        contents: e.target.value,
                      })
                    }
                    type="text"
                    className="mt-3 w-full px-0 py-2 border outline-none h-full dialog-bg-gray"
                    placeholder={t("내용")}
                    value={currentItem.contents}
                  />
                </div>
                <div className="mt-4 flex-col-left">
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <button
                      className="absolute left-0 w-48 h-8 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        console.log("삭제 click: ");
                        // TODO 삭제 넣기
                        deleteFAQ({
                          variables: {
                            id: currentItem.id,
                          },
                        });
                      }}
                    >
                      {t("삭제하기")}
                    </button>
                    <button
                      className="absolute right-0 w-48 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        console.log("수정완료 click: ");
                        updateFAQ({
                          variables: {
                            id: currentItem.id,
                            category: currentItem.category,
                            lang: currentItem.lang,
                            no: Number(currentItem.no),
                            title: currentItem.title,
                            contents: currentItem.contents,
                          },
                        });
                      }}
                    >
                      {t("수정완료")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* 대화상자 종료 버튼 */}
            <div className="w-16 bg-transparent">
              <img
                className="ml-4 mt-1 w-6 h-6 "
                src={btn_modal_close}
                alt=""
                onClick={() => setUpdateModalOpen(false)}
              />
            </div>
          </div>
        ) : (
          <div>{JSON.stringify(currentItem)}</div>
        )}
      </Modal>
      <Modal
        isOpen={createModalOpen}
        style={customStyles}
        ariaHideApp={false}
        onAfterOpen={() => {
          document.documentElement.style.overflowY = "hidden";
          document.documentElement.addEventListener(
            "touchmove",
            preventIOSScroll
          );
        }}
        onRequestClose={() => {
          document.documentElement.style.overflowY = "visible";
          document.documentElement.removeEventListener(
            "touchmove",
            preventIOSScroll
          );
          setCreateModalOpen(false);
        }}
        shouldCloseOnOverlayClick
      >
        {currentItem !== undefined ? (
          <div className="w-140 flex flex-row bg-transparent">
            <div className="p-4 w-120 table-text bg-white">
              <div className="dialog-header-bold">FAQ 추가</div>
              <div className="mt-2 blackline2" />
              <div className="w-full bg-white">
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">카테고리</div>
                  <input
                    type="text"
                    className="w-auto px-2 outline-none"
                    placeholder="카테고리"
                    value={currentItem.category}
                    readOnly
                  />
                </div>

                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">언어</div>
                  <input
                    id="lang"
                    type="text"
                    className="w-auto px-2 outline-none"
                    placeholder="언어"
                    readOnly
                    value={currentItem.lang}
                  />
                </div>
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">번호</div>
                  <input
                    id="no"
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, no: e.target.value })
                    }
                    type="text"
                    className="flex-1 px-2 outline-none"
                    placeholder="번호"
                    value={currentItem.no}
                  />
                </div>
                <div className="mt-3 flex-row-left">
                  <div className="w-12 font-bold">{t("제목")}</div>
                  <input
                    id="title"
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, title: e.target.value })
                    }
                    type="text"
                    className="flex-1 px-2 outline-none"
                    placeholder="제목"
                    value={currentItem.title}
                  />
                </div>
                <div className="mt-3 h-40 flex-col-left">
                  <div className="w-12 font-bold">{t("내용")}</div>
                  <textarea
                    onChange={(e) =>
                      setCurrentItem({
                        ...currentItem,
                        contents: e.target.value,
                      })
                    }
                    type="text"
                    className="mt-3 w-full px-0 py-2 border outline-none h-full dialog-bg-gray"
                    placeholder={t("내용")}
                    value={currentItem.contents}
                  />
                </div>
                <div className="mt-4 flex-col-left">
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <button
                      className="absolute right-0 w-48 h-8 ml-4 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        console.log("추가하기 click");
                        createFAQ({
                          variables: {
                            category: currentItem.category,
                            lang: currentItem.lang,
                            no: Number(currentItem.no),
                            title: currentItem.title,
                            contents: currentItem.contents,
                          },
                        });
                      }}
                    >
                      {t("추가하기")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* 대화상자 종료 버튼 */}
            <div className="w-16 bg-transparent">
              <img
                className="ml-4 mt-1 w-6 h-6 "
                src={btn_modal_close}
                alt=""
                onClick={() => setCreateModalOpen(false)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
});

const Screen = observer(() => {
  const setting = useSettings();
  console.log("setting.lang = ", setting.lang);
  const { loading, error, data } = useQuery(ALL_FAQ, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (!data || !data.allFAQ) return <p>Error</p>;
  const items = data.allFAQ;
  items.sort((a, b) => {
    return a.no - b.no;
  });
  console.log("data.allFAQ=", items);
  const orderItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "order"
  );
  const customItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "custom"
  );
  const imageItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "image"
  );

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="content-title">FAQ</div>
        <div className="blackline2 mt-1" />
        <div className="table-header">ORDER / PAYMENT FAQ</div>
        <div className="grayline mt-1" />
        <Accordion>
          {orderItems !== undefined && orderItems.length > 0 ? (
            orderItems.map((item) => (
              <div key={item.title}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="mt-2 table-text" key={item.id}>
                        {`${item.no} ${item.title}`}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="mt-2 pl-4 table-text">{item.contents}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
        <div
          className={
            data !== undefined ? "blackline2 mt-8" : "blackline2 mt-32"
          }
        />
        <div className="table-header">CUSTOM / REQUEST FAQ</div>
        <div className="grayline mt-1" />
        <Accordion>
          {customItems !== undefined && customItems.length > 0 ? (
            customItems.map((item) => (
              <div key={item.title}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="mt-2 table-text" key={item.id}>
                        {`${item.no} ${item.title}`}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="mt-2 pl-4 table-text">{item.contents}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
        <div
          className={
            data !== undefined ? "blackline2 mt-8" : "blackline2 mt-32"
          }
        />
        <div className="table-header">IMAGE / PRINTING FAQ</div>
        <div className="grayline mt-1" />

        <Accordion>
          {imageItems !== undefined && imageItems.length > 0 ? (
            imageItems.map((item) => (
              <div key={item.title}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="mt-2 table-text" key={item.id}>
                        {`${item.no} ${item.title}`}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="mt-2 pl-4 table-text">{item.contents}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
      </div>
    </div>
  );
});

const ScreenMobile = observer(() => {
  const setting = useSettings();
  console.log("setting.lang = ", setting.lang);

  const { loading, error, data } = useQuery(ALL_FAQ, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (!data || !data.allFAQ) return <p>Error</p>;
  const items = data.allFAQ;
  items.sort((a, b) => {
    return a.no - b.no;
  });
  console.log("data.allFAQ=", items);
  const orderItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "order"
  );
  const customItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "custom"
  );
  const imageItems = items.filter(
    (item) => item.lang === setting.lang && item.category === "image"
  );

  return (
    <div>
      <div className="w-full px-3 mt-6">
        <div className="content-title ">FAQ</div>
        <div className="blackline2 mt-1 mb-4" />
        <div className="table-header">Order / Payment </div>
        <div className="blackline mt-2" />
        <Accordion>
          {orderItems !== undefined && orderItems.length > 0 ? (
            orderItems.map((item) => (
              <div key={item.title}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="mt-2 table-text" key={item.id}>
                        {`${item.title}`}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="mt-2 pl-4 table-text">{item.contents}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>

        <div className="mt-8" />
        <div className="table-header">Custom / Request DAQ</div>
        <div className="blackline mt-2" />
        <Accordion>
          {customItems !== undefined && customItems.length > 0 ? (
            customItems.map((item) => (
              <div key={item.title}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="mt-2 table-text" key={item.id}>
                        {`${item.title}`}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="mt-2 pl-4 table-text">{item.contents}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>

        <div className="mt-8" />
        <div className="table-header ">Image / Printing FAQ</div>
        <div className="blackline mt-2" />
        <Accordion>
          {imageItems !== undefined && imageItems.length > 0 ? (
            imageItems.map((item) => (
              <div key={item.title}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="mt-2 table-text" key={item.id}>
                        {`${item.title}`}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="mt-2 pl-4 table-text">{item.contents}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            ))
          ) : (
            <div className="mt-2 table-text flex-row-left">자료가 없습니다</div>
          )}
        </Accordion>
      </div>
    </div>
  );
});

const FAQ = () => {
  const setting = useSettings();
  const history = useHistory();
  // 로그인 안한 경우
  if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";
  const screen1 = setting.isAdminScreenMode ? (
    <MyPage>
      <ScreenAdmin />
    </MyPage>
  ) : (
    <MyPage>
      <Screen />
    </MyPage>
  );
  const screen = setting.isMobile ? <ScreenMobile /> : screen1;
  return <div>{screen}</div>;
};

export default FAQ;
