import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Moment from "react-moment";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { SPECIAL_ORDER, DELETE_SPECIAL_ORDER, UPDATE_SPECIAL_ORDER } from "graphql/gql/select";
import useSettings from "stores/settings";
import { SRLWrapper } from "simple-react-lightbox";
import MyPage from "./MyPage";
import CheckBox from "../../components/CheckBox";
import MultiImages from "../../components/MultiImages";

const SpecialOrdersAdmin = ({ specialOrderId }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const history = useHistory();
  const [currentItem, setCurrentItem] = useState(null);

  const [updateSpecialOrder, { data: updateData }] = useMutation(UPDATE_SPECIAL_ORDER, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updateData !== undefined) {
      const data1 = updateData.updateSpecialOrder;
      if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.specialOrdersUpdates = true;
        history.push("/mypage/myspecialorders");
      }
    }
  }, [updateData]);

  const handlerSaveClick = () => {
    const status = getStatus();
    updateSpecialOrder({
      variables: {
        id: specialOrderId,
        status,
        replyAt: currentItem.replyAt,
        processMemo: currentItem.processMemo,
      },
    });
  };

  const getStatus = () => {
    if (currentItem.replyAt && currentItem.replyAt !== "") return "답변완료";
    return "의뢰완료";
  };

  const { loading, error, data } = useQuery(SPECIAL_ORDER, {
    variables: { id: specialOrderId },
    skip: !!currentItem,
  });
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  if (data && data.specialOrder) {
    console.log(data.specialOrder);
    setCurrentItem(data.specialOrder);
  }

  if (!currentItem) return <div />;

  console.log(currentItem);

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">SPECIAL ORDER DETAIL</div>
      <div className="blackline2 mt-1" />
      <div className="flex flex-row flex-wrap items-start justify-center text-medium-13 ">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around mb-2">
            <p className="text-bold-15 text-center ">{t("주문/단체 제작 의뢰 폼제목")}</p>
          </div>

          <div className="mx-8 flex flex-col items-center justify-around">
            <p className="w-full bg-black height-1" />
            <div className="mx-8 mt-0 mb-0">
              <Moment unix format="YYYY-MM-DD" className="w-full text-right">
                {Number(currentItem.createdAt) / 1000}
              </Moment>
            </div>
          </div>

          <div className="mx-8 mt-4 mb-4">
            <input
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이름")}
              value={currentItem.member.name}
              readOnly
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              id="email"
              type="email"
              className="w-full px-2 py-2  outline-none"
              placeholder={t("이메일")}
              value={currentItem.member.email}
              readOnly
            />
            <p className="w-full bg-black  height-1" />
          </div>
          <div className="mx-8 mb-4 flex flex-row  flex-wrap">
            <div
              className="flex flex-row items-center mr-8"
              onClick={() => setCurrentItem({ ...currentItem, productType: "의류" })}
            >
              <CheckBox isCheck={currentItem.productType === "의류"} />
              <div className="ml-2">{t("의류")}</div>
            </div>
            <div
              className="flex flex-row items-center"
              onClick={() => setCurrentItem({ ...currentItem, productType: "폰케이스" })}
            >
              <CheckBox isCheck={currentItem.productType === "폰케이스"} />
              <div className="ml-2">{t("폰케이스")}</div>
            </div>
          </div>
          <p className="mx-8 bg-black  height-1" />
          <div className="mx-8 mb-4 h-32">
            <textarea
              onChange={(e) => setCurrentItem({ ...currentItem, contents: e.target.value })}
              type="text"
              className="w-full px-2 py-2  outline-none h-full border-black border-1"
              placeholder={t("내용")}
              cols="80"
              rows="5"
              defaultValue={currentItem.contents}
            />
            {/* <p className="w-full bg-black  height-1" /> */}
          </div>
          <SRLWrapper>
            <div className="mt-3 mx-8 flex-row-left">
              <div className="w-20 font-bold">상세이미지</div>
              <MultiImages images={currentItem.detailImage} />
            </div>
          </SRLWrapper>
        </div>
        {/* 상태 표시 */}
        <div className="w-96 bg-white">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around mb-2">
            <p className="text-bold-15  text-center ">{t("답변")}</p>
          </div>

          <div className="mx-8 flex flex-col  flex-wrap">
            <div className="flex flex-row items-center mt-2">
              <CheckBox
                isCheck={currentItem.replyAt && currentItem.replyAt !== ""}
                onClick={() => {
                  console.log(currentItem.replyAt);
                  if (!currentItem.replyAt || currentItem.replyAt === "") {
                    setCurrentItem({ ...currentItem, replyAt: String(Date.now()) });
                  } else setCurrentItem({ ...currentItem, replyAt: "" });
                }}
              />
              <div className="ml-2">{t("답변완료")}</div>
              {currentItem.replyAt && currentItem.replyAt !== "" ? (
                <Moment className="text-2xs ml-2" unix format="YYYY.MM.DD">
                  {Number(currentItem.replyAt) / 1000}
                </Moment>
              ) : (
                ""
              )}
            </div>
            <p className="mx-0 mt-2 bg-black  height-1" />
          </div>
          <div className="mx-8 mb-4 h-32">
            <textarea
              type="text"
              className="w-full px-2 py-2  outline-none h-full border-black border-1"
              placeholder={t("내용")}
              cols="80"
              rows="5"
              defaultValue={currentItem.processMemo}
              onChange={(e) => setCurrentItem({ ...currentItem, processMemo: e.target.value })}
            />
          </div>

          <div className="mx-8 flex items-center justify-between">
            <button
              className="w-full text-sm bg-black text-white font-bold mt-8 py-2 px-4"
              onClick={() => handlerSaveClick()}
            >
              {t("답변완료하기")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SpecialOrders = ({ specialOrderId }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const history = useHistory();
  const [currentItem, setCurrentItem] = useState(null);

  const [deleteSpecialOrder, { data: deleteData }] = useMutation(DELETE_SPECIAL_ORDER, {
    onError(error) {
      console.log(error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      const data1 = deleteData.deleteSpecialOrder;
      if (!data1.id) {
        swal(t("취소가 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("잘 취소되었습니다"), { button: false, timer: 3000 });
        setting.specialOrdersUpdates = true;
        history.push("/mypage/myspecialorders");
      }
    }
  }, [deleteData]);

  const handlerCancelClick = () => {
    console.log(currentItem);

    if (!currentItem.id) {
      swal(t("로그인을 한 후에 취소해 주세요"), { button: false, timer: 3000 });
      return;
    }
    if (currentItem.process5 && currentItem.process5 !== "") {
      swal(t("배송완료로 취소할 수 없습니다"), { button: false, timer: 3000 });
      return;
    }
    if (currentItem.process4 && currentItem.process4 !== "") {
      swal(t("배송중으로 취소할 수 없습니다"), { button: false, timer: 3000 });
      return;
    }
    if (currentItem.process3 && currentItem.process3 !== "") {
      swal(t("제작완료되어 취소할 수 없습니다"), { button: false, timer: 3000 });
      return;
    }

    if (currentItem.process2 && currentItem.process2 !== "") {
      swal(t("제작중이므로 취소할 수 없습니다"), { button: false, timer: 3000 });
      return;
    }

    deleteSpecialOrder({ variables: { id: specialOrderId } });
  };

  const { loading, error, data } = useQuery(SPECIAL_ORDER, {
    variables: { id: specialOrderId },
    skip: !!currentItem,
  });
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  if (data && data.specialOrder) {
    console.log(data.specialOrder);
    setCurrentItem(data.specialOrder);
  }

  if (!currentItem) return <div />;

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">SPECIAL ORDER DETAIL</div>
      <div className="blackline2 mt-1" />
      <div className="flex flex-row flex-wrap items-start justify-center text-medium-13 ">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 bg-white">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around mb-2">
            <p className="text-bold-15  text-center ">{t("주문/단체 제작 의뢰 폼제목")}</p>
          </div>

          <div className="mx-8 flex flex-col items-center justify-around">
            <p className="w-full bg-black height-1" />
            <div className="mx-8 mt-0 mb-0">
              <Moment unix format="YYYY-MM-DD" className="w-full text-right">
                {Number(currentItem.createdAt) / 1000}
              </Moment>
            </div>
          </div>

          <div className="mx-8 mt-4 mb-4">
            <input
              type="text"
              className="w-full px-2 py-2 outline-none"
              placeholder={t("이름")}
              value={currentItem.member.name}
              readOnly
            />
            <p className="w-full bg-black  height-1" />
          </div>

          <div className="mx-8 mb-4">
            <input
              id="email"
              type="email"
              className="w-full px-2 py-2  outline-none"
              placeholder={t("이메일")}
              value={currentItem.member.email}
              readOnly
            />
            <p className="w-full bg-black  height-1" />
          </div>
          <div className="mx-8 mb-4 flex flex-row  flex-wrap">
            <div
              className="flex flex-row items-center mr-8"
              onClick={() => setCurrentItem({ ...currentItem, productType: "의류" })}
            >
              <CheckBox isCheck={currentItem.productType === "의류"} />
              <div className="text-xs ml-2">{t("의류")}</div>
            </div>
            <div
              className="flex flex-row items-center"
              onClick={() => setCurrentItem({ ...currentItem, productType: "폰케이스" })}
            >
              <CheckBox isCheck={currentItem.productType === "폰케이스"} />
              <div className="text-xs ml-2">{t("폰케이스")}</div>
            </div>
          </div>
          <p className="mx-8 bg-black  height-1" />
          <div className="mx-8 mb-4 h-32">
            <textarea
              onChange={(e) => setCurrentItem({ ...currentItem, contents: e.target.value })}
              type="text"
              className="w-full px-2 py-2  outline-none h-full border-black border-1"
              placeholder={t("내용")}
              cols="80"
              rows="5"
              defaultValue={currentItem.contents}
            />
            {/* <p className="w-full bg-black  height-1" /> */}
          </div>
          <SRLWrapper>
            <div className="mt-3 mx-8 flex-row-left">
              <div className="w-20 font-bold">상세이미지</div>
              <MultiImages images={currentItem.detailImage} />
            </div>
          </SRLWrapper>
          <div className="mx-8 flex items-center justify-between">
            <button
              className="w-full text-sm bg-black text-white font-bold mt-8 py-2 px-4"
              onClick={() => handlerCancelClick()}
            >
              {t("취소하기")}
            </button>
          </div>
        </div>
        {/* 상태 표시 */}
        <div className="w-96 bg-white">
          <div className="pt-8 mx-8 flex flex-row items-center justify-around mb-2">
            <p className="text-bold-15  text-center ">{t("답변")}</p>
          </div>

          <div className="mx-8 flex flex-col  flex-wrap">
            <div className="flex flex-row items-center mt-2">
              <CheckBox isCheck={currentItem.replyAt && currentItem.replyAt !== ""} />
              <div className="text-xs ml-2">{t("답변완료")}</div>
              {currentItem.replyAt && currentItem.replyAt !== "" ? (
                <Moment className="text-xs ml-2" unix format="YYYY.MM.DD">
                  {Number(currentItem.replyAt) / 1000}
                </Moment>
              ) : (
                ""
              )}
            </div>
            <p className="mx-0 mt-2 bg-black  height-1" />
          </div>
          <div className="mx-8 mb-4 h-32">
            <textarea
              type="text"
              className="w-full px-2 py-2  outline-none h-full border-black border-1"
              placeholder={t("내용")}
              cols="80"
              rows="5"
              defaultValue={currentItem.processMemo}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SpecialOrderDetail = ({ match }) => {
  console.log("match.params.id: ", match.params.id);
  const specialOrderId = match.params.id;
  console.log("specialOrderId: ", specialOrderId);
  const setting = useSettings();

  // const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <SpecialOrdersAdmin specialOrderId={specialOrderId} />
    ) : (
      <SpecialOrders specialOrderId={specialOrderId} />
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default SpecialOrderDetail;
