import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import { useQuery, useMutation } from "@apollo/client";

import swal from "@sweetalert/with-react";
import MyPage from "./MyPage";
import {
  PRODUCT,
  UPDATE_PRODUCT,
  MULTI_UPLOAD_FILES,
} from "../../graphql/gql/select";
import CheckBox from "../../components/CheckBox";
import SingleImage from "../../components/SingleImage";
import MultiImages from "../../components/MultiImages";

const ScreenAdmin = ({ id }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const memberId = setting.id;
  const history = useHistory();

  console.log(`memberId : ${memberId}`);
  console.log("product id: ", id);

  const [currentItem, setCurrentItem] = useState({
    id,
    name: "",
    price: "",
    priceDollar: "",
    displayPrice: "",
    displayDollar: "",
    comment: "",
    brand: "",
    manufacturer: "",
    model: "",
    option: "",
    mainImage: "",
    backImage: "",
    detailImage: "",
    category: "",
    keyword: "",
    colorOption: "",
    sizeOption: "",
    warning: "",
    warranty: "",
    wearingPic: "",
    blackLabel5Cm: "",
    blackLabel10Cm: "",
    whiteLabel5Cm: "",
    whiteLabel10Cm: "",
    isMain: false,
    visible: false,
  });

  const [updateProduct, { data: updatedData }] = useMutation(UPDATE_PRODUCT, {
    onError(error) {
      console.log("updateProduct: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (updatedData !== undefined) {
      const data1 = updatedData.updateProduct;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.allProductsUpdates = true;
        history.push("/mypage/all_products");
      }
    }
  }, [updatedData]);

  const updateProductHandler = ({
    id,
    name,
    price,
    priceDollar,
    displayPrice,
    displayDollar,
    comment,
    brand,
    manufacturer,
    model,
    option,
    mainImage,
    backImage,
    detailImage,
    category,
    keyword,
    colorOption,
    sizeOption,
    warning,
    warranty,
    wearingPic,
    isMain,
    blackLabel5Cm,
    blackLabel10Cm,
    whiteLabel5Cm,
    whiteLabel10Cm,
    visible,
  }) => {
    if (name && price && model) {
      const colorOptionList =
        !colorOption || colorOption === ""
          ? []
          : colorOption.split(",").map((option) => option.trim());
      const sizeOptionList =
        !sizeOption || sizeOption === ""
          ? []
          : sizeOption.split(",").map((option) => option.trim());

      updateProduct({
        variables: {
          id,
          name,
          price,
          priceDollar,
          displayPrice,
          displayDollar,
          comment,
          brand,
          manufacturer,
          model,
          option,
          mainImage,
          backImage,
          detailImage,
          category,
          keyword,
          colorOption: JSON.stringify(colorOptionList),
          sizeOption: JSON.stringify(sizeOptionList),
          warning,
          warranty,
          wearingPic,
          isMain,
          blackLabel5Cm,
          blackLabel10Cm,
          whiteLabel5Cm,
          whiteLabel10Cm,
          visible,
        },
      });
    } else {
      swal("상품명, 모델명, 가격은 빈 값이 올 수 없습니다", {
        button: false,
        timer: 3000,
      });
    }
  };

  // 메인 이미지만 올리기
  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, mainImage: filenames[0] });
      }
      console.log(data1);
    }
  }, [multiData]);

  // back 이미지만 올리기
  const [multiUpload3, { data: multiData3 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData3 !== undefined) {
      const data1 = multiData3.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, backImage: filenames[0] });
      }
      console.log(data1);
    }
  }, [multiData3]);

  // 상세 이미지들 올리기
  const [multiUpload2, { data: multiData2 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData2 !== undefined) {
      const data1 = multiData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        // 기존 이미지가 있으면 합함
        let curImages = [];
        try {
          curImages = JSON.parse(currentItem.detailImage);
        } catch (e) {
          console.log(e);
          curImages = [];
        }
        // eslint-disable-next-line array-callback-return
        filenames.map((file1) => {
          curImages.push(file1);
        });
        setCurrentItem({
          ...currentItem,
          detailImage: JSON.stringify(curImages),
        });
      }
      console.log(data1);
    }
  }, [multiData2]);

  const [multiUpload4, { data: multiData4 }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (multiData4 !== undefined) {
      const data1 = multiData4.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        let curImages = [];
        try {
          curImages = JSON.parse(currentItem.detailImage);
        } catch (e) {
          console.log(e);
          curImages = [];
        }
        filenames.map((file1) => {
          curImages.push(file1);
        });
        setCurrentItem({
          ...currentItem,
          wearingPic: JSON.stringify(curImages),
        });
      }
    }
  }, [multiData4]);

  // 5cm black label upload
  const [blackLabelUpload1, { data: blackLabelData1 }] = useMutation(
    MULTI_UPLOAD_FILES,
    {
      onError(error) {
        console.log("MULTI_UPLOAD_FILES: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    if (blackLabelData1 !== undefined) {
      const data1 = blackLabelData1.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, blackLabel5Cm: filenames[0] });
      }
      console.log(data1);
    }
  }, [blackLabelData1]);

  // 10cm black label upload
  const [blackLabelUpload2, { data: blackLabelData2 }] = useMutation(
    MULTI_UPLOAD_FILES,
    {
      onError(error) {
        console.log("MULTI_UPLOAD_FILES: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    if (blackLabelData2 !== undefined) {
      const data1 = blackLabelData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, blackLabel10Cm: filenames[0] });
      }
      console.log(data1);
    }
  }, [blackLabelData2]);

  // 5cm white label upload
  const [whiteLabelUpload1, { data: whiteLabelData1 }] = useMutation(
    MULTI_UPLOAD_FILES,
    {
      onError(error) {
        console.log("MULTI_UPLOAD_FILES: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    if (whiteLabelData1 !== undefined) {
      const data1 = whiteLabelData1.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, whiteLabel5Cm: filenames[0] });
      }
      console.log(data1);
    }
  }, [whiteLabelData1]);

  // 10cm white label upload
  const [whiteLabelUpload2, { data: whiteLabelData2 }] = useMutation(
    MULTI_UPLOAD_FILES,
    {
      onError(error) {
        console.log("MULTI_UPLOAD_FILES: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    if (whiteLabelData2 !== undefined) {
      const data1 = whiteLabelData2.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        setCurrentItem({ ...currentItem, whiteLabel10Cm: filenames[0] });
      }
      console.log(data1);
    }
  }, [whiteLabelData2]);

  // 상품 정보 가져오가
  const { loading, error, data } = useQuery(PRODUCT, { variables: { id } });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);
  if (!data || !data.product) return <p>Error</p>;
  if (currentItem.name === "") {
    const colorOption = !data.product.colorOption
      ? ""
      : JSON.parse(data.product.colorOption).join(",");
    const sizeOption = !data.product.sizeOption
      ? ""
      : JSON.parse(data.product.sizeOption).join(",");

    setCurrentItem({ ...data.product, colorOption, sizeOption });
  }
  console.log("currentItem : ", JSON.stringify(currentItem));

  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{t("상품 수정하기")}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15 ">
            <div className="w-full bg-white">
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">카테고리</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, category: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="카테고리"
                  value={currentItem.category}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">모델</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, model: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="모델"
                  value={currentItem.model}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">상품명</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, name: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="상품명"
                  value={currentItem.name}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">가격</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, price: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="가격"
                  value={currentItem.price}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">가격($)</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      priceDollar: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="가격($)"
                  value={currentItem.priceDollar}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">표시 가격</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      displayPrice: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="표시 가격"
                  value={currentItem.displayPrice}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">표시 가격($)</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      displayDollar: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="표시 가격($)"
                  value={currentItem.displayDollar}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">상품 한줄 설명</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      comment: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="상품 한줄 설명"
                  value={currentItem.comment}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">컬러 옵션</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      colorOption: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="컬러 옵션 (콤마로 구분해 주세요)"
                  value={currentItem.colorOption}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">사이즈 옵션</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      sizeOption: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="사이즈 옵션 (콤마로 구분해 주세요)"
                  value={currentItem.sizeOption}
                />
              </div>

              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">브랜드</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, brand: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="브랜드"
                  value={currentItem.brand}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">제조국</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      manufacturer: e.target.value,
                    })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="제조국"
                  value={currentItem.manufacturer}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">취급시 주의사항</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, warning: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="취급시 주의사항"
                  value={currentItem.warning}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">품질보증기준</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, warranty: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="품질보증기준"
                  value={currentItem.warranty}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">상품보이기</div>
                <CheckBox
                  isCheck={currentItem.visible}
                  onClick={() =>
                    setCurrentItem({
                      ...currentItem,
                      visible: !currentItem.visible,
                    })
                  }
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">대표상품</div>
                <CheckBox
                  isCheck={currentItem.isMain}
                  onClick={() =>
                    setCurrentItem({
                      ...currentItem,
                      isMain: !currentItem.isMain,
                    })
                  }
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">대표이미지</div>
                <SingleImage image={currentItem.wearingPic} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload4({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">앞면이미지</div>
                <SingleImage image={currentItem.mainImage} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">뒷면이미지</div>
                <SingleImage image={currentItem.backImage} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mr-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload3({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">5cm black label</div>
                <SingleImage image={currentItem.blackLabel5Cm} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mr-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files)
                            blackLabelUpload1({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">10cm black label</div>
                <SingleImage image={currentItem.blackLabel10Cm} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mr-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files)
                            blackLabelUpload2({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">5cm white label</div>
                <SingleImage image={currentItem.whiteLabel5Cm} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mr-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files)
                            whiteLabelUpload1({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">10cm white label</div>
                <SingleImage image={currentItem.whiteLabel10Cm} />
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mr-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files)
                            whiteLabelUpload2({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">상세이미지</div>
                <MultiImages images={currentItem.detailImage} />

                <div className="ml-12 flex-row-left">
                  <div className="w-14 font-bold">첨부파일들</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form
                      onSubmit={() => {
                        console.log("Submitted");
                      }}
                      encType="multipart/form-data"
                    >
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image/*"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          if (files) multiUpload2({ variables: { files } });
                        }}
                      />
                    </form>
                  </div>
                </div>
                <button
                  onClick={() =>
                    setCurrentItem({
                      ...currentItem,
                      detailImage: "",
                    })
                  }
                  className="ml-2 border"
                >
                  사진 초기화
                </button>
              </div>
              <div className="mt-4 flex-col-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <div className="absolute right-0 w-56 h-8 ml-4 flex flex-row">
                    <button
                      className="w-24 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4"
                      onClick={() => history.replace("/mypage/all_products")}
                    >
                      {t("취소")}
                    </button>
                    <button
                      className="w-24 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        console.log("currentItem : ", currentItem);
                        updateProductHandler(currentItem);
                      }}
                    >
                      {t("등록하기")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditProduct = ({ match }) => {
  const setting = useSettings();
  console.log("match.params.id: ", match.params.id);
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin id={match.params.id} />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default EditProduct;
