import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UPDATE_MEMBER, MEMBER } from "graphql/gql/select";
import swal from "@sweetalert/with-react";
import { useMutation, useQuery } from "@apollo/client";
import useSettings from "stores/settings";
import DatePicker from "react-datepicker";
import DaumPostcode from "react-daum-postcode";
import firebase from "firebase/app";
import MyPage from "./MyPage";
import facebook from "../../assets/images/img_facebook.svg";
import google from "../../assets/images/img_google.svg";
import CheckBox from "../../components/CheckBox";
import { MULTI_UPLOAD_FILES, DELETE_MEMBER } from "../../graphql/gql/select";
import "react-datepicker/dist/react-datepicker.css";
import "firebase/auth";
import initFirebase from "../../firebase";

initFirebase();

const auth = firebase.auth();

const Screen = () => {
  const setting = useSettings();
  const { t } = useTranslation();
  const history = useHistory();
  const { snsProvider } = setting;
  const isKorean = setting.lang === "ko";

  const [emailOpen, setEmailOpen] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  const [nameOpen, setNameOpen] = useState(false);
  const [nameValue, setNameValue] = useState("");

  const [birthOpen, setBirthOpen] = useState(false);
  const [birthDate, setBirthDate] = useState(null);

  const [phoneOpen, setPhoneOpen] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");

  const [sexOpen, setSexOpen] = useState(false);
  const [sexValue, setSexValue] = useState("");

  const [heightOpen, setHeightOpen] = useState(false);
  const [heightValue, setHeightValue] = useState("");

  const [weightOpen, setWeightOpen] = useState(false);
  const [weightValue, setWeightValue] = useState("");

  const [addressOpen, setAddressOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [zipValue, setZipValue] = useState("");
  const [addValue, setAddValue] = useState("");
  const [addValue2, setAddValue2] = useState("");

  const user = auth.currentUser;

  const btnClass = isKorean
    ? "w-26 px-3 py-1 absolute right-0 text-center border-black border-1 text-medium-kr-15 focus:outline-none"
    : "w-32 px-3 py-1 absolute right-0 text-center border-black border-1 text-medium-15 focus:outline-none";

  const [updateMember, { data: updateMemberData }] = useMutation(
    UPDATE_MEMBER,
    {
      onError(error) {
        console.log(error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  const [deleteMember, { data: deletedMemberData }] = useMutation(
    DELETE_MEMBER,
    {
      onError(error) {
        console.log("deleteMember: err=", error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  const getFormatDate = (date) => {
    const year = date.getFullYear();
    let month = 1 + date.getMonth();
    month = month >= 10 ? month : "0" + month;
    let day = date.getDate();
    day = day >= 10 ? day : "0" + day;
    return year + "." + month + "." + day;
  };

  useEffect(() => {
    if (updateMemberData !== undefined) {
      const data1 = updateMemberData.updateMember;
      if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        refetch();
      }
    }
  }, [updateMemberData]);

  useEffect(() => {
    if (deletedMemberData !== undefined) {
      user
        .delete()
        .then(() => {
          setting.isLogin = false;
          setting.email = "";
          setting.userName = "";
          setting.id = "";
          setting.token = "";
          setting.isSnsLogin = false;
          window.localStorage.setItem("token", "");
          history.push("/mypage/signin");
          swal("삭제되었습니다");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [deletedMemberData]);

  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });
  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        updateMember({ variables: { id: member.id, img: filenames[0] } });
      }
      console.log(data1);
    }
  }, [multiData]);

  useEffect(() => {
    if (phoneValue.length === 10) {
      setPhoneValue(phoneValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phoneValue.length === 13) {
      setPhoneValue(
        phoneValue
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phoneValue]);

  const { loading, error, data, refetch } = useQuery(MEMBER, {
    variables: { id: setting.id },
  });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);
  if (!data || !data.member) return <p>Error</p>;
  const { member } = data;

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhoneValue(e.target.value);
    }
  };

  const handlePostComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let zonecode = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, (${extraAddress})` : "";
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
      zonecode = data.zonecode;
      setZipValue(zonecode);
      setAddValue(fullAddress);
    }
    setModalShow(false);
  };

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">{t("로그인 정보")}</div>
      <div className="blackline2 mt-1" />
      <div className="table-text">
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("SNS 연결")}</div>
          <div className="flex flex-row">
            {snsProvider === "facebook" ? (
              <img
                src={facebook}
                alt=""
                className="w-10 h-10 object-cover object-center"
              />
            ) : snsProvider === "google" ? (
              <img
                src={google}
                alt=""
                className="ml-2 w-10 h-10 object-cover object-center"
              />
            ) : (
              "E-mail"
            )}
          </div>
        </div>
      </div>
      {/* 개인 정보 */}
      <div className="mt-6 content-title">{t("개인정보")}</div>
      <div className="blackline2 mt-1" />
      <div className="table-text">
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("사진")}</div>
          <div className="flex flex-col">
            <div className="w-16 h-16 rounded-full bg-black ">
              {member.img ? (
                <img
                  src={member.img}
                  alt=""
                  className="w-full h-full rounded-full object-cover object-center"
                />
              ) : (
                <div className="w-full h-full rounded-full object-cover object-center" />
              )}
            </div>
            <div className="mt-2">
              {t("회원님을 알릴 수 있는 사진을 등록해주세요")}
            </div>
            <div className="">
              {t("등록된 사진은 회원님의 게시물 또는 댓글에 사용됩니다")}
            </div>
          </div>
          <form
            className="hidden"
            onSubmit={() => {
              console.log("Submitted");
            }}
            encType="multipart/form-data"
          >
            <input
              id="document"
              name="document"
              type="file"
              accept="image/*"
              method="POST"
              onChange={async ({ target: { files } }) => {
                console.log(files);
                if (files) multiUpload({ variables: { files } });
              }}
            />
          </form>
          <button
            className={btnClass}
            onClick={() => {
              document.getElementById("document").click();
            }}
          >
            {t("사진 변경")}
          </button>
        </div>
        {emailOpen === true ? (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("이메일")}</div>
            <input
              className="foucs:outline-none"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              placeholder={t("메일을 입력하세요")}
            />
            <button onClick={() => setEmailOpen(false)} className={btnClass}>
              {t("이메일 변경")}
            </button>
          </div>
        ) : (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("이메일")}</div>
            <div className="">{member.email}</div>
            <button onClick={() => setEmailOpen(true)} className={btnClass}>
              {t("이메일 변경")}
            </button>
          </div>
        )}
        {snsProvider === "google" || snsProvider === "facebook" ? (
          ""
        ) : (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("비밀번호")}</div>
            <div className="">**********</div>
            <button className={btnClass}>{t("비밀번호 변경")}</button>
          </div>
        )}
        {nameOpen === true ? (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("이름")}</div>
            <input
              className="focus:outline-none"
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
              placeholder={t("이름을 입력하세요")}
            />
            <button
              className={btnClass}
              onClick={() => {
                updateMember({
                  variables: { id: member.id, name: nameValue },
                });
                setNameValue("");
                setNameOpen(false);
              }}
            >
              {t("저장")}
            </button>
          </div>
        ) : (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("이름")}</div>
            <div className="">{member.name}</div>
            <button
              className={btnClass}
              onClick={() => {
                setNameOpen(true);
              }}
            >
              {t("이름 수정")}
            </button>
          </div>
        )}
        {phoneOpen === true ? (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("프로필연락처")}</div>
            <input
              type="text"
              placeholder={t("연락처를 입력하세요")}
              value={phoneValue}
              onChange={handleChange}
            />
            <button
              onClick={() => {
                updateMember({
                  variables: { id: member.id, phone: phoneValue },
                });
                setPhoneOpen(false);
              }}
              className={btnClass}
            >
              {t("저장")}
            </button>
          </div>
        ) : (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("프로필연락처")}</div>
            <div className="">{member.phone}</div>
            <button
              onClick={() => {
                setPhoneOpen(true);
              }}
              className={btnClass}
            >
              {t("연락처 수정")}
            </button>
          </div>
        )}
      </div>
      {birthOpen === true ? (
        <div className="mt-4 flex flex-row relative">
          <div className="w-20 fontsize-12">{t("생일")}</div>
          <DatePicker
            className="fontsize-12 w-56"
            selected={birthDate}
            onChange={(date) => setBirthDate(date)}
            dateFormat="yyyy.mm.dd"
            placeholderText={t("여기를 눌러 변경하세요")}
          />
          <button
            className={btnClass}
            onClick={() => {
              if (birthDate && birthDate !== "") {
                updateMember({
                  variables: {
                    id: member.id,
                    birthDay: getFormatDate(birthDate),
                  },
                });
              }
              setBirthOpen(false);
            }}
          >
            {t("생일 저장")}
          </button>
        </div>
      ) : (
        <div className="fontsize-12 mt-4 flex flex-row relative">
          <div className="w-20">{t("생일")}</div>
          <div className="">{member.birthDay}</div>
          <button
            className={btnClass}
            onClick={() => {
              setBirthOpen(true);
            }}
          >
            {t("생일 수정")}
          </button>
        </div>
      )}

      {/*  추가 회원정보 */}
      <div className="mt-6 content-title">{t("추가 회원정보")}</div>
      <div className="blackline2 mt-1" />
      <div className="table-text">
        {sexOpen === true ? (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("성별")}</div>
            <CheckBox
              isCheck={sexValue === "Male" ? true : false}
              onClick={() => {
                setSexValue("Male");
              }}
            />
            <div className="ml-2 mr-4">Male</div>
            <CheckBox
              isCheck={sexValue === "Female" ? true : false}
              onClick={() => {
                setSexValue("Female");
              }}
            />
            <div className="ml-2 mr-4">Female</div>
            <button
              className={btnClass}
              onClick={() => {
                updateMember({
                  variables: { id: member.id, SEX: sexValue },
                });
                setSexOpen(false);
              }}
            >
              {t("저장")}
            </button>
          </div>
        ) : (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("성별")}</div>
            <div className="">{member.SEX}</div>
            <button
              className={btnClass}
              onClick={() => {
                setSexValue(member.SEX);
                setSexOpen(true);
              }}
            >
              {t("수정")}
            </button>
          </div>
        )}
        {heightOpen === true ? (
          <div className="fontsize-12 mt-4 flex flex-row relative">
            <div className="w-20">{t("키")}</div>
            <input
              className="focus:outline-none"
              value={heightValue}
              onChange={(e) => setHeightValue(e.target.value)}
              placeholder={t("키를 입력하세요")}
            />
            <button
              className={btnClass}
              onClick={() => {
                updateMember({
                  variables: { id: member.id, height: heightValue },
                });
                setHeightValue("");
                setHeightOpen(false);
              }}
            >
              {t("저장")}
            </button>
          </div>
        ) : (
          <div className="fontsize-12 mt-4 flex flex-row relative">
            <div className="w-20">{t("키")}</div>
            <div className="">{member.height}</div>
            <button
              className={btnClass}
              onClick={() => {
                setHeightOpen(true);
              }}
            >
              {t("수정")}
            </button>
          </div>
        )}

        {weightOpen === true ? (
          <div className="fontsize-12 mt-4 flex flex-row relative">
            <div className="w-20">{t("몸무게")}</div>
            <input
              className="focus:outline-none"
              value={weightValue}
              onChange={(e) => setWeightValue(e.target.value)}
              placeholder={t("몸무게를 입력하세요")}
            />
            <button
              className={btnClass}
              onClick={() => {
                updateMember({
                  variables: { id: member.id, weight: weightValue },
                });
                setWeightValue("");
                setWeightOpen(false);
              }}
            >
              {t("저장")}
            </button>
          </div>
        ) : (
          <div className="fontsize-12 mt-4 flex flex-row relative">
            <div className="w-20">{t("몸무게")}</div>
            <div className="">{member.weight}</div>
            <button
              className={btnClass}
              onClick={() => {
                setWeightOpen(true);
              }}
            >
              {t("수정")}
            </button>
          </div>
        )}

        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("주소")}</div>
          <div className="">{`${member.zip || ""}  ${member.address || ""} ${
            member.address2
          }`}</div>
          {/* TODO 배송지 주소 화면 필요 */}
          <button
            className={btnClass}
            onClick={() => {
              setAddressOpen(true);
            }}
          >
            {t("배송지 관리")}
          </button>
        </div>
        {addressOpen && (
          <div>
            {zipValue && (
              <div>
                <div className="mt-4 flex flex-row relative">
                  <div className="w-20" />
                  <div className="w-20">zipValue</div>
                  <div className="w-20">{zipValue}</div>
                  <button
                    className={btnClass}
                    onClick={() => {
                      updateMember({
                        variables: {
                          id: member.id,
                          zip: zipValue,
                          address: addValue,
                          address2: addValue2,
                        },
                      });
                      setAddressOpen(false);
                    }}
                  >
                    {t("저장")}
                  </button>
                </div>
                <div className="mt-4 flex flex-row relative">
                  <div className="w-20" />
                  <div className="w-20">addValue</div>
                  <div className="w-62">{addValue}</div>
                </div>
                <div className="mt-4 flex flex-row relative">
                  <div className="w-20" />
                  <div className="w-20">addValue2</div>
                  <input
                    className="w-62"
                    value={addValue2}
                    onChange={(e) => {
                      setAddValue2(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="mt-4 flex flex-row relative">
              <DaumPostcode onComplete={handlePostComplete} />
            </div>
          </div>
        )}
      </div>

      {/* 수신 설정 */}
      <div className="mt-6 content-title">{t("수신 설정")}</div>
      <div className="blackline2 mt-2" />
      <div className="table-text">
        <div className="mt-4">{t("쇼핑이벤트혜택")}</div>
        <div className="ml-2 mt-2 flex flex-row flex-wrap">
          <div className="flex flex-row items-center mr-8">
            <CheckBox
              isCheck={member.receiveSMS}
              onClick={() =>
                updateMember({
                  variables: { id: member.id, receiveSMS: !member.receiveSMS },
                })
              }
            />
            <div className="ml-2">{t("문자메시지")}</div>
          </div>
          <div className="flex flex-row items-center">
            <CheckBox
              isCheck={member.receiveEmail}
              onClick={() =>
                updateMember({
                  variables: {
                    id: member.id,
                    receiveEmail: !member.receiveEmail,
                  },
                })
              }
            />
            <div className="ml-2">{t("이메일수신")}</div>
          </div>
        </div>
      </div>

      {/* 회원 탈퇴 */}
      <div className="mt-16 ">
        <div
          className="w-20 table-text underline"
          onClick={() => {
            // TODO 주문배송중인 물건이 있는지 여부 확인 필요

            const buttons = {
              cancel: true,
              YES: true,
            };
            swal({
              text: "탈퇴하시겠습니까? 탈퇴 시 모든 정보가 지워집니다",
              buttons,
            }).then((value) => {
              if (value && value === "YES") {
                deleteMember({
                  variables: { id: member.id },
                });
              }
            });
          }}
        >
          {t("회원탈퇴하기")}
        </div>
      </div>
    </div>
  );
};

const ScreenMobile = () => {
  const setting = useSettings();
  const { t } = useTranslation();
  const { snsProvider } = setting;
  const isKorean = setting.lang === "ko";

  const btnClass = isKorean
    ? "w-16 px-0 py-1 absolute right-0 text-center border-black border-1 text-medium-kr-15"
    : "w-16 px-0 py-1 absolute right-0 text-center border-black border-1 text-medium-15";

  const btnPhotoClass = isKorean
    ? "mt-2 w-16 px-0 py-1 text-center border-black border-1 text-medium-kr-15"
    : "mt-2 w-32 px-0 py-1 text-center border-black border-1 text-medium-15";
  const btnAddressClass = isKorean
    ? "mt-2 w-16 ml-20 px-0 py-1 text-center border-black border-1 text-medium-kr-15"
    : "mt-2 w-16 ml-20 px-0 py-1 text-center border-black border-1 text-medium-15";

  const [updateMember, { data: updateMemberData }] = useMutation(
    UPDATE_MEMBER,
    {
      onError(error) {
        console.log(error);
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  useEffect(() => {
    if (updateMemberData !== undefined) {
      const data1 = updateMemberData.updateMember;
      if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        refetch();
      }
    }
  }, [updateMemberData]);

  const [multiUpload, { data: multiData }] = useMutation(MULTI_UPLOAD_FILES, {
    onError(error) {
      console.log("MULTI_UPLOAD_FILES: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });
  useEffect(() => {
    if (multiData !== undefined) {
      const data1 = multiData.multiUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        console.log(data1.length, JSON.stringify(data1));
        const filenames = data1.map((file) => file.filename);
        updateMember({ variables: { id: member.id, img: filenames[0] } });
      }
      console.log(data1);
    }
  }, [multiData]);

  const { loading, error, data, refetch } = useQuery(MEMBER, {
    variables: { id: setting.id },
  });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);
  if (!data || !data.member) return <p>Error</p>;
  const { member } = data;

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title" />
      <div className="blackline2 mt-1" />
      <div className="mt-4 content-title">{t("로그인 정보")}</div>
      <div className="grayline mt-1" />
      <div className="table-text">
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("SNS 연결")}</div>
          <div className="flex flex-row">
            {snsProvider === "facebook" ? (
              <img
                src={facebook}
                alt=""
                className="w-10 h-10 object-cover object-center"
              />
            ) : snsProvider === "google" ? (
              <img
                src={google}
                alt=""
                className="ml-2 w-10 h-10 object-cover object-center"
              />
            ) : (
              "E-mail"
            )}
          </div>
        </div>
      </div>
      {/* 개인 정보 */}
      <div className="blackline2 mt-10" />
      <div className="mt-6 content-title">{t("개인정보")}</div>
      <div className="grayline mt-1" />

      <div className="table-text">
        <div className="mt-4 flex flex-row">
          <div className="w-20">{t("사진")}</div>
          <div className="flex-col-left">
            <div className="w-20 h-20 rounded-full bg-black">
              <img
                src={member.img}
                alt=""
                className="w-full h-full rounded-full object-cover object-center"
              />
            </div>
            <form
              className="hidden"
              onSubmit={() => {
                console.log("Submitted");
              }}
              encType="multipart/form-data"
            >
              <input
                id="document"
                name="document"
                type="file"
                accept="image/*"
                method="POST"
                onChange={async ({ target: { files } }) => {
                  console.log(files);
                  if (files) multiUpload({ variables: { files } });
                }}
              />
            </form>
            <div
              className={btnPhotoClass}
              onClick={() => {
                document.getElementById("document").click();
              }}
            >
              {t("사진 변경")}
            </div>
            <div className="mt-2">
              {t("회원님을 알릴 수 있는 사진을 등록해주세요")}
            </div>
            <div className="">
              {t("등록된 사진은 회원님의 게시물 또는 댓글에 사용됩니다")}
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("이메일")}</div>
          <div className="">{member.email}</div>
        </div>
        {snsProvider === "google" || snsProvider === "facebook" ? (
          ""
        ) : (
          <div className="mt-4 flex flex-row relative">
            <div className="w-20">{t("비밀번호")}</div>
            <div className="">**********</div>
            <div className={btnClass}>{t("비밀번호 변경")}</div>
          </div>
        )}
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("이름")}</div>
          <div className="">{member.name}</div>
          <div
            className={btnClass}
            onClick={() => {
              swal({
                text: "이름을 입력하세요",
                content: {
                  element: "input",
                  attributes: {
                    type: "text",
                  },
                },
              }).then((value) => {
                if (value && value !== "") {
                  updateMember({ variables: { id: member.id, name: value } });
                }
              });
            }}
          >
            {t("이름 수정")}
          </div>
        </div>
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("프로필연락처")}</div>
          <div className="">{member.phone}</div>
          <div
            className={btnClass}
            onClick={() => {
              swal({
                text: "연락처를 입력하세요",
                content: {
                  element: "input",
                  attributes: {
                    type: "text",
                  },
                },
              }).then((value) => {
                console.log(value);
                if (value && value !== "") {
                  updateMember({ variables: { id: member.id, phone: value } });
                }
              });
            }}
          >
            {t("연락처 수정")}
          </div>
        </div>
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("생일")}</div>
          <div className="">{member.birthDay}</div>
          <div
            className={btnClass}
            onClick={() => {
              swal({
                text: "생일을 입력하세요",
                content: {
                  element: "input",
                  attributes: {
                    type: "text",
                  },
                },
              }).then((value) => {
                if (value && value !== "") {
                  updateMember({
                    variables: { id: member.id, birthDay: value },
                  });
                }
              });
            }}
          >
            {t("생일 수정")}
          </div>
        </div>
      </div>

      {/*  추가 회원정보 */}
      <div className="blackline2 mt-10" />
      <div className="mt-6 content-title">{t("추가 회원정보")}</div>
      <div className="grayline mt-1" />
      <div className="table-text">
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("성별")}</div>
          <div className="">{member.SEX}</div>
          <div
            className={btnClass}
            onClick={() => {
              swal({
                text: "성별을 선택해 주세요",
                buttons: {
                  cancel: true,
                  Female: true,
                  Male: true,
                },
              }).then((value) => {
                if (value && value !== "") {
                  updateMember({ variables: { id: member.id, SEX: value } });
                }
              });
            }}
          >
            {t("수정")}
          </div>
        </div>
        <div className="mt-4 flex flex-row relative">
          <div className="w-20">{t("키/몸무게")}</div>
          <div className="">
            {`${member.height || ""} / ${member.weight || ""}`}
          </div>
          <div
            className={btnClass}
            onClick={() => {
              let height;
              swal({
                text: "키를 입력하세요",
                content: {
                  element: "input",
                  attributes: {
                    type: "text",
                  },
                },
              })
                .then((value) => {
                  if (value && value !== "") {
                    height = value;
                  }
                })
                .then(() => {
                  swal({
                    text: "몸무게를 입력하세요",
                    content: {
                      element: "input",
                      attributes: {
                        type: "text",
                      },
                    },
                  }).then((value) => {
                    if (value && value !== "") {
                      updateMember({
                        variables: { id: member.id, height, weight: value },
                      });
                    }
                  });
                });
            }}
          >
            {t("수정")}
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="flex flex-row">
            <div className="w-20">{t("주소")}</div>
            <div className="px-4">
              {`${member.zip || ""}  ${member.address || ""}`}
            </div>
          </div>
          {/* TODO 배송지 주소 화면 필요 */}
          <div className={btnAddressClass}>{t("배송지 관리")}</div>
        </div>
      </div>

      {/* 수신 설정 */}
      <div className="mt-6 content-title">{t("수신 설정")}</div>
      <div className="blackline2 mt-2" />
      <div className="table-text">
        <div className="mt-4">{t("쇼핑이벤트혜택")}</div>
        <div className="ml-2 mt-2 flex flex-row flex-wrap">
          <div className="flex flex-row items-center mr-8">
            <CheckBox
              isCheck={member.receiveSMS}
              onClick={() =>
                updateMember({
                  variables: { id: member.id, receiveSMS: !member.receiveSMS },
                })
              }
            />
            <div className="ml-2">{t("문자메시지")}</div>
          </div>
          <div className="flex flex-row items-center">
            <CheckBox
              isCheck={member.receiveEmail}
              onClick={() =>
                updateMember({
                  variables: {
                    id: member.id,
                    receiveEmail: !member.receiveEmail,
                  },
                })
              }
            />
            <div className="ml-2">{t("이메일수신")}</div>
          </div>
        </div>
      </div>

      {/* 회원 탈퇴 */}
      <div className="mt-16 mb-4">
        <div
          className="w-20 table-text underline"
          onClick={() => {
            // TODO 주문배송중인 물건이 있는지 여부 확인 필요

            const buttons = {
              cancel: true,
              YES: true,
            };
            swal({
              text: "탈퇴하시겠습니까? 탈퇴 시 모든 정보가 지워집니다",
              buttons,
            }).then((value) => {
              if (value && value === "YES") {
                updateMember({
                  variables: { id: member.id, withdrawal: true },
                });
              }
            });
          }}
        >
          {t("회원탈퇴하기")}
        </div>
      </div>
    </div>
  );
};

const MyProfile = () => {
  const setting = useSettings();
  const history = useHistory();
  // 로그인 안한 경우
  // swal(t("로그인을 먼저 해주세요"), { button: false, timer: 3000 });
  if (!setting.isLogin) history.push("/mypage/signin");
  setting.headerTitle = "My Page";

  const screen = setting.isMobile ? (
    <ScreenMobile />
  ) : (
    <MyPage>
      <Screen />
    </MyPage>
  );
  return <div>{screen}</div>;
};

export default MyProfile;
