/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { useTranslation } from "react-i18next";

const ReviewHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-row-center table-header ">
      <div className="w-1/12 text-center">{t("주문일")}</div>
      <div className="w-4/12 ">{t("주문내역")}</div>
      <div className="w-1/12 text-center">{t("별점")}</div>
      <div className="w-2/12 text-center">{t("이미지")}</div>
      <div className="w-2/12 text-center">{t("비디오")}</div>
      <div className="w-2/12 text-center">{t("내용")}</div>
    </div>
  );
};

export default ReviewHeader;
