import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import swal from "@sweetalert/with-react";
import currencyFormatter from "currency-formatter";
import { CREATE_ORDER } from "graphql/gql/select";
import useSettings from "stores/settings";
import Moment from "react-moment";
import "./react-tabs.css";
import icon_rect_checked from "assets/images/icon_rect_checked.svg";
import { PRODUCT } from "../../graphql/gql/select";
import CheckBox from "../../components/CheckBox";

const AddressDomestic = ({ currentItem, currentOrder, setCurrentOrder }) => {
  const { t } = useTranslation();

  console.log("AddressDomestic, currentItem :", currentItem, currentOrder);

  return (
    <div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("수령인")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverName: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverName}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("연락처")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverPhone1: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverPhone1}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("추가 연락처")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverPhone2: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverPhone2}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("주소")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverZip: e.target.value })
          }
          type="text"
          className="w-40 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverZip}
        />
        <button className="w-24 h-8  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1">
          {t("우편번호찾기")}
        </button>
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 " />
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverAddr1: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverAddr1}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 " />
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverAddr2: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          placeholder="상세 주소 입력"
          value={currentOrder.receiverAddr2}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("배송메모")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, memo: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.memo}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 " />
        <div className="mt-1 flex flex-row items-center ">
          <img src={icon_rect_checked} className="w-4 h-4" alt="selected" />
          <div className="ml-1  font-mont fontsize-12 tracking-tight ">
            {t("기본 배송지로 등록")}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressOverseas = ({ currentItem, currentOrder, setCurrentOrder }) => {
  const { t } = useTranslation();
  console.log("AddressOverseas, currentItem :", currentItem, currentOrder);

  return (
    <div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("수령인")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverName: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverName}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("주소1")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverAddr1: e.target.value })
          }
          type="text"
          className="flex-1 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverAddr1}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("주소2")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverAddr2: e.target.value })
          }
          type="text"
          className="flex-1 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverAddr2}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("도시")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, city: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.city}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("주명")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, state: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.state}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("우편번호찾기")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, receiverZip: e.target.value })
          }
          type="text"
          className="w-64 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.receiverZip}
        />
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 ">{t("국가명")}</div>
        <input
          onChange={(e) =>
            setCurrentOrder({ ...currentOrder, country: e.target.value })
          }
          type="text"
          className="w-40 h-8 px-2 outline-none border-gray border-1"
          value={currentOrder.country}
        />
        <button className="w-24 h-8  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1">
          {t("FIND")}
        </button>
      </div>
      <div className="mt-2 flex-row-left">
        <div className="w-22 " />
        <div className="mt-1 flex flex-row items-center ">
          <img src={icon_rect_checked} className="w-4 h-4" alt="selected" />
          <div className="ml-1  font-mont fontsize-12 tracking-tight ">
            {t("기본 배송지로 등록")}
          </div>
        </div>
      </div>
    </div>
  );
};

const OrdersDomestic = ({ currentItem, currentOrder, setCurrentOrder }) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const [agreeBuy, setAgreeBuy] = useState(false);
  const [isBuy, setBuy] = useState(setting.isBuy);

  console.log("OrdersDomestic currentItem :", currentItem, currentOrder);

  const [order, { data }] = useMutation(CREATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.createOrder;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setBuy(true);
        setCurrentOrder({ ...currentOrder, id: data1.id });
      }
    }
  }, [data]);

  const handlerOrderClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    const {
      memberId,
      productId,
      status,
      process1,
      process2,
      process3,
      process4,
      process5,
      processMemo,
      name,
      phone,
      email,
      receiveType,
      receiverName,
      receiverPhone1,
      receiverPhone2,
      receiverZip,
      receiverAddr1,
      receiverAddr2,
      city,
      state,
      country,
      memo,
      paymentType,
      productSize,
      productCount,
      productPrice,
      productTotal,
      currency,
      invoiceNumber,
      invoiceCompany,
      invoicePhone,

      reviewStar,
      reviewImage,
      reviewVideo,
      reviewText,
    } = currentOrder;
    order({
      variables: {
        memberId,
        productId,
        status,
        process1,
        process2,
        process3,
        process4,
        process5,
        processMemo,
        name,
        phone,
        email,
        receiveType,
        receiverName,
        receiverPhone1,
        receiverPhone2,
        receiverZip,
        receiverAddr1,
        receiverAddr2,
        city,
        state,
        country,
        memo,
        paymentType,
        productSize,
        productCount,
        productPrice,
        productTotal,
        currency,
        invoiceNumber,
        invoiceCompany,
        invoicePhone,

        reviewStar,
        reviewImage,
        reviewVideo,
        reviewText,
      },
    });
  };

  return (
    <div className="mx-auto mt-10 mx-32 px-32 text-normal-tight-13">
      <div className="w-full flex-row-top">
        {!isBuy ? (
          <pre className="mt-3 mb-1 pr-48 w-full text-right text-normal-tight-13">
            {"01 CART      >      "} <span className="font-bold">02 ORDER</span>{" "}
            {"     >      03 ORDER CONFIRMED"}
          </pre>
        ) : (
          <pre className="mt-3 mb-1 pr-48 w-full text-right text-normal-tight-13">
            {"01 CART      >      02 ORDER      >      "}{" "}
            <span className="font-bold">03 ORDER CONFIRMED</span>
          </pre>
        )}
        {/* 주문정보 */}
        <div className="w-2/5 pr-10 ">
          <div className="h-full ml-10 px-3 py-3 border-black border-2">
            <div className="font-mont-bold fontsize-15 tracking-tight">
              {t("주문상품정보")}
            </div>
            <div className="blackline2 mt-1" />
            <div className="flex-row-left mt-3 ">
              <img src={currentItem.mainImage} className="w-24 h-24 " alt="" />
              <div className="ml-3 flex flex-col items-start justify-start">
                <div className="font-mont-bold fontsize-15 tracking-tight">
                  {currentItem.name}
                </div>
                <Moment unix format="YYYY.MM.DD HH:mm">
                  {Date.now() / 1000}
                </Moment>
                <div>
                  {t("디자인")} | {t("없음")}
                </div>
                <div>{t("주문사이즈")} | L(95)</div>
                <div className="mt-3 flex-1 flex justify-end ">
                  <div className="w-16 ml-1 border border-black text-center text-black py-1 px-1 ">
                    {t("색상 추가")}
                  </div>
                  <div className="w-16 ml-1 border border-black text-center text-black py-1 px-1 ">
                    {t("수량 변경")}
                  </div>
                  <div className="w-16 ml-1 bg-black text-center text-white py-1 px-1 ">
                    {t("삭제")}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 flex-row-left">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("제작 요청 사항")}
              </div>
              <input
                onChange={(e) =>
                  setCurrentOrder({
                    ...currentOrder,
                    processMemo: e.target.value,
                  })
                }
                className="mt-1 w-full h-16 border-gray border-1"
                placeholder="상품 제작 요청사힝이 있으면 작성해주세요. 자수 작업은 디자인에 따라 가능 여부가 달라지며 추가 비용이 발생합니다."
                style={{ paddingTop: "0", paddingBottom: "0" }}
                value={currentOrder.processMemo}
              />
            </div>
            <div className="mt-2 grayline" />
            <div className="mt-3 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("가격")}
              </div>
              <div>
                {currencyFormatter.format(Number(currentOrder.productPrice), {
                  code: "KRW",
                })}
              </div>
            </div>
            <div className="mt-1 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("갯수")}
              </div>
              <div>{currentOrder.productCount}</div>
            </div>
            <div className="mt-1 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("배송비")}
              </div>
              <div>0</div>
            </div>
            <div className="mt-3 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("총 결제금액")}
              </div>
              <div className="font-bold" style={{ fontSize: "20px" }}>
                <div>
                  {currencyFormatter.format(Number(currentOrder.productTotal), {
                    code: "KRW",
                  })}
                </div>
              </div>
            </div>
            <div className="mt-2 grayline" />
            {!isBuy ? (
              <div className="mt-3 flex flex-row items-center mb-2  ">
                <CheckBox
                  isCheck={agreeBuy}
                  onClick={() => setAgreeBuy(!agreeBuy)}
                />
                <div className="ml-1 text-normal-13">{t("약관 동의")}</div>
                <div
                  className="ml-1 underline text-normal-13"
                  onClick={() => alert("약관보기")}
                >
                  {t("보기")}
                </div>
              </div>
            ) : (
              ""
            )}

            {!isBuy ? (
              <div
                className="mt-3 btn-big-text"
                onClick={() => {
                  if (agreeBuy) {
                    handlerOrderClick();
                  } else
                    swal(t("약관 동의를 먼저 해주세요"), {
                      button: false,
                      timer: 3000,
                    });
                }}
              >
                {t("결제하기")}
              </div>
            ) : (
              <div
                className="mt-3 btn-big-text"
                onClick={() => alert("주문배송조회")}
              >
                {t("주문 배송 조회")}
              </div>
            )}
          </div>
        </div>
        {/* 결제 정보 */}
        <div className="w-3/5 ">
          {!isBuy ? (
            <OrderDomesticContent
              currentItem={currentItem}
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
            />
          ) : (
            <OrderDomesticConfirmed
              currentItem={currentItem}
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrdersOverseas = ({ currentItem, currentOrder, setCurrentOrder }) => {
  const { t } = useTranslation();
  const setting = useSettings();

  const [agreeBuy, setAgreeBuy] = useState(false);
  const [isBuy, setBuy] = useState(setting.isBuy);

  console.log("OrdersOverseas, currentItem :", currentItem, currentOrder);

  const [order, { data }] = useMutation(CREATE_ORDER, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.createOrder;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else if (!data1.id) {
        swal(t("저장 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setBuy(true);
        setCurrentOrder({ ...currentOrder, id: data1.id });
      }
    }
  }, [data]);

  const handlerOrderClick = () => {
    if (!setting.id) {
      swal(t("로그인을 한 후에 주문해주세요"), { button: false, timer: 3000 });
      return;
    }
    console.log(currentOrder);

    const {
      memberId,
      productId,
      status,
      process1,
      process2,
      process3,
      process4,
      process5,
      processMemo,
      name,
      phone,
      email,
      receiveType,
      receiverName,
      receiverPhone1,
      receiverPhone2,
      receiverZip,
      receiverAddr1,
      receiverAddr2,
      city,
      state,
      country,
      memo,
      paymentType,
      productSize,
      productCount,
      productPrice,
      productTotal,
      currency,
      invoiceNumber,
      invoiceCompany,
      invoicePhone,

      reviewStar,
      reviewImage,
      reviewVideo,
      reviewText,
    } = currentOrder;
    order({
      variables: {
        memberId,
        productId,
        status,
        process1,
        process2,
        process3,
        process4,
        process5,
        processMemo,
        name,
        phone,
        email,
        receiveType,
        receiverName,
        receiverPhone1,
        receiverPhone2,
        receiverZip,
        receiverAddr1,
        receiverAddr2,
        city,
        state,
        country,
        memo,
        paymentType,
        productSize,
        productCount,
        productPrice,
        productTotal,
        currency,
        invoiceNumber,
        invoiceCompany,
        invoicePhone,

        reviewStar,
        reviewImage,
        reviewVideo,
        reviewText,
      },
    });
  };

  return (
    <div className="mx-auto mt-10 mx-32 px-32 text-normal-tight-13">
      <div className="w-full flex-row-top">
        {!isBuy ? (
          <pre className="mt-3 mb-1 pr-48 w-full text-right text-normal-tight-13">
            {"01 CART      >      "} <span className="font-bold">02 ORDER</span>{" "}
            {"     >      03 ORDER CONFIRMED"}
          </pre>
        ) : (
          <pre className="mt-3 mb-1 pr-48 w-full text-right text-normal-tight-13">
            {"01 CART      >      02 ORDER      >      "}{" "}
            <span className="font-bold">03 ORDER CONFIRMED</span>
          </pre>
        )}
        {/* 주문정보 */}
        <div className="w-2/5 pr-0 ">
          <div className="h-full ml-10 px-3 py-3 border-black border-2">
            <div className="font-mont-bold fontsize-15 tracking-tight">
              {t("주문상품정보")}
            </div>
            <div className="blackline2 mt-1" />
            <div className="flex-row-left mt-3 ">
              <img src={currentItem.mainImage} className="w-24 h-24 " alt="" />
              <div className="ml-3 flex flex-col items-start justify-start">
                <div className="font-mont-bold fontsize-15 tracking-tight">
                  {currentItem.name}
                </div>
                <Moment unix format="YYYY.MM.DD HH:mm">
                  {Date.now() / 1000}
                </Moment>
                <div>
                  {t("디자인")} | {t("없음")}
                </div>
                <div>{t("주문사이즈")} | L(95)</div>
              </div>
            </div>
            <div className="mt-3 flex-row-left">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("제작 요청 사항")}
              </div>
              <input
                className="mt-1 w-full h-16 border-gray border-1"
                placeholder="상품 제작 요청사힝이 있으면 작성해주세요. 자수 작업은 디자인에 따라 가능 여부가 달라지며 추가 비용이 발생합니다."
                style={{ paddingTop: "0", paddingBottom: "0" }}
              />
            </div>
            <div className="mt-2 grayline" />
            <div className="mt-4 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("가격")}
              </div>
              <div>
                {currencyFormatter.format(Number(currentOrder.productPrice), {
                  code: "USD",
                })}
              </div>
            </div>
            <div className="mt-1 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("갯수")}
              </div>
              <div>{currentOrder.productCount}</div>
            </div>
            <div className="mt-1 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("배송비")}
              </div>
              <div>0</div>
            </div>
            <div className="mt-4 flex flex-row items-center justify-between">
              <div className=" font-mont fontsize-12 tracking-tight ">
                {t("총 결제금액")}
              </div>
              <div className="font-bold" style={{ fontSize: "20px" }}>
                <div>
                  {currencyFormatter.format(Number(currentOrder.productTotal), {
                    code: "USD",
                  })}
                </div>
              </div>
            </div>
            <div className="mt-2 grayline" />
            {!isBuy ? (
              <div className="mt-4 flex flex-row items-center mb-2  ">
                <CheckBox
                  isCheck={agreeBuy}
                  onClick={() => setAgreeBuy(!agreeBuy)}
                />
                <div className="ml-1 text-normal-13">{t("약관 동의")}</div>
                <div
                  className="ml-1 underline text-normal-13"
                  onClick={() => alert("약관보기")}
                >
                  {t("보기")}
                </div>
              </div>
            ) : (
              ""
            )}

            {!isBuy ? (
              <div
                className="mt-3 btn-big-text"
                onClick={() => {
                  if (agreeBuy) {
                    handlerOrderClick();
                  } else
                    swal(t("약관 동의를 먼저 해주세요"), {
                      button: false,
                      timer: 3000,
                    });
                }}
              >
                {t("결제하기")}
              </div>
            ) : (
              <div
                className="mt-3 btn-big-text"
                onClick={() => alert("주문배송조회")}
              >
                {t("주문 배송 조회")}
              </div>
            )}
          </div>
        </div>
        {/* 결제 정보 */}
        <div className="w-3/5 pl-5">
          {!isBuy ? (
            <OrderOverseaContent
              currentItem={currentItem}
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
            />
          ) : (
            <OrderOverseaConfirmed
              currentItem={currentItem}
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrderDomesticContent = ({
  currentItem,
  currentOrder,
  setCurrentOrder,
}) => {
  const { t } = useTranslation();
  const setting = useSettings();
  const [selectedOption, setSelectedOption] = useState(null);

  console.log("OrderDomesticContent, currentItem :", currentItem, currentOrder);

  const handleReceiveTypeChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
    setCurrentOrder({ ...currentOrder, receiveType: selectedOption.value });
  };
  const options = [
    { value: "택배배송", label: "택배배송" },
    { value: "퀵배송", label: "퀵배송" },
    { value: "방문수령", label: "방문수령" },
  ];

  const handleClickPayType = (paymentType) => {
    setCurrentOrder({ ...currentOrder, paymentType });
  };

  return (
    <div className="pr-48 w-full h-full ">
      <div>
        <div className="w-full blackline2" />
        <div className="mt-3 font-mont-bold fontsize-15 tracking-tight">
          {t("주문자 정보")}
        </div>
        <div className="mt-2 flex-row-left">
          <div className="w-22 ">{t("이름")}</div>
          <input
            type="text"
            className="w-64 h-8 px-2 outline-none border-gray border-1"
            placeholder={t("이름")}
            readOnly
            value={setting.userName}
          />
        </div>
        <div className="mt-2 flex-row-left">
          <div className="w-22 ">{t("연락처")}</div>
          <input
            type="text"
            className="w-64 h-8 px-2 outline-none border-gray border-1"
            value={setting.phone}
            readOnly
          />
        </div>
        <div className="mt-2 flex-row-left">
          <div className="w-22 ">{t("이메일")}</div>
          <div className="w-40 ">{setting.email}</div>
          <button className="w-24 h-8  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1">
            {t("이메일변경")}
          </button>
        </div>
        <div className="mt-2 flex-row-left">
          <div className="w-22 ">{t("수령방법")}</div>
          <Select
            className="w-64 h-8 px-0 outline-none text-normal-13"
            value={selectedOption}
            onChange={handleReceiveTypeChange}
            options={options}
            theme={{ borderRadius: 0, spacing: { baseUnit: 2 } }}
          />
        </div>
      </div>
      <div>
        <div className="mt-3 w-full blackline2" />
        <div className="mt-3 font-mont-bold fontsize-15 tracking-tight">
          {t("배송 정보")}
        </div>

        <div className="mt-3 relative">
          <div className="w-full">
            <Tabs>
              <TabList>
                <Tab>{t("기본 배송지")}</Tab>
                <Tab>{t("새로 입력")}</Tab>
              </TabList>
              <TabPanel>
                <AddressDomestic
                  currentItem={currentItem}
                  currentOrder={currentOrder}
                  setCurrentOrder={setCurrentOrder}
                />
              </TabPanel>
              <TabPanel>
                <AddressDomestic
                  currentItem={currentItem}
                  currentOrder={currentOrder}
                  setCurrentOrder={setCurrentOrder}
                />
              </TabPanel>
            </Tabs>
          </div>
          <div className="absolute right-0 top-0 mt-1 flex-row-center">
            <img src={icon_rect_checked} className="w-4 h-4" alt="selected" />
            <div className="ml-1 h-auto text-normal-12">
              {t("구매자 정보와 동일합니다")}
            </div>
          </div>
        </div>
      </div>
      {/* 결제 수단 */}
      <div>
        <div className="mt-4 w-full blackline2" />
        <div className="mt-3 font-mont-bold fontsize-15 tracking-tight">
          {t("결제 수단")}
        </div>
        <div className="mt-3 flex-row-left">
          {["신용카드", "무통장입금", "계좌이체", "삼성페이", "네이버페이"].map(
            (payItem) => (
              <button
                key={payItem}
                className={
                  currentOrder.paymentType === payItem
                    ? "ml-1 w-22 h-12  font-mont fontsize-12 tracking-tight  bg-black text-white border-black border-1"
                    : "ml-1 w-22 h-12  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1"
                }
                onClick={() => handleClickPayType(payItem)}
              >
                {payItem}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const OrderDomesticConfirmed = ({
  currentItem,
  currentOrder,
  setCurrentOrder,
}) => {
  const { t } = useTranslation();
  console.log(
    "OrderDomesticConfirmed, currentItem :",
    currentItem,
    currentOrder
  );

  return (
    <div className="pr-48 w-full h-full ">
      <div>
        <div className="w-full blackline2" />
        <div className="mt-3 order-complete-title">
          {t("주문이 완료 되었습니다")}
        </div>
        <div className="mt-3 w-full flex-col-left  font-mont fontsize-12 tracking-tight ">
          <div className=" ">
            {t("주문 번호")} | {currentOrder.id}
          </div>
          <div className=" ">
            {t("결제 금액")} | `{currentOrder.currentcy}{" "}
            {currentOrder.productTotal}`
          </div>
          <div className=" ">
            {t("결제 방식")} | {currentOrder.paymentType}
          </div>
          <div className="mt-3 font-bold ">
            {t("상품이 제작중 단계일 때는")}
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderOverseaContent = ({
  currentItem,
  currentOrder,
  setCurrentOrder,
}) => {
  const { t } = useTranslation();
  const setting = useSettings();

  console.log("OrderOverseaContent, currentItem :", currentItem, currentOrder);

  const handleClickPayType = (paymentType) => {
    setCurrentOrder({ ...currentOrder, paymentType });
  };
  return (
    <div className="pr-48 w-full h-full ">
      <div>
        <div className="w-full blackline2" />
        <div className="mt-3 font-mont-bold fontsize-15 tracking-tight">
          {t("주문자 정보")}
        </div>
        <div className="mt-2 flex-row-left">
          <div className="w-22 ">{t("이메일")}</div>
          <div className="w-40 ">{setting.email}</div>
          <button className="w-24 h-8  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1">
            {t("이메일변경")}
          </button>
        </div>
        <div className="mt-2 flex-row-left">
          <div className="w-22 ">{t("연락처")}</div>
          <input
            type="text"
            className="w-64 h-8 px-2 outline-none border-gray border-1"
            value={setting.phone}
            readOnly
          />
        </div>
      </div>
      <div>
        <div className="mt-3 w-full blackline2" />
        <div className="mt-3 font-mont-bold fontsize-15 tracking-tight">
          {t("배송 정보")}
        </div>

        <div className="mt-3 relative">
          <div className="w-full">
            <Tabs>
              <TabList>
                <Tab>{t("기본 배송지")}</Tab>
                <Tab>{t("새로 입력")}</Tab>
              </TabList>
              <TabPanel>
                <AddressOverseas
                  currentItem={currentItem}
                  currentOrder={currentOrder}
                  setCurrentOrder={setCurrentOrder}
                />
              </TabPanel>
              <TabPanel>
                <AddressOverseas
                  currentItem={currentItem}
                  currentOrder={currentOrder}
                  setCurrentOrder={setCurrentOrder}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      {/* 결제 수단 */}
      <div>
        <div className="mt-4 w-full blackline2" />
        <div className="mt-3 font-mont-bold fontsize-15 tracking-tight">
          {t("결제 수단")}
        </div>
        <div className="mt-3 flex-row-left">
          {["VISA", "MASTER CARD", "PAYPAL", "AMERICAN EXPRESS", "ALIPAY"].map(
            (payItem) => (
              <button
                className={
                  currentOrder.paymentType === payItem
                    ? "ml-1 w-22 h-12  font-mont fontsize-12 tracking-tight  bg-black text-white border-black border-1"
                    : "ml-1 w-22 h-12  font-mont fontsize-12 tracking-tight  bg-white text-black border-black border-1"
                }
                onClick={() => handleClickPayType(payItem)}
              >
                {payItem}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const OrderOverseaConfirmed = ({
  currentItem,
  currentOrder,
  setCurrentOrder,
}) => {
  const { t } = useTranslation();
  console.log(
    "OrderOutseasConfirmed, currentItem :",
    currentItem,
    currentOrder
  );

  return (
    <div className="pr-48 w-full h-full ">
      <div>
        <div className="w-full blackline2" />
        <div className="mt-3 order-complete-title">
          {t("주문이 완료 되었습니다")}
        </div>
        <div className="mt-3 w-full flex-col-left  font-mont fontsize-12 tracking-tight ">
          <div className=" ">
            {t("주문 번호")} | {currentOrder.id}
          </div>
          <div className=" ">
            {t("결제 금액")} | `{currentOrder.currentcy}{" "}
            {currentOrder.productTotal}`
          </div>
          <div className=" ">
            {t("결제 방식")} | {currentOrder.paymentType}
          </div>
          <div className="mt-3 font-bold ">
            {t("상품이 제작중 단계일 때는")}
          </div>
        </div>
      </div>
    </div>
  );
};

const Order = ({ match }) => {
  const setting = useSettings();
  const { id } = match.params; // product id
  console.log("Order id: ", id);

  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  const [currentItem, setCurrentItem] = useState(null);
  const [currentOrder, setCurrentOrder] = useState({
    memberId: setting.id,
    status: "주문완료",
    process1: "",
    process2: "",
    process3: "",
    process4: "",
    process5: "",
    processMemo: "",
    productId: id,
    name: setting.userName,
    phone: setting.phone,
    email: setting.email,
    receiveType: "",
    receiverName: setting.userName,
    receiverPhone1: setting.phone,
    receiverPhone2: "",
    receiverZip: "",
    receiverAddr1: "",
    receiverAddr2: "",
    city: "",
    state: "",
    country: "",
    memo: "",
    paymentType: "",

    productSize: "L(95)",
    productCount: "1",
    productPrice: "",
    productTotal: "",
    currency: "",
    invoiceNumber: "",
    invoiceCompany: "",
    invoicePhone: "",

    reviewStar: "4",
    reviewImage: "",
    reviewVideo: "",
    reviewText: "",
  });

  const { loading, error, data } = useQuery(PRODUCT, {
    variables: { id },
    skip: !!currentItem,
  });
  if (loading) return <p />;

  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  console.log("data : ", data);

  if (!currentItem && (!data || !data.product)) return <p>Error</p>;
  if (!currentItem && data && data.product) {
    console.log(data.product);
    setCurrentItem(data.product);
    setCurrentOrder({
      ...currentOrder,
      currency: setting.domestic ? "W" : "$",
      productPrice: setting.domestic
        ? data.product.price
        : data.product.priceDollar,
      productTotal: setting.domestic
        ? data.product.price
        : data.product.priceDollar,
    });
  }

  if (!currentItem) return <div />;

  return (
    <div>
      {setting.domestic ? (
        <div>
          <OrdersDomestic
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            currentOrder={currentOrder}
            setCurrentOrder={setCurrentOrder}
          />
        </div>
      ) : (
        <div>
          <OrdersOverseas
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            currentOrder={currentOrder}
            setCurrentOrder={setCurrentOrder}
          />
        </div>
      )}
    </div>
  );
};

export default Order;
