import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { ALL_REVIEW, DELETE_REVIEW } from "graphql/gql/select";
import { useQuery, useMutation } from "@apollo/client";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import useSettings from "stores/settings";
import { SRLWrapper } from "simple-react-lightbox";
import MyPage from "./MyPage";
import ReviewModalAdmin2 from "../../components/ReviewModalAmdin2";

const Reviews = () => {
  const { t } = useTranslation();
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewItem, setReviewItem] = useState("");
  const setting = useSettings();

  const [deleteReview, { data: deletedData }] = useMutation(DELETE_REVIEW, {
    onError(error) {
      console.log("deleteReview: err=", error);
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (deletedData !== undefined) {
      const data1 = deletedData.deleteReview;
      if (data1.error) {
        swal(data.error, { button: false, timer: 3000 });
      } else {
        swal(t("삭제가 잘 되었습니다"), { button: false, timer: 3000 });
      }
    }
  }, [deletedData]);

  const { loading, error, data, refetch } = useQuery(ALL_REVIEW);
  if (loading) return <p />;
  if (error) {
    swal(error.message || "Error", { button: false, timer: 3000 });
  }
  if (data) {
    console.log(data);
  }
  const items = data.allReview;
  if (setting.reviewUpdate) {
    refetch();
    setting.reviewUpdate = false;
  }

  return (
    <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
      <div className="content-title">전체 상품 리뷰</div>
      <div className="blackline2 mt-1" />
      <div className="flex-row-center text-medium-15">
        <div className="w-full flex-row-center table-header">
          <div className="w-2/12 text-center">{t("주문일")}</div>
          <div className="w-4/12 text-center">{t("이미지")}</div>
          <div className="w-4/12 text-center">{t("내용")}</div>
          <div className="w-2/12 text-center">{t("삭제")}</div>
        </div>
        <div className="blackline" />
        {items.map((item) => (
          <div
            className="mt-2 mb-2 w-full flex-row-center table-text"
            onClick={() => {
              setReviewItem(item);
              setReviewModal(true);
            }}
            key={item.id}
          >
            <div className="w-2/12 text-center">
              <Moment unix format="YYYY.MM.DD">
                {Number(item.createdAt) / 1000}
              </Moment>
            </div>
            <div className="w-4/12">
              <div className="mx-auto">
                <img
                  className="mx-auto w-12 h-12 border-black border"
                  src={item.mainImg}
                  alt=""
                />
              </div>
            </div>
            <div className="w-4/12">
              <div className="mx-auto text-center">
                <p>{item.comment}</p>
              </div>
            </div>
            <div className="w-2/12 flex-center">
              <div
                onClick={() => {
                  deleteReview({
                    variables: {
                      id: item.id,
                    },
                  });
                  setting.reviewUpdate = true;
                }}
              >
                삭제
              </div>
            </div>
            <div className="mt-2 grayline" />
          </div>
        ))}
        {items.length === 0 ? (
          <div className="w-full mt-8 font-mont-bold fontsize-15 tracking-tight text-center">
            {t("내역이 없습니다")}
          </div>
        ) : (
          ""
        )}
      </div>
      <ReviewModalAdmin2
        modalIsOpen={reviewModal}
        closeModal={() => setReviewModal(false)}
        reviewItem={reviewItem}
      />
    </div>
  );
};

const MyReviews = () => {
  // const setting = useSettings();
  // const history = useHistory();
  // 로그인 안한 경우
  // if (!setting.isLogin) history.push("/mypage/signin");
  return (
    <div>
      <MyPage>
        <SRLWrapper>
          <Reviews />
        </SRLWrapper>
      </MyPage>
    </div>
  );
};

export default MyReviews;
