import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";
import { useTranslation } from "react-i18next";
import { SRLWrapper } from "simple-react-lightbox";
import Select from "react-select";
import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { useMutation } from "@apollo/client";
import ReactPlayer from "react-player";
import MyPage from "../MyPage";
import { CREATE_TEMPLATE, UPLOAD_FILE } from "../../../graphql/gql/select";
import CheckBox from "../../../components/CheckBox";
import SingleImage from "../../../components/SingleImage";

const ScreenAdmin = observer(() => {
  const { t } = useTranslation();
  const setting = useSettings();
  const history = useHistory();

  const [currentItem, setCurrentItem] = useState({
    category: "",
    img: "",
    title: "",
    descKo: "",
    descEn: "",
    visible: true,
  });
  const [saveAndStay, setSaveAndStay] = useState(false);

  const [createTemplateImage, { data: createdData }] = useMutation(
    CREATE_TEMPLATE,
    {
      onError(error) {
        swal(error.message || "Error", { button: false, timer: 3000 });
      },
    }
  );

  const [singleUpload, { data: singleData }] = useMutation(UPLOAD_FILE, {
    onError(error) {
      swal(error.message || "Error", { button: false, timer: 3000 });
    },
  });

  useEffect(() => {
    if (singleData !== undefined) {
      const data1 = singleData.singleUpload;
      if (data1.length <= 0) {
        swal(t("업로드 실패하였습니다"), { button: false, timer: 3000 });
      } else {
        setCurrentItem({ ...currentItem, img: data1.filename });
      }
    }
  }, [singleData]);

  useEffect(() => {
    if (createdData !== undefined) {
      console.log("createdData : ", createdData.createTemplate);
      const data1 = createdData.createTemplate;
      if (data1.error) {
        swal(data1.error, { button: false, timer: 3000 });
      } else {
        swal(t("잘 저장되었습니다"), { button: false, timer: 3000 });
        setting.cmsTemplateImageUpdates = true;
        if (saveAndStay) {
          setCurrentItem({
            category: "template image",
            img: "",
            title: "",
            descKo: "",
            descEn: "",
            visible: true,
          });
        }
      }
    }
  }, [createdData]);

  const createTemplateImageHandler = ({
    category,
    img,
    title,
    descKo,
    descEn,
    visible,
  }) => {
    createTemplateImage({
      variables: {
        category,
        img,
        title,
        descKo,
        descEn,
        visible,
      },
    });
  };
  return (
    <div>
      <div className="max-w-5xl mt-6 md:mt-0 px-2 md:px-0">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <div className="content-title">{`TEMPLATE 등록하기`}</div>
          </div>
          <div className="blackline2 mt-1" />
          <div className="flex-row-center text-medium-15">
            <div className="w-full bg-white">
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">
                  <SRLWrapper>
                    <SingleImage image={currentItem.img} />
                  </SRLWrapper>
                </div>
                <div className="ml-12 flex-row-left">
                  <div className="w-12 font-bold">첨부파일</div>
                  <div className="mt-3 h-8 flex flex-row table-text relative">
                    <form onSubmit={() => {}} encType="multipart/form-data">
                      <input
                        name="document"
                        multiple
                        type="file"
                        accept="image"
                        method="POST"
                        onChange={async ({ target: { files } }) => {
                          const file = files[0];
                          if (file) singleUpload({ variables: { file } });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="mt-3 flex-row-left">
                  <div className="w-20 font-bold">제목</div>
                  <input
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, title: e.target.value })
                    }
                    type="text"
                    className="w-80 px-2 outline-none"
                    placeholder="제목"
                    value={currentItem.title}
                  />
                </div>
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">설명</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, descEn: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="설명"
                  value={currentItem.descEn}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">번역</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, descKo: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="번역"
                  value={currentItem.descKo}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">카테고리</div>
                <input
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, category: e.target.value })
                  }
                  type="text"
                  className="w-80 px-2 outline-none"
                  placeholder="카테고리"
                  value={currentItem.category}
                />
              </div>
              <div className="mt-3 flex-row-left">
                <div className="w-20 font-bold">보이기</div>
                <CheckBox
                  isCheck={currentItem.visible}
                  onClick={() =>
                    setCurrentItem({
                      ...currentItem,
                      visible: !currentItem.visible,
                    })
                  }
                />
              </div>
              <div className="mt-4 flex-col-left">
                <div className="mt-3 h-8 flex flex-row table-text relative">
                  <div className="absolute right-0 w-96 h-8 ml-4 flex flex-row">
                    <button className="w-32 h-8 ml-1 table-text bg-gray-600 text-white font-bold mt-0 py-2 px-4">
                      취소
                    </button>
                    <button
                      onClick={() => {
                        setSaveAndStay(false);
                        createTemplateImageHandler(currentItem);
                      }}
                      className="w-32 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                    >
                      등록하기
                    </button>
                    <button
                      className="w-48 h-8 ml-1 table-text bg-black text-white font-bold mt-0 py-2 px-4"
                      onClick={() => {
                        setSaveAndStay(true);
                        createTemplateImageHandler(currentItem);
                      }}
                    >
                      등록하고 계속 추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const NewTemplateImage = () => {
  const setting = useSettings();
  const screen =
    setting.isAdminScreenMode && setting.isAdmin && setting.isLogin ? (
      <ScreenAdmin />
    ) : (
      ""
    );
  return (
    <div>
      <MyPage>
        <div className="">{screen}</div>
      </MyPage>
    </div>
  );
};

export default NewTemplateImage;
